import React from 'react'
import 'twin.macro'
import { SourceInfo } from './SourceInfo'

/** @jsxImportSource @emotion/react */

//TODO: pass currnet info

interface ICurrentStatusProps {
  meterState: number
  voltage: number
  current: number
  frequency: number
}

export const CurrentStatus = (props: ICurrentStatusProps) => {
  const { current, frequency, meterState, voltage } = props
  return (
    <div tw='flex-grow p-6'>
      <SourceInfo label='Meter State' value={meterState} unit={'kWh'} />
      <SourceInfo label='Voltage' value={voltage} unit={'V'} />
      <SourceInfo label='Current' value={current} unit={'A'} />
      <SourceInfo label='Frequency' value={frequency} unit={'Hz'} />
    </div>
  )
}
