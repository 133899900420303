import 'twin.macro'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { useEffect } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import tw from 'twin.macro'
import { Txt } from '../Txt'

/** @jsxImportSource @emotion/react */

const RangeInputField = (props: {
  value: number
  onChange: (value: number) => void
}) => {
  const { value, onChange } = props
  return (
    <input
      type={'number'}
      value={value}
      // @ts-ignore
      onChange={(value) => onChange(value)}
      css={[
        tw`w-4/12 my-1 px-2.5 py-[0.675rem] border rounded-md focus:outline-none focus:ring-primary-700 focus:bg-white focus:z-10`,
        tw`font-sans font-normal text-sm text-gray-900`,
      ]}
    ></input>
  )
}

export const RangeInput = (props: {
  lowerFieldName: string
  upperFieldName: string
  label: string
  lowerLimit: number
  upperLimit: number
  valueLabel?: string
  className?: string
}): JSX.Element => {
  const {
    lowerFieldName,
    upperFieldName,
    label,
    lowerLimit,
    upperLimit,
    className,
    valueLabel = '',
  } = props

  const { control } = useFormContext()

  const fieldLower = useController({
    name: lowerFieldName,
    control,
  })
  const fieldUpper = useController({
    name: upperFieldName,
    control,
  })

  const onChange = (value: number[]) => {
    fieldUpper.field.onChange(value[1])
    fieldLower.field.onChange(value[0])
  }

  useEffect(() => {
    if (!fieldLower.field.value || !fieldUpper.field.value) {
      fieldLower.field.onChange(lowerLimit)
      fieldUpper.field.onChange(upperLimit)
    }
  }, [])

  return (
    <div className={className}>
      <label tw='font-sans font-medium text-sm text-gray-700'>{label}</label>
      <div tw='flex flex-col'>
        <div tw='w-full flex items-center justify-between'>
          <RangeInputField
            value={fieldLower.field.value}
            onChange={(value) => fieldLower.field.onChange(value)}
          />
          <RangeInputField
            value={fieldUpper.field.value}
            onChange={(value) => fieldUpper.field.onChange(value)}
          />
        </div>
        <div tw='w-full pl-2 pr-1.5 mt-3'>
          <Slider
            tw='w-full'
            range
            min={lowerLimit}
            max={upperLimit}
            defaultValue={[lowerLimit, upperLimit]}
            value={[fieldLower.field.value, fieldUpper.field.value]}
            onChange={(value) => onChange(value as number[])}
          />
          <div tw='flex w-full justify-between'>
            <Txt>
              {lowerLimit} {valueLabel}
            </Txt>
            <Txt>
              {upperLimit} {valueLabel}
            </Txt>
          </div>
        </div>
      </div>
      <div tw='h-6'></div>
    </div>
  )
}
