import 'twin.macro'

import { IDropdownOption } from '../../../../types'
import { SimpleAutocompleteField } from './SimpleAutocompleteField'
import { http } from '../../../../http'

/** @jsxImportSource @emotion/react */

interface ISimpleLocationAutocompleteFieldProps {
  name: string
  placeholder: string
  className?: string
  styles: any
}

export const SimpleLocationAutocompleteField = (
  props: ISimpleLocationAutocompleteFieldProps,
) => {
  return (
    <SimpleAutocompleteField
      name={props.name}
      placeholder={props.placeholder}
      onChange={async (term: string) => {
        const { items } = await http.searchLocations({
          order: 'ASC',
          orderField: 'name',
          page: 1,
          term,
          size: 10,
        })
        return items.map<IDropdownOption>((item) => ({
          label: item.name,
          value: item.id,
        }))
      }}
      className={props.className}
      styles={props.styles}
    />
  )
}
