import { useController, useFormContext } from 'react-hook-form'

import { ExclamationCircleIcon } from '@heroicons/react/solid'
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'

interface ISimpleInputField {
  name: string
  placeholder: string
  type?: string
  rightText?: string | undefined
  className?: string | undefined
}

export const SimpleInputField = (props: ISimpleInputField) => {
  const { name, placeholder, type, rightText } = props
  const { control } = useFormContext()
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  return (
    <div tw='relative w-full'>
      {error ? (
        <ExclamationCircleIcon
          tw='h-5 w-5 absolute right-3 top-2.5'
          color='#EF4444'
        />
      ) : rightText ? (
        <span tw='font-sans font-normal text-sm text-gray-500 absolute right-3 top-2.5'>
          {rightText}
        </span>
      ) : (
        <div />
      )}
      <input
        value={field.value ?? ''}
        type={type}
        onChange={({ target: { value } }) => field.onChange(value)}
        css={[
          tw`block w-full mb-0 px-2.5 py-2 border placeholder-gray-500 rounded-md focus:outline-none  focus:border-primary-500`,
          tw`font-sans font-normal text-sm text-gray-900`,
          error &&
            tw`font-sans font-normal text-sm text-red-900 focus:text-gray-900`,
        ]}
        className={props.className}
        placeholder={placeholder}
        onBlur={field.onBlur}
      />
    </div>
  )
}
