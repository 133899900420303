import 'twin.macro'
import { useParams } from 'react-router-dom'

import { useSuperAdminPedestal } from '../../../queries'

import { SuperadminPedestalConfiguration } from './SuperadminPedestalConfiguration'

/** @jsxImportSource @emotion/react */

export const SuperadminPedestalConfigurationPage = () => {
  const { identifier } = useParams<{ identifier: string }>()

  const { data: pedestal, isLoading: pedestalLoading } =
    useSuperAdminPedestal(identifier)

  if (!pedestal || pedestalLoading) {
    return null
  }

  return <SuperadminPedestalConfiguration pedestal={pedestal} />
}
