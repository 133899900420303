/** @jsxImportSource @emotion/react */
import 'twin.macro'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { CustomTable } from '../../common/general/table/CustomTable'
import { ISearchParams } from '../../../types'
import { TableCell } from '../../common/general/table/cells'
import { useSearch } from '../../common/general/hooks/useSearch'
import { useAdminAccountSessions } from '../../../queries'
import { Account, SourceTypeEnum, vehicleTypeLabels } from '../../../api-types'
import { adminRoutes } from '../../common/general/route-names'
import { sourceLabelMeta } from '../../common/general/utils'
import { PageLoader } from '../../common/general/PageLoader'
import {
  convertEURToHRK,
  roundToFloatWithExactlyNDecimals,
  standardizedFormatDate,
} from '../../../utils'

export const CustomerSessionList = ({ customer }: { customer: Account }) => {
  const initialSearchParams: Partial<ISearchParams> = {
    orderField: 'createdAt',
    order: 'DESC',
  }
  const [searchParams, { setPage }] = useSearch(initialSearchParams)
  const { data, isLoading } = useAdminAccountSessions(
    customer.id || -1,
    searchParams,
  )

  if (isLoading || !data) {
    return <PageLoader />
  }
  const history = useHistory()

  return (
    <div tw='mt-4'>
      <CustomTable
        loading={isLoading}
        maxPage={data.totalPages}
        currentPage={searchParams.page}
        setPage={setPage}
        pageSize={searchParams.size}
        data={data.items}
        totalCount={data.count}
        onRowClick={(e: any) => {
          const identifier = e?.source?.pedestal?.identifier || 'n-a'
          history.push(
            adminRoutes.SESSION_DETAILS.replace(
              ':identifier',
              identifier,
            ).replace(':sessionId', Number(e.id).toString()),
          )
        }}
        columns={[
          {
            Header: 'Pedestal',
            accessor: 'source',
            cell: (l) => {
              return (
                <>
                  <TableCell.Text
                    value={`${l.source?.pedestal?.identifier.toUpperCase()}`}
                  />
                  <TableCell.Text
                    light
                    value={`${sourceLabelMeta[l.source?.type]} ${
                      l.source?.position
                    }`}
                  />
                </>
              )
            },
          },
          {
            Header: 'Vehicle',
            accessor: 'vehicle',
            cell: (l) => (
              <>
                <TableCell.Text value={`${l.vehicle?.registration}`} />
                <TableCell.Text
                  light
                  value={`(${vehicleTypeLabels[l.vehicle?.type]})`}
                />
              </>
            ),
          },
          {
            Header: 'Consumption',
            accessor: 'initialMeterState',
            cell: (l) => (
              <TableCell.Text
                value={`${roundToFloatWithExactlyNDecimals(
                  l.endedAt && l.finalMeterState
                    ? l.finalMeterState - l.initialMeterState
                    : l.source.state.value - l.initialMeterState,
                  3,
                )} ${
                  l.source?.type === SourceTypeEnum.SOCKET ? 'kWh' : 'm\u00B3'
                }`}
              />
            ),
          },
          {
            Header: 'Start date',
            accessor: 'createdAt',
            cell: (l) => (
              <div tw='flex flex-col'>
                <TableCell.Text value={standardizedFormatDate(l.createdAt)} />
              </div>
            ),
          },
          {
            Header: 'Total',
            accessor: 'tariff',
            cell: (l) => {
              const state = l.finalMeterState
                ? l.finalMeterState - l.initialMeterState
                : l.source.state.value - l.initialMeterState

              const total = state * l.tariff

              const roundedTotal = roundToFloatWithExactlyNDecimals(total, 2)

              return (
                <TableCell.Text
                  value={`${roundedTotal} EUR (${convertEURToHRK(
                    parseFloat(roundedTotal),
                  )} HRK)`}
                />
              )
            },
            // cell: (l) => (
            //   <TableCell.Text
            //     value={`${roundToFloatWithExactlyNDecimals(
            //       (l.finalMeterState
            //         ? l.finalMeterState - l.initialMeterState
            //         : l.source.state.value - l.initialMeterState) * l.tariff, 2
            //     )} HRK`}
            //   />
            // ),
          },
          {
            Header: 'End date',
            accessor: 'endedAt',
            cell: (l) => (
              <div tw='flex flex-col'>
                <TableCell.Text
                  value={l.endedAt ? standardizedFormatDate(l.endedAt) : '-'}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  )
}
