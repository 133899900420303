import React from 'react'
import 'twin.macro'
import { css } from '@emotion/react'
import tw from 'twin.macro'

/** @jsxImportSource @emotion/react */

interface IProgressBarProps {
  percentage: number
}

export const ProgressBar = (props: IProgressBarProps) => {
  return (
    <div tw='h-2 w-full bg-gray-100'>
      <div css={progressBarStyle(props.percentage)}></div>
    </div>
  )
}

const progressBarStyle = (width: number) => css`
  width: ${width}%;
  transition: 1s linear;
  ${width >= 100 ? tw`bg-green-400 h-2` : tw`bg-primary-600 h-2`}
`
