/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ActionBar } from '../../common/general/ActionBar'
import { Button } from '../../common/general/Button'
import { ContentContainer } from '../../common/general/ContentContainer'
import { CustomTable } from '../../common/general/table/CustomTable'
import { FilterBar } from '../../common/general/FilterBar'
import { ISearchParams } from '../../../types'
import { PlusIcon } from '@heroicons/react/solid'
import { TableCell } from '../../common/general/table/cells'
import { pedestalStatusMeta } from '../../common/general/utils'
import { useHistory } from 'react-router-dom'
import { usePedestals } from '../../../queries'
import { useSearch } from '../../common/general/hooks/useSearch'
import { Pedestal, PedestalStatus } from '../../../api-types'
import { InputField } from '../../common/general/fields/InputField'
import { SelectField } from '../../common/general/fields/SelectField'
import { useState } from 'react'
import { Txt } from '../../common/general/Txt'
import { superadminRoutes } from '../../common/general/route-names'
import { DownloadIcon } from '@heroicons/react/solid'
import { pedestalStickerService } from '../../common/general/PedestalStickerService'
import { PageLoader } from '../../common/general/PageLoader'

export const SuperadminPedestalsPage = () => {
  const initialSearchParams: Partial<ISearchParams> = {
    term: '',
    orderField: 'id',
    order: 'ASC',
    size: 20,
  }

  const initialFilters = {
    pedestalTypeName: '',
    locationName: '',
    status: undefined,
  }

  const [filters, setFilters] = useState(initialFilters)

  const [searchParams, { setOrder, setOrderField, setPage, setTerm }] =
    useSearch(initialSearchParams)

  const { data, isLoading } = usePedestals({ ...searchParams, ...filters })

  const history = useHistory()

  const viewPedestal = (identifier: string) => {
    history.push(`${superadminRoutes.VIEW_PEDESTAL}/${identifier}`)
  }

  const configurePedestal = (identifier: string) => {
    history.push(`${superadminRoutes.PEDESTALS_CONFIGURATION}/${identifier}`)
  }

  const testPedestal = (identifier: string) => {
    history.push(`${superadminRoutes.PEDESTALS_TESTING}/${identifier}`)
  }

  const installPedestal = (identifier: string) => {
    history.push(`${superadminRoutes.PEDESTALS_INSTALLATION}/${identifier}`)
  }

  const pedestalActions = {
    [PedestalStatus.ACTIVE]: {
      handler: viewPedestal,
      label: 'View',
    },
    [PedestalStatus.INACTIVE]: {
      handler: viewPedestal,
      label: 'View',
    },
    [PedestalStatus.OUT_OF_SERVICE]: {
      handler: viewPedestal,
      label: 'View',
    },
    [PedestalStatus.READY_TO_CONFIGURE]: {
      handler: configurePedestal,
      label: 'Configure',
    },
    [PedestalStatus.READY_TO_INSTALL]: {
      handler: installPedestal,
      label: 'View',
    },
    [PedestalStatus.READY_TO_TEST]: {
      handler: testPedestal,
      label: 'Test',
    },
  }

  const handleDownloadQRCode = async () => {
    await pedestalStickerService.downloadMultiple(data.items)
  }

  const handleRouteToPedestal = (pedestal: Pedestal, action: any) => {
    action.handler(pedestal.identifier)
  }

  return (
    <ContentContainer>
      <ActionBar title='Pedestals' tw='mb-5'>
        <Button
          primary
          icon={PlusIcon}
          text='Add New'
          onClick={() => history.push(superadminRoutes.PEDESTALS_CREATION)}
        />
      </ActionBar>
      <FilterBar tw='mb-4'>
        <FilterBar.Select
          value={{
            order: searchParams.order,
            orderField: searchParams.orderField,
          }}
          options={[
            {
              label: 'ID (A-Z)',
              value: { order: 'ASC', orderField: 'identifier' },
            },
            {
              label: 'ID (Z-A)',
              value: { order: 'DESC', orderField: 'identifier' },
            },
            {
              label: 'Type (A-Z)',
              value: { order: 'ASC', orderField: 'pedestalType' },
            },
            {
              label: 'Type (Z-A)',
              value: { order: 'DESC', orderField: 'pedestalType' },
            },
            {
              label: 'Location (A-Z)',
              value: { order: 'ASC', orderField: 'location' },
            },
            {
              label: 'Location (Z-A)',
              value: { order: 'DESC', orderField: 'location' },
            },
            {
              label: 'Status (A-Z)',
              value: { order: 'ASC', orderField: 'status' },
            },
            {
              label: 'Status (Z-A)',
              value: { order: 'DESC', orderField: 'status' },
            },
          ]}
          setValue={(option) => {
            setOrder(option.value.order)
            setOrderField(option.value.orderField)
          }}
        />
        <FilterBar.Filter
          onSubmit={setFilters}
          values={filters}
          initialValues={initialFilters}
        >
          <InputField name='locationName' label='Location' />
          <InputField name='pedestalTypeName' label='Type' />
          <SelectField
            name='status'
            label='Status'
            options={[
              { label: 'Active', value: PedestalStatus.ACTIVE },
              { label: 'Inactive', value: PedestalStatus.INACTIVE },
              { label: 'Out of service', value: PedestalStatus.OUT_OF_SERVICE },
              {
                label: 'Ready to configure',
                value: PedestalStatus.READY_TO_CONFIGURE,
              },
              {
                label: 'Ready to install',
                value: PedestalStatus.READY_TO_INSTALL,
              },
              { label: 'Ready to test', value: PedestalStatus.READY_TO_TEST },
            ]}
          />
        </FilterBar.Filter>
        <FilterBar.TermSearch setValue={setTerm} placeholder='Search...' />
        <Button
          onClick={handleDownloadQRCode}
          text='Download all QRs'
          icon={DownloadIcon}
          cancel
        />
      </FilterBar>
      {isLoading || !data ? (
        <PageLoader />
      ) : (
        <CustomTable
          loading={isLoading}
          maxPage={data.totalPages}
          currentPage={searchParams.page}
          setPage={setPage}
          pageSize={searchParams.size}
          data={data.items}
          totalCount={data.count}
          onRowClick={(l) => {
            const action = pedestalActions[l.status]
            handleRouteToPedestal(l, action)
          }}
          columns={[
            {
              Header: 'ID',
              accessor: 'identifier',
              cell: (l) => (
                <TableCell.Text value={l.identifier.toUpperCase()} />
              ),
            },
            {
              Header: 'Type',
              accessor: 'pedestalType',
              cell: (l) => (
                <TableCell.Text value={l.pedestalType?.name || ''} />
              ),
            },
            {
              Header: 'Location',
              accessor: 'location',
              cell: (l) => <TableCell.Text value={l.location.name} />,
            },
            {
              Header: 'Status',
              accessor: 'status',
              cell: (l) => {
                const meta = pedestalStatusMeta[l.status]
                return (
                  <TableCell.Chip value={meta.value} {...meta.colorProps} />
                )
              },
            },
            {
              Header: '',
              //@ts-ignore
              accessor: 'action',
              cell: (l) => {
                const action = pedestalActions[l.status]
                return (
                  <a onClick={() => handleRouteToPedestal(l, action)}>
                    <Txt tw='text-primary-600'>{action.label} </Txt>
                  </a>
                )
              },
            },
          ]}
        />
      )}
    </ContentContainer>
  )
}
