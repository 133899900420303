import { ExclamationIcon, XIcon } from '@heroicons/react/solid'
import React from 'react'
import { Txt } from '../Txt'
import 'twin.macro'

/** @jsxImportSource @emotion/react */

interface ISuccessAlertProps {
  text: string
  onClose: () => void
}

export const WarningAlert = (props: ISuccessAlertProps) => {
  const { onClose, text } = props
  return (
    <div tw='p-4 rounded-md bg-yellow-50 mt-6 flex items-center'>
      <ExclamationIcon tw='text-yellow-400 w-6 h-6 mr-3' />
      <Txt tw='text-yellow-800'>{text}</Txt>
      <XIcon
        tw='text-yellow-500 w-6 h-6 ml-auto cursor-pointer'
        onClick={onClose}
      />
    </div>
  )
}
