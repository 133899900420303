import 'twin.macro'

import React, { useState, useEffect } from 'react'
import { PedestalStatuses } from '../../common/pedestal-view/pedestal-statuses/PedestalStatuses'
import { Pedestal, PedestalStatus } from '../../../api-types'
import { ContentContainer } from '../../common/general/ContentContainer'
import {
  LocationMarkerIcon,
  TemplateIcon,
  CheckCircleIcon,
  CheckIcon,
  ArrowUpIcon,
  PencilIcon,
} from '@heroicons/react/solid'
import { heartbeatService } from '../../common/general/heartbeat-service'
import { Breadcrumbs } from '../../common/general/Breadcrumbs'
import { Button } from '../../common/general/Button'
import { superadminRoutes } from '../../common/general/route-names'
import { Stepper } from '../../common/general/Stepper'
import { Txt } from '../../common/general/Txt'
import { DownloadDropdown } from '../../common/pedestal-view/DownloadDropdown'
import { HeartbeatBadge } from '../../common/pedestal-view/HeartbeatBadge'
import { PedestalPublishedModal } from './SuperadminPedestalPublishedModal'
import { http } from '../../../http'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../common/general/notification'
import { pedestalStickerService } from '../../common/general/PedestalStickerService'
import { useHistory } from 'react-router-dom'
//@ts-ignore
import FileSaver from 'file-saver' // todo: remove

/** @jsxImportSource @emotion/react */

interface PedestalInstalationProps {
  pedestal: Pedestal
}

export const SuperadminPedestalInstallation = (
  props: PedestalInstalationProps,
) => {
  const { pedestal } = props
  const { location, plcConstants } = pedestal
  const [pedestalPublishedModalVisible, setPedestalPublishedModalVisible] =
    useState(false)
  const [heartbeat, setHeartbeat] = useState<boolean>(false)
  const history = useHistory()
  const handlePublishPedestal = () => {
    setPedestalPublishedModalVisible(true)
  }

  useEffect(() => {
    if (pedestal) {
      const heartbeat = heartbeatService.getHeartbeatStable(
        pedestal.lastHartbeatReceivedOn,
        plcConstants?.thart ?? location.plcConstants.thart,
      )
      setHeartbeat(heartbeat)
    }
  }, [pedestal])

  const handleUploadConfiguration = async () => {
    try {
      await http.uploadPedestalConfiguration(pedestal.id)
      showSuccessNotification('Configuration uploaded successfully.')
    } catch (e) {
      await showErrorNotification()
    }
  }

  const handleDownloadCertificate = () => {
    FileSaver.saveAs(
      'https://s3.eu-west-1.amazonaws.com/media.marex-hc.com/certificates/certificates.zip',
      'certificates.zip',
    )
  }

  const handleDownloadQRCode = async () => {
    await pedestalStickerService.download(pedestal)
  }

  const handleSeePedestal = async () => {
    await http.updatePedestal(pedestal.id, { status: PedestalStatus.ACTIVE })
    history.push(`${superadminRoutes.VIEW_PEDESTAL}/${pedestal.identifier}`)
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-4'
        links={[
          { name: 'Pedestals', route: superadminRoutes.PEDESTALS_INSTALLATION },
          { name: pedestal.identifier },
        ]}
      />

      <div tw='flex '>
        <div>
          <Txt xxl tw='mb-2'>
            {pedestal.identifier}
          </Txt>

          <div tw='flex flex-row mb-6 items-center'>
            <HeartbeatBadge heartbeatStable={heartbeat} />

            <LocationMarkerIcon tw='h-5 w-5 mr-2 text-gray-400' />
            <Txt tw='mr-6 text-gray-500'>{pedestal.location.name}</Txt>
            {pedestal.pedestalType && (
              <>
                <TemplateIcon tw='h-5 w-5 mr-2 text-gray-400' />
                <Txt tw='text-gray-500 mr-6'>{pedestal.pedestalType.name}</Txt>
              </>
            )}

            <CheckCircleIcon tw='h-5 w-5 mr-2 text-green-400' />
            <Txt tw='text-green-800'>Ready to publish</Txt>
          </div>
        </div>
        <div tw='flex items-center ml-auto'>
          <Button
            onClick={handlePublishPedestal}
            text='Publish'
            icon={CheckIcon}
            tw='mr-3'
            primary
          />
          <Button
            onClick={handleUploadConfiguration}
            tw='mr-3'
            text='Upload Configuration'
            icon={ArrowUpIcon}
            cancel
          />
          <Button
            onClick={() => {
              history.push(
                `${superadminRoutes.PEDESTALS_CONFIGURATION}/${pedestal.identifier}`,
              )
            }}
            tw='mr-3'
            text='Edit Configuration'
            icon={PencilIcon}
            cancel
          />
          <DownloadDropdown
            onDownloadQrClick={handleDownloadQRCode}
            onDownloadCertificatesClick={handleDownloadCertificate}
          />
        </div>
      </div>
      <Stepper
        steps={['Configuration', 'Test', 'Installation']}
        activeStep={2}
        tw='mb-10'
      />
      <PedestalStatuses pedestal={pedestal} />
      <PedestalPublishedModal
        isOpen={pedestalPublishedModalVisible}
        setIsOpen={setPedestalPublishedModalVisible}
        onConfirm={handleSeePedestal}
        pedestal={pedestal}
      />
    </ContentContainer>
  )
}
