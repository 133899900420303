/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Session, sourceLabels, SourceTypeEnum } from '../../../api-types'
import { useLocationSessions } from '../../../queries'
import { ISearchParams, useSearch } from '../../common/general/hooks/useSearch'
import { PageLoader } from '../../common/general/PageLoader'
import { FilterBar } from '../../common/general/FilterBar'
import { InputField } from '../../common/general/fields/InputField'
import { SelectField } from '../../common/general/fields/SelectField'
import { DateInput } from '../../common/general/fields/DateInput'
import { CustomTable } from '../../common/general/table/CustomTable'
import { TableCell } from '../../common/general/table/cells'
import { Txt } from '../../common/general/Txt'
import { ContentContainer } from '../../common/general/ContentContainer'
import { ActionBar } from '../../common/general/ActionBar'
import {
  convertEURToHRK,
  roundToFloatWithExactlyNDecimals,
  standardizedFormatDate,
} from '../../../utils'
import { Chip } from '../../common/general/Chip'
import { CheckboxField } from '../../common/general/fields/CheckboxField'

export const AdminSessionsPage = () => {
  const history = useHistory()

  const initialSearchParams: Partial<ISearchParams> = {
    orderField: 'session.createdAt',
    order: 'DESC',
    term: '',
  }

  const initialFilters = {
    user: '',
    source: '',
    consumption: '',
    startDate: '',
    total: '',
    isOpen: undefined,
    hideManual: false,
  }

  const [filters, setFilters] = useState(initialFilters)
  const [createdAtLimit, setCreatedAtLimit] = useState<[string, string]>([
    new Date().toISOString(),
    new Date().toISOString(),
  ])

  const [
    searchParams,
    { setPage, setSearchParams, setOrder, setOrderField, setTerm },
  ] = useSearch(initialSearchParams)
  const { data, isLoading, isError } = useLocationSessions({
    ...searchParams,
    ...filters,
  })

  const handleRouteToSessionDetails = (session: Session) =>
    history.push(
      `/pedestals/view/${session.source.pedestal.identifier}/session-details/${session.id}`,
    )

  //TODO: refactor - consumption will be removed
  useEffect(() => {
    if (!isLoading) {
      setCreatedAtLimit([
        data.limits.createdAt.lower,
        data.limits.createdAt.upper,
      ])
    }
  }, [data.items.length, isLoading])

  if (isError) {
    return <div>Something went wrong</div>
  }

  return (
    <ContentContainer>
      <ActionBar title='Sessions' tw='mb-5'></ActionBar>
      <FilterBar
        tw='my-4'
        onClearFilters={() => setSearchParams(initialSearchParams)}
      >
        <FilterBar.Select
          value={{
            order: searchParams.order,
            orderField: searchParams.orderField,
          }}
          setValue={(opt) => {
            setOrder(opt.value.order)
            setOrderField(opt.value.orderField)
          }}
          options={[
            {
              label: 'User (A-Z)',
              value: { order: 'ASC', orderField: 'user.name' },
            },
            {
              label: 'User (Z-A)',
              value: { order: 'DESC', orderField: 'user.name' },
            },
            {
              label: 'Vehicle (A-Z)',
              value: { order: 'ASC', orderField: 'vehicle.registration' },
            },
            {
              label: 'Vehicle (Z-A)',
              value: { order: 'DESC', orderField: 'vehicle.registration' },
            },
            {
              label: 'Service (A-Z)',
              value: { order: 'ASC', orderField: 'source.type' },
            },
            {
              label: 'Service (Z-A)',
              value: { order: 'DESC', orderField: 'source.type' },
            },
            {
              label: 'Start date (A-Z)',
              value: { order: 'ASC', orderField: 'session.createdAt' },
            },
            {
              label: 'Start date (Z-A)',
              value: { order: 'DESC', orderField: 'session.createdAt' },
            },
          ]}
        />
        <FilterBar.Filter
          onSubmit={setFilters}
          values={filters}
          initialValues={initialFilters}
        >
          <InputField name='user' label='User' />
          <InputField name='vehicle' label='Vehicle' />
          <SelectField
            name='source'
            label='Service'
            options={[
              { label: 'Socket', value: 'SOCKET' },
              { label: 'Valve', value: 'VALVE' },
            ]}
          />
          <CheckboxField name='hideManual' label='Hide manual sessions' />
          <DateInput
            startDateName='createdAtLower'
            endDateName='createdAtUpper'
            label='Date'
            initialStartValue={createdAtLimit[0]}
            initialEndValue={createdAtLimit[1]}
          />
          <SelectField
            name='isOpen'
            label='Session status'
            options={[
              { label: 'Open', value: true },
              { label: 'Closed', value: false },
            ]}
          />
        </FilterBar.Filter>
        <FilterBar.TermSearch setValue={setTerm} placeholder='Search...' />
      </FilterBar>
      {!data || isLoading ? (
        <PageLoader />
      ) : (
        <CustomTable
          loading={isLoading}
          maxPage={data.totalPages}
          currentPage={searchParams.page}
          setPage={setPage}
          pageSize={searchParams.size}
          data={data.items}
          totalCount={data.count}
          onRowClick={(l) => handleRouteToSessionDetails(l)}
          columns={[
            {
              Header: 'User',
              accessor: 'user',
              cell: (l) => (
                <div>
                  <TableCell.Text value={l.group.account.owner.name} />
                  <TableCell.Text light value={l.vehicle.registration} />
                </div>
              ),
            },
            {
              Header: 'Service',
              accessor: 'source',
              cell: (l) => {
                return (
                  <>
                    <TableCell.Text
                      light
                      value={l.source.pedestal.identifier.toUpperCase()}
                    />
                    <TableCell.Text
                      light
                      value={`${sourceLabels[l.source.type]} ${
                        l.source.position
                      }`}
                    />
                  </>
                )
              },
            },
            {
              Header: 'Consumption',
              accessor: 'initialMeterState',
              cell: (l) => (
                <TableCell.Text
                  light
                  value={`${roundToFloatWithExactlyNDecimals(
                    l.endedAt &&
                      l.finalMeterState !== null &&
                      l.finalMeterState !== undefined
                      ? l.finalMeterState - l.initialMeterState
                      : l.source.state.value - l.initialMeterState,
                    3,
                  )} ${
                    l.source.type === SourceTypeEnum.SOCKET ? 'kWh' : 'm\u00B3'
                  }`}
                />
              ),
            },
            {
              Header: 'Start date',
              accessor: 'createdAt',
              cell: (l) => (
                <div tw='flex flex-col'>
                  <TableCell.Text value={standardizedFormatDate(l.createdAt)} />
                </div>
              ),
            },
            {
              Header: 'End date',
              accessor: 'endedAt',
              cell: (l) => (
                <div tw='flex flex-col'>
                  <TableCell.Text
                    value={l.endedAt ? standardizedFormatDate(l.endedAt) : '-'}
                  />
                </div>
              ),
            },
            {
              Header: 'Total',
              accessor: 'tariff',
              cell: (l) => {
                const total = roundToFloatWithExactlyNDecimals(
                  (l.finalMeterState
                    ? l.finalMeterState - l.initialMeterState
                    : l.source.state.value - l.initialMeterState) * l.tariff,
                  2,
                )
                return (
                  <TableCell.Text
                    light
                    value={`${total} EUR (${convertEURToHRK(
                      parseFloat(total),
                    )} HRK)`}
                  />
                )
              },
            },
            {
              Header: 'Is Manual',
              accessor: 'isManual',
              cell: (l) => (
                <div tw='flex flex-col items-start'>
                  {l.isManual && <Chip orange value={'Manual Mode'} />}
                </div>
              ),
            },
            {
              Header: '',
              accessor: 'action' as keyof Session,
              cell: (l) => {
                return (
                  <a onClick={() => handleRouteToSessionDetails(l)}>
                    <Txt tw='text-primary-600'>View</Txt>
                  </a>
                )
              },
            },
          ]}
        />
      )}
    </ContentContainer>
  )
}
