/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { CheckIcon, FilterIcon, SelectorIcon } from '@heroicons/react/solid'
import { FormProvider, useForm, useController } from 'react-hook-form'
import React, { useCallback, useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import {
  areObjectEqualShallow,
  countDifferentValuesShallow,
} from '../../../utils'
import qs from 'qs'
import { Button } from './Button'
import { CustomDrawer } from './drawer/CustomDrawer'
import { Divider } from './Divider'
import { IDropdownOption } from '../../../types'
import { Txt } from './Txt'
import { css } from '@emotion/react'
import { useHistory, useLocation } from 'react-router-dom'

import tw from 'twin.macro'
import { XIcon } from '@heroicons/react/outline'
import { debounce } from 'debounce'

export interface IFilterBarProps {
  onClearFilters?(): void
  className?: string | undefined
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IFilterBarTermProps<T extends object> {
  placeholder?: string
  setValue(value: string): void
}

export interface IFilterBarSelectProps<T extends object> {
  placeholder?: string
  options: IDropdownOption[]
  value: T
  setValue(value: IDropdownOption): void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IFilterBarFilterProps<T extends object> {
  onSubmit(values: any): void

  values: any
  initialValues: any
}

const paramsMapping = {
  SORT: 'sort',
  FILTER: 'filter',
  SEARCH: 'search',
}

const FilterBar: React.FC<IFilterBarProps> & {
  Select: React.FC<IFilterBarSelectProps<any>>
  Filter: React.FC<IFilterBarFilterProps<any>>
  TermSearch: React.FC<IFilterBarTermProps<any>>
} = (props) => {
  const children = Array.isArray(props.children)
    ? props.children
    : [props.children]
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
      className={props.className}
    >
      {children.map((child, index) => {
        return (
          <div
            key={index}
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {index !== 0 && <div tw='mr-1' />}
            {child}
          </div>
        )
      })}
    </div>
  )
}

const CustomSearch = <T extends object>(props: IFilterBarTermProps<T>) => {
  const { setValue, placeholder } = props
  const history = useHistory()
  const location = useLocation()

  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    if (params[paramsMapping.SEARCH]) {
      // @ts-ignore
      const value = JSON.parse(params[paramsMapping.SEARCH])
      setValue(value)
      field.onChange(value)
    }
  }, [])

  const { control } = useForm()
  const { field } = useController({
    name: 'term',
    control,
  })

  const onSearchTermChange = useCallback(
    (e) => {
      field.onChange(e)
      debounceHandler(e)
    },
    [location],
  )

  const swapQuerySearchParam = ({ target: { value } }: any) => {
    params[paramsMapping.SEARCH] = JSON.stringify(value)
    history.replace({
      pathname: location.pathname,
      search: qs.stringify(params),
    })
    setValue(value)
  }

  const debounceHandler = debounce(swapQuerySearchParam, 500)

  const handleReset = () => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    delete params[paramsMapping.SEARCH]
    history.replace({
      pathname: location.pathname,
      search: qs.stringify(params),
    })
    setValue('')
    field.onChange('')
  }

  return (
    <div>
      <label tw='font-sans font-medium text-sm text-gray-700'></label>
      <div tw='relative'>
        <input
          name={'term'}
          value={field.value}
          defaultValue={field.value ?? ''}
          type={'text'}
          placeholder={placeholder ?? ''}
          onChange={onSearchTermChange}
          css={[
            tw`block w-full my-1 px-2.5 py-[0.675rem] border rounded-md focus:outline-none focus:ring-primary-700 focus:bg-white focus:z-10`,
            tw`font-sans font-normal text-sm text-gray-900`,
          ]}
          onBlur={field.onBlur}
          ref={field.ref}
          onFocus={(event) => {
            true && event.target.select()
          }}
        />
        {field.value && (
          <XIcon
            tw='h-5 w-5 absolute right-3 top-3 text-primary cursor-pointer'
            onClick={handleReset}
          />
        )}
      </div>
    </div>
  )
}

const CustomSelect = <T extends object>(props: IFilterBarSelectProps<T>) => {
  const { options, placeholder, value, setValue } = props
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
    if (params[paramsMapping.SORT]) {
      // @ts-ignore
      setValue(JSON.parse(params[paramsMapping.SORT]))
    }
  }, [])

  const selectedOption = options.find((o) =>
    areObjectEqualShallow(o.value, value),
  )
  return (
    <div>
      <Select
        isSearchable={false}
        value={selectedOption}
        isOptionSelected={(o) => areObjectEqualShallow(o.value, value)}
        // @ts-ignore
        onChange={(option) => {
          if (!option) {
            return
          }
          const params =
            qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
          params[paramsMapping.SORT] = JSON.stringify(option)
          history.replace({
            pathname: location.pathname,
            search: qs.stringify(params),
          })

          setValue(option)
        }}
        placeholder={placeholder || ''}
        options={options}
        formatOptionLabel={(o) => (
          <div tw='flex items-center'>
            <Txt sm tw='text-gray-700 flex-grow'>
              {o.label}
            </Txt>
            {areObjectEqualShallow(o.value, value) && (
              <CheckIcon tw='w-4 h-4 text-primary-600' />
            )}
          </div>
        )}
        styles={{
          control: (base) => ({
            ...base,
            border: '1.5px rgb(229, 231, 235) solid !important',
            borderRadius: '0.375rem !important',
            padding: '0.49rem 0.6rem !important',
            cursor: 'pointer',
            boxShadow: 'none',
          }),
          valueContainer: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          menuPortal: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            border: 'none',
            // border: '1.5px rgb(229, 231, 235) solid !important',
            borderRadius: '0.375rem !important',
          }),
          menuList: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            border: 'none',
            borderRadius: '0.375rem !important',
          }),
          menu: (base) => ({
            ...base,
            padding: 0,
            margin: '0.5rem 0rem 0rem 0rem',
            boxShadow: 'none',
            border: '1.5px rgb(229, 231, 235) solid !important',
            borderRadius: '0.375rem !important',
          }),

          option: (base) => ({
            ...base,
            backgroundColor: 'white',
            cursor: 'pointer',
            ':hover': {
              backgroundColor: '#E6F1F7',
            },
          }),
        }}
        components={{
          IndicatorSeparator: () => <div />,
          ValueContainer: ({ selectProps: { value }, children }) => {
            return (
              <div
                // @ts-ignore
                tw='flex flex-row align-items[center]'
              >
                <Txt sm tw='flex-grow mr-2'>
                  Sort by
                </Txt>
                <Txt md tw='text-gray-500 mr-5'>
                  {/* @ts-ignore */}
                  {value?.label ?? ''}
                </Txt>
                {React.Children.map(children, (child) =>
                  // @ts-ignore
                  child && [components.SingleValue].indexOf(child.type) === -1
                    ? child
                    : null,
                )}
              </div>
            )
          },
          DropdownIndicator: () => (
            <SelectorIcon tw='h-4 w-4' color='#9CA3AF' />
          ),
        }}
      />
    </div>
  )
}

const Filter = <T extends object>(props: IFilterBarFilterProps<T>) => {
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}
  let filterParams = {}
  try {
    // @ts-ignore
    filterParams = JSON.parse(params[paramsMapping.FILTER])
  } catch (e) {
    // ignore empty filter param
  }

  useEffect(() => {
    if (params[paramsMapping.FILTER]) {
      props.onSubmit(filterParams)
    }
  }, [])

  const methods = useForm<any>({
    defaultValues: {
      ...props.values,
      ...filterParams,
    },
    shouldUseNativeValidation: false,
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    methods.handleSubmit(async (data) => {
      const trimmed = {}
      for (const key in data) {
        const val = data[key]

        // @ts-ignore
        trimmed[key] = typeof val === 'string' ? val.trim() : val
      }
      setOpen(false)
      params[paramsMapping.FILTER] = JSON.stringify(trimmed)
      history.replace({
        pathname: location.pathname,
        search: qs.stringify(params),
      })

      props.onSubmit(trimmed)
    }),
    [],
  )
  const filtersUsed = countDifferentValuesShallow(
    props.values,
    props.initialValues,
  )
  const hasFilters = filtersUsed > 0
  return (
    <>
      <Button
        cancel={!hasFilters}
        light={hasFilters}
        text={`Filters${hasFilters ? ` (${filtersUsed})` : ''}`}
        icon={FilterIcon}
        onClick={() => setOpen(true)}
      />

      <CustomDrawer
        open={open}
        title='Filters'
        subtitle=''
        render={() => (
          <form onSubmit={onSubmit} noValidate tw='space-y-6 h-full'>
            <FormProvider {...methods}>
              <div tw='flex flex-col justify-between flex-grow h-full'>
                {/* @ts-ignore */}
                <div tw='m-6 h-full flex-grow'>{props.children}</div>
                <div>
                  <Divider type='horizontal' />
                  <div tw='flex flex-row justify-end m-4 space-x-3'>
                    <Button
                      text='Reset'
                      cancel
                      onClick={() => {
                        setOpen(false)
                        methods.reset(props.initialValues)
                        params[paramsMapping.FILTER] = JSON.stringify({})
                        history.replace({
                          pathname: location.pathname,
                          search: qs.stringify(params),
                        })

                        props.onSubmit(props.initialValues)
                      }}
                    />
                    <Button text='Save' type='submit' />
                  </div>
                </div>
              </div>
            </FormProvider>
          </form>
        )}
        close={() => setOpen(false)}
      />
    </>
  )
}

FilterBar.Select = CustomSelect
FilterBar.Filter = Filter
FilterBar.TermSearch = CustomSearch

export { FilterBar }
