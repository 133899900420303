import 'twin.macro'
import React, { useState, useEffect } from 'react'

import { Tabs } from '../../common/general/Tabs'
import { SuperadminPedestalTestingTab } from './SuperadminPedestalTestingTab'
import { PedestalStatuses } from '../../common/pedestal-view/pedestal-statuses/PedestalStatuses'
import { Pedestal } from '../../../api-types'
import { Stepper } from '../../common/general/Stepper'
import { ContentContainer } from '../../common/general/ContentContainer'
import { Breadcrumbs } from '../../common/general/Breadcrumbs'
import { superadminRoutes } from '../../common/general/route-names'
import { Txt } from '../../common/general/Txt'
import { HeartbeatBadge } from '../../common/pedestal-view/HeartbeatBadge'
import {
  ArrowUpIcon,
  LocationMarkerIcon,
  PencilIcon,
  TemplateIcon,
} from '@heroicons/react/solid'
import { heartbeatService } from '../../common/general/heartbeat-service'
import { DownloadDropdown } from '../../common/pedestal-view/DownloadDropdown'
import { Button } from '../../common/general/Button'
import { pedestalStickerService } from '../../common/general/PedestalStickerService'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../common/general/notification'
import { http } from '../../../http'
//@ts-ignore
import FileSaver from 'file-saver' // todo: remove
import { useHistory } from 'react-router-dom'
import { PedestalLogs } from '../../common/pedestal-view/logs/PedestalLogs'

/** @jsxImportSource @emotion/react */

interface IPedestalTestProps {
  pedestal: Pedestal
}

export const SuperadminPedestalTest = (props: IPedestalTestProps) => {
  const { pedestal } = props
  const { location, plcConstants } = pedestal
  const [isTesting, setIsTesting] = useState(false)
  const [heartbeat, setHeartbeat] = useState<boolean>(false)
  const history = useHistory()

  useEffect(() => {
    if (pedestal) {
      const heartbeat = heartbeatService.getHeartbeatStable(
        pedestal.lastHartbeatReceivedOn,
        plcConstants?.thart ?? location.plcConstants.thart,
      )
      setHeartbeat(heartbeat)
    }
  }, [pedestal])

  const handleDownloadQRCode = async () => {
    await pedestalStickerService.download(pedestal)
  }

  /**
   * This methods uploads the plc constants
   */
  const handleUploadConfiguration = async () => {
    try {
      await http.uploadPedestalConfiguration(pedestal.id)
      showSuccessNotification('Configuration uploaded successfully.')
    } catch (e) {
      await showErrorNotification()
    }
  }

  const handleDownloadCertificate = () => {
    FileSaver.saveAs(
      'https://s3.eu-west-1.amazonaws.com/media.marex-hc.com/certificates/certificates.zip',
      'certificates.zip',
    )
  }

  return (
    <>
      <ContentContainer>
        <Breadcrumbs
          tw='mb-4'
          links={[
            { name: 'Pedestals', route: superadminRoutes.PEDESTALS_TESTING },
            { name: pedestal.identifier },
          ]}
        />
        <div tw='flex '>
          <div>
            <Txt xxl tw='mb-2'>
              {pedestal.identifier}
            </Txt>
            <div tw='flex flex-row mb-6 items-center'>
              <HeartbeatBadge heartbeatStable={heartbeat} />

              <LocationMarkerIcon tw='h-5 w-5 mr-2 text-gray-400' />
              <Txt tw='mr-6 text-gray-500'>{pedestal.location.name}</Txt>
              {pedestal.pedestalType && (
                <>
                  <TemplateIcon tw='h-5 w-5 mr-2 text-gray-400' />
                  <Txt tw='text-gray-500 mr-6'>
                    {pedestal.pedestalType.name}
                  </Txt>
                </>
              )}
            </div>
          </div>
          <div tw='flex items-center ml-auto'>
            <Button
              onClick={handleUploadConfiguration}
              tw='mr-3'
              text='Upload Configuration'
              icon={ArrowUpIcon}
              cancel
            />
            <Button
              onClick={() => {
                history.push(
                  `${superadminRoutes.PEDESTALS_CONFIGURATION}/${pedestal.identifier}`,
                )
              }}
              tw='mr-3'
              text='Edit Configuration'
              icon={PencilIcon}
              cancel
            />
            <DownloadDropdown
              onDownloadQrClick={handleDownloadQRCode}
              onDownloadCertificatesClick={handleDownloadCertificate}
            />
          </div>
        </div>
        <Stepper
          steps={['Configuration', 'Test', 'Installation']}
          activeStep={1}
          tw='mb-10'
        />
        <Tabs
          items={[
            {
              title: 'Testing',
              content: (
                <SuperadminPedestalTestingTab
                  pedestal={pedestal}
                  isTesting={isTesting}
                  setIsTesting={setIsTesting}
                />
              ),
            },
            {
              title: 'Logs',
              content: <PedestalLogs pedestal={pedestal} />,
            },
            {
              title: 'Statuses',
              content: <PedestalStatuses pedestal={pedestal} />,
            },
          ]}
        />
      </ContentContainer>
    </>
  )
}
