import 'twin.macro'

import { ChevronRightIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import React from 'react'
import { Txt } from './Txt'

/** @jsxImportSource @emotion/react */

export type LinkType = {
  name: string
  route?: string
}

type IProps = {
  links: LinkType[]
  className?: string | undefined
}

export const Breadcrumbs = ({ links, className }: IProps) => {
  return (
    <div tw='flex flex-row justify-start items-center' className={className}>
      {links.map((l, i) => {
        const text = (
          <Txt md tw='text-gray-500'>
            {l.name}
          </Txt>
        )
        return (
          <React.Fragment key={l.name}>
            {l.route ? <Link to={l.route}>{text}</Link> : text}
            {i + 1 < links.length && (
              <div tw='px-5'>
                <ChevronRightIcon color='#9CA3AF' tw='h-5 w-5' />
              </div>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}
