import React, { useEffect, useState } from 'react'
import 'twin.macro'
import { Chip } from './Chip'
import { SourceTitle } from './SourceTitle'
import { Switch } from './Switch'
/** @jsxImportSource @emotion/react */

interface ISourceTitleWSwitchProps {
  title: string
  className?: string
  details: any[]
  onToggle(value: boolean): void
  status: boolean
  activated: boolean
  disabled?: boolean
}

export const SourceTitleWSwitch = (props: ISourceTitleWSwitchProps) => {
  const {
    title,
    details,
    onToggle,
    status,
    activated,
    disabled = false,
  } = props

  const [toggled, setToggled] = useState(activated)

  useEffect(() => {
    setToggled(activated)
  }, [activated])

  const handleSwitchToggle = () => {
    setToggled(!toggled)
    onToggle(toggled)
  }

  return (
    <div tw='flex flex-row space-x-2 justify-between items-center'>
      <div tw='flex flex-row flex-grow items-center'>
        <Switch
          value={toggled}
          onToggle={handleSwitchToggle}
          tw='mr-4'
          disabled={disabled}
        />
        <SourceTitle title={title} details={details} />
      </div>
      {status ? <Chip value='True' green /> : <Chip value='False' red />}
    </div>
  )
}
