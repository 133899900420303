import React from 'react'
import 'twin.macro'
import { Card } from '../../common/general/Card'
import ReactModal from 'react-modal'
import { Txt } from '../../common/general/Txt'
import { Button } from '../../common/general/Button'
import { ExclamationIcon } from '@heroicons/react/solid'

/** @jsxImportSource @emotion/react */

interface ITestConfirmationModalProps {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  onConfirm: () => void
}

const modalStyle: ReactModal.Styles = {
  content: {
    background: 'none',
    border: 'none',
    padding: '0',
    display: 'flex',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: '#6B7280BF',
  },
}

export const SuperadminTestConfirmationModal = (
  props: ITestConfirmationModalProps,
) => {
  const { isOpen, setIsOpen, onConfirm } = props

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={modalStyle}
      ariaHideApp={false}
    >
      <Card tw='flex flex-col items-center w-[32rem]'>
        <div tw='bg-yellow-100 rounded-full w-12 h-12 mb-2.5 flex items-center justify-center'>
          <ExclamationIcon tw='text-yellow-400 w-6 h-6' />
        </div>
        <Txt l tw='text-center mb-2'>
          Are you sure you want to mark this pedestal as ready for installation?
        </Txt>
        <Txt tw='text-gray-500 text-center'>
          Not all tests have been performed with successful outcomes. Are you
          sure you want to continue?
        </Txt>
        <div tw='w-full flex gap-3 mt-6'>
          <Button text='Cancel' onClick={() => setIsOpen(false)} cancel full />
          <Button text='Mark as ready' onClick={onConfirm} primary full />
        </div>
      </Card>
    </ReactModal>
  )
}
