/** @jsxImportSource @emotion/react */
import 'twin.macro'
import tw from 'twin.macro'
import { LightningBoltIcon } from '@heroicons/react/outline'
import { Txt } from '../../common/general/Txt'
import { TableCell } from '../../common/general/table/cells'
import { AdminSourceCardContainer } from './AdminSourceCardContainer'
import { PhaseTypeEnum, Socket, SourceTypeEnum } from '../../../api-types'
import {
  sourceLabelMeta,
  sourceStateMeta,
  sourceStatusMeta,
} from '../../common/general/utils'
import { roundToFloatWithExactlyNDecimals } from '../../../utils'

interface AdminSocketCardProps {
  socket: Socket
  onClick(socket: Socket): void
}

export const AdminSocketCard = (props: AdminSocketCardProps) => {
  const { socket, onClick } = props
  const { type, phase, position, current, state } = socket
  const availableMeta = sourceStatusMeta[socket.status]

  return (
    <AdminSourceCardContainer
      tw='w-[45%] min-w-[300px]'
      source={socket}
      onClick={() => onClick(socket)}
    >
      <div tw='h-2/3 flex flex-row pb-6 gap-5 px-6 pt-6'>
        <div
          css={[
            tw`h-12 w-12 flex justify-center items-center rounded-md bg-primary-500`,
            phase === PhaseTypeEnum.MONO_PHASE && tw`bg-blue-500`,
            phase === PhaseTypeEnum.THREE_PHASE && tw`bg-red-600`,
          ]}
        >
          <LightningBoltIcon width={24} tw='text-white' />
        </div>
        <div tw='flex flex-col gap-0.5'>
          <div tw='flex flex-row gap-2'>
            <Txt tw='font-semibold text-gray-900' xl>
              {sourceLabelMeta[type as SourceTypeEnum]} {position}
            </Txt>
            <TableCell.Chip
              value={availableMeta.value}
              {...availableMeta.colorProps}
            />
          </div>
          <div tw='flex flex-row gap-1'>
            <Txt tw='text-gray-500' sm>
              {phase && sourceStateMeta[phase as PhaseTypeEnum]}
            </Txt>
            <Txt tw='text-gray-500' sm>
              {current && `(${current}A)`}
            </Txt>
          </div>
          <div tw='flex flex-row gap-1'>
            <Txt tw='text-gray-500' sm>
              Consumption: {state.value} kWh
            </Txt>
          </div>
          <div tw='flex flex-row gap-1'>
            {socket.activeSession && (
              <Txt tw='text-gray-500' sm>
                Session Consumption:{' '}
                {roundToFloatWithExactlyNDecimals(
                  state.value - socket.activeSession.initialMeterState,
                  3,
                )}{' '}
                kWh
              </Txt>
            )}
          </div>
        </div>
      </div>
      <div tw='h-1/3 bg-gray-50 px-6 py-3 rounded-b-lg'>
        <span>
          <Txt tw='text-primary-600 font-medium' sm>
            More info
          </Txt>
        </span>
      </div>
    </AdminSourceCardContainer>
  )
}
