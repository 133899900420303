/** @jsxImportSource @emotion/react */
import 'twin.macro'

import { useController, useFormContext } from 'react-hook-form'
import uploadImage from '../../../../assets/upload-image.png'
import { v4 as uuidv4 } from 'uuid'
import { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { http } from '../../../../http'
import { uploadFile } from '../../file/upload-file'
import { ClipLoader } from 'react-spinners'
import { css } from '@emotion/react'

const uploadSectionStyle = (backgroundUrl?: string) => css`
  ${backgroundUrl ? `background-image: url(${backgroundUrl});` : ''}
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const loaderStyle = css`
  border-color: black;
  height: 3rem;
  width: 3rem;
`

interface IDropzoneField {
  name: string
  maxFiles?: number
  label?: string
  className?: string
}

const DropzoneField = (props: IDropzoneField) => {
  const { name, maxFiles } = props
  const { control, setValue } = useFormContext()
  const [backgroundUrl, setBackgroundUrl] = useState<string | undefined>()
  const [isUploading, setIsUploading] = useState(false)

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })
  useEffect(() => {
    if (backgroundUrl !== undefined || !field.value) {
      return
    }
    setBackgroundUrl(field.value || '')
  }, [field.value])

  useEffect(() => {
    if (error?.ref?.focus) {
      error.ref.focus()
    }
  }, [error])

  const onFileDrop = async (acceptedFiles: any[]) => {
    try {
      const file = acceptedFiles?.[0]
      if (!file) {
        return
      }
      setIsUploading(true)
      setBackgroundUrl('')
      const extension = file.name.substring(file.name.lastIndexOf('.'))
      const { downloadUrl, uploadUrl } = await http.getSignedUrl(
        `${uuidv4()}${extension}`,
      )
      setValue(field.name, downloadUrl)
      await uploadFile(uploadUrl, file)
      // wait for s3 start responding with 200 on newly uploaded image
      setTimeout(() => {
        setBackgroundUrl(downloadUrl)
      }, 100)
      setIsUploading(false)
    } catch (e) {
      console.error(e)
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <Dropzone
      maxFiles={maxFiles || 1}
      accept={{
        'image/*': ['.png', '.jpeg', '.jpg'],
      }}
      disabled={isUploading}
      onDrop={onFileDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <section
          tw='w-full border-dashed border-2'
          css={uploadSectionStyle(backgroundUrl)}
        >
          <div tw='flex flex-col items-center' {...getRootProps()}>
            <input {...getInputProps()} />
            {isUploading && <ClipLoader css={loaderStyle} />}
            {!isUploading && (
              <>
                <img src={uploadImage} />
                <p tw='text-gray-600 text-md mt-2'>
                  Drag and drop some files here, or click to select files
                </p>
                <p tw='text-gray-600 text-md'>PNG, JPG up to 10MB</p>
              </>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  )
}

export { DropzoneField }
