import 'twin.macro'

import * as yup from 'yup'

import { FormProvider, useFieldArray, useForm } from 'react-hook-form'

import { Breadcrumbs } from '../../common/general/Breadcrumbs'
import { Button } from '../../common/general/Button'
import { ContentContainer } from '../../common/general/ContentContainer'
import { IPedestalTypeForm } from '../../common/location/pedestal-types-section/pedestal-type-drawer/PedestalTypeDrawer'
import { IUserForm } from '../../common/general/UserDrawer'
import { SuperadminLocationFormBasicSection } from '../../common/location/SuperadminLocationFormBasicSection'
import { SuperadminLocationFormConstantsSection } from '../../common/location/SuperadminLocationFormConstantsSection'
import { SuperadminLocationFormPedestalConstantSection } from '../../common/location/SuperadminLocationFormPedestalConstantSection'
import { SuperadminLocationFormPedestalTypesSection } from '../../common/location/pedestal-types-section/SuperadminLocationFormPedestalTypesSection'
import { SuperadminLocationFormUsersSection } from '../../common/location/SuperadminLocationFormUsersSection'
import { Txt } from '../../common/general/Txt'
import { errors } from '../../common/general/utils'
import { http } from '../../../http'
import { queries } from '../../../queries'
import { superadminRoutes } from '../../common/general/route-names'
import { showErrorNotification } from '../../common/general/notification'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'
/** @jsxImportSource @emotion/react */
import { yupResolver } from '@hookform/resolvers/yup'
import { SuperAdminWorkingHoursFormSection } from '../../common/location/SuperAdminWorkingHoursFormSection'

export interface WorkingHoursInfo {
  dayOfTheWeek: number
  isDaySelected: boolean
  openingTime: string
  closingTime: string
}

export interface ILocationForm {
  type: string
  name: string
  city: string
  postcode: string
  country: string
  address: string
  latitude: number
  longitude: number
  workingHours: WorkingHoursInfo[]
  visible: boolean
  pedestalTypes: IPedestalTypeForm[]
  users: IUserForm[]
  plcConstants: {
    twait: number
    treset: number
    tshutdown: number
    tupdatecycle: number
    twateroff: number
    tfilt: number
    tmodbus: number
    thart: number
  }
  constants: {
    minLightRequests: number
    turnOnLightsTime: number
    turnOffLightsTime: number
    minHeatRequests: number
  }
}

const schema = yup.object().shape({
  type: yup.string().required(errors.required),
  name: yup.string().required(errors.required),
  city: yup.string().required(errors.required),
  postcode: yup.string().required(errors.required),
  country: yup.string().required(errors.required),
  address: yup.string().required(errors.required),
  latitude: yup.number().min(0).required(errors.required),
  longitude: yup.number().min(0).required(errors.required),
  workingHours: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          isDaySelected: yup.boolean().required(errors.required),
          dayOfTheWeek: yup.number().min(1).max(7).required(errors.required),
          openingTime: yup.string().required(errors.required),
          closingTime: yup.string().required(errors.required),
        })
        .required(errors.required),
    )
    .required(errors.required),
  visible: yup.boolean().required(errors.required),
  plcConstants: yup.object().shape({
    twait: yup.string().required(errors.required),
    treset: yup.string().required(errors.required),
    tshutdown: yup.string().required(errors.required),
    tupdatecycle: yup.string().required(errors.required),
    twateroff: yup.string().required(errors.required),
    tfilt: yup.string().required(errors.required),
    tmodbus: yup.string().required(errors.required),
    thart: yup.string().required(errors.required),
  }),
  constants: yup.object().shape({
    minLightRequests: yup
      .number()
      .typeError(errors.number)
      .required(errors.required),
    turnOnLightsTime: yup
      .number()
      .typeError(errors.number)
      .required(errors.required),
    turnOffLightsTime: yup
      .number()
      .typeError(errors.number)
      .required(errors.required),
    minHeatRequests: yup
      .number()
      .typeError(errors.number)
      .required(errors.required),
  }),
  pedestalTypes: yup.array(),
  users: yup.array(),
})

export const SuperadminNewLocationPage = () => {
  const methods = useForm<ILocationForm>({
    defaultValues: {
      pedestalTypes: [],
      users: [],
      plcConstants: {},
      workingHours: [
        {
          dayOfTheWeek: 1,
          isDaySelected: true,
          openingTime: '8',
          closingTime: '20',
        },
        {
          dayOfTheWeek: 2,
          isDaySelected: true,
          openingTime: '8',
          closingTime: '20',
        },
        {
          dayOfTheWeek: 3,
          isDaySelected: true,
          openingTime: '8',
          closingTime: '20',
        },
        {
          dayOfTheWeek: 4,
          isDaySelected: true,
          openingTime: '8',
          closingTime: '20',
        },
        {
          dayOfTheWeek: 5,
          isDaySelected: true,
          openingTime: '8',
          closingTime: '20',
        },
        {
          dayOfTheWeek: 6,
          isDaySelected: true,
          openingTime: '8',
          closingTime: '20',
        },
        {
          dayOfTheWeek: 7,
          isDaySelected: true,
          openingTime: '8',
          closingTime: '20',
        },
      ],
      visible: false,
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const history = useHistory()
  const client = useQueryClient()
  const { fields } = useFieldArray({
    control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'workingHours', // unique name for your Field Array
  })

  const onSubmit = useCallback(
    methods.handleSubmit(async (data) => {
      try {
        await http.createLocation(data)
        client.invalidateQueries(queries.locations)
        history.push(superadminRoutes.LOCATIONS)
      } catch (e) {
        showErrorNotification('Something went wrong. Please try again')
      }
    }),
    [],
  )
  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-2'
        links={[
          { name: 'Locations', route: '/locations' },
          { name: 'New location' },
        ]}
      />
      <form noValidate>
        <FormProvider {...methods}>
          <Txt xxl tw='mb-4'>
            New Location
          </Txt>

          <SuperadminLocationFormBasicSection />
          <SuperAdminWorkingHoursFormSection fields={fields} />
          <SuperadminLocationFormConstantsSection />
          <SuperadminLocationFormPedestalConstantSection />
          <SuperadminLocationFormPedestalTypesSection />
          <SuperadminLocationFormUsersSection />

          <div tw='flex flex-row justify-end align-items[center]'>
            <Button
              text='Cancel'
              cancel
              tw='mr-3'
              onClick={() => history.replace(superadminRoutes.LOCATIONS)}
            />
            <Button text='Create' primary onClick={onSubmit} />
          </div>
        </FormProvider>
      </form>
    </ContentContainer>
  )
}
