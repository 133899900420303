import 'twin.macro'

import { PhaseTypeEnum } from '../../../../../api-types'
/** @jsxImportSource @emotion/react */
import { SimpleInputField } from '../../../general/fields/SimpleInputField'
import { SimpleSelectField } from '../../../general/fields/SimpleSelectField'
import { Txt } from '../../../general/Txt'

interface ICurrentFieldArray {
  className?: string | undefined
}

export const SuperadminCurrentFieldArray = (props: ICurrentFieldArray) => {
  const renderSection = (index: number) => (
    <div tw='flex flex-row'>
      <div tw='flex items-center'>
        <Txt tw='text-primary-600 ml-1 mr-5 tabular-nums' xxxl>
          {index + 1}
        </Txt>
      </div>
      <div tw='w-full'>
        <div tw='flex flex-row flex-grow'>
          <SimpleInputField
            name={`configuration.currents[${index}].modbus`}
            placeholder={`Modbus ${index + 1}`}
            tw='rounded-br-none rounded-bl-none rounded-tr-none border-b-0'
          />
          <SimpleInputField
            name={`configuration.currents[${index}].impulses`}
            placeholder={`Impulses ${index + 1}`}
            tw='rounded-br-none rounded-bl-none rounded-tl-none border-l-0 border-b-0'
          />
        </div>
        <div tw='flex flex-row items-center'>
          <SimpleSelectField
            name={`configuration.currents[${index}].phases`}
            placeholder={`Phases ${index + 1}`}
            options={[
              { label: 'Mono-phase', value: PhaseTypeEnum.MONO_PHASE },
              { label: 'Three-phases', value: PhaseTypeEnum.THREE_PHASE },
            ]}
            styles={{
              control: {
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomRightRadius: 0,
              },
            }}
            tw='rounded-tl-none rounded-tr-none rounded-br-none'
          />
          <SimpleInputField
            placeholder={`Power ${index + 1}`}
            name={`configuration.currents[${index}].power`}
            rightText='A'
            tw='rounded-bl-none rounded-tr-none rounded-tl-none border-l-0'
          />
        </div>
      </div>
    </div>
  )

  return (
    <div tw='flex flex-col flex-grow' className={props.className}>
      <div tw='mb-2'>
        <label tw='font-sans font-medium text-sm text-gray-700'>Currents</label>
      </div>
      <div tw='space-y-6'>
        {renderSection(0)}
        {renderSection(1)}
        {renderSection(2)}
        {renderSection(3)}
      </div>
    </div>
  )
}
