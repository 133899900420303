/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ContentContainer } from '../../common/general/ContentContainer'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { InputField } from '../../common/general/fields/InputField'
import { Txt } from '../../common/general/Txt'
import { http } from '../../../http'
import { CountrySelectField } from '../../common/general/fields/CountrySelectField'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import { SelectField } from '../../common/general/fields/SelectField'
import { PaymentTypeEnum } from '../../../api-types'
import { Button } from '../../common/general/Button'
import { adminRoutes } from '../../common/general/route-names'
import { useCurrentLocation } from '../../../queries'
import { Breadcrumbs } from '../../common/general/Breadcrumbs'
import { ActionBar } from '../../common/general/ActionBar'

export interface CreateCustomerForm {
  withoutEmail: boolean
  email: string
  phoneNumber: string
  name: string
  country: string
  paymentType: string
  powerTariff: number
  waterTariff: number
  charter: boolean
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .when('withoutEmail', {
      is: (withoutEmail: boolean) => withoutEmail === true,
      then: yup.string().nullable().optional(),
      otherwise: yup.string().email().required(),
    }),
  phoneNumber: yup.string().optional(),
  name: yup.string().required(),
  country: yup.string().required(),
  paymentType: yup.string().required(),
  powerTariff: yup.number().required(),
  waterTariff: yup.number().required(),
})

export const AddNewCustomer = () => {
  const { search } = useLocation()
  const history = useHistory()
  const queryParams: Record<string, any> = qs.parse(search, {
    ignoreQueryPrefix: true,
  })
  const { data: location } = useCurrentLocation()
  const { constants } = location || {}
  const { powerTariff, waterTariff } = constants || {}
  const defaultValues = {
    withoutEmail: queryParams?.withoutEmail === '1',
    email: queryParams?.email || '',
    phoneNumber: queryParams?.phoneNumber || '',
    name: queryParams?.name || '',
    country: queryParams?.country || '',
    paymentType:
      queryParams?.charter === '1'
        ? PaymentTypeEnum.PERIODICAL
        : queryParams?.paymentType || location?.constants.defaultPaymentType,
    powerTariff: queryParams?.powerTariff || powerTariff || '',
    waterTariff: queryParams?.waterTariff || waterTariff || '',
    charter: queryParams?.charter === '1',
  }

  const methods = useForm<CreateCustomerForm>({
    defaultValues,
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  useEffect(() => {
    methods.reset({ ...(defaultValues ?? {}) })
  }, [location])

  const onSubmit = methods.handleSubmit(async (data) => {
    const account = await http.addUserAccount(data)
    history.push(`${adminRoutes.VIEW_CUSTOMER}/${account.id}`)
  })

  const withoutEmail = methods.watch('withoutEmail')

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-2'
        links={[
          { name: 'Customers', route: adminRoutes.CUSTOMERS },
          {
            name: `Add new ${
              queryParams.charter === '1' ? 'charter' : 'customer'
            }`,
            route: adminRoutes.CHECK_EMAIL,
          },
        ]}
      />
      <ActionBar
        title={`Add new ${
          queryParams.charter === '1' ? 'charter' : 'customer'
        }`}
        tw='mb-5'
      ></ActionBar>
      <FormProvider {...methods}>
        <form noValidate tw='space-y-6 flex-grow'>
          <div
            id='add-new-customer'
            tw='bg-white rounded-lg p-6 divide-y mb-5'
            style={{
              boxShadow:
                '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
              marginTop: 24,
            }}
          >
            <div>
              <Txt xl tw='mb-2'>
                Customer info
              </Txt>
              <Txt tw='mb-2 text-gray-400'>Personal details</Txt>
            </div>
            <div tw='flex pt-3'>
              <Txt tw='w-1/3'>Full name</Txt>
              <InputField tw='w-1/3' name='name' />
            </div>
            <div tw='flex pt-3'>
              <Txt tw='w-1/3'>Phone</Txt>
              <InputField tw='w-1/3' name='phoneNumber' />
            </div>
            {!withoutEmail && (
              <div tw='flex pt-3'>
                <Txt tw='w-1/3'>Email address</Txt>
                <InputField tw='w-1/3' name='email' />
              </div>
            )}
            <div tw='flex pt-3'>
              <Txt tw='w-1/3'>Country</Txt>
              <CountrySelectField tw='w-1/3' name='country' />
            </div>
          </div>
          <div
            id='customer-payment-info'
            tw='bg-white rounded-lg p-6 divide-y mb-5'
            style={{
              boxShadow:
                '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
              marginTop: 24,
            }}
          >
            <div>
              <Txt xl tw='mb-2'>
                Payment info
              </Txt>
            </div>
            <div tw='flex pt-3'>
              <Txt tw='w-1/3'>Type</Txt>
              <SelectField
                tw='w-1/3'
                name='paymentType'
                isDisabled={queryParams?.charter === '1'}
                options={Object.keys(PaymentTypeEnum).map((key) => ({
                  label: key,
                  value: key,
                }))}
              />
            </div>
            <div tw='flex pt-3'>
              <Txt tw='w-1/3'>Power tariff</Txt>
              <InputField tw='w-1/3' type='number' name='powerTariff' />
            </div>
            <div tw='flex pt-3'>
              <Txt tw='w-1/3'>Water tariff</Txt>
              <InputField tw='w-1/3' type='number' name='waterTariff' />
            </div>
          </div>
          <Button primary type='submit' text='Save' onClick={onSubmit} />
        </form>
      </FormProvider>
    </ContentContainer>
  )
}
