/** @jsxImportSource @emotion/react */
import 'twin.macro'

import { Card } from '../general/Card'
import { InputField } from '../general/fields/InputField'
import { Txt } from '../general/Txt'

export const SuperadminLocationFormPedestalConstantSection = () => {
  return (
    <Card tw='mb-6'>
      <Txt xl tw='mb-1'>
        Pedestals constants
      </Txt>
      <Txt sm tw='mb-6 text-gray-500'>
        All of the following fields are required.
      </Txt>
      <div tw='flex flex-col space-y-3'>
        <InputField name='plcConstants.twait' label='twait' />
        <InputField name='plcConstants.treset' label='treset' />
        <InputField name='plcConstants.tshutdown' label='tshutdown' />
        <InputField name='plcConstants.tupdatecycle' label='updatecycle' />
        <InputField name='plcConstants.twateroff' label='twateroff' />
        <InputField name='plcConstants.tfilt' label='tfilt' />
        <InputField name='plcConstants.tmodbus' label='tmodbus' />
        <InputField name='plcConstants.thart' label='thart' />
      </div>
    </Card>
  )
}
