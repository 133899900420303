import 'twin.macro'

import { Button } from './Button'
import { useState } from 'react'

/** @jsxImportSource @emotion/react */
interface ITab {
  title: string
  content: any
}
interface ITabsProps {
  className?: string | undefined
  items: ITab[]
}

export const Tabs = (props: ITabsProps) => {
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <div>
      <div tw='flex flex-row items-center space-x-8'>
        {props.items.map((t, i) => (
          <Button
            text={t.title}
            flat={i !== activeIndex}
            light={i === activeIndex}
            onClick={() => setActiveIndex(i)}
            key={i}
          />
        ))}
      </div>

      {props.items[activeIndex].content}
    </div>
  )
}
