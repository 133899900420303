/** @jsxImportSource @emotion/react */
import 'twin.macro'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Account,
  Pedestal,
  powerLabels,
  sourceLabels,
  SourceStatusEnum,
  SourceStatusLabels,
} from '../../../api-types'
import { http } from '../../../http'
import { IDropdownOption } from '../../../types'
import { CustomDrawer } from '../../common/general/drawer/CustomDrawer'
import { SimpleAutocompleteField } from '../../common/general/fields/SimpleAutocompleteField'
import { Divider } from '../../common/general/Divider'
import { Button } from '../../common/general/Button'
import { SelectField } from '../../common/general/fields/SelectField'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { parseBackendException } from '../../../utils'
import { queries } from '../../../queries'
import { useQueryClient } from 'react-query'

interface IAdminStartSessionForClientDrawerProps {
  customer: Account
  open: boolean
  setOpen: (val: boolean) => void
  setOpenParentDrawer?: (val: boolean) => void
  level?: string
  levelMove?: number
  className?: string
}

interface IStartSessionForm {
  pedestalIdentifier: {
    label: string
    value: string
  }
  sourceId: number
  accountId: number
  vehicleId: number
}

export default function AdminStartSessionForClientDrawer(
  props: IAdminStartSessionForClientDrawerProps,
) {
  const {
    customer,
    open,
    setOpen,
    level,
    levelMove,
    className,
    setOpenParentDrawer,
  } = props
  const client = useQueryClient()
  const [pedestals, setPedestals] = useState<Pedestal[]>()
  const [vehicleOptions, setVehicleOptions] = useState<IDropdownOption[]>([
    { label: '', value: '' },
  ])
  const [sourceOptions, setSourceOptions] = useState<IDropdownOption[]>([
    { label: '', value: '' },
  ])

  const schema = yup.object().shape({
    sourceId: yup.number().required(),
    accountId: yup.number().required(),
    vehicleId: yup.number().required(),
  })

  const defaultValues = {
    accountId: customer.id,
  }

  const methods = useForm<IStartSessionForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    shouldUseNativeValidation: false,
    mode: 'onBlur',
  })

  const pedestalIdentifier = methods.watch('pedestalIdentifier')

  const onSubmit = methods.handleSubmit(async (data) => {
    try {
      await http.startSession(data.sourceId, data.accountId, data.vehicleId)
      setOpen(false)
      setOpenParentDrawer && setOpenParentDrawer(false)
      await client.invalidateQueries(queries.accountSessions)
    } catch (e: any) {
      parseBackendException(e)
    }
  })
  useEffect(() => {
    const vehicles = customer?.owner.vehicles.map(
      (vehicle): IDropdownOption => ({
        label: vehicle.registration,
        value: vehicle.id,
      }),
    )
    vehicles && setVehicleOptions(vehicles)
  }, [customer])

  useEffect(() => {
    if (pedestals) {
      const pedestal = pedestals.find(
        (pedestal) => pedestal.identifier == pedestalIdentifier.value,
      )
      const valves = pedestal?.valves.map(
        (source): IDropdownOption => ({
          label: `${sourceLabels[source.type] ?? 'Source'} ${
            source.position ?? '-'
          }, ${source.connectionSize ?? '-'}, ${
            SourceStatusLabels[
              source.status ?? SourceStatusEnum.STATUS_UNAVAILABLE
            ]
          }`,
          value: source.id,
        }),
      )
      const sockets = pedestal?.sockets.map(
        (source): IDropdownOption => ({
          label: `${sourceLabels[source.type] ?? 'Source'} ${
            source.position ?? '-'
          }, ${powerLabels[source.phase] ?? '-'} (${source.current ?? '-'}A), ${
            SourceStatusLabels[
              source.status ?? SourceStatusEnum.STATUS_UNAVAILABLE
            ]
          }`,
          value: source.id,
        }),
      )
      const sources = valves && sockets && sockets.concat(valves)
      sources && setSourceOptions(sources)
    }
  }, [pedestalIdentifier])

  return (
    <CustomDrawer
      open={open}
      title={'Activate Session'}
      subtitle={'Manually activate a session for a user'}
      close={() => setOpen(false)}
      level={level}
      levelMove={levelMove}
      className={className}
      render={() => (
        <form onSubmit={onSubmit} noValidate tw='mt-8 space-y-6 flex-grow'>
          <FormProvider {...methods}>
            <div tw='flex h-full flex-col justify-between'>
              <div tw='m-6 h-full'>
                <SimpleAutocompleteField
                  tw='mb-6'
                  name='pedestalIdentifier'
                  placeholder='Search pedestals...'
                  label='Pedestal'
                  onChange={async (term: string) => {
                    const searchResult = await http.searchAdminPedestals({
                      order: 'ASC',
                      orderField: 'identifier',
                      page: 1,
                      term,
                      size: 10,
                    })
                    setPedestals(searchResult.items)
                    return searchResult.items.map<IDropdownOption>(
                      (pedestal: Pedestal) => ({
                        label: pedestal.identifier.toLocaleUpperCase(),
                        value: pedestal.identifier,
                      }),
                    )
                  }}
                />
                <SelectField
                  name='sourceId'
                  label='Outlet/Water'
                  options={sourceOptions}
                  placeholder={
                    pedestalIdentifier
                      ? 'Select source...'
                      : 'Select a pedestal first...'
                  }
                  isDisabled={!pedestalIdentifier}
                />
                <SelectField
                  name='accountId'
                  label='Customer'
                  options={[
                    {
                      label: `${customer.owner.name}`,
                      value: customer.id,
                    },
                  ]}
                  defaultValue={{
                    label: `${customer.owner.name}`,
                    value: customer.id,
                  }}
                  isDisabled
                />
                <SelectField
                  name='vehicleId'
                  label='Vehicle'
                  options={vehicleOptions}
                />
              </div>
              <div>
                <Divider type='horizontal' />
                <div tw='flex flex-row justify-end m-4'>
                  <Button text={'Activate'} onClick={onSubmit} />
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      )}
    />
  )
}
