/** @jsxImportSource @emotion/react */
import { useController, useFormContext } from 'react-hook-form'
import { ErrorText } from '../ErrorText'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import tw from 'twin.macro'
import { useEffect, useState } from 'react'

interface DynamicIconProps {
  iconPath: string
}

const DynamicIcon: React.FC<DynamicIconProps> = ({ iconPath }) => {
  const [icon, setIcon] = useState<string | null>(null)

  useEffect(() => {
    const importIcon = async () => {
      try {
        const iconModule = await import(`../../../../assets${iconPath}`)
        setIcon(iconModule.default)
      } catch (error) {
        console.error()
      }
    }

    importIcon()
  }, [iconPath])

  return (
    <div className='icon-container'>
      {icon && <img src={icon} width='15' height='20' />}
    </div>
  )
}

export default DynamicIcon

interface ICheckboxField {
  id: string
  name: string
  iconUrl: string
  label?: string
  placeholder?: string
  className?: string
  selectOnFoucs?: boolean | undefined
}

const CheckboxArrayField = (props: ICheckboxField) => {
  const { name, label, className, selectOnFoucs, placeholder, id, iconUrl } =
    props
  const { control, setValue } = useFormContext()
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })
  useEffect(() => {
    if (error?.ref?.focus) {
      error.ref.focus()
    }
  }, [error])

  return (
    <div className={className} tw='flex'>
      <div tw='mr-3'>
        <input
          name={field.name}
          checked={field.value?.includes(id)}
          type='checkbox'
          placeholder={placeholder}
          onChange={(e) => {
            const currentValue = field.value || []
            if (e.target.checked) {
              currentValue.push(id)
              setValue(field.name, [...currentValue])
            } else {
              setValue(field.name, [
                ...currentValue.filter((v: string) => v !== id),
              ])
            }
          }}
          css={[
            tw`block w-full my-1 px-2.5 py-[0.675rem] border rounded-md focus:outline-none focus:ring-primary-700 focus:bg-white focus:z-10`,
            tw`font-sans font-normal text-sm text-gray-900 w-auto`,
            error &&
              tw`font-sans font-normal text-sm border-error text-red-900 focus:text-gray-900`,
          ]}
          onBlur={field.onBlur}
          ref={field.ref}
          onFocus={(event) => {
            selectOnFoucs && event.target.select()
          }}
        />
        {error && (
          <ExclamationCircleIcon
            tw='h-5 w-5 absolute right-3 top-3'
            color='#EF4444'
          />
        )}
      </div>
      <label tw='font-sans font-medium text-sm text-gray-700'>{label}</label>

      <div tw='h-6'>
        {error && error.message && (
          <ErrorText tw='mt-2'>{error.message}</ErrorText>
        )}
      </div>
      <div tw='ml-1 mt-0.5'>
        <DynamicIcon iconPath={iconUrl} />
      </div>
    </div>
  )
}

export { CheckboxArrayField }
