/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ContentContainer } from '../../common/general/ContentContainer'
import { ActionBar } from '../../common/general/ActionBar'
import { CustomTable } from '../../common/general/table/CustomTable'
import { FilterBar } from '../../common/general/FilterBar'
import { ISearchParams } from '../../../types'
import { TableCell } from '../../common/general/table/cells'
import { useSearch } from '../../common/general/hooks/useSearch'
import { useState } from 'react'
import { useAdminAlarms } from '../../../queries'
import { SwitchField } from '../../common/general/fields/SwitchField'
import { alarmStatusMeta, sourceLabelMeta } from '../../common/general/utils'
import { PageLoader } from '../../common/general/PageLoader'

type IAlarmSearchParams = ISearchParams & { resolved: boolean }

export const AlarmList = () => {
  const initialSearchParams: Partial<IAlarmSearchParams> = {
    term: '',
    orderField: 'createdAt',
    order: 'DESC',
  }
  const initialFilters = {
    resolved: false,
  }

  const [filters, setFilters] = useState(initialFilters)
  const [searchParams, { setOrder, setOrderField, setPage, setTerm }] =
    useSearch(initialSearchParams)
  const { data, isLoading } = useAdminAlarms({ ...searchParams, ...filters })

  return (
    <ContentContainer>
      <ActionBar title='Alarms' tw='mb-5' />
      <FilterBar tw='mb-4'>
        <FilterBar.Select
          value={{
            order: searchParams.order,
            orderField: searchParams.orderField,
          }}
          options={[
            {
              label: 'Created (Z-A)',
              value: { order: 'DESC', orderField: 'createdAt' },
            },
            {
              label: 'Created (A-Z)',
              value: { order: 'ASC', orderField: 'createdAt' },
            },
          ]}
          setValue={(option) => {
            setOrder(option.value.order)
            setOrderField(option.value.orderField)
          }}
        />
        <FilterBar.Filter
          onSubmit={setFilters}
          values={filters}
          initialValues={initialFilters}
        >
          <SwitchField name='resolved' label='Is Resolved' />
        </FilterBar.Filter>
        <FilterBar.TermSearch setValue={setTerm} placeholder='Search...' />
      </FilterBar>

      {isLoading || !data ? (
        <PageLoader />
      ) : (
        <CustomTable
          loading={isLoading}
          maxPage={data.totalPages}
          currentPage={searchParams.page}
          setPage={setPage}
          pageSize={searchParams.size}
          data={data.items}
          totalCount={data.count}
          columns={[
            {
              Header: 'Type',
              accessor: 'type',
              cell: (l) => {
                const meta = alarmStatusMeta[l.type]
                return (
                  <TableCell.Chip value={meta.value} {...meta.colorProps} />
                )
              },
            },
            {
              Header: 'Message',
              accessor: 'message',
              cell: (l) => <TableCell.Text value={l.message} />,
            },
            {
              Header: 'Location',
              accessor: 'location',
              cell: (l) => <TableCell.Text value={l.pedestal.location.name} />,
            },
            {
              Header: 'Pedestal',
              accessor: 'pedestal',
              cell: (l) => <TableCell.Text value={l.pedestal.identifier} />,
            },
            {
              Header: 'Source',
              accessor: 'source',
              cell: (l) => (
                <TableCell.Text
                  value={
                    l.source
                      ? `${sourceLabelMeta[l.source.type]}-${l.source.position}`
                      : '-'
                  }
                />
              ),
            },
            {
              Header: 'Created',
              accessor: 'createdAt',
              cell: (l) => (
                <TableCell.Text
                  value={new Date(l.createdAt).toLocaleString()}
                />
              ),
            },
          ]}
        />
      )}
    </ContentContainer>
  )
}
