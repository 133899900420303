import 'twin.macro'

import { Chip } from '../Chip'
import { Txt } from '../Txt'

/** @jsxImportSource @emotion/react */

const Text = (props: {
  value: string | number
  bold?: boolean
  light?: boolean
}) => {
  if (props.bold) {
    return <Txt md>{props.value}</Txt>
  }
  if (props.light) {
    return (
      <Txt sm tw='text-gray-500'>
        {props.value}
      </Txt>
    )
  }
  return <Txt sm>{props.value}</Txt>
}
// const Image = (props: { value: string }) => {
//   return <img src={props.value} alt={props.value} />
// }
// const DateCell = (props: { value: Date; dateFormat?: string }) => {
//   const { value, dateFormat } = props
//   return <span>{formatDate(new Date(value), { dateFormat })}</span>
// }
// const YesNo = (props: { value: boolean }) => {
//   return <span>{props.value ? 'Yes' : 'No'}</span>
// }
// const Pill = (props: { value: string; color?: string }) => {
//   const { value, color } = props
//   const cssArray = []
//   cssArray.push(pillBaseStyle)
//   if (color) {
//     if (color === 'red') {
//       cssArray.push(pillRedStyle)
//     } else if (color === 'yellow') {
//       cssArray.push(pillYellowStyle)
//     } else if (color === 'green') {
//       cssArray.push(pillGreenStyle)
//     } else if (color === 'blue') {
//       cssArray.push(pillBlueStyle)
//     }
//   } else {
//     if (value === 'new' || value === 'no') {
//       cssArray.push(pillRedStyle)
//     } else if (value === 'close' || value === 'yes') {
//       cssArray.push(pillGreenStyle)
//     } else if (value === 'interest') {
//       cssArray.push(pillYellowStyle)
//     } else if (value === 'match') {
//       cssArray.push(pillBlueStyle)
//     }
//   }
//   return <span css={cssArray}>{props.value}</span>
// }

export const TableCell = {
  Text,
  // Date: DateCell,
  // YesNo,
  Chip,
  // Image,
}
