import 'twin.macro'

import { useController, useFormContext } from 'react-hook-form'

import ReactSwitch from 'react-switch'
import { Txt } from '../Txt'

/** @jsxImportSource @emotion/react */

interface ISwitchFieldProps {
  name: string
  label?: string
  className?: string
}

export const SwitchField = ({ name, label, className }: ISwitchFieldProps) => {
  const { control } = useFormContext()
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control })

  return (
    <div tw='flex flex-row align-items[center] mb-6'>
      <ReactSwitch
        className={className}
        onChange={onChange}
        checked={value ?? false}
        offColor='#E5E7EB'
        onColor='#1D5883'
        style={{ padding: 20 }}
        checkedIcon={false}
        uncheckedIcon={false}
        hidden
        height={24}
        width={44}
        handleDiameter={20}
        tw='mr-4'
      />
      <Txt>{label}</Txt>
      {error && <Txt>{error.message}</Txt>}
    </div>
  )
}
