import React from 'react'
import 'twin.macro'
/** @jsxImportSource @emotion/react */

interface ISourceInfoProps {
  label: string
  value: string | number
  unit: string
}

export const SourceInfo = (props: ISourceInfoProps) => {
  const { label, value, unit } = props
  return (
    <div tw='grid grid-cols-2'>
      <span tw='text-gray-500 col-span-1'>{label}</span>
      <span tw='font-mono text-primary-600 col-span-1 grid grid-cols-3 gap-2'>
        <span tw='col-span-2 justify-self-end'>{value}</span>
        <span tw='col-span-1'>{unit}</span>
      </span>
    </div>
  )
}
