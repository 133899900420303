/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { FormProvider, useForm } from 'react-hook-form'
import { InputField } from '../../common/general/fields/InputField'
import { Button } from '../../common/general/Button'
import { CustomDrawer } from '../../common/general/drawer/CustomDrawer'
import * as yup from 'yup'
import { errors } from '../../common/general/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Account } from '../../../api-types'

export interface EditCustomerInfoForm {
  id: number
  name: string
  phoneNumber: string
  email: string
  country: string
}

const schema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(errors.required),
  phoneNumber: yup.string().required(errors.required),
  email: yup.string().required(errors.required),
  country: yup.string().required(errors.required),
})

interface EditCustomerInfoDrawerProps {
  open: boolean
  close: () => void
  onSubmit(val: any): void
  customer: Account
}

export const EditCustomerInfoDrawer = (props: EditCustomerInfoDrawerProps) => {
  const { open, onSubmit, close, customer } = props

  const methods = useForm<EditCustomerInfoForm>({
    defaultValues: {
      id: customer.owner.id,
      name: customer.owner.name,
      phoneNumber: customer.owner.phoneNumber,
      email: customer.owner.email,
      country: customer.owner.country,
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const handleSubmit = methods.handleSubmit(async (data) => {
    onSubmit(data)
  })

  return (
    <CustomDrawer
      open={open}
      title={'Edit Customer Info'}
      subtitle={''}
      render={() => (
        <form
          onSubmit={onSubmit}
          noValidate
          tw='space-y-6 flex-grow'
          style={{ padding: 24 }}
        >
          <FormProvider {...methods}>
            <div tw='flex h-full flex-col justify-between flex-grow'>
              <div tw='m-6 flex flex-grow flex-col'>
                <InputField name='name' label='Full Name' type={'text'} />
                <InputField name='phoneNumber' label='Phone' type={'number'} />
                <InputField name='email' label='Email Address' type={'email'} />
                <InputField name='country' label='Country' type={'text'} />
              </div>
              <div>
                <div tw='flex flex-row justify-end m-4'>
                  <Button text={'Close'} onClick={close} cancel tw='mr-3' />
                  <Button text={'Save'} onClick={handleSubmit} />
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      )}
      close={() => close()}
    />
  )
}
