/** @jsxImportSource @emotion/react */
import 'twin.macro'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { InputField } from '../../common/general/fields/InputField'
import { Button } from '../../common/general/Button'
import { CustomDrawer } from '../../common/general/drawer/CustomDrawer'
import * as yup from 'yup'
import { errors } from '../../common/general/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { SelectField } from '../../common/general/fields/SelectField'
import { Account, PaymentTypeEnum } from '../../../api-types'

export interface EditPaymentInfoForm {
  accountId: number
  paymentType: PaymentTypeEnum
  numberOfDaysUntilPayment: number
  powerTariff: number
  waterTariff: number
}

const schema = yup.object().shape({
  accountId: yup.number(),
  paymentType: yup
    .mixed<keyof typeof PaymentTypeEnum>()
    .oneOf(Object.values(PaymentTypeEnum)),
  numberOfDaysUntilPayment: yup
    .number()
    .min(0)
    .nullable(true)
    .transform((_, val) => {
      return val ? Number(val) : null
    }),
  powerTariff: yup.number().required(errors.required),
  waterTariff: yup.number().required(errors.required),
})

interface EditPaymentInfoDrawerProps {
  open: boolean
  close: () => void
  onSubmit(val: any): void
  customer: Account
}

export const EditPaymentInfoDrawer = (props: EditPaymentInfoDrawerProps) => {
  const { open, onSubmit, close, customer } = props

  const methods = useForm<EditPaymentInfoForm>({
    defaultValues: {
      accountId: customer.id,
      paymentType: customer.paymentType,
      numberOfDaysUntilPayment: customer.numberOfDaysUntilPayment,
      powerTariff: customer.powerTariff,
      waterTariff: customer.waterTariff,
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const paymentType = methods.watch('paymentType')

  const handleSubmit = methods.handleSubmit(async (data) => {
    onSubmit(data)
  })

  return (
    <CustomDrawer
      open={open}
      title={'Edit Payment Info'}
      subtitle={''}
      render={() => (
        <form
          onSubmit={onSubmit}
          noValidate
          tw='space-y-6 flex-grow'
          style={{ padding: 24 }}
        >
          <FormProvider {...methods}>
            <div tw='flex h-full flex-col justify-between flex-grow'>
              <div tw='m-6 flex flex-grow flex-col'>
                <SelectField
                  name='paymentType'
                  label='Type'
                  defaultValue={{
                    label: customer.paymentType,
                    value: customer.paymentType,
                  }}
                  options={Object.keys(PaymentTypeEnum).map((key) => ({
                    label: key,
                    value: key,
                  }))}
                  // isDisabled
                />
                {paymentType === PaymentTypeEnum.POSTPAID && (
                  <InputField
                    name='numberOfDaysUntilPayment'
                    label='Due Date (days after session end)'
                    type={'number'}
                  />
                )}
                <InputField
                  name='powerTariff'
                  label='Power Tariff'
                  type={'number'}
                />
                <InputField
                  name='waterTariff'
                  label='Water Tariff'
                  type={'number'}
                />
              </div>
              <div>
                <div tw='flex flex-row justify-end m-4'>
                  <Button text={'Close'} onClick={close} cancel tw='mr-3' />
                  <Button text={'Save'} onClick={handleSubmit} />
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      )}
      close={() => close()}
    />
  )
}
