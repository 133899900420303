import 'twin.macro'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Card } from '../../common/general/Card'
import { InputField } from '../../common/general/fields/InputField'
import { SwitchField } from '../../common/general/fields/SwitchField'
import { Pedestal } from '../../../api-types'
import { Button } from '../../common/general/Button'
import { superadminRoutes } from '../../common/general/route-names'
import { SelectField } from '../../common/general/fields/SelectField'
import { http } from '../../../http'
import { useQueryClient } from 'react-query'
import { queries } from '../../../queries'
import { useMemo } from 'react'
import { Stepper } from '../../common/general/Stepper'
import { pedestalStickerService } from '../../common/general/PedestalStickerService'
//@ts-ignore
import FileSaver from 'file-saver' // todo: remove
import { Txt } from '../../common/general/Txt'
import {
  DownloadIcon,
  LocationMarkerIcon,
  TemplateIcon,
} from '@heroicons/react/solid'
import { ContentContainer } from '../../common/general/ContentContainer'
import { Breadcrumbs } from '../../common/general/Breadcrumbs'

/** @jsxImportSource @emotion/react */

interface IPedestalConfigurationProps {
  pedestal: Pedestal
}

export const SuperadminPedestalConfiguration = ({
  pedestal,
}: IPedestalConfigurationProps) => {
  const history = useHistory()
  const client = useQueryClient()

  const methods = useForm<any>({
    defaultValues: {
      ...(pedestal.plcConstants ?? pedestal.location.plcConstants),
      hasHydrostat: pedestal.hasHydrostat,
      hasLuxomat: pedestal.hasLuxomat,
      pedestalTypeId: pedestal.pedestalType?.id,
      identifier: pedestal.identifier,
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(formValidationSchema),
    mode: 'onBlur',
  })

  const onSubmit = methods.handleSubmit(
    async ({
      pedestalTypeId,
      hasHydrostat,
      hasLuxomat,
      plcConstantId,
      identifier,
      ...plcConstants
    }) => {
      await http.updatePedestalConfiguration(pedestal.id, {
        pedestalTypeId,
        hasHydrostat,
        hasLuxomat,
        plcConstants: { id: plcConstantId, ...plcConstants },
        identifier,
      })
      client.invalidateQueries([queries.pedestal, pedestal.identifier])
    },
  )

  const determineSelectDefault = () => {
    return pedestal.pedestalType
      ? {
          label: pedestal.pedestalType.name,
          value: pedestal.pedestalType.id.toString(),
        }
      : undefined
  }
  const defaultPedestalType = useMemo(determineSelectDefault, [pedestal])

  const handleDownloadQRCode = async () => {
    await pedestalStickerService.download(pedestal)
  }

  const handleNextStep = async () => {
    await onSubmit()
    history.push(`${superadminRoutes.PEDESTALS_TESTING}/${pedestal.identifier}`)
  }

  const handleFinishForNow = async () => {
    await onSubmit()
    history.replace(superadminRoutes.PEDESTALS)
  }

  const handleDownloadCertificate = () => {
    FileSaver.saveAs(
      'https://s3.eu-west-1.amazonaws.com/media.marex-hc.com/certificates/certificates.zip',
      'certificates.zip',
    )
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-4'
        links={[
          {
            name: 'Pedestals',
            route: superadminRoutes.PEDESTALS_CONFIGURATION,
          },
          { name: pedestal.identifier },
        ]}
      />
      <div tw='flex '>
        <div>
          <Txt xxl tw='mb-2'>
            {pedestal.identifier}
          </Txt>
          <div tw='flex flex-row mb-6 items-center'>
            <LocationMarkerIcon tw='h-5 w-5 mr-2 text-gray-400' />
            <Txt tw='mr-6 text-gray-500'>{pedestal.location.name}</Txt>
            {pedestal.pedestalType && (
              <>
                <TemplateIcon tw='h-5 w-5 mr-2 text-gray-400' />
                <Txt tw='text-gray-500 mr-6'>{pedestal.pedestalType.name}</Txt>
              </>
            )}
          </div>
        </div>
        <div tw='flex items-center ml-auto'>
          <>
            <Button
              onClick={handleDownloadCertificate}
              tw='mr-3'
              text='Download certificates'
              icon={DownloadIcon}
              cancel
            />
            <Button
              onClick={handleDownloadQRCode}
              text='Download QR sticker'
              icon={DownloadIcon}
              cancel
            />
          </>
        </div>
      </div>
      <Stepper
        steps={['Configuration', 'Test', 'Installation']}
        activeStep={0}
        tw='mb-10'
      />
      <form onSubmit={onSubmit} noValidate>
        <FormProvider {...methods}>
          <Card tw='mb-6 p-0'>
            <div tw='p-6'>
              <InputField name='identifier' label='Identifier' />
              <SelectField
                isDisabled={!!pedestal.pedestalType}
                placeholder={defaultPedestalType?.label}
                name='pedestalTypeId'
                options={pedestal.location.pedestalTypes.map((pedestalType) => {
                  return {
                    label: pedestalType.name,
                    value: pedestalType.id.toString(),
                  }
                })}
                label='Type'
                tw='mb-6'
              />
              <SwitchField
                name='hasHydrostat'
                label='This pedestal has a hydrostat'
              />
              <SwitchField
                name='hasLuxomat'
                label='This pedestal has a luxomat'
              />
              <InputField name='twait' label='twait' />
              <InputField name='treset' label='treset' />
              <InputField name='tshutdown' label='tshutdown' />
              <InputField name='tupdatecycle' label='tupdatecycle' />
              <InputField name='twateroff' label='twateroff' />
              <InputField name='tfilt' label='tfilt' />
              <InputField name='tmodbus' label='tmodbus' />
              <InputField name='thart' label='thart' />
              <Controller name='constantsId' render={() => <></>} />
            </div>

            <div tw='bg-gray-50 px-6 py-3 rounded-b-md'>
              <div tw='flex flex-row justify-end align-items[center]'>
                <Button
                  text='Finish for now'
                  cancel
                  tw='mr-3'
                  onClick={handleFinishForNow}
                />
                <Button text='Next step' primary onClick={handleNextStep} />
              </div>
            </div>
          </Card>
        </FormProvider>
      </form>
    </ContentContainer>
  )
}

const formValidationSchema = yup.object().shape({
  pedestalTypeId: yup.number().required('This field is required'),
  twait: yup.string().required('This field is required'),
  treset: yup.string().required('This field is required'),
  tshutdown: yup.string().required('This field is required'),
  tupdatecycle: yup.string().required('This field is required'),
  twateroff: yup.string().required('This field is required'),
  tfilt: yup.string().required('This field is required'),
  tmodbus: yup.string().required('This field is required'),
  thart: yup.string().required('This field is required'),
  identifier: yup.string().required('This field is required'),
})
