import 'rc-drawer/assets/index.css'
/** @jsxImportSource @emotion/react */
import 'twin.macro'

import { FC, ReactNode, useCallback, useEffect, useState } from 'react'

import Drawer from 'rc-drawer'
import { Txt } from '../Txt'
import { XIcon } from '@heroicons/react/outline'
import { isMobile } from '../../../../utils'

interface ICustomDrawerProps {
  open?: boolean
  title: string
  subtitle: string
  render(): ReactNode
  widthPercentage?: number
  close(): void
  level?: any
  levelMove?: number
  className?: string
}

const ANIMATION_DURATION = 0.3

export const CustomDrawer: FC<ICustomDrawerProps> = (props) => {
  const { widthPercentage } = props
  const [open, setOpen] = useState(props.open)

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  const closeDrawer = useCallback(() => {
    setOpen(false)
    setTimeout(() => {
      props.close()
    }, ANIMATION_DURATION)
  }, [])

  const maxPercentage = 100
  const width = isMobile ? maxPercentage : widthPercentage ?? 40

  const outherWidth = Math.min(maxPercentage, width)

  return (
    <Drawer
      width={`${outherWidth}%`}
      open={open}
      handler={false}
      maskClosable
      duration={ANIMATION_DURATION.toString()}
      placement='right'
      onClose={closeDrawer}
      level={props.level}
      levelMove={props.levelMove}
      className={props.className}
    >
      {open && (
        <div tw='flex flex-col h-full'>
          <div tw='flex flex-col w-full bg-primary-700 p-6'>
            <div tw='flex flex-row justify-between'>
              <Txt xl tw='text-white'>
                {props.title}
              </Txt>
              <XIcon
                onClick={() => props.close()}
                tw='h-5 w-5 cursor-pointer'
                color='#C7D2FE'
              />
            </div>
            {props.subtitle && (
              <Txt sm tw='text-primary-300 mt-1'>
                {props.subtitle}
              </Txt>
            )}
          </div>
          <div tw='h-full flex flex-col'>{props.render()}</div>
        </div>
      )}
    </Drawer>
  )
}
