/** @jsxImportSource @emotion/react */
import 'twin.macro'
import tw from 'twin.macro'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SuperadminEditLocationPage } from './pages/superadmin/location-edit/SuperadminEditLocationPage'
import { SuperadminLocationsPage } from './pages/superadmin/locations-page/SuperadminLocationsPage'
import { LoginPage } from './pages/common/login-page/LoginPage'
import { SuperadminNewLocationPage } from './pages/superadmin/location-new/SuperadminNewLocationPage'
import { SuperadminNewPedestalsPage } from './pages/superadmin/pedestal-new/SuperadminPedestalNewPage'
import { PedestalViewPage } from './pages/superadmin/pedestal-view/SuperadminPedestalViewPage'
import { SuperadminPedestalsPage } from './pages/superadmin/pedestals-page/SuperadminPedestalsPage'
import { Sidebar } from './pages/common/general/Sidebar'
import { SuperadminUsersPage } from './pages/superadmin/users-page/SuperadminUsersPage'
import { isMobile } from './utils'
import {
  publicRoutes,
  adminRoutes,
  superadminRoutes,
} from './pages/common/general/route-names'
import { useLoggedInUser } from './pages/common/general/hooks/useUser'
import { SuperadminPedestalConfigurationPage } from './pages/superadmin/pedestal-configuuration/SuperadminPedestalConfigurationPage'
import { SuperadminPedestalTestPage } from './pages/superadmin/pedestal-test/SuperadminPedestalTestPage'
import { SuperadminPedestalInstallationPage } from './pages/superadmin/pedestal-installation/SuperadminPedestalInstallationPage'
import { ForgotPasswordPage } from './pages/common/forgot-password-page/ForgotPasswordPage'
import { ResetPasswordPage } from './pages/common/reset-password-page/ResetPasswordPage'
import { SessionDetailsPage } from './pages/common/session-details-page/SessionDetailsPage'
import { UserRole } from './api-types'
import { AdminPedestalsPage } from './pages/admin/pedestals-page/AdminPedestalsPage'
import { AdminPedestalViewPage } from './pages/admin/pedestal-view/AdminPedestalViewPage'
import { Dashboard } from './pages/admin/dashboard/Dashboard'
import { CustomerList } from './pages/admin/customer-list/CustomerList'
import { CustomerView } from './pages/admin/customer-view/CustomerView'
import { EmailCheck } from './pages/admin/add-new-customer/EmailCheck'
import { AddNewCustomer } from './pages/admin/add-new-customer/AddNewCustomer'
import { AlarmList } from './pages/admin/alarm-list/AlarmList'
import { CustomerTransactionDetails } from './pages/admin/customer-view/CustomerTransactionDetails'
import { AdminSettings } from './pages/admin/settings/AdminSettings'
import { AdminSessionsPage } from './pages/admin/sessions-page/AdminSessionsPage'
import { ReportsPage } from './pages/common/reports/report-components/ReportsPage'
import { SessionReportPage } from './pages/common/reports/reports-pages/SessionReportPage'
import { ManualSessionsReportPage } from './pages/common/reports/reports-pages/ManualSessionsReportPage'
import { AlarmsReportPage } from './pages/common/reports/reports-pages/AlarmsReportPage'
import { TransactionsReportPage } from './pages/common/reports/reports-pages/TransactionsReportPage'
import { SourcesReportPage } from './pages/common/reports/reports-pages/SourcesReportPage'
import { ConsumptionReportPage } from './pages/common/reports/reports-pages/ConsumptionReportPage'
import { AdminTransactionsTablePage } from './pages/admin/transactions/AdminTransactionsTablePage'

const PublicRouter = () => {
  return (
    <Switch>
      <Route path={publicRoutes.LOGIN} component={LoginPage} />
      <Route
        path={publicRoutes.FORGOT_PASSWORD}
        component={ForgotPasswordPage}
      />
      <Route
        path={`${publicRoutes.RESET_PASSWORD}/:passwordResetToken`}
        component={ResetPasswordPage}
      />
      <Redirect to={publicRoutes.LOGIN} />
    </Switch>
  )
}

const AdminRouter = () => {
  return (
    <div
      css={[
        tw`flex bg-gray-100 min-h-screen`,
        isMobile ? tw`flex-col` : tw`flex-row`,
      ]}
    >
      <Sidebar />
      <div tw='flex-grow'>
        <Switch>
          <Route exact path={adminRoutes.DASHBOARD} component={Dashboard} />
          <Route exact path={adminRoutes.ALARMS} component={AlarmList} />
          <Route exact path={adminRoutes.CUSTOMERS} component={CustomerList} />
          <Route exact path={adminRoutes.SETTINGS} component={AdminSettings} />
          <Route exact path={adminRoutes.CHECK_EMAIL} component={EmailCheck} />
          <Route
            exact
            path={adminRoutes.ADD_NEW_CUSTOMER}
            component={AddNewCustomer}
          />
          <Route
            exact
            path={`${adminRoutes.VIEW_CUSTOMER}/:id`}
            component={CustomerView}
          />
          <Route
            exact
            path={adminRoutes.PEDESTALS}
            component={AdminPedestalsPage}
          />
          <Route
            exact
            path={`${adminRoutes.VIEW_PEDESTAL}/:identifier`}
            component={AdminPedestalViewPage}
          />
          <Route
            exact
            path={adminRoutes.SESSION_DETAILS}
            component={SessionDetailsPage}
          />
          <Route
            exact
            path={`${adminRoutes.VIEW_TRANSACTION}/:id`}
            component={CustomerTransactionDetails}
          />
          <Route
            exact
            path={adminRoutes.SESSIONS}
            component={AdminSessionsPage}
          />
          <Route exact path={adminRoutes.REPORTS} component={ReportsPage} />
          <Route
            exact
            path={adminRoutes.REPORTS_SESSIONS}
            component={SessionReportPage}
          />
          <Route
            exact
            path={adminRoutes.REPORTS_MANUAL_SESSIONS}
            component={ManualSessionsReportPage}
          />
          <Route
            exact
            path={adminRoutes.REPORTS_ALARMS}
            component={AlarmsReportPage}
          />
          <Route
            exact
            path={adminRoutes.REPORTS_TRANSACTIONS}
            component={TransactionsReportPage}
          />
          <Route
            exact
            path={adminRoutes.REPORTS_SOURCES}
            component={SourcesReportPage}
          />
          <Route
            exact
            path={adminRoutes.ADMIN_TRANSACTIONS_TABLE}
            component={AdminTransactionsTablePage}
          />
          {/*<Route*/}
          {/*  exact*/}
          {/*  path={adminRoutes.REPORTS_CONSUMPTION}*/}
          {/*  component={ConsumptionReportPage}*/}
          {/*/>*/}
          <Redirect to={adminRoutes.DASHBOARD} />
        </Switch>
      </div>
    </div>
  )
}

const MarinaOperatorRouter = () => {
  return (
    <div
      css={[
        tw`flex bg-gray-100 min-h-screen`,
        isMobile ? tw`flex-col` : tw`flex-row`,
      ]}
    >
      <Sidebar />
      <div tw='flex-grow'>
        <Switch>
          <Route exact path={adminRoutes.DASHBOARD} component={Dashboard} />
          <Route
            exact
            path={adminRoutes.PEDESTALS}
            component={AdminPedestalsPage}
          />
          <Route
            exact
            path={`${adminRoutes.VIEW_PEDESTAL}/:identifier`}
            component={AdminPedestalViewPage}
          />
          <Route exact path={adminRoutes.ALARMS} component={AlarmList} />
          <Redirect to={adminRoutes.DASHBOARD} />
        </Switch>
      </div>
    </div>
  )
}

const SuperadminRouter = () => {
  return (
    <div
      css={[
        tw`flex bg-gray-100 min-h-screen`,
        isMobile ? tw`flex-col` : tw`flex-row`,
      ]}
    >
      <Sidebar />
      <div tw='flex-grow'>
        <Switch>
          {/*<Route exact path='/' component={DashboardPage} />*/}
          <Route
            exact
            path={superadminRoutes.NEW_LOCATION}
            component={SuperadminNewLocationPage}
          />
          <Route exact path={adminRoutes.ALARMS} component={AlarmList} />
          <Route
            exact
            path={`${superadminRoutes.EDIT_LOCATION}/:id`}
            component={SuperadminEditLocationPage}
          />
          <Route
            exact
            path={superadminRoutes.LOCATIONS}
            component={SuperadminLocationsPage}
          />
          <Route
            exact
            path={superadminRoutes.PEDESTALS_CREATION}
            component={SuperadminNewPedestalsPage}
          />
          <Route
            exact
            path={`${superadminRoutes.PEDESTALS_CONFIGURATION}/:identifier`}
            component={SuperadminPedestalConfigurationPage}
          />
          <Route
            exact
            path={`${superadminRoutes.PEDESTALS_TESTING}/:identifier`}
            component={SuperadminPedestalTestPage}
          />
          <Route
            exact
            path={`${superadminRoutes.PEDESTALS_INSTALLATION}/:identifier`}
            component={SuperadminPedestalInstallationPage}
          />
          <Route
            exact
            path={`${superadminRoutes.VIEW_PEDESTAL}/:identifier`}
            component={PedestalViewPage}
          />
          <Route
            exact
            path={superadminRoutes.PEDESTALS}
            component={SuperadminPedestalsPage}
          />
          <Route
            exact
            path={superadminRoutes.USERS}
            component={SuperadminUsersPage}
          />
          <Route
            exact
            path={superadminRoutes.SESSION_DETAILS}
            component={SessionDetailsPage}
          />
          <Redirect to={superadminRoutes.LOCATIONS} />
        </Switch>
      </div>
    </div>
  )
}

export const AppRouter = () => {
  const { role } = useLoggedInUser()
  if (role === UserRole.SUPERADMIN) {
    return <SuperadminRouter />
  } else if (role === UserRole.ADMIN) {
    return <AdminRouter />
  } else if (role === UserRole.MARINA_OPERATOR) {
    return <MarinaOperatorRouter />
  } else {
    return <PublicRouter />
  }
}
