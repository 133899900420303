import { useState } from 'react'
import { ActionBar } from '../../general/ActionBar'
import { Breadcrumbs } from '../../general/Breadcrumbs'
import { ContentContainer } from '../../general/ContentContainer'
import { PageLoader } from '../../general/PageLoader'
import { adminRoutes } from '../../general/route-names'
import { useGetAllPedestals, useGetAllPedestalTypes } from '../../../../queries'
import { ReportComponent } from '../report-components/ReportComponent'
import { useSearchQueryParams } from '../../general/hooks/useSearchQueryParams'
import { isEmpty } from 'lodash'
import { ManualSessionsReportSearchParams } from '../report-types/report-search-params-types'
import { initialReportFilters } from '../report-types/report-types'
import { ManualSessionType, ReportType } from '../report-types/report-enums'

export const ManualSessionsReportPage = () => {
  const [searchParams] =
    useSearchQueryParams<ManualSessionsReportSearchParams>()

  const [filters, setFilters] = useState<ManualSessionsReportSearchParams>(
    !isEmpty(searchParams) ? searchParams : initialReportFilters,
  )

  //#region Fetching data
  const {
    data: pedestals,
    error: pedestalsError,
    isLoading: isLoadingPedestals,
  } = useGetAllPedestals()
  const {
    data: pedestalTypes,
    error: pedestalTypesError,
    isLoading: isLoadingPedestalTypes,
  } = useGetAllPedestalTypes()
  //#endregion

  if (isLoadingPedestals || isLoadingPedestalTypes) {
    return <PageLoader />
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-4'
        links={[
          { name: 'Reports', route: adminRoutes.REPORTS },
          { name: 'Manual sessions' },
        ]}
      />
      <ActionBar title='Manual sessions report' tw='mb-5' />
      <ReportComponent
        reportType={ReportType.MANUAL_SESSIONS}
        filters={filters}
        setFilters={setFilters}
        filtersOptions={[
          {
            label: 'Manual type',
            filterKey: 'manualSessionType',
            options: [
              {
                label: 'Socket',
                value: ManualSessionType.SOCKET,
              },
              {
                label: 'Valve',
                value: ManualSessionType.VALVE,
              },
            ],
            linkedFilters: ['pedestalId', 'pedestalTypeId'],
          },
          {
            label: 'Pedestal',
            filterKey: 'pedestalId',
            options: pedestals
              ? pedestals.map((pedestal) => ({
                  label: pedestal.identifier,
                  value: pedestal.id,
                }))
              : [],
            disablesFilters: ['pedestalTypeId'],
          },
          {
            label: 'Pedestal type',
            filterKey: 'pedestalTypeId',
            options: pedestalTypes
              ? pedestalTypes.map((pedestalType) => ({
                  label: pedestalType.name,
                  value: pedestalType.id,
                }))
              : [],
          },
        ]}
        isError={pedestalsError || pedestalTypesError}
      />
    </ContentContainer>
  )
}
