import React from 'react'
import { css } from '@emotion/react'
import 'twin.macro'
import tw from 'twin.macro'
import {
  Pedestal,
  powerLabels,
  Socket,
  Source,
  sourceLabels,
  SourceTestStatuses,
  SourceTypeEnum,
  Valve,
} from '../../../api-types'
import { InputField } from '../../common/general/fields/InputField'
import { roundToFloatWithExactlyNDecimals } from '../../../utils'

/** @jsxImportSource @emotion/react */

const listContainerSyle = css`
  li input:checked + label {
    ${tw`bg-primary-50 border-primary-200`}
  }
  label {
    ${tw`border border-gray-200 hover:border-primary-200`}
  }
  li:first-of-type {
    ${tw`rounded-t-lg`}
    label {
      ${tw`rounded-t-lg`}
    }
  }
  li:last-child {
    ${tw`rounded-b-lg`}
    label {
      ${tw`rounded-b-lg`}
    }
  }
  label {
    cursor: pointer;
  }
  span {
    flex-basis: 0;
    flex-grow: 1;
    align-content: center;
  }
`

interface SourceListProps {
  pedestal: Pedestal
  onSourceSelect(source: Source): void
  selectedSourceId: any
}

export const SuperadminSourceList = (props: SourceListProps) => {
  const { pedestal, onSourceSelect, selectedSourceId } = props

  const selectedSource = pedestal.sources.find(
    (source) => source.id === selectedSourceId,
  )!

  const determineStatus = (status: SourceTestStatuses | undefined) => {
    if (!status) {
      return <span tw='text-right text-gray-500'>Not tested yet</span>
    }
    if (status === SourceTestStatuses.SUCCESSFUL) {
      return (
        <span tw='text-right text-green-500'>Last test was successful</span>
      )
    } else {
      return (
        <span tw='text-right text-red-500'>Last test was inconsistent</span>
      )
    }
  }

  return (
    <>
      <div tw='flex justify-center py-6'>
        <ul
          css={listContainerSyle}
          tw='bg-white rounded-lg w-full text-gray-900'
        >
          {[...pedestal.sockets, ...pedestal.valves].map(
            (source: Source, i) => {
              const inProgressTests = source.tests.filter(
                (test) =>
                  test.status === SourceTestStatuses.IN_PROGRESS &&
                  !test.endedAt,
              )
              const hasInProgressTests = inProgressTests.length > 0

              return (
                <li tw='w-full flex items-center' key={i}>
                  <input
                    disabled={hasInProgressTests}
                    id={`source-option-${i}`}
                    type='radio'
                    name='sources'
                    value=''
                    tw='ml-4 h-4 w-4 border-gray-200 flex-shrink absolute'
                    aria-labelledby={`source-option-${i}`}
                    aria-describedby={`source-option-${i}`}
                    onChange={() => {
                      onSourceSelect(source)
                    }}
                  />
                  <label
                    htmlFor={`source-option-${i}`}
                    tw='pr-6 pl-11 py-4 text-sm font-medium text-gray-900 w-full flex items-center justify-between'
                  >
                    <span>
                      {sourceLabels[source.type]} {source.position}
                    </span>
                    {source.type === SourceTypeEnum.SOCKET ? (
                      <span tw='text-center'>
                        {powerLabels[(source as Socket).phase]}
                        {` (${(source as Socket).current}A)`}
                      </span>
                    ) : (
                      <span tw='text-center'>{`${
                        (source as Valve).connectionSize
                      }"`}</span>
                    )}
                    {source.lastTest ? (
                      <span tw='text-center'>{`${
                        source.type === SourceTypeEnum.SOCKET
                          ? 'Expected'
                          : 'Start'
                      } value: ${
                        source.lastTest.expectedValue === null
                          ? '?'
                          : roundToFloatWithExactlyNDecimals(
                              source.lastTest.expectedValue,
                              3,
                            )
                      }; Actual value: ${
                        source.lastTest.actualValue === null
                          ? '?'
                          : roundToFloatWithExactlyNDecimals(
                              source.lastTest.actualValue,
                              3,
                            )
                      }`}</span>
                    ) : (
                      <span></span>
                    )}
                    {hasInProgressTests ? (
                      <span tw='text-right text-yellow-500'>In Progress</span>
                    ) : (
                      determineStatus(source.lastTest?.status)
                    )}
                  </label>
                </li>
              )
            },
          )}
        </ul>
      </div>
      {selectedSourceId && (
        <>
          {selectedSource?.type &&
            selectedSource.type === SourceTypeEnum.SOCKET && (
              <InputField
                name='power'
                label={'Power'}
                type={'number'}
                selectOnFoucs
              />
            )}
          <InputField
            name='time'
            label={'Time (s)'}
            type={'number'}
            selectOnFoucs
          />
          <InputField
            name='allowedDeviation'
            label={'Allowed Deviation (%)'}
            type={'number'}
            selectOnFoucs
          />
        </>
      )}
    </>
  )
}
