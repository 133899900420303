import 'twin.macro'
/** @jsxImportSource @emotion/react */
import React from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import tw from 'twin.macro'

interface DownloadDropdownProps {
  onResetPassword(): void
  onDeactivateUser(): void
  onSendPasswordResetEmail(): void
}

export const CustomerOptionsDropdown = (props: DownloadDropdownProps) => {
  const { onResetPassword, onDeactivateUser, onSendPasswordResetEmail } = props
  return (
    <Menu as='div' tw='relative inline-block text-left'>
      <div>
        <Menu.Button tw='inline-flex justify-center w-full rounded-md border border-gray-200 border-width[1.5px] shadow-sm px-4 py-2.5 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500'>
          Options
          <ChevronDownIcon tw='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items tw='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-gray ring-opacity-5 focus:outline-none'>
          <div tw='py-1'>
            <Menu.Item onClick={onResetPassword}>
              {({
                //@ts-ignore
                active,
              }) => (
                <a
                  href='#'
                  css={[
                    active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                    tw`block px-4 py-2 text-sm`,
                  ]}
                >
                  Edit password
                </a>
              )}
            </Menu.Item>
            <Menu.Item onClick={onSendPasswordResetEmail}>
              {({
                //@ts-ignore
                active,
              }) => (
                <a
                  href='#'
                  css={[
                    active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                    tw`block px-4 py-2 text-sm`,
                  ]}
                >
                  Send reset password email
                </a>
              )}
            </Menu.Item>
            <Menu.Item onClick={onDeactivateUser}>
              {({
                //@ts-ignore
                active,
              }) => (
                <a
                  href='#'
                  css={[
                    active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`,
                    tw`block px-4 py-2 text-sm`,
                  ]}
                >
                  Deactivate user
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
