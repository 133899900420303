import 'twin.macro'

import React from 'react'
import { Txt } from './Txt'

/** @jsxImportSource @emotion/react */

type IProps = {
  label: string
  value: string | JSX.Element
  className?: string | undefined
}

export const Field = ({ label, value, className }: IProps) => {
  return (
    <div tw='flex flex-col justify-start' className={className}>
      <Txt md tw='text-gray-500 mb-0.5'>
        {label}
      </Txt>
      <Txt sm>{value}</Txt>
    </div>
  )
}
