import { ICSSProps } from '../../../types'
/** @jsxImportSource @emotion/react */
import { MouseEventHandler, useState } from 'react'
import { Txt } from './Txt'
import tw from 'twin.macro'
import { Loader } from './Loader'

interface IButtonProps extends ICSSProps {
  type?: any
  primary?: boolean | undefined
  light?: boolean | undefined
  cancel?: boolean | undefined
  abort?: boolean | undefined
  full?: boolean | undefined
  flat?: boolean | undefined
  icon?: any
  text: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string | undefined
  disabled?: boolean
}

export const Button: React.FC<IButtonProps> = (props: IButtonProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const primary = !props.light && !props.cancel && !props.flat && !props.abort
  const Icon = props.icon

  const handlePress = async (e: any) => {
    setIsLoading(true)
    if (props.onClick) {
      await props.onClick(e)
    }
    setIsLoading(false)
  }

  return (
    <button
      type={props.type ?? 'button'}
      className={props.className}
      disabled={props.disabled}
      css={[
        tw`border-0 rounded-md cursor-pointer flex flex-row items-center px-3 py-2.5`,
        primary &&
          tw`bg-primary-600 py-2.5 border border-width[1px] border-primary-600 hover:bg-primary-700 hover:border-primary-700`,
        props.light &&
          tw`bg-primary-100  hover:bg-primary-200  border-gray-200 border-0`,
        props.cancel &&
          tw`bg-white hover:bg-gray-50 border-gray-200 border-width[1.5px]`,
        props.abort && tw`bg-red-600 hover:bg-red-800 `,
        props.flat && tw`bg-none`,
        props.full && tw`w-full`,
        props.disabled &&
          tw`bg-gray-400 hover:bg-gray-400 border-none cursor-default`,
      ]}
      onClick={(e) => handlePress(e)}
    >
      {isLoading ? (
        <div css={[tw`flex-grow`]}>
          <Loader />
        </div>
      ) : (
        <>
          {Icon && (
            <Icon
              width={20}
              color={primary ? 'white' : props.light ? '#39749B' : '#6B7280'}
              tw='mr-3'
            />
          )}
          <Txt
            md
            css={[
              tw`flex-grow`,
              primary && tw`text-white`,
              props.light && tw`text-primary-700`,
              props.cancel && tw`text-gray-700`,
              props.abort && tw`text-white`,
              props.flat && tw`text-gray-500`,
            ]}
          >
            {props.text}
          </Txt>
        </>
      )}
    </button>
  )
}
