/** @jsxImportSource @emotion/react */
import 'twin.macro'
import React, { useEffect } from 'react'
import { useState } from 'react'
import {
  Pedestal,
  Session,
  sourceLabels,
  SourceTypeEnum,
} from '../../../../api-types'
import { CustomTable } from '../../general/table/CustomTable'
import { ISearchParams } from '../../../../types'
import { useSearch } from '../../general/hooks/useSearch'
import { TableCell } from '../../general/table/cells'
import { FilterBar } from '../../general/FilterBar'
import { InputField } from '../../general/fields/InputField'
import { SelectField } from '../../general/fields/SelectField'
import { Txt } from '../../general/Txt'
import { useSessions } from '../../../../queries'
import { DateInput } from '../../general/fields/DateInput'
import { useHistory } from 'react-router-dom'
import { PageLoader } from '../../general/PageLoader'
import {
  convertEURToHRK,
  roundToFloatWithExactlyNDecimals,
  standardizedFormatDate,
} from '../../../../utils'

interface PedestalSessionProps {
  pedestal: Pedestal
}

const initialSearchParams: Partial<ISearchParams> = {
  orderField: 'session.createdAt',
  order: 'DESC',
  term: '',
}

export const PedestalSessions = (props: PedestalSessionProps) => {
  const { pedestal } = props

  const history = useHistory()

  const initialFilters = {
    user: '',
    source: '',
    consumption: '',
    startDate: '',
    total: '',
  }
  const [filters, setFilters] = useState(initialFilters)
  const [createdAtLimit, setCreatedAtLimit] = useState<[string, string]>([
    new Date().toISOString(),
    new Date().toISOString(),
  ])

  const [searchParams, { setPage, setSearchParams, setOrder, setOrderField }] =
    useSearch(initialSearchParams)
  const { data, isLoading, isError } = useSessions(
    {
      ...searchParams,
      ...filters,
    },
    pedestal.identifier,
  )

  const handleRouteToSessionDetails = (session: Session) =>
    history.push(
      `/pedestals/view/${pedestal.identifier}/session-details/${session.id}`,
    )

  useEffect(() => {
    if (!isLoading) {
      setCreatedAtLimit([
        data.limits.createdAt.lower,
        data.limits.createdAt.upper,
      ])
    }
  }, [data.items.length, isLoading])

  if (!data || isLoading) {
    return <PageLoader />
  }

  if (isError) {
    return <div>Something went wrong</div>
  }

  return (
    <div>
      <FilterBar
        tw='my-4'
        onClearFilters={() => setSearchParams(initialSearchParams)}
      >
        <FilterBar.Select
          value={{
            order: searchParams.order,
            orderField: searchParams.orderField,
          }}
          setValue={(opt) => {
            setOrder(opt.value.order)
            setOrderField(opt.value.orderField)
          }}
          options={[
            {
              label: 'User (A-Z)',
              value: { order: 'ASC', orderField: 'user.name' },
            },
            {
              label: 'User (Z-A)',
              value: { order: 'DESC', orderField: 'user.name' },
            },
            {
              label: 'Vehicle (A-Z)',
              value: { order: 'ASC', orderField: 'vehicle.registration' },
            },
            {
              label: 'Vehicle (Z-A)',
              value: { order: 'DESC', orderField: 'vehicle.registration' },
            },
            {
              label: 'Service (A-Z)',
              value: { order: 'ASC', orderField: 'source.type' },
            },
            {
              label: 'Service (Z-A)',
              value: { order: 'DESC', orderField: 'source.type' },
            },
            {
              label: 'Start date (A-Z)',
              value: { order: 'ASC', orderField: 'session.createdAt' },
            },
            {
              label: 'Start date (Z-A)',
              value: { order: 'DESC', orderField: 'session.createdAt' },
            },
          ]}
        />
        <FilterBar.Filter
          onSubmit={setFilters}
          values={filters}
          initialValues={initialFilters}
        >
          <InputField name='user' label='User' />
          <InputField name='vehicle' label='Vehicle' />
          <SelectField
            name='source'
            label='Service'
            options={[
              { label: 'Socket', value: 'SOCKET' },
              { label: 'Valve', value: 'VALVE' },
            ]}
          />
          <DateInput
            startDateName='createdAtLower'
            endDateName='createdAtUpper'
            label='Date'
            initialStartValue={createdAtLimit[0]}
            initialEndValue={createdAtLimit[1]}
          />
        </FilterBar.Filter>
      </FilterBar>
      <CustomTable
        loading={isLoading}
        maxPage={data.totalPages}
        currentPage={searchParams.page}
        setPage={setPage}
        pageSize={searchParams.size}
        data={data.items}
        totalCount={data.count}
        onRowClick={(l) => handleRouteToSessionDetails(l)}
        columns={[
          {
            Header: 'User',
            accessor: 'user',
            cell: (l) => (
              <div>
                <TableCell.Text value={l.group.account.owner.name} />
                <TableCell.Text light value={l.vehicle.registration} />
              </div>
            ),
          },
          {
            Header: 'Service',
            accessor: 'source',
            cell: (l) => {
              return (
                <>
                  <TableCell.Text
                    light
                    value={l.source.pedestal.identifier.toUpperCase()}
                  />
                  <TableCell.Text
                    light
                    value={`${sourceLabels[l.source.type]} ${
                      l.source.position
                    }`}
                  />
                </>
              )
            },
          },
          {
            Header: 'Consumption',
            accessor: 'initialMeterState',
            cell: (l) => (
              <TableCell.Text
                light
                value={`${roundToFloatWithExactlyNDecimals(
                  l.endedAt && l.finalMeterState
                    ? l.finalMeterState - l.initialMeterState
                    : l.source.state.value - l.initialMeterState,
                  3,
                )} ${
                  l.source.type === SourceTypeEnum.SOCKET ? 'kWh' : 'm\u00B3'
                }`}
              />
            ),
          },
          {
            Header: 'Start date',
            accessor: 'createdAt',
            cell: (l) => (
              <div tw='flex flex-col'>
                <TableCell.Text value={standardizedFormatDate(l.createdAt)} />
              </div>
            ),
          },
          {
            Header: 'End date',
            accessor: 'endedAt',
            cell: (l) => (
              <div tw='flex flex-col'>
                <TableCell.Text
                  value={l.endedAt ? standardizedFormatDate(l.endedAt) : '-'}
                />
              </div>
            ),
          },
          {
            Header: 'Total',
            accessor: 'tariff',
            cell: (l) => {
              const total = roundToFloatWithExactlyNDecimals(
                (l.finalMeterState
                  ? l.finalMeterState - l.initialMeterState
                  : l.source.state.value - l.initialMeterState) * l.tariff,
                2,
              )
              return (
                <TableCell.Text
                  light
                  value={`${total} EUR (${convertEURToHRK(
                    parseFloat(total),
                  )} HRK)`}
                />
              )
            },
          },
          {
            Header: '',
            // @ts-ignore
            accessor: 'action',
            cell: (l) => {
              return (
                <a onClick={() => handleRouteToSessionDetails(l)}>
                  <Txt tw='text-primary-600'>View</Txt>
                </a>
              )
            },
          },
        ]}
      />
    </div>
  )
}
