/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { FormProvider, useForm } from 'react-hook-form'
import { InputField } from '../../common/general/fields/InputField'
import { Button } from '../../common/general/Button'
import { CustomDrawer } from '../../common/general/drawer/CustomDrawer'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Account } from '../../../api-types'
import { useQueryClient } from 'react-query'
import { queries } from '../../../queries'

export interface AddPrepaidPaymentForm {
  amount: number
}

const schema = yup.object().shape({
  amount: yup
    .number()
    .transform((_value, originalValue) =>
      Number(originalValue.replace(/,/, '.')),
    )
    .required(),
})

interface AddPrepaidPaymentDrawerProps {
  open: boolean
  close: () => void
  onSubmit(val: any): void
  customer: Account
}

export const AddPrepaidPaymentDrawer = (
  props: AddPrepaidPaymentDrawerProps,
) => {
  const { open, onSubmit, close, customer } = props
  const client = useQueryClient()

  const methods = useForm<AddPrepaidPaymentForm>({
    defaultValues: {
      amount: 0,
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const handleSubmit = methods.handleSubmit(async (data) => {
    onSubmit(data)
    methods.reset()
    await client.refetchQueries([queries.clients, customer.id])
    close()
  })

  return (
    <CustomDrawer
      open={open}
      title={'Edit prepaid funds'}
      subtitle={''}
      render={() => (
        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
          noValidate
          tw='space-y-6 flex-grow'
          style={{ padding: 24 }}
        >
          <FormProvider {...methods}>
            <div tw='flex h-full flex-col justify-between flex-grow'>
              <div tw='m-6 flex flex-grow flex-col'>
                <p tw='mb-4'>
                  If you would like to reduce the balance add a minus sign
                  before the amount.
                </p>

                <InputField
                  name='amount'
                  label='Amount'
                  type={'number'}
                  unit='EUR'
                />
              </div>
              <div>
                <div tw='flex flex-row justify-end m-4'>
                  <Button
                    text={'Close'}
                    onClick={() => {
                      methods.reset()
                      close()
                    }}
                    cancel
                    tw='mr-3'
                  />
                  <Button text={'Save'} onClick={handleSubmit} />
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      )}
      close={() => close()}
    />
  )
}
