import React from 'react'
import 'twin.macro'
import { Txt } from '../general/Txt'
/** @jsxImportSource @emotion/react */

interface HeartbeatBadgeProps {
  heartbeatStable: boolean
}

export const HeartbeatBadge = (props: HeartbeatBadgeProps) => {
  const { heartbeatStable } = props
  return (
    <>
      {heartbeatStable ? (
        <div tw='bg-green-100 py-1.5 pr-2.5 pl-2 mr-6 flex items-center rounded-full'>
          <div tw='w-2 h-2 bg-green-400 rounded-full'></div>
          <Txt xs tw='ml-1.5 text-green-800'>
            Heartbeat
          </Txt>
        </div>
      ) : (
        <div tw='bg-red-100 py-1.5 pr-2.5 pl-2 mr-6 flex items-center rounded-full'>
          <div tw='w-2 h-2 bg-red-500 rounded-full'></div>
          <Txt xs tw='ml-1.5 text-red-800'>
            Heartbeat
          </Txt>
        </div>
      )}
    </>
  )
}
