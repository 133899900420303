import 'twin.macro'

import { DownloadIcon, LocationMarkerIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'

import { Breadcrumbs } from '../../common/general/Breadcrumbs'
import { Button } from '../../common/general/Button'
import { Card } from '../../common/general/Card'
import { ContentContainer } from '../../common/general/ContentContainer'
import { Divider } from '../../common/general/Divider'
import { Pedestal } from '../../../api-types'
import { SuperadminPedestalForm } from './SuperadminPedestalForm'
import { Txt } from '../../common/general/Txt'
import { useHistory } from 'react-router-dom'
import { superadminRoutes } from '../../common/general/route-names'
import { pedestalStickerService } from '../../common/general/PedestalStickerService'

//@ts-ignore
import FileSaver from 'file-saver' // todo: remove

/** @jsxImportSource @emotion/react */

export const SuperadminNewPedestalsPage = () => {
  const [pedestals, setPedestals] = useState<Pedestal[]>([])
  const [activePage, setActivePage] = useState(1)
  const history = useHistory()

  const handleDownloadQRCode = async () => {
    await pedestalStickerService.downloadMultiple(pedestals)
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-2'
        links={[
          { name: 'Pedestals', route: '/pedestals' },
          { name: 'Add new' },
        ]}
      />

      <Txt xxl tw='mb-4'>
        Add new
      </Txt>

      {activePage === 1 && (
        <SuperadminPedestalForm
          setActivePage={setActivePage}
          setPedestals={setPedestals}
        />
      )}
      {activePage === 2 && (
        <div>
          <Card tw='mb-6 p-0'>
            <div tw='p-6'>
              <Txt xl tw='mb-1'>
                Download certificates
              </Txt>
              <Txt sm tw='mb-6 text-gray-500'>
                Downloaded certificates need to be installed in order to
                continue the configuration.
              </Txt>

              <Button
                light
                tw='mt-6'
                icon={DownloadIcon}
                text='Download all certificates'
                onClick={() => {
                  FileSaver.saveAs(
                    'https://s3.eu-west-1.amazonaws.com/media.marex-hc.com/certificates/certificates.zip',
                    'certificates.zip',
                  )
                }}
              />
            </div>
          </Card>
          <Card tw='mb-6 p-0'>
            <div tw='p-6'>
              <Txt xl tw='mb-1'>
                Download QR stickers
              </Txt>
              <Txt sm tw='mb-6 text-gray-500'>
                Downloaded stickers need to be printed out and placed properly
                onto the pedestals.
              </Txt>

              <Button
                light
                tw='mt-6'
                icon={DownloadIcon}
                text='Download all QR stickers'
                onClick={handleDownloadQRCode}
              />
            </div>
          </Card>
          {/*<Card tw='mb-6 p-0'>*/}
          {/*  <div tw='p-6'>*/}
          {/*    <Txt xl tw='mb-1'>*/}
          {/*      Download QR stickers*/}
          {/*    </Txt>*/}
          {/*    <Txt sm tw='mb-6 text-gray-500'>*/}
          {/*      Downloaded stickers need to be printed out and placed properly*/}
          {/*      onto the pedestals.*/}
          {/*    </Txt>*/}

          {/*    <Button*/}
          {/*      light*/}
          {/*      tw='mt-6'*/}
          {/*      icon={DownloadIcon}*/}
          {/*      text='Download all QR stickers'*/}
          {/*      onClick={() => {}}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</Card>*/}
          <Card tw='mb-6 p-0'>
            <div tw='flex flex-col mt-6 cursor-pointer'>
              {pedestals.map((p, i) => {
                return (
                  <React.Fragment key={i}>
                    <div
                      tw='flex flex-col flex-grow px-6 py-4 items-start'
                      onClick={() =>
                        history.push(
                          `${superadminRoutes.PEDESTALS_CONFIGURATION}/${p.identifier}`,
                        )
                      }
                    >
                      <Txt md tw='text-primary-600 mb-2'>
                        {p.identifier}
                      </Txt>
                      <div tw='flex flex-row items-center -ml-1'>
                        <LocationMarkerIcon tw='h-6 w-6 mr-2' color='#C4C4C4' />
                        <Txt sm tw='text-gray-500'>
                          {p.location.name}
                        </Txt>
                      </div>
                    </div>
                    <Divider />
                  </React.Fragment>
                )
              })}
            </div>
          </Card>

          <div tw='flex flex-row justify-end'>
            <Button
              text='Finish for now'
              cancel
              onClick={() => history.push(superadminRoutes.PEDESTALS)}
            />
          </div>
        </div>
      )}
    </ContentContainer>
  )
}
