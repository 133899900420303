import 'twin.macro'

/** @jsxImportSource @emotion/react */
import React from 'react'
import { Socket, Valve } from '../../../api-types'

export const AdminSourceCardContainer: React.FC<{
  className?: string
  onClick: (source: Valve | Socket) => void
  source: Valve | Socket
}> = (props) => {
  return (
    <div
      className={props.className}
      tw='bg-white rounded-lg cursor-pointer'
      style={{
        boxShadow:
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
      }}
      onClick={() => props.onClick(props.source)}
    >
      {props.children}
    </div>
  )
}
