/** @jsxImportSource @emotion/react */
import 'twin.macro'

import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { InputField } from '../../common/general/fields/InputField'
import { Txt } from '../../common/general/Txt'
import { SelectField } from '../../common/general/fields/SelectField'
import { PaymentTypeEnum } from '../../../api-types'
import { Button } from '../../common/general/Button'
import {
  useCurrentLocation,
  useUpdateLocationPaymentInfo,
} from '../../../queries'

interface PaymentInfoSettings {
  defaultPaymentType: PaymentTypeEnum
  defaultNumberOfDaysUntilPayment: number
  powerTariff: number
  waterTariff: number
  defaultMaxActivePowerSessions: number
  defaultMaxActiveWaterSessions: number
}

const schema = yup.object().shape({
  defaultPaymentType: yup
    .mixed<keyof typeof PaymentTypeEnum>()
    .oneOf(Object.values(PaymentTypeEnum)),
  defaultNumberOfDaysUntilPayment: yup.number().positive().required(),
  powerTariff: yup.number().positive().required(),
  waterTariff: yup.number().positive().required(),
  defaultMaxActivePowerSessions: yup.number().min(0).required(),
  defaultMaxActiveWaterSessions: yup.number().min(0).required(),
})

export const PaymentSettings = () => {
  const { data: location, refetch } = useCurrentLocation()
  const updateLocationPaymentInfo = useUpdateLocationPaymentInfo()

  const defaultValues = {
    defaultPaymentType: location?.constants.defaultPaymentType,
    defaultNumberOfDaysUntilPayment:
      location?.constants?.defaultNumberOfDaysUntilPayment,
    powerTariff: location?.constants?.powerTariff,
    waterTariff: location?.constants?.waterTariff,
    defaultMaxActivePowerSessions:
      location?.constants.defaultMaxActivePowerSessions,
    defaultMaxActiveWaterSessions:
      location?.constants.defaultMaxActiveWaterSessions,
  }

  const methods = useForm<PaymentInfoSettings>({
    defaultValues: { ...(defaultValues ?? {}) },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  useEffect(() => {
    methods.reset({ ...(defaultValues ?? {}) })
  }, [location])

  const onSubmit = methods.handleSubmit(async (data) => {
    await updateLocationPaymentInfo(data)
    await refetch()
  })

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} noValidate tw='space-y-6 flex-grow'>
          <div
            id='customer-payment-info'
            tw='bg-white rounded-lg p-6 mb-5'
            style={{
              boxShadow:
                '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
              marginTop: 24,
            }}
          >
            <Txt xl tw='mb-2'>
              Payment setup
            </Txt>
            <Txt tw='mb-2 text-gray-400'>WSpay Configuration</Txt>
          </div>
          <div
            id='customer-payment-info'
            tw='bg-white rounded-lg p-6 divide-y mb-5'
            style={{
              boxShadow:
                '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
              marginTop: 24,
            }}
          >
            <div>
              <Txt xl tw='mb-2'>
                Payment rules
              </Txt>
              <Txt tw='mb-2 text-gray-400'>Default payment ruels for users</Txt>
            </div>
            <div tw='pt-3'>
              <Txt>Default Payment Type</Txt>
              <SelectField
                name='defaultPaymentType'
                options={Object.keys(PaymentTypeEnum).map((key) => ({
                  label: key,
                  value: key,
                }))}
              />
            </div>
            <div tw='pt-3'>
              <Txt>Default due date</Txt>
              <InputField
                type='number'
                name='defaultNumberOfDaysUntilPayment'
                unit='days after'
              />
            </div>
            <div tw='pt-3'>
              <Txt>Default power tariff</Txt>
              <InputField type='number' name='powerTariff' unit='EUR/kWh' />
            </div>
            <div tw='pt-3'>
              <Txt>Default water tariff</Txt>
              <InputField
                type='number'
                name='waterTariff'
                unit={`EUR/m\u00B3`}
              />
            </div>
          </div>
          <div
            id='customer-payment-info'
            tw='bg-white rounded-lg p-6 divide-y mb-5'
            style={{
              boxShadow:
                '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
              marginTop: 24,
            }}
          >
            <div>
              <Txt xl tw='my-2'>
                Session permissions
              </Txt>
              <Txt tw='mb-2 text-gray-400'>
                Default sessions permissions for users
              </Txt>
            </div>
            <div tw='pt-3'>
              <Txt>Max active power sessions</Txt>
              <InputField type='number' name='defaultMaxActivePowerSessions' />
            </div>
            <div tw='pt-3'>
              <Txt>Max active water sessions</Txt>
              <InputField type='number' name='defaultMaxActiveWaterSessions' />
            </div>
          </div>
          <Button tw='' primary type='submit' text='Save' onClick={onSubmit} />
        </form>
      </FormProvider>
    </>
  )
}
