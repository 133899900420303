/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ActionBar } from '../../common/general/ActionBar'
import { Button } from '../../common/general/Button'
import { ContentContainer } from '../../common/general/ContentContainer'
import { CustomTable } from '../../common/general/table/CustomTable'
import { FilterBar } from '../../common/general/FilterBar'
import { ISearchParams } from '../../../types'
import { InputField } from '../../common/general/fields/InputField'
import { LocationStatus } from '../../../api-types'
import { PlusIcon } from '@heroicons/react/solid'
import { SelectField } from '../../common/general/fields/SelectField'
import { TableCell } from '../../common/general/table/cells'
import { locationStatusMeta } from '../../common/general/utils'
import { useHistory } from 'react-router-dom'
import { useLocations } from '../../../queries'
import { useSearch } from '../../common/general/hooks/useSearch'
import { useState } from 'react'
import { superadminRoutes } from '../../common/general/route-names'
import { Txt } from '../../common/general/Txt'
import { PageLoader } from '../../common/general/PageLoader'

export const SuperadminLocationsPage = () => {
  const history = useHistory()

  const initialSearchParams: Partial<ISearchParams> = {
    term: '',
    orderField: 'name',
    order: 'ASC',
  }
  const initialFilters = {
    city: '',
    country: '',
    status: undefined,
  }

  const [filters, setFilters] = useState(initialFilters)
  const [
    searchParams,
    { setSearchParams, setOrder, setOrderField, setPage, setTerm },
  ] = useSearch(initialSearchParams)

  const { data, isLoading } = useLocations({ ...searchParams, ...filters })

  return (
    <ContentContainer>
      <ActionBar title='Locations' tw='mb-5'>
        <Button
          primary
          icon={PlusIcon}
          text='Add New'
          onClick={() => history.push(superadminRoutes.NEW_LOCATION)}
        />
      </ActionBar>
      <FilterBar
        tw='mb-4'
        onClearFilters={() => setSearchParams(initialSearchParams)}
      >
        <FilterBar.Select
          value={{
            order: searchParams.order,
            orderField: searchParams.orderField,
          }}
          setValue={(opt) => {
            setOrder(opt.value.order)
            setOrderField(opt.value.orderField)
          }}
          options={[
            {
              label: 'Name (A-Z)',
              value: { order: 'ASC', orderField: 'name' },
            },
            {
              label: 'Name (Z-A)',
              value: { order: 'DESC', orderField: 'name' },
            },
            {
              label: 'Status (A-Z)',
              value: { order: 'ASC', orderField: 'status' },
            },
            {
              label: 'Status (Z-A)',
              value: { order: 'DESC', orderField: 'status' },
            },
          ]}
        />
        <FilterBar.Filter
          onSubmit={setFilters}
          values={filters}
          initialValues={initialFilters}
        >
          <InputField name='city' label='City' />
          <InputField name='country' label='Country' />
          <SelectField
            name='status'
            label='Status'
            options={[
              { label: 'Active', value: LocationStatus.ACTIVE },
              { label: 'Inactive', value: LocationStatus.INACTIVE },
              { label: 'Test', value: LocationStatus.TEST },
            ]}
          />
        </FilterBar.Filter>
        <FilterBar.TermSearch setValue={setTerm} placeholder='Search...' />
      </FilterBar>
      {isLoading || !data ? (
        <PageLoader />
      ) : (
        <CustomTable
          loading={isLoading}
          maxPage={data.totalPages}
          currentPage={searchParams.page}
          setPage={setPage}
          pageSize={searchParams.size}
          data={data.items}
          totalCount={data.count}
          onRowClick={(data) => {
            history.push(`${superadminRoutes.EDIT_LOCATION}/${data.id}`)
          }}
          mobileColumns={[
            {
              Header: 'Name',
              accessor: 'name',
              cell: (l) => <TableCell.Text value={l.name} />,
            },
            {
              Header: 'Status',
              accessor: 'type',
              cell: (l) => {
                const meta = locationStatusMeta[l.status]
                return (
                  <TableCell.Chip value={meta.value} {...meta.colorProps} />
                )
              },
            },
          ]}
          columns={[
            {
              Header: 'Name',
              accessor: 'name',
              cell: (l) => (
                <div tw='flex items-center'>
                  {l.logoUrl && (
                    <img
                      tw='rounded-3xl bg-cover mr-4 w-10 h-10'
                      src={l.logoUrl}
                    />
                  )}
                  <TableCell.Text value={l.name} />
                </div>
              ),
            },
            {
              Header: 'Location',
              accessor: 'address',
              cell: (l) => (
                <div tw='flex flex-col'>
                  <TableCell.Text value={l.city} />
                  <TableCell.Text light value={l.country} />
                </div>
              ),
            },
            {
              Header: 'Status',
              accessor: 'type',
              cell: (l) => {
                const meta = locationStatusMeta[l.status]
                return (
                  <TableCell.Chip value={meta.value} {...meta.colorProps} />
                )
              },
            },
            {
              Header: 'Pedestals',
              accessor: 'pedestals',
              cell: (l) => <TableCell.Text value={l.pedestals.length} />,
            },
            {
              Header: '',
              // @ts-ignore
              accessor: 'action',
              cell: (l) => {
                return (
                  <a
                    onClick={() => {
                      history.push(`${superadminRoutes.EDIT_LOCATION}/${l.id}`)
                    }}
                  >
                    <Txt tw='text-primary-600'>Edit</Txt>
                  </a>
                )
              },
            },
          ]}
        />
      )}
    </ContentContainer>
  )
}
