import dayjs from 'dayjs'

class HeartbeatService {
  getHeartbeatStable(lastHartbeatReceivedOn: Date, thart = 1800): boolean {
    const maxTimeDiff = (thart / 2 + 60) * 1000
    const timeDiff = dayjs
      .utc()
      .diff(dayjs(lastHartbeatReceivedOn), 'milliseconds')
    return timeDiff <= maxTimeDiff
  }
}

export const heartbeatService = new HeartbeatService()
