import { WorkingHoursInfo } from './pages/superadmin/location-new/SuperadminNewLocationPage'

export enum UserRole {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  MARINA_OPERATOR = 'MARINA_OPERATOR',
}

export const userRoleLabels = {
  [UserRole.ADMIN]: 'Admin',
  [UserRole.SUPERADMIN]: 'SuperAdmin',
  [UserRole.CLIENT]: 'Client',
  [UserRole.MARINA_OPERATOR]: 'Marina operator',
}

export enum UserStatusEnum {
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export enum UserStatusLabelEnum {
  INVITED = 'Invited',
  ACTIVE = 'Active',
  DEACTIVATED = 'Deactivated',
}

export const userStatusLabels = {
  [UserStatusEnum.INVITED]: UserStatusLabelEnum.INVITED,
  [UserStatusEnum.ACTIVE]: UserStatusLabelEnum.ACTIVE,
  [UserStatusEnum.DEACTIVATED]: UserStatusLabelEnum.DEACTIVATED,
}

export interface User {
  id: number
  email: string
  newEmail: string
  name: string
  role: UserRole
  phoneNumber: string
  country: string
  status: UserStatusEnum
  username: string
  workingLocation?: Location
  vehicles: Vehicle[]
  deletedAt: Date
  accounts: Account[]
  ownedCharter?: Charter
  charter?: Charter
}

export interface Facility {
  id: string
  name: string
  iconUrl: string
}

export enum AccountTypeEnum {
  STANDARD = 'STANDARD',
  CHARTER = 'CHARTER',
}

export enum PaymentTypeEnum {
  PREPAID = 'PREPAID',
  POSTPAID = 'POSTPAID',
  PERIODICAL = 'PERIODICAL',
}

export enum LocationTypeEnum {
  MARINA = 'MARINA',
  HARBOR = 'HARBOR',
  CAMP = 'CAMP',
  OTHER = 'OTHER',
}

export enum SourceLabelEnum {
  POWER = 'Power',
  WATER = 'Water',
}

export enum PowerLabelEnum {
  SINGLE = 'Mono-phase',
  TRIPLE = 'Three-phase',
}

export enum SourceTypeEnum {
  SOCKET = 'SOCKET',
  VALVE = 'VALVE',
}
export enum PhaseTypeEnum {
  MONO_PHASE = 'MONO_PHASE',
  THREE_PHASE = 'THREE_PHASE',
}

export const sourceLabels = {
  [SourceTypeEnum.SOCKET]: SourceLabelEnum.POWER,
  [SourceTypeEnum.VALVE]: SourceLabelEnum.WATER,
}

export const powerLabels = {
  [PhaseTypeEnum.MONO_PHASE]: PowerLabelEnum.SINGLE,
  [PhaseTypeEnum.THREE_PHASE]: PowerLabelEnum.TRIPLE,
}

export enum LocationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  TEST = 'TEST',
}

export enum SessionStatus {
  PAID = 'PAID',
  IN_USE = 'IN_USE',
  UNPAID = 'UNPAID',
}

export enum SessionEventType {
  USER = 'USER',
  DANGER = 'DANGER',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}

export enum PedestalStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  READY_TO_CONFIGURE = 'READY_TO_CONFIGURE',
  READY_TO_TEST = 'READY_TO_TEST',
  READY_TO_INSTALL = 'READY_TO_INSTALL',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
}

export interface PlcConstants {
  id?: number
  twait: number
  treset: number
  tshutdown: number
  tupdatecycle: number
  twateroff: number
  tfilt: number
  tmodbus: number
  thart: number
}

export enum PedestalLogsTypes {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}

export interface PedestalLog {
  type: PedestalLogsTypes
  message: string
  createdAt: Date
  timestamp: Date
}

export interface Pedestal {
  id: number
  identifier: string
  status: PedestalStatus
  hasHydrostat: boolean
  hasLuxomat: boolean
  pedestalType?: PedestalType
  location: Location
  sources: Source[]
  sockets: Socket[]
  valves: Valve[]
  plcConstants: PlcConstants
  luxomatOn: boolean
  hydrostatOn: boolean
  luxomatStatus: boolean
  hydrostatStatus: boolean
  valvesManualModeOn: boolean
  socketsManualModeOn: boolean
  lastHartbeatReceivedOn: Date
  logs: PedestalLog[]
  alarms: Alarm[]
  unresolvedAlarms: Alarm[]
  charter: Charter[]
}
export enum SourceTestStatuses {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESSFUL = 'SUCCESSFUL',
  INCONSISTENT = 'INCONSISTENT',
}

export interface SignedUrl {
  uploadUrl: string
  downloadUrl: string
}

export interface SourceTest {
  id: number
  status: SourceTestStatuses
  expectedValue: number
  duration: number
  actualValue: number
  endedAt: Date
}

export interface SocketState {
  activated: boolean
  value: number
  voltage: number
  current: number
  frequency: number
  fuseStable: boolean
  socketStable: boolean
  modbusStable: boolean
  contactorCmd: boolean
}

export interface Charter {
  id: number
  owner: User
  employees?: User[]
  dedicatedPedestals?: Pedestal[]
  deletedAt?: Date
  createdAt?: Date
  updatedAt?: Date
}

export interface Account {
  id: number
  balance: number
  type: AccountTypeEnum
  numberOfDaysUntilPayment: number
  owner: User
  paymentType: PaymentTypeEnum
  maxActivePowerSessions: number
  maxActiveWaterSessions: number
  deletedAt?: Date
  createdAt?: Date
  updatedAt?: Date
  location?: Location
  powerTariff?: number
  waterTariff?: number
}
export interface Alarm {
  id?: number
  type: AlarmTypeEnum
  isSent: boolean
  resolved: boolean
  message: string
  location: Location
  pedestal: Pedestal
  source: Source
  createdAt: Date
}

export enum AlarmTypeEnum {
  FUSE = 'FUSE',
  MODBUS = 'MODBUS',
  HEARTBEAT = 'HEARTBEAT',
}

export enum ReceiptStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
}

export interface Receipt {
  id: number
  transactionId: string
  account: Account
  paidAt?: Date
  createdAt: Date
  status?: ReceiptStatus
  paymentType: PaymentTypeEnum
  periodStartDate: Date
  periodEndDate: Date
  items?: ReceiptItem[]
}

export interface ReceiptItem {
  consumption: number
  createdAt: Date
  deletedAt: Date
  endDate: Date
  id: number
  service: string
  pedestalIdentifier: string
  phaseType?: PhaseTypeEnum
  sourceType: SourceTypeEnum
  startDate: Date
  tariff: number
  updatedAt: Date
  vehicleRegistration: string
  vehicleType: string
}

export interface UserExistsInfo {
  exists: boolean
  userData: {
    name: string
    phoneNumber: string
    email: string
    country: string
  }
}

export interface Session {
  id: number
  createdAt: Date
  endedAt: Date
  tariff: number
  initialMeterState: number
  finalMeterState?: number
  source: Source
  vehicle: Vehicle
  group: SessionGroup
  user: User
  activatedBy?: User
  isManual: boolean
}

export interface SessionGroup {
  id: number
  createdAt: string
  updatedAt: string
  deletedAt: string
  endedAt: string
  account: Account
  receipt: Receipt[]
  sessions?: Session[]
}

export enum SessionRequestStatusEnum {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  PENDING = 'PENDING',
}

export enum SessionRequestActionEnum {
  ON = 'ON',
  OFF = 'OFF',
}

export type SessionRequest = {
  id: number
  account: Account
  pedestal: Pedestal
  source: Source
  vehicle: Vehicle
  activatedBy: User
  status: SessionRequestStatusEnum
  action: SessionRequestActionEnum
}

export enum VehicleTypeEnum {
  BOAT = 'BOAT',
  CAMPING_VAN = 'CAMPING_VAN',
}

enum VehicleTypeLabelEnum {
  BOAT = 'Boat',
  CAMPING_VAN = 'Camper van',
}

export const vehicleTypeLabels = {
  [VehicleTypeEnum.BOAT]: VehicleTypeLabelEnum.BOAT,
  [VehicleTypeEnum.CAMPING_VAN]: VehicleTypeLabelEnum.CAMPING_VAN,
}

export interface Vehicle {
  id: number
  createdAt: string
  updatedAt: string
  deletedAt: string
  type: VehicleTypeEnum
  registration: string
  length: number
  user: User
}

export interface ValveState {
  activated: boolean
  value: number
  valveCmd: boolean
}

export interface Valve {
  id: number
  type: SourceTypeEnum
  pedestal: Pedestal
  position: number
  connectionSize: string
  tests: SourceTest[]
  state: ValveState
  lastTest?: SourceTest
  activeSession: Session
  status: SourceStatusEnum
}

export interface Socket {
  id: number
  type: SourceTypeEnum
  phase: PhaseTypeEnum
  current: number
  pedestal: Pedestal
  position: number
  tests: SourceTest[]
  state: SocketState
  lastTest?: SourceTest
  activeSession: Session
  status: SourceStatusEnum
}

export enum SourceStatusEnum {
  AVAILABLE = 'AVAILABLE',
  IN_USE = 'IN_USE',
  PLUGGED_IN = 'PLUGGED_IN',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
  MANUAL_MODE = 'MANUAL_MODE',
  STATUS_UNAVAILABLE = 'STATUS_UNAVAILABLE',
  REQUEST_PENDING = 'REQUEST_PENDING',
}

export enum SourceStatusLabelEnum {
  AVAILABLE = 'Available',
  IN_USE = 'In use',
  PLUGGED_IN = 'Plugged in',
  OUT_OF_SERVICE = 'Out of service',
  MANUAL_MODE = 'Manual mode',
  STATUS_UNAVAILABLE = 'Status unavailable',
  REQUEST_PENDING = 'Request pending',
}

export const SourceStatusLabels = {
  [SourceStatusEnum.AVAILABLE]: SourceStatusLabelEnum.AVAILABLE,
  [SourceStatusEnum.IN_USE]: SourceStatusLabelEnum.IN_USE,
  [SourceStatusEnum.PLUGGED_IN]: SourceStatusLabelEnum.PLUGGED_IN,
  [SourceStatusEnum.OUT_OF_SERVICE]: SourceStatusLabelEnum.OUT_OF_SERVICE,
  [SourceStatusEnum.STATUS_UNAVAILABLE]:
    SourceStatusLabelEnum.STATUS_UNAVAILABLE,
  [SourceStatusEnum.REQUEST_PENDING]: SourceStatusLabelEnum.REQUEST_PENDING,
  [SourceStatusEnum.MANUAL_MODE]: SourceStatusLabelEnum.MANUAL_MODE,
}

export type Source = Valve | Socket

export interface LocationConstants {
  minLightRequests: number
  turnOnLightsTime: number
  turnOffLightsTime: number
  minHeatRequests: number
  berthCount: number
  maxVehicleLength: number
  maxVehicleBeamLength: number
  maxSocketSize?: string
  waterSocketSize?: string
  portDepthRange: number
  waterTariff: number
  powerTariff: number
  defaultPaymentType: PaymentTypeEnum
  defaultNumberOfDaysUntilPayment: number
  defaultMaxActivePowerSessions: number
  defaultMaxActiveWaterSessions: number
  openHours: any
  visible: boolean
}
export interface Location {
  id: number
  type: LocationTypeEnum
  status: LocationStatus
  name: string
  country: string
  city: string
  address: string
  postcode: string
  description: string
  logoUrl: string
  imageUrl: string
  thumbnailUrl: string
  websiteUrl: string
  mapUrl: string
  phone: string
  email: string
  latitude: number
  longitude: number
  defaultAccountType: AccountTypeEnum
  pedestals: Pedestal[]
  facilities: Facility[]
  pedestalTypes: PedestalType[]
  plcConstants: PlcConstants
  constants: LocationConstants
  employees: User[]
  workingHours: WorkingHoursInfo[]
}

export interface CurrentConfiguration {
  modbus?: string
  impulses?: string
  phases: PhaseTypeEnum
  power: string
}
export interface WaterConfiguration {
  impulses: string
  size: string
}
export interface PedestalType {
  id: number
  name: string
  configuration: {
    currents: CurrentConfiguration[]
    waters: WaterConfiguration[]
  }
}
