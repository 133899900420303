/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { CardWithHeader } from '../general/CardWithHeader'
import { Field } from '../general/Field'
import { Session, vehicleTypeLabels } from '../../../api-types'
import { Link } from 'react-router-dom'
import { adminRoutes } from '../general/route-names'

interface ISessionCustomerDetailsSection {
  session: Session
}

export const SessionCustomerDetailsSection: React.FC<ISessionCustomerDetailsSection> =
  (props: ISessionCustomerDetailsSection) => {
    const { session } = props

    return (
      <CardWithHeader tw='mb-6' title='Customer Details'>
        <div tw='flex flex-col space-y-8'>
          <div tw='flex flex-row'>
            <Link
              to={{
                pathname: `${adminRoutes.VIEW_CUSTOMER}/${session.group.account.id}`,
              }}
              tw='flex-1'
            >
              <Field
                tw='flex-1'
                label='Full name'
                value={session.group.account.owner.name}
              />
            </Link>
            <Field
              tw='flex-1'
              label='Email address'
              value={session.group.account.owner.email}
            />
          </div>
          <div tw='flex flex-row'>
            <Field
              tw='flex-1'
              label='Vehicle type'
              value={vehicleTypeLabels[session.vehicle.type]}
            />
            <Field
              tw='flex-1'
              label='Vehicle registration'
              value={session.vehicle.registration}
            />
          </div>
        </div>
      </CardWithHeader>
    )
  }
