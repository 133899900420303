import 'twin.macro'

/** @jsxImportSource @emotion/react */
import { SimpleInputField } from '../../../general/fields/SimpleInputField'
import { Txt } from '../../../general/Txt'

interface IWaterFieldArray {
  className?: string | undefined
}

export const SuperadminWaterFieldArray = (props: IWaterFieldArray) => {
  const renderSection = (index: number) => (
    <div tw='flex flex-row'>
      <div tw='flex items-center'>
        <Txt tw='text-primary-600 ml-1 mr-5 tabular-nums' xxxl>
          {index + 1}
        </Txt>
      </div>
      <div tw='flex flex-col flex-grow'>
        <SimpleInputField
          name={`configuration.waters[${index}].impulses`}
          placeholder={`Impulses ${index + 1}`}
          tw='rounded-br-none rounded-bl-none border-b-0'
        />
        <SimpleInputField
          name={`configuration.waters[${index}].size`}
          placeholder={`Size`}
          rightText='“'
          tw='rounded-tr-none rounded-tl-none'
        />
      </div>
    </div>
  )

  return (
    <div tw='flex flex-col flex-grow' className={props.className}>
      <div tw='mb-2'>
        <label tw='font-sans font-medium text-sm text-gray-700'>Waters</label>
      </div>
      <div tw='space-y-6'>
        {renderSection(0)}
        {renderSection(1)}
        {renderSection(2)}
        {renderSection(3)}
      </div>
    </div>
  )
}
