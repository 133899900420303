import React, { useEffect, useState } from 'react'
import 'twin.macro'
import {
  LocationMarkerIcon,
  PencilIcon,
  TemplateIcon,
} from '@heroicons/react/solid'
import { heartbeatService } from '../../common/general/heartbeat-service'
import { Link, useHistory, useParams } from 'react-router-dom'
import { PedestalLogs } from '../../common/pedestal-view/logs/PedestalLogs'
import { PedestalStatuses } from '../../common/pedestal-view/pedestal-statuses/PedestalStatuses'
import { useSuperAdminPedestal } from '../../../queries'
import { superadminRoutes } from '../../common/general/route-names'
import { HeartbeatBadge } from '../../common/pedestal-view/HeartbeatBadge'
import { ContentContainer } from '../../common/general/ContentContainer'
import { Breadcrumbs } from '../../common/general/Breadcrumbs'
import { Txt } from '../../common/general/Txt'
import { Tabs } from '../../common/general/Tabs'
import { Button } from '../../common/general/Button'
import { DownloadDropdown } from '../../common/pedestal-view/DownloadDropdown'
/** @jsxImportSource @emotion/react */
//@ts-ignore
import FileSaver from 'file-saver' // todo: remove
import { PedestalSessions } from '../../common/pedestal/sessions/PedestalSessions'

export const PedestalViewPage = () => {
  const { identifier } = useParams<{ identifier: string }>()
  const { data: pedestal, isLoading: pedestalLoading } =
    useSuperAdminPedestal(identifier)
  const history = useHistory()
  const [heartbeat, setHeartbeat] = useState<boolean>(false)

  useEffect(() => {
    if (pedestal) {
      const { location, plcConstants } = pedestal
      const heartbeat = heartbeatService.getHeartbeatStable(
        pedestal.lastHartbeatReceivedOn,
        plcConstants?.thart ?? location.plcConstants.thart,
      )
      setHeartbeat(heartbeat)
    }
  }, [pedestal])

  if (!pedestal || pedestalLoading) {
    return null
  }

  const handleEditConfiguration = () => {
    history.push(`${superadminRoutes.PEDESTALS_CONFIGURATION}/${identifier}`)
  }

  const handleDownloadCertificate = () => {
    FileSaver.saveAs(
      'https://s3.eu-west-1.amazonaws.com/media.marex-hc.com/certificates/certificates.zip',
      'certificates.zip',
    )
  }

  const handleDownloadQRCode = () => {
    const qrCodeLink = `https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data=https://marex-hc.com/home/pedestal/${identifier}`
    window.open(qrCodeLink)
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-4'
        links={[
          { name: 'Pedestals', route: superadminRoutes.PEDESTALS },
          { name: pedestal.identifier },
        ]}
      />

      <div tw='flex flex-wrap'>
        <div>
          <Txt xxl tw='mb-2'>
            {pedestal.identifier}
          </Txt>

          <div tw='flex flex-row mb-6 items-center'>
            <HeartbeatBadge heartbeatStable={heartbeat} />
            <Link
              to={{
                pathname: `${superadminRoutes.EDIT_LOCATION}/${pedestal.location.id}`,
              }}
              tw='flex items-center'
            >
              <LocationMarkerIcon tw='h-5 w-5 mr-2' color='#9CA3AF' />
              <Txt tw='text-gray-500'>{pedestal.location.name}</Txt>
            </Link>
            {pedestal.pedestalType && (
              <>
                <TemplateIcon tw='h-5 w-5 mr-2 ml-6' color='#9CA3AF' />
                <Txt tw='text-gray-500'>{pedestal.pedestalType.name}</Txt>
              </>
            )}
          </div>
        </div>
        <div tw='flex items-center ml-auto'>
          <Button
            onClick={handleEditConfiguration}
            tw='mr-3'
            text='Edit Configuration'
            icon={PencilIcon}
            cancel
          />
          <DownloadDropdown
            onDownloadCertificatesClick={handleDownloadCertificate}
            onDownloadQrClick={handleDownloadQRCode}
          />
        </div>
      </div>

      <Tabs
        items={[
          {
            title: 'Statuses',
            content: <PedestalStatuses pedestal={pedestal} />,
          },
          {
            title: 'Logs',
            content: <PedestalLogs pedestal={pedestal} />,
          },
          {
            title: 'Sessions',
            content: <PedestalSessions pedestal={pedestal} />,
          },
        ]}
      />
    </ContentContainer>
  )
}
