/** @jsxImportSource @emotion/react */
import { FieldArrayWithId } from 'react-hook-form'
import 'twin.macro'
import { ILocationForm } from '../../superadmin/location-new/SuperadminNewLocationPage'

import { Card } from '../general/Card'
import { InputField } from '../general/fields/InputField'
import { SwitchField } from '../general/fields/SwitchField'
import { Txt } from '../general/Txt'

interface ISuperAdminWorkingHoursFormSectionProps {
  fields: FieldArrayWithId<ILocationForm, 'workingHours', 'id'>[]
}

export const SuperAdminWorkingHoursFormSection = ({
  fields,
}: ISuperAdminWorkingHoursFormSectionProps) => {
  const daysOfTheWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]
  return (
    <Card tw='mb-6'>
      <Txt xl tw='mb-1'>
        Working hours
      </Txt>
      <Txt sm tw='mb-6 text-gray-500'>
        Select working days and add working hours
      </Txt>
      <div tw='flex flex-col'>
        {fields.map((field, index) => {
          return (
            <div key={field.id} tw='flex flex-row justify-around'>
              <div tw='flex flex-grow flex-row w-1/4'>
                <SwitchField
                  name={`workingHours.${index}.isDaySelected`}
                  label={daysOfTheWeek[index]}
                />
              </div>
              <div tw='flex flex-row w-3/4'>
                <InputField
                  name={`workingHours.${index}.openingTime`}
                  label='Opening Time'
                  tw='flex-grow mr-4'
                />
                <InputField
                  name={`workingHours.${index}.closingTime`}
                  label='Closing Time'
                  tw='flex-grow'
                />
              </div>
            </div>
          )
        })}
      </div>
    </Card>
  )
}
