import 'twin.macro'

/** @jsxImportSource @emotion/react */
import React from 'react'
import { Txt } from './Txt'

interface IErrorTextProps {
  className?: string
}

export const ErrorText: React.FC<IErrorTextProps> = (props) => {
  return (
    <Txt {...props} sm tw='text-error'>
      {props.children}
    </Txt>
  )
}
