import { Component } from 'react'

export class SVGUrl extends Component {
  state = {
    svg: null,
    loading: false,
  }

  componentDidMount() {
    // @ts-ignore
    fetch(this.props.src)
      .then((res) => res.text())
      .then((text) => this.setState({ svg: text }))
  }

  render() {
    const { loading, svg } = this.state
    if (loading) {
      return <div className='spinner' />
    } else if (!svg) {
      return <div className='error' />
    }
    return (
      <div
        // @ts-ignore
        id={this.props.id}
        // @ts-ignore
        dangerouslySetInnerHTML={{ __html: this.state.svg }}
      />
    )
  }
}
