import 'twin.macro'

import * as yup from 'yup'

import { FormProvider, useForm } from 'react-hook-form'
import React, { useCallback } from 'react'

import { Button } from '../general/Button'
import { ErrorText } from '../general/ErrorText'
/** @jsxImportSource @emotion/react */
import { LockClosedIcon } from '@heroicons/react/solid'
import { SimpleInputField } from '../general/fields/SimpleInputField'
import { http } from '../../../http'
import { mapErrorToCode } from '../../../utils'
import { queries } from '../../../queries'
import { showErrorNotification } from '../general/notification'
import { useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { publicRoutes } from '../general/route-names'
import { Txt } from '../general/Txt'

interface ILoginForm {
  username: string
  password: string
  rememberMe: boolean
}

const schema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
  rememberMe: yup.boolean(),
})

export const LoginForm: React.FC = () => {
  const client = useQueryClient()
  const methods = useForm<ILoginForm>({
    defaultValues: {
      username: '',
      password: '',
      rememberMe: true,
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    methods.handleSubmit(async (data) => {
      try {
        const { token } = await http.login(data)
        if (data.rememberMe) {
          window.localStorage.setItem('token', token)
        } else {
          window.sessionStorage.setItem('token', token)
        }
        client.invalidateQueries(queries.loggedInUser)
      } catch (e) {
        const status = mapErrorToCode(e)
        const message =
          status === 401 ? 'Wrong username or password' : 'Something went wrong'
        showErrorNotification(message)
      }
    }),
    [],
  )
  const errors = Object.values(methods.formState.errors)

  return (
    <form onSubmit={onSubmit} noValidate tw='w-full'>
      <FormProvider {...methods}>
        <div>
          <SimpleInputField
            name='username'
            placeholder='Username'
            type='username'
            tw='rounded-br-none rounded-bl-none border-b-0'
          />
          <SimpleInputField
            name='password'
            placeholder='Password'
            type='password'
            tw='rounded-tr-none rounded-tl-none'
          />
        </div>
        <div tw='flex flex-col space-y-1 mb-4 mt-2'>
          {errors.map((e) => (
            <ErrorText key={e.message}>{e.message}</ErrorText>
          ))}
        </div>
        <div tw='flex mb-6'>
          <Txt>
            <label>
              <input {...methods.register('rememberMe')} type='checkbox' />
              <span tw='ml-2'>Remember me</span>
            </label>
          </Txt>
          <Txt tw='ml-auto text-primary-600'>
            <a href={publicRoutes.FORGOT_PASSWORD}>Forgot your password?</a>
          </Txt>
        </div>
        <Button
          text='Sign in'
          type='submit'
          icon={LockClosedIcon}
          full
          primary
          onClick={() => {}}
        />
      </FormProvider>
    </form>
  )
}
