/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ActionBar } from '../../common/general/ActionBar'
import { ContentContainer } from '../../common/general/ContentContainer'
import { Tabs } from '../../common/general/Tabs'
import { AdminUserList } from './AdminUserList'
import { InfoSettings } from './InfoSettings'
import { PaymentSettings } from './PaymentSettings'

export const AdminSettings = () => {
  return (
    <ContentContainer>
      <ActionBar title='Settings' tw='mb-5'></ActionBar>
      <Tabs
        items={[
          {
            title: 'Info',
            content: <InfoSettings />,
          },
          {
            title: 'Users',
            content: <AdminUserList />,
          },
          {
            title: 'Payment & permissions',
            content: <PaymentSettings />,
          },
        ]}
      />
    </ContentContainer>
  )
}
