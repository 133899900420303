/** @jsxImportSource @emotion/react */
import 'twin.macro'
import React from 'react'
import { Txt } from './Txt'
import { css } from '@emotion/react'

export interface IActionBarProps {
  title: string
  className?: string | undefined
}

export const ActionBar: React.FC<IActionBarProps> = (props) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 2.625rem;
      `}
      className={props.className}
    >
      <Txt xxl>{props.title}</Txt>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
        tw='space-x-3'
      >
        {props.children}
      </div>
    </div>
  )
}
