/** @jsxImportSource @emotion/react */
import 'twin.macro'

import { ContentContainer } from '../../common/general/ContentContainer'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { InputField } from '../../common/general/fields/InputField'
import { Button } from '../../common/general/Button'
import { Txt } from '../../common/general/Txt'
import { http } from '../../../http'
import { useHistory, useLocation } from 'react-router-dom'
import { adminRoutes } from '../../common/general/route-names'
import { Card } from '../../common/general/Card'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { ActionBar } from '../../common/general/ActionBar'
import { Breadcrumbs } from '../../common/general/Breadcrumbs'

const schema = yup.object().shape({
  email: yup.string().email().required(),
})

export const EmailCheck = () => {
  const history = useHistory()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const methods = useForm<{ email: string }>({
    defaultValues: { email: '' },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const charter = queryParams.get('charter')

  const onSubmit = methods.handleSubmit(async (data) => {
    const userExistsInfo = await http.checkIfUserExists(data.email)
    if (userExistsInfo.exists && !userExistsInfo.userData) {
      return methods.setError('email', {
        message: 'User is already a customer at this location.',
      })
    }

    if (!userExistsInfo.userData) {
      queryParams.append('email', data.email)
    } else {
      queryParams.append('country', userExistsInfo.userData.country)
      queryParams.append('email', userExistsInfo.userData.email)
      queryParams.append('name', userExistsInfo.userData.name)
      queryParams.append('phoneNumber', userExistsInfo.userData.phoneNumber)
    }
    queryParams.append('withoutEmail', '0')

    history.push({
      pathname: adminRoutes.ADD_NEW_CUSTOMER,
      search: queryParams.toString(),
    })
  })

  const onProceed = () => {
    queryParams.append('withoutEmail', '1')

    history.push({
      pathname: adminRoutes.ADD_NEW_CUSTOMER,
      search: queryParams.toString(),
    })
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-2'
        links={[
          { name: 'Customers', route: adminRoutes.CUSTOMERS },
          {
            name: 'Add new',
          },
        ]}
      />
      <ActionBar title='Add new' tw='mb-5'></ActionBar>
      <div
        tw='bg-white rounded-lg p-6 divide-y mb-5'
        style={{
          boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
          marginTop: 24,
        }}
      >
        <form onSubmit={onSubmit} noValidate tw='space-y-6 flex-grow'>
          <div>
            <Txt xl tw='mb-2'>
              Find user by email
            </Txt>
            <Txt tw='mb-2 text-gray-400'>
              First we need to check if user already exists
            </Txt>
          </div>
          <FormProvider {...methods}>
            <div tw='flex h-full flex-row justify-start flex-grow'>
              <div tw='mr-6 flex flex-col w-1/2'>
                <InputField
                  placeholder='you@example.com'
                  name='email'
                  type={'email'}
                />
              </div>
              <div tw='mt-1'>
                <Button text={'Check'} onClick={onSubmit} />
              </div>
            </div>
          </FormProvider>
        </form>
      </div>
      {charter === '0' && (
        <Card>
          <div>
            <Txt xl tw='mb-2'>
              Create customer without email
            </Txt>
            <div>
              <Txt tw='mb-2 text-gray-400 flex items-center'>
                <InformationCircleIcon tw='mr-1 text-primary' width={24} /> A
                user without email does not have access to client application.
              </Txt>
              <div tw='mt-1'>
                <Button text={'Proceed'} onClick={onProceed} />
              </div>
            </div>
          </div>
        </Card>
      )}
    </ContentContainer>
  )
}
