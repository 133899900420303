import React from 'react'
import 'twin.macro'
import { Chip } from './Chip'
import { SourceTitle } from './SourceTitle'

/** @jsxImportSource @emotion/react */

interface ISourceTitleWChip {
  title: string
  details: any[]
  chipState: boolean
}

export const SourceTitleWChip = (props: ISourceTitleWChip) => {
  const { chipState, details, title } = props

  return (
    <div tw='flex flex-row space-x-2 justify-between items-center'>
      <div tw='flex flex-row flex-grow items-center'>
        <SourceTitle title={title} details={details} />
      </div>
      {chipState ? <Chip value='True' green /> : <Chip value='False' red />}
    </div>
  )
}
