/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ActionBar } from '../../common/general/ActionBar'
import { Button } from '../../common/general/Button'
import { ContentContainer } from '../../common/general/ContentContainer'
import { CustomTable } from '../../common/general/table/CustomTable'
import { ISearchParams } from '../../../types'
import { PlusIcon } from '@heroicons/react/solid'
import { useSearch } from '../../common/general/hooks/useSearch'
import {
  useAdminsAndSuperAdminUsers,
  useCreateAdminOrSuperAdmin,
  useDeleteUser,
  useUpdateSuperAdmin,
} from '../../../queries'
import { FilterBar } from '../../common/general/FilterBar'
import { TableCell } from '../../common/general/table/cells'
import { Txt } from '../../common/general/Txt'
import { User } from '../../../api-types'
import { useState } from 'react'
import { InputField } from '../../common/general/fields/InputField'
import { UserDrawer } from '../../common/general/UserDrawer'
import { parseBackendException } from '../../../utils'
import { PageLoader } from '../../common/general/PageLoader'

export const SuperadminUsersPage = () => {
  const initialSearchParams: Partial<ISearchParams> = {
    term: '',
    orderField: 'createdAt',
    order: 'DESC',
  }
  const initialFilters = {
    name: '',
    email: '',
    status: undefined,
  }
  const [filters, setFilters] = useState(initialFilters)
  const [open, setOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)
  const [
    searchParams,
    { setPage, setSearchParams, setOrder, setOrderField, setTerm },
  ] = useSearch(initialSearchParams)

  const { data, isLoading, refetch } = useAdminsAndSuperAdminUsers({
    ...searchParams,
    ...filters,
  })
  const createSuperAdmin = useCreateAdminOrSuperAdmin()
  const updateSuperAdmin = useUpdateSuperAdmin()
  const deleteUser = useDeleteUser()

  const onSubmit = async (user: any) => {
    try {
      if (selectedUser !== undefined) {
        await updateSuperAdmin(user)
      } else {
        await createSuperAdmin(user)
      }
    } catch (e: any) {
      parseBackendException(e)
    }
    await refetch()
  }

  const onDelete = async (id: number) => {
    await deleteUser(id)
    await refetch()
  }

  const handleEditUser = (user: User) => {
    setSelectedUser(user)
    setOpen(true)
  }

  return (
    <ContentContainer>
      <ActionBar tw='mb-5' title='Users'>
        <Button
          primary
          icon={PlusIcon}
          text='Add New'
          onClick={() => {
            setSelectedUser(undefined)
            setOpen(true)
          }}
        />
      </ActionBar>
      <FilterBar
        tw='mb-4'
        onClearFilters={() => setSearchParams(initialSearchParams)}
      >
        <FilterBar.Select
          value={{ order: searchParams.order, field: searchParams.orderField }}
          options={[
            {
              label: 'Name (A-Z)',
              value: { order: 'ASC', orderField: 'name' },
            },
            {
              label: 'Name (Z-A)',
              value: { order: 'DESC', orderField: 'name' },
            },
            {
              label: 'Email (A-Z)',
              value: { order: 'ASC', orderField: 'email' },
            },
            {
              label: 'Email (Z-A)',
              value: { order: 'DESC', orderField: 'email' },
            },
          ]}
          setValue={(opt) => {
            setOrder(opt.value.order)
            setOrderField(opt.value.orderField)
          }}
        />
        <FilterBar.Filter
          onSubmit={setFilters}
          values={filters}
          initialValues={initialFilters}
        >
          <InputField name='name' label='Name' />
          <InputField name='email' label='Email' />
        </FilterBar.Filter>
        <FilterBar.TermSearch setValue={setTerm} placeholder='Search...' />
      </FilterBar>
      {isLoading || !data ? (
        <PageLoader />
      ) : (
        <CustomTable
          loading={isLoading}
          maxPage={data.totalPages}
          currentPage={searchParams.page}
          setPage={setPage}
          pageSize={searchParams.size}
          data={data.items}
          totalCount={data.count}
          onRowClick={(l) => handleEditUser(l)}
          columns={[
            {
              Header: 'Name',
              accessor: 'name',
              cell: (l) => <TableCell.Text value={l.name} />,
            },
            {
              Header: 'Email',
              accessor: 'email',
              cell: (l) => <TableCell.Text light value={l.email} />,
            },
            {
              Header: 'Username',
              accessor: 'username',
              cell: (l) => <TableCell.Text light value={l.username} />,
            },
            {
              Header: 'Role',
              accessor: 'role',
              cell: (l) => <TableCell.Text light value={l.role} />,
            },
            {
              Header: '',
              // @ts-ignore
              accessor: 'action',
              cell: (l) => {
                return (
                  <a onClick={() => handleEditUser(l)}>
                    <Txt tw='text-primary-600'>Edit</Txt>
                  </a>
                )
              },
            },
          ]}
        />
      )}
      <UserDrawer
        open={open}
        showSuperAdminRole
        isEdit={selectedUser != undefined}
        onDelete={onDelete}
        initialValues={selectedUser !== undefined ? selectedUser : undefined}
        setOpen={setOpen}
        onSubmit={onSubmit}
      />
    </ContentContainer>
  )
}
