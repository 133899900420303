/** @jsxImportSource @emotion/react */
import 'twin.macro'

import { Card } from '../general/Card'
import { InputField } from '../general/fields/InputField'
import { LocationTypeEnum } from '../../../api-types'
import { SelectField } from '../general/fields/SelectField'
import { Txt } from '../general/Txt'
import { SwitchField } from '../general/fields/SwitchField'

export const SuperadminLocationFormBasicSection = () => {
  return (
    <Card tw='mb-6'>
      <Txt xl tw='mb-1'>
        Required info
      </Txt>
      <Txt sm tw='mb-6 text-gray-500'>
        All of the following fields are required.
      </Txt>
      <div tw='flex flex-col space-y-3'>
        <SelectField
          name='type'
          label='Type'
          options={[
            { label: 'Marina', value: LocationTypeEnum.MARINA },
            { label: 'Harbor', value: LocationTypeEnum.HARBOR },
            { label: 'Camp', value: LocationTypeEnum.CAMP },
            { label: 'Other', value: LocationTypeEnum.OTHER },
          ]}
        />
        <InputField name='name' label='Name' />
        <SwitchField name='visible' label='Visible location' />
        <InputField name='address' label='Address' />
        <div tw='flex  flex-col md:flex-row md:space-x-6'>
          <InputField tw='flex-grow' name='city' label='City' />
          <InputField tw='flex-grow' name='postcode' label='Postal Code' />
          <InputField tw='flex-grow' name='country' label='Country' />
        </div>
        <div tw='flex  flex-col md:flex-row md:space-x-6'>
          <InputField tw='flex-grow' name='latitude' label='Latitude' />
          <InputField tw='flex-grow' name='longitude' label='Longitude' />
        </div>
      </div>
    </Card>
  )
}
