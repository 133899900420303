/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { FormProvider, useForm } from 'react-hook-form'
import { InputField } from '../../common/general/fields/InputField'
import { Button } from '../../common/general/Button'
import { CustomDrawer } from '../../common/general/drawer/CustomDrawer'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'

export interface EditCustomerPasswordForm {
  password: string
}

const schema = yup.object().shape({
  password: yup.string().min(10),
  passwordConfirm: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
})

interface EditCustomerPasswordDrawerProps {
  open: boolean
  close: () => void
  onSubmit(val: any): void
}

export const EditCustomerPasswordDrawer = (
  props: EditCustomerPasswordDrawerProps,
) => {
  const { open, onSubmit, close } = props

  const methods = useForm<EditCustomerPasswordForm>({
    defaultValues: {
      password: '',
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const handleSubmit = methods.handleSubmit(async (data) => {
    methods.reset()
    await onSubmit(data)
  })

  return (
    <CustomDrawer
      open={open}
      title={'Edit Customer Password'}
      subtitle={''}
      render={() => (
        <form
          onSubmit={onSubmit}
          noValidate
          tw='space-y-6 flex-grow'
          style={{ padding: 24 }}
        >
          <FormProvider {...methods}>
            <div tw='flex h-full flex-col justify-between flex-grow'>
              <div tw='m-6 flex flex-grow flex-col'>
                <InputField
                  name='password'
                  label='Password'
                  type={'password'}
                />
                <InputField
                  name='passwordConfirm'
                  label='Confirm the password'
                  type={'password'}
                />
              </div>
              <div>
                <div tw='flex flex-row justify-end m-4'>
                  <Button text={'Close'} onClick={close} cancel tw='mr-3' />
                  <Button text={'Save'} onClick={handleSubmit} />
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      )}
      close={() => close()}
    />
  )
}
