/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { Txt } from '../../../general/Txt'
import { GranularityTab } from './GranularityTab'
import { DatePadding } from './DatePadding'
import { Report } from '../../report-types/report-types'
import { ReportSearchParams } from '../../report-types/report-search-params-types'
import { Granularity } from '../../report-types/report-enums'
import { roundToFloatWithExactlyNDecimals } from '../../../../../utils'

interface ReportHeaderProps {
  report?: Report
  filters: ReportSearchParams
  setFilters: (newPadding: ReportSearchParams) => void
}

export const ReportHeader = (props: ReportHeaderProps) => {
  const { report, filters, setFilters } = props

  //#region Functions
  const onGranularityChanged = (granularity: Granularity) => {
    setFilters({
      ...filters,
      granularity: granularity,
    })
  }
  const onDatesChanged = (from: Date, to: Date) => {
    setFilters({
      ...filters,
      from,
      to,
    })
  }
  //#endregion

  return (
    <div tw='flex flex-row ml-6 mr-6 mt-12 mb-6'>
      <div tw='flex flex-1 flex-row'>
        <Txt tw='mr-2' xxl>
          Total:
        </Txt>
        <div tw='flex flex-col'>
          {!report ? (
            <Txt xxl>...</Txt>
          ) : (
            report.map((reportItem) => (
              <Txt key={reportItem.key} xxl>
                {Number.isInteger(reportItem.total)
                  ? reportItem.total
                  : roundToFloatWithExactlyNDecimals(reportItem.total, 2)}{' '}
                {reportItem.unit}
              </Txt>
            ))
          )}
        </div>
      </div>
      <GranularityTab
        selectedGranularity={filters.granularity}
        setSelectedGranularity={onGranularityChanged}
      />
      <DatePadding
        from={filters.from}
        to={filters.to}
        setDates={onDatesChanged}
      />
    </div>
  )
}
