export const uploadFile = async (
  uploadUrl: string,
  file: File,
  options?: any,
) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', uploadUrl)
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          options?.onSuccess()
          // @ts-ignore
          resolve()
        } else {
          options?.onError()
          reject()
        }
      }
    }
    xhr.setRequestHeader('Content-Type', file.type)
    xhr.upload.addEventListener(
      'progress',
      (event) => {
        if (event.lengthComputable && options && options.onProgress) {
          const percent = (event.loaded / event.total) * 100

          options.onProgress(percent, file)
        }
      },
      false,
    )

    xhr.send(file)
  })
}
