import 'twin.macro'

import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import Select from 'react-select'

import { ErrorText } from '../ErrorText'
import { IDropdownOption } from '../../../../types'
import { Txt } from '../Txt'

/** @jsxImportSource @emotion/react */

interface ISelectField {
  label?: string
  options: IDropdownOption[]
  selectedOption?: IDropdownOption
  onChange: (option: IDropdownOption) => void
  className?: string
  defaultValue?: IDropdownOption
  placeholder?: string
  isDisabled?: boolean
  isSearchable?: boolean
  error?: boolean
  errorMessage?: string
}

export const SelectField = (props: ISelectField) => {
  const {
    label,
    options,
    selectedOption = undefined,
    onChange,
    className,
    isDisabled = false,
    defaultValue,
    placeholder,
    isSearchable = false,
    error = false,
    errorMessage,
  } = props

  return (
    <div className={className} tw='min-w-min'>
      <div tw='mb-1'>
        <label tw='font-sans font-medium text-sm text-gray-700'>{label}</label>
      </div>
      <Select
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        value={selectedOption?.value ?? null}
        onChange={(option) => onChange(option)}
        placeholder={selectedOption?.label ?? placeholder}
        defaultValue={defaultValue?.value}
        options={options}
        menuPlacement='auto'
        formatOptionLabel={(o) => (
          <div tw='flex items-center'>
            <Txt sm tw='text-gray-700 flex-grow'>
              {o.label}
            </Txt>
            {o.value === selectedOption?.value && (
              <CheckIcon tw='w-4 h-4 text-primary-600' />
            )}
          </div>
        )}
        styles={{
          placeholder: (base) => ({
            ...base,
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: 14,
            color: '#6B7280',
          }),
          container: (base) => ({
            ...base,
            minWidth: 150,
          }),
          input: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            minWidth: 150,
          }),
          control: (base) => ({
            ...base,
            border: error
              ? '1px #EF4444 solid !important'
              : '1.5px rgb(229, 231, 235) solid !important',
            borderRadius: '0.375rem !important',
            padding: '0.49rem 0.6rem',
            cursor: 'pointer',
            boxShadow: 'none',
          }),
          valueContainer: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          menuPortal: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            border: 'none',
            borderRadius: '0.375rem !important',
          }),
          menuList: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            border: 'none',
            borderRadius: '0.375rem !important',
          }),
          menu: (base) => ({
            ...base,
            padding: 0,
            margin: '0.5rem 0rem 0rem 0rem',
            boxShadow: 'none',
            border: '1.5px rgb(229, 231, 235) solid !important',
            borderRadius: '0.375rem !important',
          }),
          option: (base) => ({
            ...base,
            backgroundColor: 'white',
            cursor: 'pointer',
            ':hover': {
              backgroundColor: '#E6F1F7',
            },
          }),
        }}
        components={{
          IndicatorSeparator: () => <div />,
          DropdownIndicator: () => (
            <SelectorIcon tw='h-4 w-4' color='#9CA3AF' />
          ),
        }}
      />
      <div tw='h-6'>
        {error && errorMessage && (
          <ErrorText tw='mt-2'>{errorMessage}</ErrorText>
        )}
      </div>
    </div>
  )
}
