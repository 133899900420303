import 'twin.macro'

import * as yup from 'yup'

import { FormProvider, useFieldArray, useForm } from 'react-hook-form'

import { Button } from '../../common/general/Button'
import { Card } from '../../common/general/Card'
import { IconButton } from '../../common/general/IconButton'
import { PlusIcon } from '@heroicons/react/solid'
import { SimpleInputField } from '../../common/general/fields/SimpleInputField'
import { SimpleLocationAutocompleteField } from '../../common/general/fields/SimpleLocationAutocompleteField'
import { TrashIcon } from '@heroicons/react/outline'
import { Txt } from '../../common/general/Txt'
import { useCallback } from 'react'
/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { superadminRoutes } from '../../common/general/route-names'
import { http } from '../../../http'
import { useQueryClient } from 'react-query'
import { queries } from '../../../queries'
import { showErrorNotification } from '../../common/general/notification'
import { IDropdownOption } from '../../../types'

export interface IPedestalForm {
  pedestals: { identifier: string; location: IDropdownOption }[]
}

const pedestalSchema = {
  location: yup.object().required(''),
  identifier: yup.string().required(''),
}

const schema = yup.object().shape({
  pedestals: yup.array().of(yup.object().shape(pedestalSchema)),
})

interface IPedestalFormProps {
  setActivePage: (activePage: number) => void
  setPedestals: (pedestals: any) => void
}

export const SuperadminPedestalForm = ({
  setActivePage,
  setPedestals,
}: IPedestalFormProps) => {
  const history = useHistory()
  const client = useQueryClient()

  const pedestalDefaultValue = {
    identifier: '',
    location: undefined,
  }

  const methods = useForm<IPedestalForm>({
    defaultValues: {
      pedestals: [pedestalDefaultValue],
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const onSubmit = useCallback(
    methods.handleSubmit(async (data) => {
      try {
        const createdPedestals = await http.createPedestals(data)
        setPedestals(createdPedestals.data)
        client.invalidateQueries(queries.pedestals)
        setActivePage(2)
      } catch (e) {
        showErrorNotification('Something went wrong. Please try again')
      }
    }),
    [],
  )
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'pedestals',
  })
  const isEmpty = fields.length === 0

  return (
    <form onSubmit={onSubmit} noValidate>
      <FormProvider {...methods}>
        <Card tw='mb-6 p-0'>
          <div tw='p-6'>
            <Txt xl tw='mb-1'>
              Initialize new pedestals
            </Txt>
            <Txt sm tw='mb-6 text-gray-500'>
              Enter new pedestal IDs and their location.
            </Txt>

            <div tw='flex flex-col space-y-6'>
              {fields.map((f, i) => (
                <div tw='flex flex-row items-center justify-start' key={f.id}>
                  <SimpleLocationAutocompleteField
                    name={`pedestals[${i}].location`}
                    placeholder='Location'
                    tw='max-w-448 border-r-0'
                    styles={{
                      control: {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderRight: 0,
                      },
                    }}
                  />
                  <div tw='max-w-448 mr-3'>
                    <SimpleInputField
                      name={`pedestals[${i}].identifier`}
                      placeholder='Identifier'
                      tw='border-l-0 rounded-bl-none rounded-tl-none'
                    />
                  </div>
                  <IconButton
                    icon={TrashIcon}
                    light
                    onClick={() => remove(i)}
                  />
                </div>
              ))}
            </div>
            <Button
              light
              tw='mt-6'
              icon={PlusIcon}
              text='Add New'
              onClick={() => append(pedestalDefaultValue)}
            />
          </div>
          {!isEmpty && (
            <div tw='bg-gray-50 px-6 py-3 rounded-b-md'>
              <div tw='flex flex-row justify-end align-items[center]'>
                <Button
                  text='Cancel'
                  cancel
                  tw='mr-3'
                  onClick={() => history.replace(superadminRoutes.PEDESTALS)}
                />
                <Button text='Create' primary onClick={onSubmit} />
              </div>
            </div>
          )}
        </Card>
      </FormProvider>
    </form>
  )
}
