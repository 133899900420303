/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useEffect } from 'react'
import { useDashboardPedestals } from '../../../queries'
import { SVGUrl } from '../../common/svg/SVGUrl'
import { css } from '@emotion/react'
import { adminRoutes } from '../../common/general/route-names'
import { useHistory } from 'react-router-dom'
import { SourceStatusEnum } from '../../../api-types'
import { PageLoader } from '../../common/general/PageLoader'
import { useLoggedInUser } from '../../common/general/hooks/useUser'
import { Txt } from '../../common/general/Txt'
import { heartbeatService } from '../../common/general/heartbeat-service'

interface CustomWindow extends Window {
  Hammer: any
  svgPanZoom: any
}

const dashboardContainerStyle = css`
  width: 100%;
  height: 100vh;
  svg {
    width: 100%;
    height: 100vh;
    @media screen and (max-width: 800px) {
      height: calc(100vh - 64px);
    }

    .st4.st5.st6 {
      cursor: pointer;
    }
  }
`

const colorDefinitionStyle = (color: string) => css`
  width: 15px;
  height: 4px;
  background: ${color};
`

const stateColors = {
  AVAILABLE: '#77ff77',
  OFFLINE: '#ff0000',
  SOCKET_MANUAL_MODE: '#ff9c08',
  FUSE_OR_MODBUS_DOWN: '#9e80ff',
  IN_USE: '#5dd8f1',
  PLUGGED_IN: '#5B21B6',
}

export const Dashboard = () => {
  const user = useLoggedInUser()
  const {
    data: pedestals,
    isError: isErrorPedestals,
    isLoading: isLoadingPedestals,
  } = useDashboardPedestals()

  useEffect(() => {
    if (location && pedestals) {
      setSvgColors()
    }
  }, [pedestals, location])

  const history = useHistory()

  const viewPedestal = (identifier: string) => {
    history.push(`${adminRoutes.VIEW_PEDESTAL}/${identifier}`)
  }

  const setSvgColors = () => {
    if (!pedestals) {
      return
    }
    pedestals.forEach(
      ({
        identifier,
        sources,
        lastHartbeatReceivedOn,
        plcConstants,
        socketsManualModeOn,
        valvesManualModeOn,
        location,
      }) => {
        let pedestalColor = 'black'
        if (lastHartbeatReceivedOn) {
          const hasHeartbeat = heartbeatService.getHeartbeatStable(
            lastHartbeatReceivedOn,
            plcConstants?.thart ?? location?.plcConstants?.thart,
          )
          pedestalColor = stateColors.AVAILABLE
          if (!hasHeartbeat) {
            pedestalColor = stateColors.OFFLINE
          } else {
            sources?.forEach((source) => {
              if (source.status === SourceStatusEnum.OUT_OF_SERVICE) {
                return (pedestalColor = stateColors.FUSE_OR_MODBUS_DOWN)
              }
              if (source.status === SourceStatusEnum.IN_USE) {
                return (pedestalColor = stateColors.IN_USE)
              }
              if (source.status === SourceStatusEnum.PLUGGED_IN) {
                return (pedestalColor = stateColors.PLUGGED_IN)
              }
            })
            if (socketsManualModeOn || valvesManualModeOn) {
              pedestalColor = stateColors.SOCKET_MANUAL_MODE
            }
          }
        }

        setTimeout(() => {
          // Old convention (ACI Dubrovnik)
          const p = document.querySelectorAll('g')
          const polygon = Array.from(p).filter(
            (t) => t.getAttribute('id') === `${identifier.toUpperCase()}`,
          )[0]

          // New convention (Trget)
          const t = document.querySelectorAll('rect')
          const fallbackPolygon = Array.from(t).filter(
            (t) =>
              t.getAttribute('inkscape:label') ===
              `${identifier.toUpperCase()}-box`,
          )[0]

          if (!polygon && !fallbackPolygon) {
            console.error('Unable to find for ', identifier)
            return
          }

          if (polygon) {
            // @ts-ignore
            polygon.children[0].style.fill = pedestalColor
          }

          if (fallbackPolygon) {
            fallbackPolygon.style.fill = pedestalColor
          }

          // New convention (Trget) - get labels, text for identifiers
          const t2 = document.querySelectorAll('text')
          const textLabelsFallback = Array.from(t2).filter(
            (t) =>
              t.getAttribute('inkscape:label') ===
              `${identifier.toUpperCase()}-text`,
          )

          for (let i = 0; i < textLabelsFallback.length; i += 1) {
            textLabelsFallback[i].style.cursor = 'pointer'
            textLabelsFallback[i].addEventListener('click', function () {
              // @ts-ignore
              const identifier = (this.textContent || '').toLowerCase()
              viewPedestal(identifier)
            })
          }

          // Old text labels (ACI Dubrovnik)
          const btns = document.querySelectorAll('.st4.st5.st6')
          for (let i = 0; i < btns.length; i += 1) {
            btns[i].addEventListener('click', function () {
              // @ts-ignore
              const identifier = (this.textContent || '').toLowerCase()
              viewPedestal(identifier)
            })
          }
        }, 2000)
      },
    )
    setTimeout(() => {
      if (!document?.querySelector('#marine-map')) {
        return
      }
      const customWindow = window as Window as CustomWindow
      customWindow.svgPanZoom('#marine-map svg', {
        zoomEnabled: true,
        controlIconsEnabled: true,
        init: function (options: any) {
          // @ts-ignore-start
          const instance = options.instance

          let pannedX = 0,
            pannedY = 0,
            initialScale = 1

          this.hammer = customWindow.Hammer(options.svgElement, {
            inputClass: customWindow.Hammer.SUPPORT_POINTER_EVENTS
              ? customWindow.Hammer.PointerEventInput
              : customWindow.Hammer.TouchInput,
          })

          // Enable pinch
          this.hammer.get('pinch').set({ enable: true })

          // Handle double tap
          this.hammer.on('doubletap', function () {
            instance.zoomIn()
          })

          // Handle pan
          this.hammer.on('panstart panmove', function (ev: any) {
            // On pan start reset panned variables
            if (ev.type === 'panstart') {
              pannedX = 0
              pannedY = 0
            }

            // Pan only the difference
            instance.panBy({ x: ev.deltaX - pannedX, y: ev.deltaY - pannedY })
            pannedX = ev.deltaX
            pannedY = ev.deltaY
          })

          // Handle pinch
          this.hammer.on('pinchstart pinchmove', function (ev: any) {
            // On pinch start remember initial zoom
            if (ev.type === 'pinchstart') {
              initialScale = instance.getZoom()
              instance.zoomAtPoint(initialScale * ev.scale, {
                x: ev.center.x,
                y: ev.center.y,
              })
            }

            instance.zoomAtPoint(initialScale * ev.scale, {
              x: ev.center.x,
              y: ev.center.y,
            })
          })

          // Prevent moving the page on some devices when panning over SVG
          options.svgElement.addEventListener('touchmove', function (e: Event) {
            e.preventDefault()
          })
        },
      })
    }, 2000)
  }

  if (isErrorPedestals) {
    return <p>Something went wrong.</p>
  }

  if (isLoadingPedestals) {
    return <PageLoader />
  }

  return (
    <div css={dashboardContainerStyle}>
      <div tw='m-3 absolute bg-gray-100'>
        <div tw='flex flex-row'>
          <div
            tw='mr-2 mt-2'
            css={colorDefinitionStyle(stateColors.OFFLINE)}
          ></div>
          <div>Offline</div>
        </div>
        <div tw='flex flex-row'>
          <div
            tw='mr-2 mt-2'
            css={colorDefinitionStyle(stateColors.SOCKET_MANUAL_MODE)}
          ></div>
          <div>Manual mode</div>
        </div>
        <div tw='flex flex-row'>
          <div
            tw='mr-2 mt-2'
            css={colorDefinitionStyle(stateColors.FUSE_OR_MODBUS_DOWN)}
          ></div>
          <div>Fuse or modbus down</div>
        </div>
        <div tw='flex flex-row'>
          <div
            tw='mr-2 mt-2'
            css={colorDefinitionStyle(stateColors.IN_USE)}
          ></div>
          <div>Source in use</div>
        </div>
        <div tw='flex flex-row'>
          <div
            tw='mr-2 mt-2'
            css={colorDefinitionStyle(stateColors.PLUGGED_IN)}
          ></div>
          <div>Plugged in</div>
        </div>
        <div tw='flex flex-row'>
          <div
            tw='mr-2 mt-2'
            css={colorDefinitionStyle(stateColors.AVAILABLE)}
          ></div>
          <div>Available</div>
        </div>
      </div>
      {user.workingLocation?.mapUrl ? (
        <SVGUrl
          // @ts-ignore
          id={'marine-map'}
          // @ts-ignore
          src={user.workingLocation.mapUrl}
        />
      ) : (
        <div tw='flex w-full h-full items-center justify-center'>
          <Txt xl>Location map not uploaded yet.</Txt>
        </div>
      )}
    </div>
  )
}
