/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { RiseLoader } from 'react-spinners'

export const PageLoader = () => {
  return (
    <div tw='flex place-content-center h-full content-center'>
      <div tw='self-center mt-5'>
        <RiseLoader color={`#1D5883`} size={10} />
      </div>
    </div>
  )
}
