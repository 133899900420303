/** @jsxImportSource @emotion/react */
import 'twin.macro'
import React from 'react'
import { Divider } from './Divider'
import { Txt } from './Txt'

export const CardWithHeader: React.FC<{ className?: string; title?: string }> =
  (props) => {
    return (
      <div
        className={props.className}
        tw='bg-white'
        style={{
          boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
        }}
      >
        {props.title && (
          <div tw='px-4 py-5'>
            <Txt xl>{props.title}</Txt>
          </div>
        )}
        <Divider />
        <div tw='px-4 py-5'>{props.children}</div>
      </div>
    )
  }
