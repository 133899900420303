/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { CustomTable } from '../../common/general/table/CustomTable'
import { FilterBar } from '../../common/general/FilterBar'
import { ISearchParams } from '../../../types'
import { TableCell } from '../../common/general/table/cells'
import { useSearch } from '../../common/general/hooks/useSearch'
import { useState } from 'react'
import {
  useCreateLocationUser,
  useDeleteLocationUser,
  useEditLocationUser,
  useLocationAdminUsers,
} from '../../../queries'
import { Button } from '../../common/general/Button'
import { PlusIcon } from '@heroicons/react/solid'
import { User, userRoleLabels } from '../../../api-types'
import { IUserForm, UserDrawer } from '../../common/general/UserDrawer'
import { Txt } from '../../common/general/Txt'
import { parseBackendException } from '../../../utils'
import { PageLoader } from '../../common/general/PageLoader'

export const AdminUserList = () => {
  const initialSearchParams: Partial<ISearchParams> = {
    orderField: 'id',
    order: 'ASC',
  }
  const initialFilters = {}
  const [open, setOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)

  const [filters, setFilters] = useState(initialFilters)
  const [searchParams, { setOrder, setOrderField, setPage }] =
    useSearch(initialSearchParams)
  const { data, isLoading, refetch } = useLocationAdminUsers(searchParams)
  const createLocationUser = useCreateLocationUser()
  const editLocationUser = useEditLocationUser()
  const deleteLocationUser = useDeleteLocationUser()

  const handleCreateUser = async (user: IUserForm) => {
    try {
      await createLocationUser(user)
      refetch()
    } catch (e: any) {
      parseBackendException(e)
    }
  }

  const handleEditUser = async (user: IUserForm) => {
    try {
      await editLocationUser(user)
      refetch()
    } catch (e: any) {
      parseBackendException(e)
    }
  }

  const onDeleteUser = async (id: number) => {
    try {
      await deleteLocationUser(id)
      refetch()
    } catch (e: any) {
      parseBackendException(e)
    }
  }

  if (isLoading || !data) {
    return <PageLoader />
  }

  return (
    <>
      <FilterBar tw='my-5'>
        <FilterBar.Select
          value={{
            order: searchParams.order,
            orderField: searchParams.orderField,
          }}
          options={[
            {
              label: 'Name (A-Z)',
              value: { order: 'ASC', orderField: 'name' },
            },
            {
              label: 'Name (Z-A)',
              value: { order: 'DESC', orderField: 'name' },
            },
            {
              label: 'Email (A-Z)',
              value: { order: 'ASC', orderField: 'email' },
            },
            {
              label: 'Email (Z-A)',
              value: { order: 'DESC', orderField: 'email' },
            },
          ]}
          setValue={(option) => {
            setOrder(option.value.order)
            setOrderField(option.value.orderField)
          }}
        />
        <FilterBar.Filter
          onSubmit={setFilters}
          values={filters}
          initialValues={initialFilters}
        ></FilterBar.Filter>
        {/* TODO: fix alignmet to right */}
        <Button
          primary
          tw='ml-auto'
          icon={PlusIcon}
          text='Add New'
          onClick={() => {
            setSelectedUser(undefined)
            setOpen(true)
          }}
        />
      </FilterBar>

      <CustomTable
        loading={isLoading}
        maxPage={data.totalPages}
        currentPage={searchParams.page}
        setPage={setPage}
        pageSize={searchParams.size}
        data={data.items}
        totalCount={data.count}
        onRowClick={(l) => {
          setSelectedUser(l)
          setOpen(true)
        }}
        columns={[
          {
            Header: 'Name',
            accessor: 'id',
            cell: (l) => <TableCell.Text value={l.name ?? ''} />,
          },
          {
            Header: 'Email',
            accessor: 'email',
            cell: (l) => <TableCell.Text value={l.email ?? ''} />,
          },
          {
            Header: 'Username',
            accessor: 'username',
            cell: (l) => <TableCell.Text value={l.username} />,
          },
          {
            Header: 'Type',
            accessor: 'role',
            cell: (l) => <TableCell.Text value={userRoleLabels[l.role]} />,
          },
          {
            Header: 'Status',
            accessor: 'deletedAt',
            cell: (l) => {
              // @ts-ignore
              const value = l.deletedAt ? 'Deactivated' : 'Active'
              const color = l.deletedAt ? 'gray' : 'green'
              return <TableCell.Chip value={value} {...{ [color]: true }} />
            },
          },
          {
            Header: '',
            // @ts-ignore
            accessor: 'action',
            cell: (l) => {
              return (
                <a
                  onClick={() => {
                    setSelectedUser(l)
                    setOpen(true)
                  }}
                >
                  <Txt tw='text-primary-600'>Edit</Txt>
                </a>
              )
            },
          },
        ]}
      />
      <UserDrawer
        open={open}
        showWorkingLocationInput={false}
        isEdit={selectedUser != undefined}
        initialValues={selectedUser !== undefined ? selectedUser : undefined}
        onDelete={onDeleteUser}
        setOpen={setOpen}
        onSubmit={(user) => {
          if (selectedUser != undefined) {
            handleEditUser(user)
          } else {
            handleCreateUser(user)
          }
        }}
      />
    </>
  )
}
