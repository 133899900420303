/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { FormProvider, useForm } from 'react-hook-form'
import { InputField } from '../../common/general/fields/InputField'
import { Button } from '../../common/general/Button'
import { CustomDrawer } from '../../common/general/drawer/CustomDrawer'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Account } from '../../../api-types'
import { Txt } from '../../common/general/Txt'
import { InformationCircleIcon } from '@heroicons/react/outline'

export interface EditCustomerSessionPermissionsForm {
  accountId: number
  maxActivePowerSessions: number
  maxActiveWaterSessions: number
}

const schema = yup.object().shape({
  accountId: yup.number().required(),
  maxActivePowerSessions: yup.number().min(0).required(),
  maxActiveWaterSessions: yup.number().min(0).required(),
})

interface EditCustomerSessionPermissionsDrawerProps {
  open: boolean
  close: () => void
  onSubmit(val: any): void
  customer: Account
}

export const EditCustomerSessionPermissionsDrawer = (
  props: EditCustomerSessionPermissionsDrawerProps,
) => {
  const { open, onSubmit, close, customer } = props

  const methods = useForm<EditCustomerSessionPermissionsForm>({
    defaultValues: {
      accountId: customer.id,
      maxActivePowerSessions: customer.maxActivePowerSessions,
      maxActiveWaterSessions: customer.maxActiveWaterSessions,
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const handleSubmit = methods.handleSubmit(async (data) => {
    await onSubmit(data)
  })

  return (
    <CustomDrawer
      open={open}
      title={'Edit Session permissions'}
      subtitle={'Permissions for activating sessions'}
      render={() => (
        <form
          onSubmit={onSubmit}
          noValidate
          tw='space-y-6 flex-grow'
          style={{ padding: 24 }}
        >
          <FormProvider {...methods}>
            <div tw='flex h-full flex-col justify-between flex-grow'>
              <div tw='m-6 flex flex-grow flex-col'>
                <InputField
                  name='maxActivePowerSessions'
                  label='Max active power sessions'
                  type={'number'}
                />
                <InputField
                  name='maxActiveWaterSessions'
                  label='Max active water sessions'
                  type={'number'}
                />
                <Txt tw='my-3 flex items-center'>
                  <InformationCircleIcon tw='mr-1 text-primary' width={24} /> If
                  set to 0, customer wont be able to have any sessions active.
                </Txt>
              </div>
              <div>
                <div tw='flex flex-row justify-end m-4'>
                  <Button text={'Close'} onClick={close} cancel tw='mr-3' />
                  <Button text={'Save'} onClick={handleSubmit} />
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      )}
      close={() => close()}
    />
  )
}
