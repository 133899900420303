/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useHistory } from 'react-router-dom'

import { Txt } from '../../general/Txt'

export type ReportOption = {
  title: string
  link: string
}

interface ReportOptionCardProps {
  option: ReportOption
}

export const ReportOptionCard = (props: ReportOptionCardProps) => {
  const { option } = props

  const history = useHistory()

  return (
    <div
      tw='w-72 h-60 flex py-24 px-12 rounded-2xl bg-primary-600 shadow-xl hover:cursor-pointer items-center justify-center'
      onClick={() => history.push(option.link)}
    >
      <Txt xxl tw='text-center text-white'>
        {option.title}
      </Txt>
    </div>
  )
}
