import 'twin.macro'

import { ReactNode } from 'react'
import { Txt } from './Txt'

/** @jsxImportSource @emotion/react */

interface ISourceTitleProps {
  title: string
  details: (string | ReactNode)[]
}

export const SourceTitle = (props: ISourceTitleProps) => {
  return (
    <div tw='flex flex-row space-x-1.5'>
      <Txt xxl tw='text-primary-600 '>
        {props.title}
      </Txt>
      {props.details.map((d, i) => (
        <Txt xxl tw='text-primary-300' key={i}>
          {d}
        </Txt>
      ))}
    </div>
  )
}
