import React, { useMemo } from 'react'
import 'twin.macro'

import { Card } from '../../general/Card'
import { Txt } from '../../general/Txt'
import { Divider } from '../../general/Divider'
import { SourceTitle } from '../../general/SourceTitle'
import { SourceTitleWChip } from '../../general/SourceTitleWChip'
import { CurrentStatus } from './CurrentStatus'
import { SourceInfo } from './SourceInfo'
import { SourceTitleWSwitch } from '../../general/SourceTitleWSwitch'
import { formatFraction } from '../../../../utils'
import { Pedestal, PhaseTypeEnum, Socket, Valve } from '../../../../api-types'
import { http } from '../../../../http'

/** @jsxImportSource @emotion/react */

interface PedestalStatusesProps {
  pedestal: Pedestal
}

export const PedestalStatuses = (props: PedestalStatusesProps) => {
  const { pedestal } = props

  const sockets: Socket[] = useMemo(() => {
    return (
      (pedestal.sockets.sort((a, b) =>
        a.position > b.position ? 1 : -1,
      ) as Socket[]) || ([] as Socket[])
    )
  }, [pedestal])

  const valves: Valve[] = useMemo(() => {
    return (
      (pedestal.valves.sort((a, b) => a.position - b.position) as Valve[]) ||
      ([] as Valve[])
    )
  }, [pedestal])

  const handleToggleValve = async (id: number, state: boolean) => {
    await http.updateValveState(id, state ? 'off' : 'on')
  }

  const handleToggleSocket = async (id: number, state: boolean) => {
    await http.updateSocketState(id, state ? 'off' : 'on')
  }

  const handleToggleLight = async (identifier: string, state: boolean) => {
    await http.updateLightState(identifier, state ? 'off' : 'on')
  }

  const handleToggleHeat = async (identifier: string, state: boolean) => {
    await http.updateHeatState(identifier, state ? 'off' : 'on')
  }

  return (
    <>
      <Card tw='flex flex-row mb-6 mt-6 p-0 flex-wrap'>
        <div tw='flex-grow p-6'>
          <span>Fuses</span>
          {sockets.map((socket: Socket, index: number) => {
            const phase =
              socket.phase === PhaseTypeEnum.MONO_PHASE ? '1F' : '3F'
            return (
              <SourceTitleWChip
                title={`P${index + 1}`}
                details={[phase, `${socket.current}A`]}
                chipState={socket.state.fuseStable}
              />
            )
          })}
        </div>
        <Divider type='vertical' />
        <div tw='flex-grow p-6'>
          <span>Sockets</span>
          {sockets.map((socket, index: number) => {
            const phase =
              socket.phase === PhaseTypeEnum.MONO_PHASE ? '1F' : '3F'
            return (
              <SourceTitleWChip
                title={`P${index + 1}`}
                details={[phase, `${socket.current}A`]}
                chipState={socket.state.socketStable}
              />
            )
          })}
        </div>
        <Divider type='vertical' />
        <div tw='flex-grow p-6'>
          <span>Modbus</span>
          {sockets.map((socket, index: number) => {
            const phase =
              socket.phase === PhaseTypeEnum.MONO_PHASE ? '1F' : '3F'
            return (
              <SourceTitleWChip
                title={`P${index + 1}`}
                details={[phase, `${socket.current}A`]}
                chipState={socket.state.modbusStable}
              />
            )
          })}
        </div>
      </Card>
      <Card tw='flex flex-row mb-6 mt-6 p-0 flex-wrap'>
        <div tw='flex-grow p-6'>
          <span>Contactors Cmd</span>
          {sockets.map((socket: Socket, index: number) => {
            const phase =
              socket.phase === PhaseTypeEnum.MONO_PHASE ? '1F' : '3F'
            return (
              <SourceTitleWSwitch
                status={socket.state.contactorCmd}
                activated={socket.state.activated}
                key={index}
                title={`P${index + 1}`}
                details={[phase, `${socket.current}A`]}
                onToggle={(value) => handleToggleSocket(socket.id, value)}
              />
            )
          })}
        </div>
        <Divider type='vertical' />
        <div tw='flex-grow p-6'>
          <span>Valves Cmd</span>
          {valves.map((valve: Valve, index: number) => {
            const size = valve.connectionSize.split('/')
            return (
              <SourceTitleWSwitch
                status={valve.state.valveCmd}
                activated={valve.state.activated}
                onToggle={(value) => handleToggleValve(valve.id, value)}
                key={index}
                title={`W${index + 1}`}
                details={[formatFraction(parseInt(size[0]), parseInt(size[1]))]}
              />
            )
          })}
        </div>
        <Divider type='vertical' />
        <div tw='flex-grow'>
          <div tw='p-6'>
            <span>Manual</span>
            <SourceTitleWChip
              title={'Power'}
              details={[]}
              chipState={pedestal.socketsManualModeOn}
            />
            <SourceTitleWChip
              title={'Water'}
              details={[]}
              chipState={pedestal.valvesManualModeOn}
            />
          </div>
          <Divider type='horizontal' />
          <div tw='p-6'>
            <span>Ambient</span>
            <SourceTitleWSwitch
              status={pedestal.hydrostatStatus}
              activated={pedestal.hydrostatOn}
              title='Heat'
              details={[]}
              onToggle={(value) => handleToggleHeat(pedestal.identifier, value)}
            />
            <SourceTitleWSwitch
              status={pedestal.luxomatStatus}
              activated={pedestal.luxomatOn}
              title='Light'
              details={[]}
              onToggle={(value) =>
                handleToggleLight(pedestal.identifier, value)
              }
            />
          </div>
        </div>
      </Card>
      <Txt l tw='mb-1'>
        Current Count
      </Txt>
      <Card tw='flex flex-row mb-6 mt-6 p-0 flex-wrap'>
        {sockets.map((socket: Socket, index) => {
          const phase = socket.phase === PhaseTypeEnum.MONO_PHASE ? '1F' : '3F'
          return (
            <>
              <div tw='flex-grow p-6'>
                <SourceTitle
                  title={`P${index + 1}`}
                  details={[phase, `${socket.current}A`]}
                />
                <CurrentStatus
                  meterState={socket.state.value}
                  voltage={socket.state.voltage}
                  current={socket.state.current}
                  frequency={socket.state.frequency}
                />
              </div>
              {!(sockets.length - 1 === index) && <Divider type='vertical' />}
            </>
          )
        })}
      </Card>
      <Txt l tw='mb-1'>
        Water Count
      </Txt>
      <Card tw='flex flex-row mb-6 mt-6 p-0 flex-wrap'>
        {valves.map((valve: Valve, index) => {
          return (
            <>
              <div tw='flex-grow p-6'>
                <SourceTitle
                  title={`W${index + 1}`}
                  details={[valve.connectionSize]}
                />
                <SourceInfo
                  label='Meter State'
                  value={valve.state.value}
                  unit={'m\u00B3'}
                />
              </div>
              {!(valves.length - 1 === index) && <Divider type='vertical' />}
            </>
          )
        })}
      </Card>
    </>
  )
}
