/** @jsxImportSource @emotion/react */
import 'twin.macro'
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Pedestal, PedestalLogsTypes } from '../../../../api-types'
import { ISearchParams } from '../../../../types'
import { usePedestalLogs } from '../../../../queries'
import { useSearch } from '../../general/hooks/useSearch'
import { CustomTable } from '../../general/table/CustomTable'
import { TableCell } from '../../general/table/cells'
import { FilterBar } from '../../general/FilterBar'
import { SelectField } from '../../general/fields/SelectField'

const initialSearchParams: Partial<ISearchParams> = {
  orderField: 'timestamp',
  order: 'DESC',
  term: '',
}
const initialFilters = {
  type: '',
}
interface PedestalLogsProps {
  pedestal: Pedestal
}

export const PedestalLogs = (props: PedestalLogsProps) => {
  const { pedestal } = props
  const [filters, setFilters] = useState(initialFilters)
  const [searchParams, { setSearchParams, setOrder, setOrderField, setPage }] =
    useSearch(initialSearchParams)
  const { data, isLoading, isError } = usePedestalLogs({
    ...searchParams,
    ...filters,
    pedestalId: pedestal.id,
  })

  if (isError) {
    return <div>Somethting went wrong!</div>
  }

  return (
    <div>
      <FilterBar
        tw='my-4'
        onClearFilters={() => setSearchParams(initialSearchParams)}
      >
        <FilterBar.Select
          value={{
            order: searchParams.order,
            orderField: searchParams.orderField,
          }}
          setValue={(opt) => {
            setOrder(opt.value.order)
            setOrderField(opt.value.orderField)
          }}
          options={[
            {
              label: 'Date (newest-oldest)',
              value: { order: 'DESC', orderField: 'timestamp' },
            },
            {
              label: 'Date (oldest-newest)',
              value: { order: 'ASC', orderField: 'timestamp' },
            },
          ]}
        />
        <FilterBar.Filter
          onSubmit={setFilters}
          values={filters}
          initialValues={initialFilters}
        >
          <SelectField
            name='type'
            label='Type'
            options={[
              { label: 'Info', value: PedestalLogsTypes.INFO },
              { label: 'Success', value: PedestalLogsTypes.SUCCESS },
              { label: 'Warning', value: PedestalLogsTypes.WARNING },
              { label: 'Error', value: PedestalLogsTypes.ERROR },
            ]}
          />
        </FilterBar.Filter>
      </FilterBar>
      <CustomTable
        loading={isLoading}
        maxPage={data.totalPages}
        currentPage={searchParams.page}
        setPage={setPage}
        pageSize={searchParams.size}
        data={data.items}
        totalCount={data.count}
        mobileColumns={[
          {
            Header: 'Type',
            accessor: 'type',
            cell: (pl) => <TableCell.Text value={pl.type} />,
          },
          {
            Header: 'Message',
            accessor: 'message',
            cell: (pl) => {
              return <TableCell.Text value={pl.message} />
            },
          },
        ]}
        columns={[
          {
            Header: 'Type',
            accessor: 'type',
            cell: (pl) => <TableCell.Text value={pl.type} />,
          },
          {
            Header: 'Message',
            accessor: 'message',
            cell: (pl) => <TableCell.Text value={pl.message} />,
          },
          {
            Header: 'Date',
            accessor: 'timestamp',
            cell: (pl) => (
              <TableCell.Text
                value={dayjs(pl.timestamp).format('HH:mm - DD/MM/YYYY')}
              />
            ),
          },
        ]}
      />
    </div>
  )
}
