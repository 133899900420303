/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useMemo } from 'react'
import { Session, SourceTypeEnum } from '../../../api-types'
import {
  convertEURToHRK,
  getDurationFormat,
  roundToFloatWithExactlyNDecimals,
} from '../../../utils'
import { CardWithHeader } from '../general/CardWithHeader'
import { Field } from '../general/Field'
import dayjs from 'dayjs'
import { Chip } from '../general/Chip'

interface ISessionDetailsSectionProps {
  session: Session
}

const calculateDuration = (session: Session): string => {
  const endTime = session.endedAt ? dayjs(session.endedAt) : dayjs()
  const diff = endTime.diff(dayjs(session.createdAt))
  const duration = dayjs.duration(diff)
  return duration.format(getDurationFormat(duration))
}

export const SessionDetailsSection = (props: ISessionDetailsSectionProps) => {
  const { session } = props

  const duration = useMemo(() => calculateDuration(session), [session])

  const total = useMemo(() => {
    return roundToFloatWithExactlyNDecimals(
      (session.finalMeterState
        ? session.finalMeterState - session.initialMeterState
        : session.source.state.value - session.initialMeterState) *
        session.tariff,
      2,
    )
  }, [session])

  return (
    <CardWithHeader tw='mb-6' title='Session Details'>
      <div tw='flex flex-col space-y-8'>
        <div tw='flex flex-row'>
          <Field
            tw='flex-1'
            label='Start date'
            value={dayjs(session.createdAt).format('DD.MM.YYYY. HH:mm')}
          />
          <Field
            tw='flex-1'
            label='End date'
            value={
              session.endedAt
                ? dayjs(session.endedAt).format('DD.MM.YYYY. HH:mm')
                : '-'
            }
          />
        </div>
        <div tw='flex flex-row'>
          <Field
            tw='flex-1'
            label='Initial meter state'
            value={`${roundToFloatWithExactlyNDecimals(
              session.initialMeterState,
              3,
            )} ${
              session.source.type === SourceTypeEnum.SOCKET ? 'kWh' : 'm\u00B3'
            }`}
          />
          <Field
            tw='flex-1'
            label='Final meter state'
            value={
              session.endedAt &&
              session.finalMeterState !== null &&
              session.finalMeterState !== undefined
                ? `${roundToFloatWithExactlyNDecimals(
                    session.finalMeterState,
                    3,
                  )} ${
                    session.source.type === SourceTypeEnum.SOCKET
                      ? 'kWh'
                      : 'm\u00B3'
                  }`
                : '-'
            }
          />
        </div>
        <div tw='flex flex-row'>
          <Field tw='flex-1' label='Duration' value={duration} />
          <Field
            tw='flex-1'
            label='Consumption'
            value={`${roundToFloatWithExactlyNDecimals(
              session.endedAt && session.finalMeterState
                ? session.finalMeterState - session.initialMeterState
                : session.source.state.value - session.initialMeterState,
              3,
            )} ${
              session.source.type === SourceTypeEnum.SOCKET ? 'kWh' : 'm\u00B3'
            } `}
          />
        </div>

        <div tw='flex flex-row'>
          <Field
            tw='flex-1'
            label='Tariff'
            value={`${roundToFloatWithExactlyNDecimals(session.tariff, 2)} EUR`}
          />
          <Field
            tw='flex-1'
            label='Total'
            value={`${total} EUR (${convertEURToHRK(parseFloat(total))} HRK)`}
          />
        </div>
        <div tw='flex flex-row'>
          {session.isManual ? (
            <Field
              tw='flex-1'
              label='Activated by'
              value={<Chip orange value={'Manual Mode'} />}
            />
          ) : (
            session.activatedBy?.name && (
              <Field
                tw='flex-1'
                label='Activated by'
                value={`${session.activatedBy?.name}`}
              />
            )
          )}
        </div>
      </div>
    </CardWithHeader>
  )
}
