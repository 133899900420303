/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useState } from 'react'
import { capitalize } from 'lodash'
import { Txt } from '../../common/general/Txt'
import {
  Account,
  PaymentTypeEnum,
  User,
  Vehicle,
  vehicleTypeLabels,
} from '../../../api-types'
import { Button } from '../../common/general/Button'
import { EditPaymentInfoDrawer } from './EditPaymentInfoDrawer'
import { EditCustomerInfoDrawer } from './EditCustomerInfoDrawer'
import {
  queries,
  updateAccountSessionPermissions,
  useAssignPedestal,
  useCreateEmployee,
  useCreateVehicle,
  useDeleteEmployee,
  useDeleteVehicle,
  useEditEmployee,
  useEditVehicle,
  useGetAllAssignablePedestals,
  useUnassignPedestal,
  useUpdateAccountPaymentInfo,
  useUpdateCustomerInfo,
} from '../../../queries'
import { useQueryClient } from 'react-query'
import { VehicleDrawer } from './EditVehicleDrawer'
import { AddPrepaidPaymentDrawer } from './AddPrepaidPaymentDrawer'
import { useAddPrepaidPayment } from '../../../queries'
import { convertEURToHRK, parseBackendException } from '../../../utils'
import { EditCustomerSessionPermissionsDrawer } from './EditCustomerSessionPermissions'
import { EmployeeDrawer } from './EditEmployeeDrawer'
import { SelectField } from '../../common/general/non-form-fields/SelectField'
import { IDropdownOption } from '../../../types'

export const CustomerInfo = ({
  customer,
  refetch,
}: {
  customer: Account
  refetch: () => void
}) => {
  const [editCustomerInfoDrawerVisible, setEditCustomerInfoDrawerVisible] =
    useState<boolean>(false)
  const [editPaymentInfoDrawerVisible, setEditPaymentInfoDrawerVisible] =
    useState<boolean>(false)
  const [vehicleDrawer, setVehicleDrawerVisible] = useState<boolean>(false)
  const [addPrepaidPaymentDrawerVisible, setAddPrepaidPaymentDrawerVisible] =
    useState<boolean>(false)
  const [
    editSessionPermissionsDrawerVisible,
    setEditSessionPermissionsDrawerVisible,
  ] = useState<boolean>(false)
  const [employeeDrawer, setEmployeeDrawerVisible] = useState<boolean>(false)
  const [dedicatedPedestalsFieldShown, setDedicatedPedestalsFieldShown] =
    useState<boolean>(false)

  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>()
  const [selectedEmployee, setSelectedEmployee] = useState<User | undefined>()
  const [selectedPedestal, setSelectedPedestal] = useState<
    IDropdownOption | undefined
  >()

  const updateCustomerInfo = useUpdateCustomerInfo()
  const updateAccountPaymentInfo = useUpdateAccountPaymentInfo()
  const createVehicle = useCreateVehicle()
  const editVehicle = useEditVehicle()
  const deleteVehicle = useDeleteVehicle()
  const createEmployee = useCreateEmployee()
  const editEmployee = useEditEmployee()
  const deleteEmployee = useDeleteEmployee()
  const addPrepaidPayment = useAddPrepaidPayment()
  const editSessionPermissions = updateAccountSessionPermissions()
  const assignPedestal = useAssignPedestal()
  const unassignPedestal = useUnassignPedestal()
  const { data: pedestals } = useGetAllAssignablePedestals()
  const client = useQueryClient()

  if (!customer) {
    return null
  }

  const owner = customer.owner
  const charter = owner.ownedCharter

  const handleOpenEditPaymentInfo = () => {
    setEditPaymentInfoDrawerVisible(true)
  }

  const handleOpenAddPrepaidPaymentInfo = () => {
    setAddPrepaidPaymentDrawerVisible(true)
  }

  const handleOpenEditCustomerInfo = () => {
    setEditCustomerInfoDrawerVisible(true)
  }

  const handleOpenEditVehicle = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
    setVehicleDrawerVisible(true)
  }

  const handleAddNewVehicle = () => {
    setSelectedVehicle(undefined)
    setVehicleDrawerVisible(true)
  }

  const handleOpenEditEmployee = (employee: User) => {
    setSelectedEmployee(employee)
    setEmployeeDrawerVisible(true)
  }

  const handleAddNewEmployee = () => {
    setSelectedEmployee(undefined)
    setEmployeeDrawerVisible(true)
  }

  const handleSubmitPaymentInfo = async (values: any) => {
    try {
      await updateAccountPaymentInfo(values)
      refetch()
      setEditPaymentInfoDrawerVisible(false)
    } catch (e: any) {
      parseBackendException(e)
    }
  }

  const handleAddPrepaidPayment = async (values: { amount: number }) => {
    if (!customer.id) {
      return
    }
    await addPrepaidPayment({ id: customer.id, amount: values.amount })
    refetch()
  }

  const handleSubmitCustomerInfo = async (values: any) => {
    await updateCustomerInfo(values)
    refetch()
    setEditCustomerInfoDrawerVisible(false)
  }

  const handleEditSessionPermissions = async (values: any) => {
    if (!customer.id) {
      return
    }
    await editSessionPermissions(values)
    refetch()
    setEditSessionPermissionsDrawerVisible(false)
  }

  const handleSubmitVehicle = async (values: any) => {
    try {
      if (selectedVehicle !== undefined) {
        await editVehicle(values)
      } else {
        await createVehicle(values)
      }
      setVehicleDrawerVisible(false)
      await client.invalidateQueries(queries.clients)
    } catch (e: any) {
      parseBackendException(e)
    }
  }

  const handleDeleteVehicle = async (vehicle: Vehicle) => {
    if (
      !confirm(
        `Are you sure you want to delete vehicle: ${vehicle.registration}`,
      )
    ) {
      return
    }
    await deleteVehicle(vehicle.id)
    await client.invalidateQueries(queries.clients)
  }

  const handleSubmitEmployee = async (values: any) => {
    try {
      if (selectedEmployee !== undefined) {
        await editEmployee(values)
      } else {
        await createEmployee(values)
      }
      setEmployeeDrawerVisible(false)
      await client.invalidateQueries(queries.clients)
    } catch (e: any) {
      parseBackendException(e)
    }
  }

  const handleDeleteEmployee = async (employee: User) => {
    if (
      !confirm(`Are you sure you want to delete employee: ${employee.name}`)
    ) {
      return
    }
    await deleteEmployee(employee.id)
    await client.invalidateQueries(queries.clients)
  }

  const handleAssignNewPedestal = async () => {
    try {
      if (charter && selectedPedestal) {
        await assignPedestal({
          charterId: charter?.id,
          pedestalId: selectedPedestal?.value,
        })
      }
      await client.invalidateQueries(queries.clients)
      await client.invalidateQueries(queries.assignablePedestals)
      setDedicatedPedestalsFieldShown(false)
      setSelectedPedestal(undefined)
    } catch (e: any) {
      parseBackendException(e)
    }
  }

  const handleUnassignPedestal = async (pedestalId: number) => {
    try {
      await unassignPedestal({
        pedestalId,
        charterId: customer?.owner?.ownedCharter?.id,
      })
      await client.invalidateQueries(queries.clients)
      await client.invalidateQueries(queries.assignablePedestals)
    } catch (e: any) {
      parseBackendException(e)
    }
  }

  return (
    <>
      <div
        id='customer-info'
        tw='bg-white rounded-lg p-6 divide-y mb-5 mt-4'
        style={{
          boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
        }}
      >
        <div>
          <Txt xl tw='mb-2'>
            Customer info
          </Txt>
          <Txt tw='mb-2'>Personal details</Txt>
        </div>
        <div tw='flex py-6'>
          <div tw='flex-auto w-1/3'>
            <Txt tw='text-gray-400'>Full name</Txt>
          </div>
          <div tw='flex-auto w-2/3'>
            <Txt>{customer.owner.name}</Txt>
          </div>
        </div>
        <div tw='flex py-6'>
          <div tw='flex-auto w-1/3'>
            <Txt tw='text-gray-400'>Phone</Txt>
          </div>
          <div tw='flex-auto w-2/3'>
            <Txt>{customer.owner.phoneNumber}</Txt>
          </div>
        </div>
        <div tw='flex py-6'>
          <div tw='flex-auto w-1/3'>
            <Txt tw='text-gray-400'>Email address</Txt>
          </div>
          <div tw='flex-auto w-2/3'>
            <Txt>{customer.owner.email}</Txt>
          </div>
        </div>
        <div tw='flex py-6'>
          <div tw='flex-auto w-1/3'>
            <Txt tw='text-gray-400'>Country</Txt>
          </div>
          <div tw='flex-auto w-2/3'>
            <Txt>{customer.owner.country}</Txt>
          </div>
        </div>
        <div tw='flex py-6'>
          <div tw='flex-auto w-1/3'>
            <Txt tw='text-gray-400 mb-3'>Vehicles</Txt>
            <Button
              text='Add new'
              light
              onClick={() => handleAddNewVehicle()}
            />
          </div>
          {customer.owner?.vehicles.length !== 0 ? (
            <div tw='flex-auto w-2/3'>
              <div tw='border'>
                {customer.owner?.vehicles?.map((vehicle, i) => (
                  <div tw='w-full flex p-3 justify-between' key={i}>
                    <div tw='flex items-center'>
                      <Txt>{vehicle.registration}</Txt>
                      <Txt tw='text-gray-400 pl-1'>
                        ({vehicleTypeLabels[vehicle.type]})
                      </Txt>
                    </div>
                    <div tw='flex'>
                      <Txt tw='ml-auto text-primary-600 border-r-2 px-3'>
                        <Button
                          text={'Edit'}
                          onClick={() => handleOpenEditVehicle(vehicle)}
                        >
                          Edit
                        </Button>
                      </Txt>
                      <Txt tw='ml-auto text-primary-600 mx-3'>
                        <Button
                          text={'Delete'}
                          onClick={() => handleDeleteVehicle(vehicle)}
                          cancel
                        >
                          Delete
                        </Button>
                      </Txt>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div tw='flex-auto w-2/3'>
              <Txt>This customer currently has no vehicles.</Txt>
            </div>
          )}
        </div>
        <Button
          tw='mt-6 self-end'
          text={'Edit'}
          onClick={handleOpenEditCustomerInfo}
        />
      </div>
      <div
        id='customer-payment'
        tw='bg-white rounded-lg p-6 divide-y'
        style={{
          boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
        }}
      >
        <div>
          <Txt xl tw='mb-2'>
            Payment info
          </Txt>
          <div>
            <div tw='bg-gray-100 p-6'>
              <Txt tw='font-bold mb-2'>{capitalize(customer.paymentType)}</Txt>
              <div tw='flex mb-2'>
                {customer.paymentType === PaymentTypeEnum.PREPAID && (
                  <div tw='flex'>
                    <Txt tw='flex-auto text-gray-400 mr-2'>Available funds</Txt>
                    <Txt tw='flex-auto'>
                      {customer.balance.toFixed(3)} EUR (
                      {convertEURToHRK(customer.balance)} HRK)
                    </Txt>
                  </div>
                )}
                {customer.paymentType !== PaymentTypeEnum.PREPAID && (
                  <div tw='flex'>
                    <Txt tw='flex-auto text-gray-400'>Due date</Txt>
                    <Txt tw='flex-auto ml-2'>
                      after {customer.numberOfDaysUntilPayment} days
                    </Txt>
                  </div>
                )}
              </div>
              <div tw='flex '>
                <div tw='flex mr-6'>
                  {customer.powerTariff && (
                    <>
                      <Txt tw='flex-auto text-gray-400 mr-2'>Power tariff</Txt>
                      <Txt tw='flex-auto'>
                        {customer.powerTariff} EUR/kWh (
                        {convertEURToHRK(customer.powerTariff)} HRK/kWh)
                      </Txt>
                    </>
                  )}
                </div>
                <div tw='flex'>
                  <Txt tw='flex-auto text-gray-400 mr-2'>Water tariff</Txt>
                  {customer.waterTariff && (
                    <Txt tw='flex-auto'>
                      {customer.waterTariff} EUR/{'m\u00B3'} (
                      {convertEURToHRK(customer.waterTariff)} HRK/{'m\u00B3'})
                    </Txt>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div tw='flex'>
            <Button
              tw='mt-6 mr-6 self-end'
              text={'Edit'}
              onClick={handleOpenEditPaymentInfo}
            />
            {customer.paymentType === PaymentTypeEnum.PREPAID && (
              <Button
                light
                tw='mt-6 self-end'
                text={'Edit prepaid funds'}
                onClick={handleOpenAddPrepaidPaymentInfo}
              />
            )}
          </div>
        </div>
      </div>
      <div
        id='customer-payment'
        tw='bg-white rounded-lg p-6 divide-y'
        style={{
          boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
          marginTop: 24,
        }}
      >
        <div>
          <Txt xl tw='mb-2'>
            Session permissions
          </Txt>
          <Txt tw='mb-2'>Permissions for activating sessions</Txt>
        </div>
        <div tw='flex py-6'>
          <Txt tw='text-gray-400 w-1/3'>Max active power sessions</Txt>
          <Txt tw='w-2/3'>{customer.maxActivePowerSessions}</Txt>
        </div>
        <div tw='flex py-4'>
          <Txt tw='text-gray-400 w-1/3'>Max active water sessions</Txt>
          <Txt tw='w-2/3'>{customer.maxActiveWaterSessions}</Txt>
        </div>
        <div tw='flex'>
          <Button
            tw='mt-6 mr-6 self-end'
            text={'Edit'}
            onClick={() => setEditSessionPermissionsDrawerVisible(true)}
          />
        </div>
      </div>
      {charter && (
        <div
          id='customer-charter-info'
          tw='bg-white rounded-lg p-6 divide-y'
          style={{
            boxShadow:
              '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
            marginTop: 24,
          }}
        >
          <div>
            <Txt xl tw='mb-2'>
              Charter info
            </Txt>
            <Txt tw='mb-2'>Charter personnel info</Txt>
          </div>
          <div tw='flex py-6'>
            <div tw='flex-auto w-1/3'>
              <Txt tw='text-gray-400 mb-3'>Personnel</Txt>
              <Button
                text='Add new'
                light
                onClick={() => handleAddNewEmployee()}
              />
            </div>
            {charter.employees?.length !== 0 ? (
              <div tw='flex-auto w-2/3'>
                <div tw='border'>
                  {charter.employees?.map((employee, i) => (
                    <div tw='w-full flex p-3 justify-between' key={i}>
                      <div tw='flex items-center'>
                        <Txt>{employee.name}</Txt>
                        <Txt tw='text-gray-400 pl-1'>({employee.email})</Txt>
                      </div>
                      <div tw='flex'>
                        <Txt tw='ml-auto text-primary-600 border-r-2 px-3'>
                          <Button
                            text={'Edit'}
                            onClick={() => handleOpenEditEmployee(employee)}
                          ></Button>
                        </Txt>
                        <Txt tw='ml-auto text-primary-600 mx-3'>
                          <Button
                            text={'Delete'}
                            onClick={() => handleDeleteEmployee(employee)}
                            cancel
                          >
                            Delete
                          </Button>
                        </Txt>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div tw='flex-auto w-2/3'>
                <Txt>This charter currently has no employees.</Txt>
              </div>
            )}
          </div>
          <div tw='flex py-6'>
            <div tw='flex-auto w-1/3'>
              <Txt tw='text-gray-400 mb-3'>Dedicated pedestals</Txt>
              {!dedicatedPedestalsFieldShown && (
                <Button
                  text='Add new'
                  light
                  onClick={() => setDedicatedPedestalsFieldShown(true)}
                />
              )}
            </div>
            <div tw='flex-auto w-2/3'>
              {charter.dedicatedPedestals?.length !== 0 ? (
                <div tw='border'>
                  {charter.dedicatedPedestals?.map((pedestal, i) => (
                    <div tw='w-full flex p-3 justify-between' key={i}>
                      <div tw='flex items-center'>
                        <Txt tw=''>{pedestal.identifier}</Txt>
                        <Txt tw='text-gray-400 pl-1'>({pedestal.status})</Txt>
                      </div>
                      <div tw='flex'>
                        <Txt tw='ml-auto text-primary-600 mx-3'>
                          <Button
                            text={'Remove'}
                            onClick={() => handleUnassignPedestal(pedestal.id)}
                            cancel
                          >
                            Delete
                          </Button>
                        </Txt>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                !dedicatedPedestalsFieldShown && (
                  <Txt>This charter currently has no dedicated pedestals.</Txt>
                )
              )}
              {dedicatedPedestalsFieldShown && (
                <div tw='flex p-3'>
                  <SelectField
                    tw='flex'
                    isSearchable
                    selectedOption={selectedPedestal}
                    options={
                      pedestals?.map((pedestal) => ({
                        label: pedestal.identifier,
                        value: pedestal.id,
                      })) ?? []
                    }
                    onChange={(option) => setSelectedPedestal(option)}
                  />
                  <Button
                    tw='ml-10'
                    text='Assign'
                    onClick={handleAssignNewPedestal}
                  />
                  <Button
                    tw='ml-2'
                    text='Cancel'
                    flat
                    onClick={() => setDedicatedPedestalsFieldShown(false)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <EditCustomerInfoDrawer
        open={editCustomerInfoDrawerVisible}
        close={() => setEditCustomerInfoDrawerVisible(false)}
        onSubmit={async (values: any) => {
          await handleSubmitCustomerInfo(values)
        }}
        customer={customer}
      />
      <EditPaymentInfoDrawer
        open={editPaymentInfoDrawerVisible}
        close={() => setEditPaymentInfoDrawerVisible(false)}
        onSubmit={async (values: any) => {
          await handleSubmitPaymentInfo(values)
        }}
        customer={customer}
      />
      <VehicleDrawer
        isEdit={selectedVehicle != undefined}
        open={vehicleDrawer}
        close={() => setVehicleDrawerVisible(false)}
        onSubmit={async (values: any) => await handleSubmitVehicle(values)}
        customer={customer}
        vehicle={selectedVehicle}
      />
      {charter && (
        <EmployeeDrawer
          isEdit={selectedEmployee !== undefined}
          open={employeeDrawer}
          close={() => setEmployeeDrawerVisible(false)}
          onSubmit={async (values: any) => await handleSubmitEmployee(values)}
          owner={owner}
          charter={charter}
          employee={selectedEmployee}
        />
      )}
      <AddPrepaidPaymentDrawer
        open={addPrepaidPaymentDrawerVisible}
        onSubmit={async (values: { amount: number }) => {
          await handleAddPrepaidPayment(values)
        }}
        close={() => setAddPrepaidPaymentDrawerVisible(false)}
        customer={customer}
      />
      <EditCustomerSessionPermissionsDrawer
        open={editSessionPermissionsDrawerVisible}
        close={() => setEditSessionPermissionsDrawerVisible(false)}
        customer={customer}
        onSubmit={async (values: any) =>
          await handleEditSessionPermissions(values)
        }
      />
    </>
  )
}
