/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ContentContainer } from '../general/ContentContainer'
import { Breadcrumbs } from '../general/Breadcrumbs'
import { ActionBar } from '../general/ActionBar'
import { SessionDetailsSection } from './SessionDetailsSection'
import { SessionCustomerDetailsSection } from './SessionCustomerDetailsSection'
import { Link, useParams } from 'react-router-dom'
import { useRequestSessionStopById, useSession } from '../../../queries'
import { Txt } from '../../common/general/Txt'
import { StopIcon } from '@heroicons/react/outline'
import {
  LocationMarkerIcon,
  TemplateIcon,
  UserIcon,
} from '@heroicons/react/solid'
import { adminRoutes, superadminRoutes } from '../general/route-names'
import { SessionRequest, sourceLabels } from '../../../api-types'
import { Button } from '../general/Button'
import { useState } from 'react'
import { RequestSessionStopStatusModalForSessionDetails } from './RequestSessionStopStatusModalForSessionDetails'

export const SessionDetailsPage = () => {
  const { identifier, sessionId } =
    useParams<{ identifier: string; sessionId: string }>()

  const [sessionRequestStatusModalOpen, setSessionRequestStatusModalOpen] =
    useState<boolean>(false)
  const [SessionRequests, setSessionRequests] = useState<
    SessionRequest[] | undefined
  >(undefined)

  const requestSessionStopById = useRequestSessionStopById()

  const {
    data: session,
    isLoading: sessionIsLoading,
    isError: sessionIsError,
    refetch: refetchSession,
  } = useSession(sessionId)

  const handleSessionStop = async (sessionId: number) => {
    setSessionRequestStatusModalOpen(true)
    const request = await requestSessionStopById(sessionId)
    setSessionRequests([request])
  }

  if (sessionIsError) {
    return <div>Something went wrong!</div>
  }

  if (sessionIsLoading || !session) {
    return <div>Loading</div>
  }

  const { pedestal } = session.source

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-2'
        links={[
          { name: 'Pedestals', route: '/pedestals' },
          {
            name: identifier,
            route: `${superadminRoutes.VIEW_PEDESTAL}/${identifier}`,
          },
          { name: 'Session details' },
        ]}
      />
      <ActionBar title='Session details' tw='mb-2'>
        {!session.endedAt && (
          <Button
            cancel
            icon={StopIcon}
            text='End session'
            onClick={() => handleSessionStop(session.id)}
          />
        )}
      </ActionBar>
      <div tw='flex '>
        <div>
          <div tw='flex flex-row mb-6 items-center'>
            <Link
              to={{
                pathname: `${superadminRoutes.EDIT_LOCATION}/${pedestal.location.id}`,
              }}
              tw='flex items-center'
            >
              <LocationMarkerIcon tw='h-5 w-5 mr-2' color='#9CA3AF' />
              <Txt tw='text-gray-500'>{pedestal.location.name}</Txt>
            </Link>
            {pedestal.pedestalType && (
              <Link
                to={{
                  pathname: `${adminRoutes.VIEW_PEDESTAL}/${pedestal.identifier}`,
                }}
                tw='flex items-center'
              >
                <TemplateIcon tw='h-5 w-5 mr-2 ml-6' color='#9CA3AF' />
                <Txt tw='text-gray-500'>{`${
                  sourceLabels[session.source.type]
                } ${session.source.position}`}</Txt>
              </Link>
            )}
            <Link
              to={{
                pathname: `${adminRoutes.VIEW_PEDESTAL}/${pedestal.identifier}`,
              }}
              tw='flex items-center'
            >
              <TemplateIcon tw='h-5 w-5 mr-2 ml-6' color='#9CA3AF' />
              <Txt tw='text-gray-500'>{pedestal.identifier}</Txt>
            </Link>
            {session.activatedBy && (
              <>
                <UserIcon tw='h-5 w-5 mr-2 ml-6' color='#9CA3AF' />
                <Txt tw='text-gray-500'>
                  Activated by {session.activatedBy.name}
                </Txt>
              </>
            )}
          </div>
        </div>
      </div>
      <SessionDetailsSection session={session} />
      <SessionCustomerDetailsSection session={session} />
      <RequestSessionStopStatusModalForSessionDetails
        isOpen={sessionRequestStatusModalOpen}
        onConfirm={() => {}}
        setIsOpen={(val) => {
          setSessionRequestStatusModalOpen(val)
        }}
        sessionRequests={SessionRequests}
        setSessionRequests={setSessionRequests}
        refetchSession={refetchSession}
      />
    </ContentContainer>
  )
}
