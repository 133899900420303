/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { capitalize } from 'lodash'
import tw from 'twin.macro'

import { Txt } from '../../../general/Txt'
import { Granularity } from '../../report-types/report-enums'

interface GranularityTabProps {
  selectedGranularity: Granularity
  setSelectedGranularity: (granularity: Granularity) => void
}

export const GranularityTab = (props: GranularityTabProps) => {
  const { selectedGranularity, setSelectedGranularity } = props

  return (
    <div tw='flex flex-row flex-1 justify-center items-center'>
      {Object.values(Granularity).map((granularity, index) => (
        <div
          key={index}
          css={[
            tw`py-2 px-6 bg-primary-100`,
            index === 0 && tw`rounded-l-md`,
            index === Object.values(Granularity).length - 1 && tw`rounded-r-md`,
            selectedGranularity === granularity && tw`bg-primary-500`,
            selectedGranularity !== granularity &&
              tw`hover:cursor-pointer hover:bg-primary-200`,
          ]}
          onClick={() =>
            selectedGranularity !== granularity &&
            setSelectedGranularity(granularity)
          }
        >
          <Txt
            l
            css={[
              tw`font-semibold`,
              selectedGranularity === granularity && tw`text-white`,
            ]}
          >
            {capitalize(granularity)}
          </Txt>
        </div>
      ))}
    </div>
  )
}
