export enum ReportType {
  SESSIONS = 'sessions',
  MANUAL_SESSIONS = 'manual-sessions',
  ALARMS = 'alarms',
  TRANSACTIONS = 'transactions',
  CONSUMPTION = 'consumption',
}

export enum Granularity {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum ManualSessionType {
  SOCKET = 'Socket',
  VALVE = 'Valve',
}

export enum ReportLabelOptions {
  DAY = 'Do MMM',
  WEEK = 'Do MMM',
  MONTH = `MMM YY[']`,
  YEAR = 'YYYY',
}

export enum AlarmTypeEnum {
  FUSE = 'FUSE',
  MODBUS = 'MODBUS',
  HEARTBEAT = 'HEARTBEAT',
}
