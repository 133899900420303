/** @jsxImportSource @emotion/react */
import 'twin.macro'
import React, { useCallback, useState } from 'react'
import { Card } from '../../common/general/Card'
import { Txt } from '../../common/general/Txt'
import { SuperadminSourceList } from './SuperadminSourceList'
import { Button } from '../../common/general/Button'
import { ProgressBar } from './SuperadminProgressBar'
import { SuperadminTestConfirmationModal } from './SuperadminTestConfirmationModal'
import { SuccessAlert } from '../../common/general/alerts/SuccessAlert'
import { WarningAlert } from '../../common/general/alerts/WarningAlert'
import {
  Pedestal,
  PedestalStatus,
  Source,
  SourceTypeEnum,
} from '../../../api-types'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { errors } from '../../common/general/utils'
import { showErrorNotification } from '../../common/general/notification'
import { http } from '../../../http'
import { useQueryClient } from 'react-query'
import { queries } from '../../../queries'
import { useHistory } from 'react-router-dom'
import { superadminRoutes } from '../../common/general/route-names'

export interface ISourceTestForm {
  power?: number
  time: number
  sourceId?: number
  allowedDeviation: number
}

const schema = yup.object().shape({
  power: yup.number().optional(),
  time: yup
    .number()
    .moreThan(0, 'Must be higher than 0')
    .required(errors.required),
  allowedDeviation: yup.number().required(errors.required),
  sourceId: yup.number().optional(),
})

interface IPedestalTestingTabProps {
  isTesting: boolean
  setIsTesting: (val: boolean) => void
  pedestal: Pedestal
}

export const SuperadminPedestalTestingTab = (
  props: IPedestalTestingTabProps,
) => {
  const { isTesting, setIsTesting, pedestal } = props
  const [readyConfirmationModalIsOpen, setReadyConfirmationModalIsOpen] =
    useState(false)
  const [testFinishedAlertVisible, setTestFinishedAlertVisible] = useState(true)
  const [testingIsFinished] = useState(false)
  const [testingIsSuccessful] = useState(false)
  const history = useHistory()
  const client = useQueryClient()

  const methods = useForm<ISourceTestForm>({
    defaultValues: {
      power: 0,
      time: 0,
      allowedDeviation: 0,
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const selectedSourceId = methods.watch('sourceId')

  const handleStartTesting = useCallback(
    methods.handleSubmit(async (data) => {
      try {
        // todo: refactor
        const source = pedestal.sources.find(
          (source) => source.id === data.sourceId,
        )!
        if (source.type === SourceTypeEnum.SOCKET) {
          await http.createSocketTest(data)
        } else {
          await http.createValveTest(data)
        }
        await client.invalidateQueries([queries.pedestal, pedestal.identifier])
      } catch (e) {
        showErrorNotification('Something went wrong. Please try again')
      }
    }),
    [],
  )

  const handleOnSourceSelect = (source: Source) => {
    methods.reset({
      power: 0,
      time: 0,
      sourceId: source.id,
      allowedDeviation: 0,
    })
  }

  const handleNewTesting = () => {
    setIsTesting(false)
    //TODO implementation
    return null
  }

  const handleCancelTesting = () => {
    setIsTesting(false)
    //TODO implementation
    return null
  }

  const handleConfirm = async () => {
    await http.updatePedestal(pedestal.id, {
      status: PedestalStatus.READY_TO_INSTALL,
    })
    history.push(
      `${superadminRoutes.PEDESTALS_INSTALLATION}/${pedestal.identifier}`,
    )
  }

  const handleMarkAsReadyForInstallation = () => {
    handleConfirm()
  }

  return (
    <>
      <SuperadminTestConfirmationModal
        isOpen={readyConfirmationModalIsOpen}
        setIsOpen={setReadyConfirmationModalIsOpen}
        onConfirm={handleConfirm}
      />
      {isTesting ? (
        <>
          {testFinishedAlertVisible &&
            testingIsFinished &&
            (testingIsSuccessful ? (
              <SuccessAlert
                text={'Successfully finished'}
                onClose={() => setTestFinishedAlertVisible(false)}
              />
            ) : (
              <WarningAlert
                text={'Finished with inconsistencies'}
                onClose={() => setTestFinishedAlertVisible(false)}
              />
            ))}
          <Card tw='p-0 mt-6'>
            <div tw='p-6'>
              {testingIsFinished ? (
                <Txt xl>Test results</Txt>
              ) : (
                <Txt xl>Test in progress...</Txt>
              )}
            </div>
            <ProgressBar percentage={60} />
            <div tw='p-6 grid grid-cols-2'>
              <div tw='col-span-1'>
                <Txt tw='text-gray-500'>Expected value</Txt>
                <Txt>{123}</Txt>
              </div>
              <div tw='col-span-1'>
                <Txt tw='text-gray-500'>Real value</Txt>
                <Txt>{123}</Txt>
              </div>
            </div>
            <div tw='bg-gray-50 px-6 py-3 rounded-b-md'>
              <div tw='flex flex-row justify-end align-items[center]'>
                {testingIsFinished ? (
                  <>
                    <Button
                      text='New testing'
                      onClick={handleNewTesting}
                      tw='mr-3'
                      cancel
                    />
                    <Button
                      text='Mark as ready for installation'
                      onClick={handleMarkAsReadyForInstallation}
                      primary
                    />
                  </>
                ) : (
                  <>
                    <Button
                      text='Cancel testing'
                      onClick={handleCancelTesting}
                      abort
                    />
                  </>
                )}
              </div>
            </div>
          </Card>
        </>
      ) : (
        <form noValidate>
          <FormProvider {...methods}>
            <Card tw='mb-6 mt-6 p-0'>
              <div tw='p-6'>
                <Txt xl tw='mb-1'>
                  Test config
                </Txt>
                <Txt sm tw='mb-6 text-gray-500'>
                  Enter the parameters and compare the expected and real usage
                  values after the test completes.
                </Txt>
                <SuperadminSourceList
                  pedestal={pedestal}
                  onSourceSelect={handleOnSourceSelect}
                  selectedSourceId={selectedSourceId}
                />
              </div>
              <div tw='bg-gray-50 px-6 py-3 rounded-b-md'>
                <div tw='flex flex-row justify-end align-items[center]'>
                  <Button
                    text='Mark as ready for instalation'
                    onClick={handleMarkAsReadyForInstallation}
                    cancel
                    tw='mr-3'
                  />
                  <Button
                    text='Start Testing'
                    onClick={handleStartTesting}
                    primary
                  />
                </div>
              </div>
            </Card>
          </FormProvider>
        </form>
      )}
    </>
  )
}
