import 'twin.macro'

import ReactSwitch from 'react-switch'

/** @jsxImportSource @emotion/react */

interface ISwitchProps {
  value: boolean
  onToggle(): void
  className?: string | undefined
  disabled?: boolean
}

export const Switch = (props: ISwitchProps) => {
  return (
    <ReactSwitch
      disabled={props.disabled || false}
      className={props.className}
      onChange={props.onToggle}
      checked={props.value}
      offColor='#E5E7EB'
      onColor='#1D5883'
      style={{ padding: 20 }}
      checkedIcon={false}
      uncheckedIcon={false}
      hidden
      height={24}
      width={44}
      handleDiameter={20}
    />
  )
}
