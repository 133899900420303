/** @jsxImportSource @emotion/react */
import 'twin.macro'
import tw from 'twin.macro'
import 'twin.macro'
import { useEffect } from 'react'
import { useController } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import 'react-datepicker/dist/react-datepicker.css'

const DateInputField = (props: {
  value: Date
  onChange: (value: Date) => void
}) => {
  const { value, onChange } = props
  return (
    <DatePicker
      css={[
        tw`flex w-full my-1 px-2.5 py-[0.675rem] border rounded-md focus:outline-none focus:ring-primary-700 focus:bg-white focus:z-10`,
        tw`font-sans font-normal text-sm text-gray-900`,
      ]}
      selected={value}
      onChange={(date: Date) => onChange(date)}
    />
  )
}

export const DateInput = (props: {
  startDateName: string
  endDateName: string
  label: string
  className?: string
  initialStartValue?: string
  initialEndValue?: string
}): JSX.Element => {
  const { startDateName, endDateName, label, className, initialStartValue } =
    props

  const startDate = useController({
    name: startDateName,
  })
  const endDate = useController({
    name: endDateName,
  })

  useEffect(() => {
    if (!startDate.field.value || !endDate.field.value) {
      if (!initialStartValue || !initialStartValue) {
        startDate.field.onChange(dayjs().toDate())
        endDate.field.onChange(dayjs().add(1, 'day').toDate())
      } else {
        startDate.field.onChange(dayjs(initialStartValue).toDate())
        endDate.field.onChange(dayjs(initialStartValue).add(1, 'day').toDate())
      }
    }
  }, [])

  return (
    <div className={className}>
      <label tw='font-sans font-medium text-sm text-gray-700'>{label}</label>
      <div tw='w-full flex items-center justify-between'>
        <DateInputField
          value={dayjs(startDate.field.value).toDate()}
          onChange={(date) => startDate.field.onChange(date)}
        />
        <DateInputField
          value={dayjs(endDate.field.value).toDate()}
          onChange={(date) => endDate.field.onChange(date)}
        />
      </div>
      <div tw='h-6'></div>
    </div>
  )
}
