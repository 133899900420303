import 'twin.macro'

/** @jsxImportSource @emotion/react */
import React from 'react'

import { Txt } from '../../common/general/Txt'

export const AdminSourcesContainer = (props: {
  title: string
  children: React.ReactNode
}) => {
  const { title, children } = props
  return (
    <div tw='w-full'>
      <Txt l tw='font-medium w-full py-4'>
        {title}
      </Txt>
      <div tw='w-full flex flex-row flex-wrap gap-5 h-auto'>{children}</div>
    </div>
  )
}
