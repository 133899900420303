/** @jsxImportSource @emotion/react */
import 'twin.macro'
import * as yup from 'yup'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { queries, useEmployees, useLocation } from '../../../queries'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumbs } from '../../common/general/Breadcrumbs'
import { Button } from '../../common/general/Button'
import { ContentContainer } from '../../common/general/ContentContainer'
import {
  ILocationForm,
  WorkingHoursInfo,
} from '../location-new/SuperadminNewLocationPage'
import { SuperadminLocationFormBasicSection } from '../../common/location/SuperadminLocationFormBasicSection'
import { SuperadminLocationFormConstantsSection } from '../../common/location/SuperadminLocationFormConstantsSection'
import { SuperadminLocationFormPedestalConstantSection } from '../../common/location/SuperadminLocationFormPedestalConstantSection'
import { SuperadminLocationFormPedestalTypesSection } from '../../common/location/pedestal-types-section/SuperadminLocationFormPedestalTypesSection'
import { SuperadminLocationFormUsersSection } from '../../common/location/SuperadminLocationFormUsersSection'
import { Txt } from '../../common/general/Txt'
import { errors } from '../../common/general/utils'
import { http } from '../../../http'
import { superadminRoutes } from '../../common/general/route-names'
import { showErrorNotification } from '../../common/general/notification'
import { useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { PageLoader } from '../../common/general/PageLoader'
import { SuperAdminWorkingHoursFormSection } from '../../common/location/SuperAdminWorkingHoursFormSection'
import { e } from 'mathjs'

const schema = yup.object().shape({
  type: yup.string().required(errors.required),
  name: yup.string().required(errors.required),
  city: yup.string().required(errors.required),
  postcode: yup.string().required(errors.required),
  country: yup.string().required(errors.required),
  address: yup.string().required(errors.required),
  latitude: yup.number().min(0).required(errors.required),
  longitude: yup.number().min(0).required(errors.required),
  workingHours: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          isDaySelected: yup.boolean().required(errors.required),
          dayOfTheWeek: yup.number().min(1).max(7).required(errors.required),
          openingTime: yup.string().required(errors.required),
          closingTime: yup.string().required(errors.required),
        })
        .required(errors.required),
    )
    .required(errors.required),
  visible: yup.boolean().required(errors.required),
  plcConstants: yup.object().shape({
    twait: yup.string().required(errors.required),
    treset: yup.string().required(errors.required),
    tshutdown: yup.string().required(errors.required),
    tupdatecycle: yup.string().required(errors.required),
    twateroff: yup.string().required(errors.required),
    tfilt: yup.string().required(errors.required),
    tmodbus: yup.string().required(errors.required),
    thart: yup.string().required(errors.required),
  }),
  constants: yup.object().shape({
    minLightRequests: yup.string().required(errors.required),
    turnOnLightsTime: yup.string().required(errors.required),
    turnOffLightsTime: yup.string().required(errors.required),
    minHeatRequests: yup.string().required(errors.required),
  }),
  pedestalTypes: yup.array(),
  users: yup.array(),
})

export const SuperadminEditLocationPage = () => {
  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()

  const {
    data: location,
    isLoading: locationIsLoading,
    isError: locationIsError,
  } = useLocation(id)
  const {
    data: employees,
    isLoading: employeesIsLoading,
    isError: employeesIsError,
  } = useEmployees(id)

  const methods = useForm<ILocationForm>({
    defaultValues: {
      pedestalTypes: [],
      users: [],
      plcConstants: {},
      constants: {},
      workingHours: [],
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const history = useHistory()
  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      await http.updateLocation(location.id, values)
      await queryClient.refetchQueries([queries.locations, id])
      await queryClient.refetchQueries([queries.employees, id])
      history.push(superadminRoutes.LOCATIONS)
    } catch (e) {
      console.error(e)
      showErrorNotification('Something went wrong. Please try again')
    }
  })

  const { fields } = useFieldArray({
    control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'workingHours', // unique name for your Field Array
  })

  useEffect(() => {
    if (!locationIsLoading && location && !employeesIsLoading && employees) {
      const workingHours: WorkingHoursInfo[] = []
      for (let i = 1; i <= 7; i++) {
        const day = location.workingHours.find((wh) => wh.dayOfTheWeek === i)
        if (day) {
          workingHours.push({ ...day, isDaySelected: true })
        } else {
          workingHours.push({
            dayOfTheWeek: i,
            openingTime: '8',
            closingTime: '20',
            isDaySelected: false,
          })
        }
      }
      location.workingHours = workingHours
      methods.reset({
        users: employees,
        ...location,
      })
    }
  }, [locationIsLoading, employeesIsLoading])

  if (locationIsLoading || employeesIsLoading) {
    return <PageLoader />
  }

  if (!location || !employees || locationIsError || employeesIsError) {
    return <div>Somethting went wrong!</div>
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-2'
        links={[
          { name: 'Locations', route: '/locations' },
          { name: location.name },
        ]}
      />
      <form noValidate>
        <FormProvider {...methods}>
          <Txt xxl tw='mb-4'>
            {location.name}
          </Txt>

          <SuperadminLocationFormBasicSection />
          <SuperAdminWorkingHoursFormSection fields={fields} />
          <SuperadminLocationFormConstantsSection />
          <SuperadminLocationFormPedestalConstantSection />
          <SuperadminLocationFormPedestalTypesSection />
          <SuperadminLocationFormUsersSection />

          <div tw='flex flex-row justify-end align-items[center]'>
            <Button
              text='Back'
              cancel
              tw='mr-3'
              onClick={() => history.replace(superadminRoutes.LOCATIONS)}
            />
            <Button text='Update' primary onClick={onSubmit} />
          </div>
        </FormProvider>
      </form>
    </ContentContainer>
  )
}
