/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ActionBar } from '../../general/ActionBar'
import { ContentContainer } from '../../general/ContentContainer'
import { adminRoutes } from '../../general/route-names'
import { ReportOption, ReportOptionCard } from './ReportOptionCard'

const reportOptions: ReportOption[] = [
  {
    title: 'Sessions Report',
    link: adminRoutes.REPORTS_SESSIONS,
  },
  {
    title: 'Manual Sessions Report',
    link: adminRoutes.REPORTS_MANUAL_SESSIONS,
  },
  {
    title: 'Alarms Report',
    link: adminRoutes.REPORTS_ALARMS,
  },
  {
    title: 'Transactions Report',
    link: adminRoutes.REPORTS_TRANSACTIONS,
  },
  // {
  //   title: 'Consumption Report',
  //   link: adminRoutes.REPORTS_CONSUMPTION,
  // },
  {
    title: 'Sources Reports',
    link: adminRoutes.REPORTS_SOURCES,
  },
]

export const ReportsPage = () => {
  return (
    <ContentContainer>
      <ActionBar title='Reports' tw='mb-5' />
      <div tw='flex flex-wrap flex-row gap-4'>
        {reportOptions.map((option, index) => (
          <ReportOptionCard key={index} option={option} />
        ))}
      </div>
    </ContentContainer>
  )
}
