import React, { useState } from 'react'
import 'twin.macro'
import { AdminSourcesContainer } from './AdminSourcesContainer'
import { AdminSourceDrawer } from './AdminSourceDrawer'
import { Pedestal, Session, Socket, Source, Valve } from '../../../api-types'
import { AdminSocketCard } from './AdminSocketCard'
import { AdminValveCard } from './AdminValveCard'
import { http } from '../../../http'

interface AdminPedestalStatusesProps {
  pedestal: Pedestal
}

export const AdminPedestalStatuses = (props: AdminPedestalStatusesProps) => {
  const { pedestal } = props
  const [open, setOpen] = useState(false)
  const [selectedSource, setSelectedSource] = useState({} as Source)

  const handeSourceCardClick = (source: Source) => {
    setSelectedSource(source)
    setOpen(true)
  }

  const handleStopSession = async (session: Session) => {
    await http.requestSessionStopById(session.id)
    setOpen(false)
  }

  return (
    <>
      <AdminSourcesContainer title='Power'>
        {pedestal.sockets.map((socket: Socket, index: number) => (
          <AdminSocketCard
            socket={socket}
            onClick={handeSourceCardClick}
            key={index}
          />
        ))}
      </AdminSourcesContainer>
      <AdminSourcesContainer title='Water'>
        {pedestal.valves.map((valve: Valve, index: number) => (
          <AdminValveCard
            valve={valve}
            onClick={handeSourceCardClick}
            key={index}
          />
        ))}
      </AdminSourcesContainer>
      <AdminSourceDrawer
        source={selectedSource}
        pedestal={pedestal}
        open={open}
        setOpen={setOpen}
        close={() => setOpen(false)}
        onStopSession={handleStopSession}
      />
    </>
  )
}
