/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useEffect, useState } from 'react'
import {
  useGetAllPedestals,
  useGetAllPedestalTypes,
  useGetAllSourcesByPedestalId,
} from '../../../../queries'
import { adminRoutes } from '../../general/route-names'
import { ActionBar } from '../../general/ActionBar'
import { Breadcrumbs } from '../../general/Breadcrumbs'
import { ContentContainer } from '../../general/ContentContainer'
import { PageLoader } from '../../general/PageLoader'
import { isEmpty } from 'lodash'
import { ReportComponent } from '../report-components/ReportComponent'
import { useSearchQueryParams } from '../../general/hooks/useSearchQueryParams'
import { SessionReportSearchParams } from '../report-types/report-search-params-types'
import { initialReportFilters } from '../report-types/report-types'
import { ReportType } from '../report-types/report-enums'

export const SessionReportPage = () => {
  const [searchParams] = useSearchQueryParams<SessionReportSearchParams>()

  const [filters, setFilters] = useState<SessionReportSearchParams>(
    !isEmpty(searchParams) ? searchParams : initialReportFilters,
  )

  //#region Fetching data
  const {
    data: pedestals,
    error: pedestalsError,
    isLoading: isLoadingPedestals,
  } = useGetAllPedestals()
  const {
    data: pedestalTypes,
    error: pedestalTypesError,
    isLoading: isLoadingPedestalTypes,
  } = useGetAllPedestalTypes()
  const {
    data: sources,
    error: sourcesError,
    refetch: refetchSources,
  } = useGetAllSourcesByPedestalId(filters.pedestalId ?? -1)
  //#endregion

  //#region Hooks
  useEffect(() => {
    refetchSources()
  }, [filters.pedestalId])
  //#endregion

  if (isLoadingPedestals || isLoadingPedestalTypes) {
    return <PageLoader />
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-4'
        links={[
          { name: 'Reports', route: adminRoutes.REPORTS },
          { name: 'Sessions' },
        ]}
      />
      <ActionBar title='Sessions report' tw='mb-5' />
      <ReportComponent
        reportType={ReportType.SESSIONS}
        filters={filters}
        setFilters={setFilters}
        filtersOptions={[
          {
            label: 'Pedestal',
            filterKey: 'pedestalId',
            options: pedestals
              ? pedestals.map((pedestal) => ({
                  label: pedestal.identifier,
                  value: pedestal.id,
                }))
              : [],
            linkedFilters: ['sourceId'],
            disablesFilters: ['pedestalTypeId'],
          },
          {
            label: 'Pedestal type',
            filterKey: 'pedestalTypeId',
            options: pedestalTypes
              ? pedestalTypes.map((pedestalType) => ({
                  label: pedestalType.name,
                  value: pedestalType.id,
                }))
              : [],
          },
          {
            label: 'Source',
            filterKey: 'sourceId',
            options: sources
              ? sources.map((source) => ({
                  label: `${source.type} ${source.position}`,
                  value: source.id,
                }))
              : [],
          },
        ]}
        isError={pedestalsError || pedestalTypesError || sourcesError}
      />
    </ContentContainer>
  )
}
