/** @jsxImportSource @emotion/react */

import tw from 'twin.macro'

interface IChipProps {
  value: string | number
  gray?: boolean | undefined
  purple?: boolean | undefined
  blue?: boolean | undefined
  yellow?: boolean | undefined
  green?: boolean | undefined
  red?: boolean | undefined
  white?: boolean | undefined
  orange?: boolean | undefined
  className?: string
  onClick?: any
}

export const Chip = (props: IChipProps) => {
  return (
    <span
      onClick={props.onClick}
      css={[
        tw`py-1 px-3 rounded-xl font-sans font-medium text-xs`,
        props.gray && tw`bg-gray-200 text-gray-800`,
        props.purple && tw`bg-purple-100 text-purple-800`,
        props.blue && tw`bg-blue-100 text-blue-800`,
        props.yellow && tw`bg-yellow-100 text-yellow-800`,
        props.green && tw`bg-green-100 text-green-800`,
        props.red && tw`bg-red-100 text-red-800`,
        props.white && tw`bg-white border`,
        props.orange && tw`bg-[#ff9c0899]`,
      ]}
      className={props.className}
    >
      {props.value}
    </span>
  )
}
