/** @jsxImportSource @emotion/react */
import 'twin.macro'
import tw from 'twin.macro'
import Drawer from 'rc-drawer'
import { useCallback, useMemo, useState } from 'react'
import { XIcon } from '@heroicons/react/outline'
import { useHistory } from 'react-router-dom'
import { LightningBoltIcon, DotsVerticalIcon } from '@heroicons/react/outline'
import { adminRoutes } from '../../common/general/route-names'
import { Txt } from '../../common/general/Txt'
import {
  Pedestal,
  PhaseTypeEnum,
  powerLabels,
  Session,
  Socket,
  SocketState,
  Source,
  SourceTypeEnum,
  ValveState,
} from '../../../api-types'
import { sourceLabelMeta } from '../../common/general/utils'
import { Button } from '../../common/general/Button'
import { AdminStartSessionDrawer } from './AdminStartSessionDrawer'
import { useSession } from '../../../queries'
import { PageLoader } from '../../common/general/PageLoader'
import { roundToFloatWithExactlyNDecimals } from '../../../utils'
import 'rc-drawer/assets/index.css'

const ANIMATION_DURATION = 0.3

const DetailsProperty = (props: {
  label: string
  value: string
  error?: string
}) => {
  const { label, value, error } = props

  return (
    <div tw='w-full flex flex-col gap-2 py-3'>
      <Txt sm tw='font-medium text-gray-500'>
        {label}
      </Txt>
      {error && (
        <Txt sm tw='font-semibold text-red-600'>
          {error}
        </Txt>
      )}
      <Txt sm>{value}</Txt>
    </div>
  )
}

interface AdminSourceDrawerProps {
  pedestal: Pedestal
  source: Source
  open: boolean
  setOpen(val: boolean): void
  close(): void
  onStopSession(source: Session): void
}

export const AdminSourceDrawer = (props: AdminSourceDrawerProps) => {
  const { source, onStopSession, pedestal, open, setOpen } = props
  const { type, position, activeSession, state } = source
  const { phase, current } = source as Socket
  const { constants } = pedestal.location

  const {
    data: session,
    isError,
    isLoading,
  } = useSession(`${activeSession?.id}`, !!activeSession)
  const [openSessionDrawer, setOpenSessionDrawer] = useState<boolean>(false)
  const history = useHistory()

  const closeDrawer = useCallback(() => {
    setOpen(false)
    setTimeout(() => {
      props.close()
    }, ANIMATION_DURATION)
  }, [])

  const isManualMode = useMemo(
    () =>
      (source.type === SourceTypeEnum.VALVE &&
        source.pedestal.valvesManualModeOn) ||
      (source.type === SourceTypeEnum.SOCKET &&
        source.pedestal.socketsManualModeOn),
    [source],
  )

  return (
    <Drawer
      width={`40%`}
      open={open}
      handler={false}
      maskClosable
      duration={ANIMATION_DURATION.toString()}
      placement='right'
      onClose={closeDrawer}
      className={'drawer1'}
    >
      {isError ? (
        <div tw='flex flex-col h-full'>Error...</div>
      ) : isLoading ? (
        <div tw='flex flex-col h-full'>
          <PageLoader />
        </div>
      ) : (
        open && (
          <div tw='flex flex-col h-full'>
            <AdminStartSessionDrawer
              source={source}
              pedestal={pedestal}
              open={openSessionDrawer}
              setOpen={setOpenSessionDrawer}
              setOpenParentDrawer={setOpen}
              level={'.drawer1'}
              levelMove={100}
            />
            <div tw='flex flex-col w-full p-6'>
              <div tw='flex flex-row justify-between'>
                <Txt l tw='font-medium'>
                  Details
                </Txt>
                <XIcon
                  onClick={() => props.close()}
                  tw='h-5 w-5 cursor-pointer'
                  color='#9CA3AF'
                />
              </div>
              <div tw='h-full flex flex-col'>
                <div tw='flex flex-row pb-6 gap-5 pt-6'>
                  <div
                    css={[
                      tw`h-12 w-12 flex justify-center items-center rounded-md bg-primary-500`,
                      phase === PhaseTypeEnum.MONO_PHASE && tw`bg-blue-500`,
                      // // @ts-ignore
                      phase === PhaseTypeEnum.THREE_PHASE && tw`bg-red-600`,
                    ]}
                  >
                    <LightningBoltIcon width={24} tw='text-white' />
                  </div>
                  <div tw='flex flex-col gap-0.5'>
                    <div tw='flex flex-row gap-2'>
                      <Txt tw='font-semibold text-gray-900' xl>
                        {sourceLabelMeta[type as SourceTypeEnum]} {position}
                      </Txt>
                      {/* <TableCell.Chip
                      value={availabeMeta.value}
                      {...availabeMeta.colorProps}
                    /> */}
                    </div>
                    <div tw='flex flex-row'>
                      <Txt tw='text-gray-500' sm>
                        {/*{pedestal.identifier}*/}
                      </Txt>
                    </div>
                  </div>
                </div>
                <div tw='flex flex-row w-full gap-2 py-2'>
                  {/* TODO: Handle out of service state */}
                  {/* TODO: refactor after refactoring active sessions state for source */}
                  {activeSession ? (
                    <>
                      <Button
                        tw='flex-grow'
                        text='End session'
                        onClick={() => onStopSession(activeSession)}
                        disabled={isManualMode}
                      />
                      <Button
                        tw='flex-grow'
                        cancel
                        text='Session details'
                        onClick={() =>
                          history.push(
                            adminRoutes.SESSION_DETAILS.replace(
                              ':identifier',
                              pedestal.identifier,
                            ).replace(
                              ':sessionId',
                              Number(activeSession.id).toString(),
                            ),
                          )
                        }
                      />
                    </>
                  ) : source.type === SourceTypeEnum.SOCKET ? (
                    <>
                      {!(state as SocketState).socketStable &&
                        !(state as SocketState).activated && (
                          <Button
                            tw='flex-grow'
                            text='Activate session'
                            disabled
                          />
                          // handle activate
                        )}
                      {(state as SocketState).socketStable &&
                        !(state as SocketState).activated && (
                          <Button
                            tw='flex-grow'
                            text='Activate session'
                            onClick={() => {
                              setOpenSessionDrawer(true)
                            }}
                          />
                        )}
                    </>
                  ) : (
                    !(state as ValveState).activated && (
                      <Button
                        tw='flex-grow'
                        text='Activate session'
                        onClick={() => {
                          setOpenSessionDrawer(true)
                        }}
                      />
                    )
                  )}
                  <div tw='border-0 rounded-md cursor-pointer flex flex-row items-center px-3 py-2.5 bg-white hover:bg-gray-50 border-gray-200 border-width[1.5px]'>
                    <DotsVerticalIcon width={20} />
                  </div>
                </div>
                {session && activeSession && (
                  <>
                    <DetailsProperty
                      label='Customer'
                      value={session.group.account.owner.name}
                    />
                    <DetailsProperty
                      label='Vehicle registration'
                      value={session.vehicle.registration}
                    />
                  </>
                )}
                {phase && (
                  <DetailsProperty label='Phases' value={powerLabels[phase]} />
                )}
                {current && (
                  <DetailsProperty label='Current' value={`${current}A`} />
                )}
                {source.type === SourceTypeEnum.SOCKET ? (
                  <DetailsProperty
                    label='Default tariff'
                    value={`${constants.powerTariff} EUR`}
                  />
                ) : (
                  <DetailsProperty
                    label='Default tariff'
                    value={`${constants.waterTariff} EUR`}
                  />
                )}
                {source.type === SourceTypeEnum.SOCKET ? (
                  session && activeSession ? (
                    <DetailsProperty
                      label='Session Consumption'
                      value={`${roundToFloatWithExactlyNDecimals(
                        state.value - session.initialMeterState,
                        3,
                      )} kWh`}
                    />
                  ) : (
                    <DetailsProperty
                      label='Consumption'
                      value={`${roundToFloatWithExactlyNDecimals(
                        state.value,
                        3,
                      )} kWh`}
                    />
                  )
                ) : session && activeSession ? (
                  <DetailsProperty
                    label='Session Consumption'
                    value={`${roundToFloatWithExactlyNDecimals(
                      state.value - session.initialMeterState,
                      3,
                    )} m\u00B3`}
                  />
                ) : (
                  <DetailsProperty
                    label='Consumption'
                    value={`${roundToFloatWithExactlyNDecimals(
                      state.value,
                      3,
                    )} m\u00B3`}
                  />
                )}
              </div>
            </div>
          </div>
        )
      )}
    </Drawer>
  )
}
