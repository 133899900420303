import 'twin.macro'

/** @jsxImportSource @emotion/react */
import React from 'react'

export const Card: React.FC<{ className?: string }> = (props) => {
  return (
    <div
      className={props.className}
      tw='p-6 bg-white rounded-md'
      style={{
        boxShadow:
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
      }}
    >
      {props.children}
    </div>
  )
}
