/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useEffect } from 'react'
import { SessionRequest, SessionRequestStatusEnum } from '../../../api-types'
import { useGetSessionRequests } from '../../../queries'
import { showErrorNotification } from '../general/notification'
import { Card } from '../general/Card'
import { Txt } from '../general/Txt'
import ReactModal from 'react-modal'
import Loader from 'react-spinners/ClipLoader'

interface IRequestSessionStopStatusModalProps {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  onConfirm: () => void
  sessionRequests: SessionRequest[] | undefined
  setSessionRequests: (val: SessionRequest[] | undefined) => void
  refetchSession: () => void
}

const modalStyle: ReactModal.Styles = {
  content: {
    background: 'none',
    border: 'none',
    padding: '0',
    display: 'flex',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: '#6B7280BF',
  },
}

export const RequestSessionStopStatusModalForSessionDetails = (
  props: IRequestSessionStopStatusModalProps,
) => {
  const {
    isOpen,
    setIsOpen,
    sessionRequests,
    setSessionRequests,
    refetchSession,
  } = props

  const { data: freshSessionRequests } = useGetSessionRequests(
    sessionRequests?.map((req) => req.id),
    !!sessionRequests,
  )

  useEffect(() => {
    //Check if all session requests are resolved,then refetch session or show error message
    if (freshSessionRequests) {
      if (
        freshSessionRequests.every(
          (sessionRequest) =>
            sessionRequest.status !== SessionRequestStatusEnum.PENDING,
        )
      ) {
        setSessionRequests(undefined)
        if (
          freshSessionRequests.every(
            (sessionRequest) =>
              sessionRequest.status === SessionRequestStatusEnum.APPROVED,
          )
        ) {
          refetchSession()
        } else {
          showErrorNotification('Request denied, session not closed')
        }
        setIsOpen(false)
      }
    }
  }, [freshSessionRequests])

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={modalStyle}
      ariaHideApp={false}
    >
      <Card tw='flex flex-col items-center w-[32rem]'>
        <Txt l tw='text-center mb-2'>
          Waiting for response...
        </Txt>
        <Loader />
      </Card>
    </ReactModal>
  )
}
