import { useController, useFormContext } from 'react-hook-form'

import { ErrorText } from '../ErrorText'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import tw from 'twin.macro'
import { useEffect } from 'react'

/** @jsxImportSource @emotion/react */

interface ICheckboxField {
  name: string
  label?: string
  placeholder?: string
  className?: string
  selectOnFocus?: boolean | undefined
}

const CheckboxField = (props: ICheckboxField) => {
  const { name, label, className, selectOnFocus, placeholder } = props
  const { control } = useFormContext()
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })
  useEffect(() => {
    if (error?.ref?.focus) {
      error.ref.focus()
    }
  }, [error])

  return (
    <div className={className} tw='flex'>
      <div tw='mr-3'>
        <input
          name={field.name}
          value={field.value}
          checked={field.value}
          type='checkbox'
          placeholder={placeholder}
          onChange={field.onChange}
          css={[
            tw`block w-full my-1 px-2.5 py-[0.675rem] border rounded-md focus:outline-none focus:ring-primary-700 focus:bg-white focus:z-10`,
            tw`font-sans font-normal text-sm text-gray-900 w-auto`,
            error &&
              tw`font-sans font-normal text-sm border-error text-red-900 focus:text-gray-900`,
          ]}
          onBlur={field.onBlur}
          ref={field.ref}
          onFocus={(event) => {
            selectOnFocus && event.target.select()
          }}
        />
        {error && (
          <ExclamationCircleIcon
            tw='h-5 w-5 absolute right-3 top-3'
            color='#EF4444'
          />
        )}
      </div>
      <label tw='font-sans font-medium text-sm text-gray-700'>{label}</label>

      <div tw='h-6'>
        {error && error.message && (
          <ErrorText tw='mt-2'>{error.message}</ErrorText>
        )}
      </div>
    </div>
  )
}

export { CheckboxField }
