import QRCode from 'qrcode'
import { Pedestal } from '../../../api-types'
import JSZip from 'jszip'

const mouseEvents = [
  'click',
  true,
  true,
  window,
  0,
  0,
  0,
  0,
  0,
  false,
  false,
  false,
  false,
  0,
  null,
]

class PedestalStickerService {
  private canvas: HTMLCanvasElement
  private context: CanvasRenderingContext2D | null

  constructor(width: number, height: number) {
    // TODO: check type
    // @ts-ignore
    this.canvas = document.createElement('CANVAS')
    this.canvas.width = width
    this.canvas.height = height
    this.context = this.canvas.getContext('2d')
  }

  private triggerDownload = (identifier: string, dataURL: any) => {
    const lnk = document.createElement('a')

    lnk.download = identifier
    let e
    // @ts-ignore
    lnk.href = dataURL
    if (document.createEvent) {
      e = document.createEvent('MouseEvents')
      //@ts-ignore
      e.initMouseEvent(...mouseEvents)

      lnk.dispatchEvent(e)
    } else {
      // @ts-ignore
      if (lnk.fireEvent) {
        // @ts-ignore
        lnk.fireEvent('onclick')
      }
    }
  }

  private drawLogoImage = (
    src: string,
    posX: number,
    posY: number,
    height?: number,
    width?: number,
  ) => {
    const image = new Image()
    image.src = src

    return new Promise((resolve) => {
      image.onload = () => {
        if (!this.context) throw Error
        //@ts-ignore
        this.context.drawImage(image, posX, posY, width, height)
        resolve(image)
      }
    })
  }

  private drawSVGImage = (
    src: string,
    posX: number,
    posY: number,
    height?: number,
    width?: number,
  ) => {
    const image = new Image()

    const template = document.createElement('template')
    template.innerHTML = src
    const svgElement = template.content.firstElementChild

    //@ts-ignore
    const blob = new Blob([svgElement?.outerHTML], {
      type: 'image/svg+xml;charset=utf-8',
    })
    const blobUrl = URL.createObjectURL(blob)
    image.src = blobUrl

    return new Promise((resolve) => {
      image.onload = () => {
        if (!this.context) throw Error
        //@ts-ignore
        this.context.drawImage(image, posX, posY, width, height)
        resolve(image)
      }
    })
  }

  private drawIdentifierText = (
    identifier: string,
    width: number,
    height: number,
  ) => {
    if (!this.context) throw Error
    this.context.font = '76px Arial'
    this.context.fillStyle = 'black'
    this.context.textAlign = 'center'
    const xCoeff = 1
    const yCoeff = 0.95
    this.context.fillText(
      identifier.toUpperCase(),
      0.5 * xCoeff * width,
      0.9380530974 * yCoeff * height,
    )
  }

  private generateSticekr = async (identifier: string) => {
    const width = 680 // 170 is original, revert if printer cannot support
    const height = 1.3294117647 * width
    const qrSize = 0.8117647059 * width
    const imgSize = 0.08849557522 * height

    // const [canvas, context] = createCanvas(width, height)

    if (!this.context) throw Error

    this.context.fillStyle = 'white'
    this.context.fillRect(0, 0, width, height)

    const qrCodeSvgString = await QRCode.toString(
      'https://marex-hc.com/home/pedestal/' + identifier,
      { version: 4, width: qrSize },
    )

    await this.drawLogoImage(
      '/canvas-logo.png',
      0.388 * width,
      0.039823 * height,
      imgSize,
      1.85 * imgSize,
    )
    await this.drawSVGImage(
      qrCodeSvgString,
      0.0953 * width,
      0.1681 * height,
      qrSize,
      qrSize,
    )

    await this.drawIdentifierText(identifier, width, height)

    return this.canvas.toDataURL('image/png;base64')
  }

  public download = async (pedestal: Pedestal) => {
    const dataURL = await this.generateSticekr(pedestal.identifier)

    this.triggerDownload(pedestal.identifier.toUpperCase() + '.png', dataURL)
  }

  public downloadMultiple = async (pedestals: Pedestal[]) => {
    const zip = new JSZip()

    for (let i = 0; i < pedestals.length; i += 1) {
      const data = await this.generateSticekr(pedestals[i].identifier)
      const imgData = data.split(',')[1]
      zip.file(pedestals[i].identifier.toUpperCase() + '.png', imgData, {
        base64: true,
      })
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      this.triggerDownload('pedestals.zip', window.URL.createObjectURL(content))
    })
  }
}

export const pedestalStickerService = new PedestalStickerService(
  680,
  1.3294117647 * 680,
)
