/** @jsxImportSource @emotion/react */
import 'twin.macro'

import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { InputField } from '../../common/general/fields/InputField'
import { Txt } from '../../common/general/Txt'
import { CountrySelectField } from '../../common/general/fields/CountrySelectField'
import { SelectField } from '../../common/general/fields/SelectField'
import { LocationStatus, LocationTypeEnum } from '../../../api-types'
import { Button } from '../../common/general/Button'
import { queries, useCurrentLocation, useFacilities } from '../../../queries'
import { CheckboxArrayField } from '../../common/general/fields/CheckboxArrayField'
import { http } from '../../../http'
import { PhoneNumberInput } from '../../common/general/fields/PhoneNumberInputField'
import { DropzoneField } from '../../common/general/fields/DropzoneField'
import { showSuccessNotification } from '../../common/general/notification'
import { Loader } from '../../common/general/Loader'
import { useQueryClient } from 'react-query'

export interface SettingsForm {
  email: string
  phoneNumber: string
  name: string
  country: string
  paymentType: string
  powerTariff: number
  waterTariff: number
}

const schema = yup.object().shape({
  status: yup.string().nullable(),
  type: yup.string().nullable(),
  name: yup.string().nullable(),
  address: yup.string().nullable(),
  city: yup.string().nullable(),
  postcode: yup.string().nullable(),
  latitude: yup.number().nullable(),
  longitude: yup.number().nullable(),
  country: yup.string().nullable(),
  email: yup.string().nullable(),
  phone: yup.string().nullable(),
  websiteUrl: yup.string().nullable(),
  imageUrl: yup.string().nullable(),
  logoUrl: yup.string().nullable(),
  thumbnailUrl: yup.string().nullable(),
  mapUrl: yup.string().nullable(),
  constants: yup.object({
    portDepthRange: yup.string().nullable(),
    berthCount: yup.number().nullable(),
    maxVehicleLength: yup.number().nullable(),
    maxVehicleBeamLength: yup.number().nullable(),
    maxSocketSize: yup.string().nullable(),
    waterSocketSize: yup.string().nullable(),
  }),
})

export const InfoSettings = () => {
  const methods = useForm({
    defaultValues: {},
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const { data: facilities } = useFacilities()
  const { data: locationInfo } = useCurrentLocation()
  const queryClient = useQueryClient()

  useEffect(() => {
    methods.reset({
      status: locationInfo?.status,
      type: locationInfo?.type,
      name: locationInfo?.name,
      address: locationInfo?.address,
      city: locationInfo?.city,
      postcode: locationInfo?.postcode,
      country: locationInfo?.country,
      email: locationInfo?.email,
      latitude: locationInfo?.latitude,
      longitude: locationInfo?.longitude,
      phone: locationInfo?.phone,
      websiteUrl: locationInfo?.websiteUrl,
      imageUrl: locationInfo?.imageUrl,
      logoUrl: locationInfo?.logoUrl,
      mapUrl: locationInfo?.mapUrl,
      thumbnailUrl: locationInfo?.thumbnailUrl,
      constants: {
        portDepthRange: locationInfo?.constants.portDepthRange,
        berthCount: locationInfo?.constants.berthCount,
        maxVehicleLength: locationInfo?.constants.maxVehicleLength,
        maxVehicleBeamLength: locationInfo?.constants.maxVehicleBeamLength,
        maxSocketSize: locationInfo?.constants.maxSocketSize,
        waterSocketSize: locationInfo?.constants.waterSocketSize,
      },
      facilities: locationInfo?.facilities.map((f) => f.id),
    })
  }, [locationInfo])

  if (!locationInfo) {
    return <Loader />
  }

  const onSubmit = methods.handleSubmit(async (data) => {
    await http.updateAdminLocation(data)
    // TODO: dashboard is using the map from the working location of the logged in user
    // Dashboard should use a location query in the future.
    await queryClient.refetchQueries(queries.loggedInUser)
    await showSuccessNotification('Saved successfully!')
  })

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} noValidate tw='space-y-6 flex-grow'>
          <div
            id='location-settings'
            tw='bg-white rounded-lg p-6 divide-y mb-5'
            style={{
              boxShadow:
                '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
              marginTop: 24,
            }}
          >
            <div>
              <Txt xl tw='mb-2'>
                Required info
              </Txt>
              <Txt tw='mb-2 text-gray-400'>
                All of the following fields are required.
              </Txt>
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Status</Txt>
              <SelectField
                options={Object.keys(LocationStatus).map((key) => ({
                  label: key,
                  value: key,
                }))}
                name='status'
              />
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Type</Txt>
              <SelectField
                options={Object.keys(LocationTypeEnum).map((key) => ({
                  label: key,
                  value: key,
                }))}
                name='type'
              />
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Name</Txt>
              <InputField name='name' />
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Street address</Txt>
              <InputField name='address' />
            </div>
            <div tw='flex'>
              <div tw='pt-3 pr-2 w-1/3'>
                <Txt tw='font-medium'>City</Txt>
                <InputField name='city' />
              </div>
              <div tw='pt-3 pr-2 w-1/3'>
                <Txt tw='font-medium'>Postal code</Txt>
                <InputField name='postcode' />
              </div>
              <div tw='pt-3 pr-2 w-1/3'>
                <Txt tw='font-medium'>Country</Txt>
                <CountrySelectField name='country' />
              </div>
            </div>
            <div tw='flex  flex-col md:flex-row md:space-x-6'>
              <InputField tw='flex-grow' name='latitude' label='Latitude' />
              <InputField tw='flex-grow' name='longitude' label='Longitude' />
            </div>
          </div>
          <div
            id='lighting-and-heating'
            tw='bg-white rounded-lg p-6 divide-y mb-5'
            style={{
              boxShadow:
                '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
              marginTop: 24,
            }}
          >
            <div>
              <Txt xl tw='mb-2'>
                Location Info
              </Txt>
              <Txt tw='mb-2 text-gray-400'>
                This information will be displayed publicly.
              </Txt>
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Number of berths</Txt>
              <InputField name='constants.berthCount' />
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Max length</Txt>
              <InputField
                type='number'
                name='constants.maxVehicleLength'
                unit='m'
              />
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Depth</Txt>
              <InputField
                placeholder='1-20'
                type='string'
                name='constants.portDepthRange'
                unit='m'
              />
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Max beam</Txt>
              <InputField
                type='number'
                name='constants.maxVehicleBeamLength'
                unit='m'
              />
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Max socket size</Txt>
              <InputField
                type='string'
                name='constants.maxSocketSize'
                unit='A'
              />
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Water socket size</Txt>
              <InputField
                type='string'
                name='constants.waterSocketSize'
                unit="''"
              />
            </div>
            <div tw='pt-3'>
              <Txt>Facilities</Txt>
              <div tw='flex flex-wrap	'>
                {(facilities || []).map((f) => (
                  <div tw='w-1/3'>
                    <CheckboxArrayField
                      id={f.id}
                      label={f.name}
                      iconUrl={f.iconUrl}
                      name='facilities'
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            id='visuals'
            tw='bg-white rounded-lg p-6 divide-y mb-5'
            style={{
              boxShadow:
                '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
              marginTop: 24,
            }}
          >
            <div>
              <Txt xl tw='mb-2'>
                Visuals
              </Txt>
              <Txt tw='mb-2 text-gray-400'>
                Add your logo and cover photo to display your brand.
              </Txt>
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Logo</Txt>
              <DropzoneField name='logoUrl' />
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Cover photo</Txt>
              <DropzoneField name='imageUrl' />
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Thumbnail photo</Txt>
              <DropzoneField name='thumbnailUrl' />
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Location map</Txt>
              <DropzoneField name='mapUrl' />
            </div>
          </div>

          <div
            id='contact-info'
            tw='bg-white rounded-lg p-6 divide-y mb-5'
            style={{
              boxShadow:
                '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
              marginTop: 24,
            }}
          >
            <div>
              <Txt xl tw='mb-2'>
                Contact info
              </Txt>
              <Txt tw='mb-2 text-gray-400'>
                Add contact info so your customers can easily contact you.
              </Txt>
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>URL</Txt>
              <div tw='flex'>
                <Txt tw='bg-gray-200 px-2 pt-3 pb-2 mt-1 h-11 whitespace-nowrap'>
                  marex-hc.com/
                </Txt>
                <InputField tw='w-full' name='websiteUrl' />
              </div>
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Phone Number</Txt>
              <PhoneNumberInput name='phone' />
            </div>
            <div tw='pt-3'>
              <Txt tw='font-medium'>Email</Txt>
              <InputField type='email' name='email' />
            </div>
          </div>
          <Button primary type='submit' text='Save' />
        </form>
      </FormProvider>
    </>
  )
}
