import { useController, useFormContext } from 'react-hook-form'

import { ErrorText } from '../ErrorText'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import tw from 'twin.macro'
import { css } from '@emotion/react'
import { useEffect } from 'react'

/** @jsxImportSource @emotion/react */

interface IInputField {
  name: string
  type?: string
  label?: string
  placeholder?: string
  unit?: string
  className?: string
  selectOnFoucs?: boolean | undefined
  disabled?: boolean | undefined
}

const unitStyle = css`
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  color: #6b7280;
`

const InputField = (props: IInputField) => {
  const {
    name,
    label,
    type,
    className,
    selectOnFoucs,
    placeholder,
    unit,
    disabled = false,
  } = props
  const { control } = useFormContext()
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })
  useEffect(() => {
    if (error?.ref?.focus) {
      error.ref.focus()
    }
  }, [error])
  // @ts-ignore
  const onChange = ({ target: { value } }) => {
    field.onChange(value)
  }

  return (
    <div className={className}>
      <label tw={'font-sans font-medium text-sm text-gray-700'}>{label}</label>
      <div tw='relative'>
        <input
          name={field.name}
          value={field.value}
          defaultValue={field.value ?? ''}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          css={[
            tw`block w-full my-1 px-2.5 py-[0.675rem] border rounded-md focus:outline-none focus:ring-primary-700 focus:bg-white focus:z-10`,
            tw`font-sans font-normal text-sm text-gray-900`,
            error &&
              tw`font-sans font-normal text-sm border-error text-red-900 focus:text-gray-900`,
            disabled && tw`bg-[#F3F4F6]`,
          ]}
          onBlur={field.onBlur}
          ref={field.ref}
          onFocus={(event) => {
            selectOnFoucs && event.target.select()
          }}
          disabled={disabled}
        />
        {unit && <span css={unitStyle}>{unit}</span>}
        {error && (
          <ExclamationCircleIcon
            tw='h-5 w-5 absolute right-3 top-3'
            color='#EF4444'
          />
        )}
      </div>
      <div tw='h-6'>
        {error && error.message && (
          <ErrorText tw='mt-2'>{error.message}</ErrorText>
        )}
      </div>
    </div>
  )
}

export { InputField }
