/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import React, { useCallback, useState, useEffect } from 'react'
import Select from 'react-select'
import { useController, useFormContext } from 'react-hook-form'
import { ErrorText } from '../ErrorText'
import { IDropdownOption } from '../../../../types'
import { Txt } from '../Txt'
import { debounce } from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ISimpleAutocompleteField<T> {
  name: string
  placeholder?: string
  className?: string
  styles?: any
  label?: string
  onChange(term: string): Promise<IDropdownOption[]>
}

export const SimpleAutocompleteField = <T extends Object>(
  props: ISimpleAutocompleteField<T>,
) => {
  const { name, placeholder, className, label } = props
  const { control } = useFormContext()
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<IDropdownOption[]>([])

  const onInputChange = useCallback(
    debounce(async (t) => {
      const options = await props.onChange(t)
      setOptions(options)
    }, 350),
    [],
  )

  useEffect(() => {
    onInputChange(inputValue)
  }, [])

  const selectedOption =
    options.find((o) => o.value === field.value?.value) ?? field.value

  return (
    <div className={className} tw='w-full'>
      <div tw='mb-1'>
        <label tw='font-sans font-medium text-sm text-gray-700'>{label}</label>
      </div>
      <Select
        value={selectedOption}
        inputValue={inputValue}
        onChange={(option) => field.onChange(option)}
        placeholder={selectedOption ? '' : placeholder}
        options={options}
        onInputChange={(value, action) => {
          // only set the input when the action that caused the
          // change equals to "input-change" and ignore the other
          // ones like: "input-blur", and "menu-close"
          if (action.action === 'input-change') {
            setInputValue(value)
          } else if (action.action === 'set-value') {
            setInputValue(value)
          }
          onInputChange(value)
        }}
        formatOptionLabel={(o, { context }) => (
          <div tw='flex items-center'>
            <Txt sm tw='text-gray-700 flex-grow'>
              {o.label}
            </Txt>
            {context === 'menu' && o.value === field.value?.value && (
              <CheckIcon tw='w-4 h-4 text-primary-600' />
            )}
          </div>
        )}
        styles={{
          placeholder: (base) => ({
            ...base,
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: 14,
            color: '#6B7280',
          }),
          control: (base) => ({
            ...base,
            border: error
              ? '1px #EF4444 solid !important'
              : '1px rgb(229, 231, 235) solid',
            borderRadius: '0.375rem',
            padding: '0.35rem 0.6rem !important',
            cursor: 'pointer',
            boxShadow: 'none',

            ...((props.styles ?? {}).control ?? {}),
          }),

          input: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          valueContainer: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            border: 'none',
          }),
          menuPortal: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            border: 'none',
            borderRadius: '0.375rem !important',
          }),
          menuList: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            border: 'none',
            // borderRadius: '0.375rem !important',
          }),
          menu: (base) => ({
            ...base,
            padding: 0,
            margin: '0.5rem 0rem 0rem 0rem',
            boxShadow: 'none',
            border: '1.5px rgb(229, 231, 235) solid !important',
            // borderRadius: '0.375rem !important',
          }),

          option: (base) => ({
            ...base,
            backgroundColor: 'white',
            cursor: 'pointer',
            ':hover': {
              backgroundColor: '#E6F1F7',
            },
          }),
        }}
        components={{
          IndicatorSeparator: () => <div />,
          DropdownIndicator: () => (
            <SelectorIcon tw='h-4 w-4' color='#9CA3AF' />
          ),
        }}
      />
      {error && error.message && (
        <ErrorText tw='mt-2'>{error.message}</ErrorText>
      )}
    </div>
  )
}
