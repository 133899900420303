import dayjs from 'dayjs'
import { ConfigOptions, download, generateCsv } from 'export-to-csv'
import cleanDeep from 'clean-deep'

class CsvExporter {
  constructor() {}

  public generateAndDownload(
    data: any[],
    usedFilters: any,
    reportName?: string,
  ) {
    const parsedFilters = this.parseUsedFilters(usedFilters)

    const config: ConfigOptions = {
      title: parsedFilters,
      filename: reportName,
      replaceUndefinedWith: '-',
      fieldSeparator: ';',
      decimalSeparator: ',',
      showTitle: true,
      useTextFile: false,
      useBom: true,
      columnHeaders: [
        'id',
        'owner_email',
        'owner_name',
        'status',
        'transaction_id',
        'payment_type',
        'paid_at',
        'period_start_date',
        'period_end_date',
        'vehicle_registration',
        'total',
      ],
    }

    const csv = generateCsv(config)(data)
    return download(config)(csv)
  }

  private parseUsedFilters = (usedFilters: object): string => {
    const filters = cleanDeep(usedFilters)
    const parsedArray = [] as string[]
    for (const key of Object.keys(filters)) {
      // @ts-ignore
      const value = filters[key]
      if (!Number(value) && Date.parse(value)) {
        parsedArray.push(`${key}: ${dayjs(value).format('DD/MM/YYYY')}`)
        parsedArray.push('\n')
      } else {
        parsedArray.push(`${key}: ${value}`)
        parsedArray.push('\n')
      }
    }
    parsedArray.push('\n')
    return parsedArray.slice(0, -1).toString().replaceAll(',', '')
  }
}

export const csvExporter = new CsvExporter()
