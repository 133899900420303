/** @jsxImportSource @emotion/react */
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import 'twin.macro'
import { powerLabels, SourceTypeEnum } from '../../../../api-types'
import { http } from '../../../../http'
import { useSourcesReport } from '../../../../queries'
import { csvExporter } from '../../../../services/csv-exporter'
import { ISearchParams } from '../../../../types'
import { ActionBar } from '../../general/ActionBar'
import { Breadcrumbs } from '../../general/Breadcrumbs'
import { ContentContainer } from '../../general/ContentContainer'
import { DateInput } from '../../general/fields/DateInput'
import { RangeInput } from '../../general/fields/RangeInput'
import { SelectField } from '../../general/fields/SelectField'
import { FilterBar } from '../../general/FilterBar'
import { useSearch } from '../../general/hooks/useSearch'
import { showErrorNotification } from '../../general/notification'
import { PageLoader } from '../../general/PageLoader'
import { adminRoutes } from '../../general/route-names'
import { TableCell } from '../../general/table/cells'
import {
  CustomTable,
  ICustomColumnProps,
} from '../../general/table/CustomTable'
import { Granularity } from '../report-types/report-enums'
import { SourceReportSearchParams } from '../report-types/report-search-params-types'
import { SocketReport } from '../report-types/report-types'

const initialSearchParams: Partial<ISearchParams> = {
  orderField: 'numberOfSessions',
  order: 'DESC',
  term: '',
}

const initialFilters = {
  granularity: Granularity.DAY,
  from: dayjs().startOf('year').toDate(),
  to: dayjs().endOf('year').toDate(),
  source: SourceTypeEnum.SOCKET,
  minLength: 0,
  maxLength: 200,
}

export const SourcesReportPage = () => {
  const [filters, setFilters] =
    useState<SourceReportSearchParams>(initialFilters)

  const [searchParams, { setPage, setSearchParams }] =
    useSearch(initialSearchParams)

  const { data, isLoading, isError } = useSourcesReport({
    ...searchParams,
    ...filters,
  })

  if (isError) {
    return <div>Something went wrong</div>
  }
  const onDownloadCsv = async () => {
    const data = await http.searchSourcesReport({
      ...searchParams,
      ...filters,
    })

    if (data) {
      csvExporter.generateAndDownload(data.items, filters, 'sources')
    } else {
      showErrorNotification('No data to export')
    }
  }

  const socketColumns: ICustomColumnProps<SocketReport>[] = useMemo(
    () =>
      filters.source === SourceTypeEnum.SOCKET
        ? [
            {
              Header: 'Phase',
              accessor: 'phase',
              cell: (l) => (
                <div>
                  <TableCell.Text value={powerLabels[l.phase]} />
                </div>
              ),
            },
            {
              Header: 'Current (A)',
              accessor: 'current',
              cell: (l) => (
                <div>
                  <TableCell.Text value={l.current} />
                </div>
              ),
            },
            {
              Header: '# of sessions',
              accessor: 'numberOfSessions',
              cell: (l) => (
                <div>
                  <TableCell.Text value={l.numberOfSessions} />
                </div>
              ),
            },
            {
              Header: 'Total consumption (kWh)',
              accessor: 'totalConsumption',
              cell: (l) => (
                <div>
                  <TableCell.Text value={l.totalConsumption} />
                </div>
              ),
            },
          ]
        : [
            {
              Header: 'Connection size',
              accessor: 'connectionSize',
              cell: (l) => (
                <div>
                  <TableCell.Text value={l.connectionSize} />
                </div>
              ),
            },
            {
              Header: '# of sessions',
              accessor: 'numberOfSessions',
              cell: (l) => (
                <div>
                  <TableCell.Text value={l.numberOfSessions} />
                </div>
              ),
            },
            {
              Header: 'Total consumption (m\u00B3)',
              accessor: 'totalConsumption',
              cell: (l) => (
                <div>
                  <TableCell.Text value={l.totalConsumption} />
                </div>
              ),
            },
          ],
    [filters.source],
  )

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-4'
        links={[
          { name: 'Reports', route: adminRoutes.REPORTS },
          { name: 'Sources' },
        ]}
      />
      <ActionBar title='Sources report' tw='mb-5'>
        <button
          tw='bg-primary-500 px-4 py-2 rounded-md text-white font-medium font-sans text-l'
          onClick={onDownloadCsv}
        >
          Export to CSV
        </button>
      </ActionBar>
      <FilterBar
        tw='my-4'
        onClearFilters={() => setSearchParams(initialSearchParams)}
      >
        <FilterBar.Filter
          onSubmit={(data) => {
            setFilters({ ...filters, ...data })
          }}
          values={filters}
          initialValues={initialFilters}
        >
          <SelectField
            name='source'
            label='Source'
            options={[
              { label: 'Socket', value: 'SOCKET' },
              { label: 'Valve', value: 'VALVE' },
            ]}
          />
          <RangeInput
            label='Vehicle length'
            lowerFieldName={'minLength'}
            lowerLimit={0}
            upperFieldName={'maxLength'}
            upperLimit={200}
            valueLabel={'m'}
          />
          <DateInput startDateName='from' endDateName='to' label='Date' />
        </FilterBar.Filter>
      </FilterBar>
      {!data || isLoading ? (
        <PageLoader />
      ) : (
        //@ts-ignore
        <CustomTable
          loading={isLoading}
          maxPage={data.totalPages}
          currentPage={searchParams.page}
          setPage={(value) => {
            setPage(value)
            setSearchParams({
              ...searchParams,
              page: value,
            })
          }}
          pageSize={searchParams.size}
          data={data.items}
          totalCount={data.count}
          columns={socketColumns}
        />
      )}
    </ContentContainer>
  )
}
