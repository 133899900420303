/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { FormProvider, useForm } from 'react-hook-form'
import { InputField } from '../../common/general/fields/InputField'
import { CustomDrawer } from '../../common/general/drawer/CustomDrawer'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Account,
  PaymentTypeEnum,
  SessionRequest,
  SourceTypeEnum,
} from '../../../api-types'
import dayjs from 'dayjs'
import {
  useCreatePeriodicalReceipt,
  useCreatePostpaidReceipt,
  useReceiptPreview,
} from '../../../queries'
import { showErrorNotification } from '../../common/general/notification'
import { Txt } from '../../common/general/Txt'
import { Button } from '../../common/general/Button'
import { Loader } from '../../common/general/Loader'
import { CustomTable } from '../../common/general/table/CustomTable'
import { TableCell } from '../../common/general/table/cells'
import {
  convertEURToHRK,
  roundToFloatWithExactlyNDecimals,
  standardizedFormatDate,
} from '../../../utils'
import { useMemo } from 'react'
import { sum } from 'lodash'

export interface AddPrepaidPaymentForm {
  periodEndDate: Date
  accountId: number
}

const schema = yup.object().shape({
  periodEndDate: yup.date().required(),
  accountId: yup.number().required(),
})

interface CreateTransactionDrawerProps {
  open: boolean
  close: () => void
  customer: Account
  setSessionRequests: (sessionReqests: SessionRequest[]) => void
  setSessionRequestStatusModalOpen: (val: boolean) => void
}

export const CreateTransactionDrawer = (
  props: CreateTransactionDrawerProps,
) => {
  const { open, close, customer } = props

  const createPostpaidReceipt = useCreatePostpaidReceipt()
  const createPeriodicalReceipt = useCreatePeriodicalReceipt()

  const methods = useForm<AddPrepaidPaymentForm>({
    defaultValues: {
      periodEndDate: dayjs().toDate(),
      accountId: customer.id,
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const periodEndDate = methods.watch('periodEndDate')

  const {
    data: receiptPreview,
    isLoading: isLoadingReceiptPreview,
    isError: isErrorReceiptPreview,
  } = useReceiptPreview(customer.id, periodEndDate)

  const waterTotal = useMemo(() => {
    if (receiptPreview) {
      const items = receiptPreview.filter(
        (p) => p.sourceType === SourceTypeEnum.VALVE,
      )
      return sum(items.map((i) => i.consumption * i.tariff))
    }
    return 0
  }, [receiptPreview])

  const powerTotal = useMemo(() => {
    if (receiptPreview) {
      const items = receiptPreview.filter(
        (p) => p.sourceType === SourceTypeEnum.SOCKET,
      )
      return sum(items.map((i) => i.consumption * i.tariff))
    }
    return 0
  }, [receiptPreview])

  if (isErrorReceiptPreview) {
    throw new Error()
  }

  const handleCreateTransaction = async () => {
    switch (customer.paymentType) {
      case PaymentTypeEnum.POSTPAID:
        await handleCreateReceiptForPostpaid()
        break
      case PaymentTypeEnum.PERIODICAL:
        await handleCreateReceiptForPeriodical()
        break
      default:
        return
    }
  }

  const handleCreateReceiptForPostpaid = methods.handleSubmit(async (data) => {
    try {
      const { accountId } = data
      await createPostpaidReceipt({
        accountId,
      })
      close()
    } catch (error: any) {
      showErrorNotification(error)
    }
  })

  const handleCreateReceiptForPeriodical = methods.handleSubmit(
    async (data) => {
      try {
        const { accountId, periodEndDate } = data
        await createPeriodicalReceipt({
          accountId,
          periodEndDate,
        })
        close()
      } catch (error: any) {
        showErrorNotification(error)
      }
    },
  )

  return (
    <CustomDrawer
      open={open}
      widthPercentage={70}
      title={'Create a receipt'}
      subtitle={'Create a new receipt for a selected period'}
      render={() => (
        <form noValidate tw='space-y-6 flex-grow' style={{ padding: 24 }}>
          <FormProvider {...methods}>
            <div tw='flex h-full flex-col justify-between flex-grow'>
              <div tw='my-6 flex flex-col'>
                <InputField
                  name='periodEndDate'
                  label='Period end date'
                  type={'date'}
                  disabled={customer.paymentType !== PaymentTypeEnum.PERIODICAL}
                />
                {customer.paymentType !== PaymentTypeEnum.PERIODICAL && (
                  <Txt>
                    Since the account is not of payment type periodical you
                    cannot change the period end date.
                  </Txt>
                )}
                {isLoadingReceiptPreview && (
                  <div tw='flex items-center justify-center'>
                    <Loader color='#000000' />
                  </div>
                )}
                {receiptPreview && receiptPreview.length > 0 ? (
                  <div tw='mt-8'>
                    <CustomTable
                      data={receiptPreview}
                      loading={isLoadingReceiptPreview}
                      currentPage={1}
                      maxPage={1}
                      pageSize={20}
                      setPage={() => {}}
                      totalCount={100}
                      columns={[
                        {
                          Header: 'Pedestal',
                          accessor: 'pedestalIdentifier',
                          cell: (c) => {
                            return (
                              <TableCell.Text
                                value={`${c.pedestalIdentifier}`}
                              />
                            )
                          },
                        },
                        {
                          Header: 'Service',
                          accessor: 'service',
                          cell: (c) => {
                            return <TableCell.Text value={`${c.service}`} />
                          },
                        },
                        {
                          Header: 'Start date',
                          accessor: 'startDate',
                          cell: (c) => {
                            return (
                              <TableCell.Text
                                value={`${standardizedFormatDate(c.startDate)}`}
                              />
                            )
                          },
                        },
                        {
                          Header: 'End date',
                          accessor: 'endDate',
                          cell: (c) => {
                            return (
                              <TableCell.Text
                                value={`${standardizedFormatDate(c.endDate)}`}
                              />
                            )
                          },
                        },
                        {
                          Header: 'Consumption',
                          accessor: 'consumption',
                          cell: (c) => {
                            return (
                              <TableCell.Text
                                value={`${c.consumption.toFixed(3)}`}
                              />
                            )
                          },
                        },
                        {
                          Header: 'Price',
                          accessor: 'tariff',
                          cell: (c) => {
                            const total = roundToFloatWithExactlyNDecimals(
                              c.consumption * c.tariff,
                              2,
                            )
                            return (
                              <TableCell.Text
                                value={`${total} EUR (${convertEURToHRK(
                                  parseFloat(total),
                                )} HRK)`}
                              />
                            )
                          },
                        },
                      ]}
                    />
                    <Txt tw='mt-8 text-blue-500'>{`Water total: ${roundToFloatWithExactlyNDecimals(
                      waterTotal,
                      2,
                    )} EUR (${convertEURToHRK(waterTotal)} HRK)`}</Txt>
                    <Txt tw='mt-2 text-red-500'>{`Power total: ${roundToFloatWithExactlyNDecimals(
                      powerTotal,
                      2,
                    )} EUR (${convertEURToHRK(powerTotal)} HRK)`}</Txt>
                    <Txt tw='mt-2'>{`Grand total: ${roundToFloatWithExactlyNDecimals(
                      powerTotal + waterTotal,
                      2,
                    )} EUR (${convertEURToHRK(
                      powerTotal + waterTotal,
                    )} HRK)`}</Txt>
                  </div>
                ) : (
                  <Txt>
                    Everything in this period has been paid by the user.
                  </Txt>
                )}
              </div>
              <Button text='Create' onClick={handleCreateTransaction} />
            </div>
          </FormProvider>
        </form>
      )}
      close={() => close()}
    />
  )
}
