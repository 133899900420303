/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useAdminTransactions } from '../../../queries'
import { ISearchParams } from '../../../types'
import { convertEURToHRK, standardizedFormatDate } from '../../../utils'
import { useSearch } from '../../common/general/hooks/useSearch'
import { PageLoader } from '../../common/general/PageLoader'
import { TableCell } from '../../common/general/table/cells'
import { CustomTable } from '../../common/general/table/CustomTable'

export const AdminTransactionsTablePage = () => {
  const initialSearchParams: Partial<ISearchParams> = {
    orderField: 'receipt.createdAt',
    order: 'DESC',
  }
  const [searchParams, { setPage }] = useSearch(initialSearchParams)
  const { data, isLoading } = useAdminTransactions(searchParams)

  if (isLoading || !data) {
    return <PageLoader />
  }

  return (
    <div tw='mt-4'>
      <CustomTable
        loading={isLoading}
        maxPage={data.totalPages}
        currentPage={searchParams.page}
        setPage={setPage}
        pageSize={searchParams.size}
        data={data.items}
        totalCount={data.count}
        columns={[
          {
            Header: 'User',
            accessor: 'ownerName',
            cell: (l) => <TableCell.Text value={`${l.ownerName}`} />,
          },
          {
            Header: 'Transaction date',
            accessor: 'date',
            cell: (l) => (
              <TableCell.Text
                value={l.date ? standardizedFormatDate(l.date) : '-'}
              />
            ),
          },
          {
            Header: 'Amount',
            accessor: 'amount',
            cell: (l) => {
              return (
                <TableCell.Text
                  value={`${l.amount.toFixed(2)} EUR (${convertEURToHRK(
                    parseFloat(l.amount.toFixed(2)),
                  )} HRK)`}
                />
              )
            },
          },
          {
            Header: 'Type',
            accessor: 'type',
            cell: (l) => (
              <TableCell.Text
                value={`${l.type === 'PREPAID' ? 'WSPAY' : 'MANUAL'}`}
              />
            ),
          },
        ]}
      />
    </div>
  )
}
