/** @jsxImportSource @emotion/react */
import 'twin.macro'
import React, { useEffect, useState } from 'react'
import { Card } from '../../common/general/Card'
import ReactModal from 'react-modal'
import { Txt } from '../../common/general/Txt'
import { SessionRequest, SessionRequestStatusEnum } from '../../../api-types'
import {
  useGetLatestReceiptForAccount,
  useGetSessionRequests,
} from '../../../queries'
import Loader from 'react-spinners/ClipLoader'
import { showErrorNotification } from '../../common/general/notification'
import { useHistory } from 'react-router-dom'
import { adminRoutes } from '../../common/general/route-names'
import { useAsync } from 'react-use'

interface IRequestSessioStopStatusModalProps {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  onConfirm: () => void
  sessionRequests: SessionRequest[] | undefined
  setSessionRequests: (val: SessionRequest[] | undefined) => void
}

const modalStyle: ReactModal.Styles = {
  content: {
    background: 'none',
    border: 'none',
    padding: '0',
    display: 'flex',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: '#6B7280BF',
  },
}

export const RequestSessioStopStatusModal = (
  props: IRequestSessioStopStatusModalProps,
) => {
  const { isOpen, setIsOpen, sessionRequests, setSessionRequests } = props
  const history = useHistory()
  const [accountId, setAccountId] = useState<number | undefined>()

  const { data: freshSessionRequests } = useGetSessionRequests(
    sessionRequests?.map((req) => req.id),
    !!sessionRequests,
  )

  const { data: receipt, remove: removeReceiptQuery } =
    useGetLatestReceiptForAccount(accountId, !!accountId)

  useEffect(() => {
    //Check if all session requests are resolved, then set Account id to fetch new receipt or show error message
    if (freshSessionRequests) {
      if (
        freshSessionRequests.every(
          (sessionRequest) =>
            sessionRequest.status !== SessionRequestStatusEnum.PENDING,
        )
      ) {
        setSessionRequests(undefined)
        if (
          freshSessionRequests.every(
            (sessionRequest) =>
              sessionRequest.status === SessionRequestStatusEnum.APPROVED,
          )
        ) {
          setAccountId(freshSessionRequests[0].account.id)
        } else {
          setIsOpen(false)
          showErrorNotification(
            'Not all sessions have been closed, receipt not created',
          )
        }
      }
    }
  }, [freshSessionRequests])

  useAsync(async () => {
    if (receipt && isOpen) {
      setAccountId(undefined)
      removeReceiptQuery()
      history.push({
        pathname: `${adminRoutes.VIEW_TRANSACTION}/${receipt.transactionId}`,
      })
    }
  }, [receipt])

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={modalStyle}
      ariaHideApp={false}
    >
      <Card tw='flex flex-col items-center w-[32rem]'>
        <Txt l tw='text-center mb-2'>
          Waiting for response...
        </Txt>
        <Loader />
      </Card>
    </ReactModal>
  )
}
