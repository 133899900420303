/** @jsxImportSource @emotion/react */
import 'twin.macro'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Account,
  Pedestal,
  Source,
  sourceLabels,
  Vehicle,
} from '../../../api-types'
import { http } from '../../../http'
import { IDropdownOption } from '../../../types'
import { CustomDrawer } from '../../common/general/drawer/CustomDrawer'
import { SimpleAutocompleteField } from '../../common/general/fields/SimpleAutocompleteField'
import { Divider } from '../../common/general/Divider'
import { Button } from '../../common/general/Button'
import { SelectField } from '../../common/general/fields/SelectField'
import { yupResolver } from '@hookform/resolvers/yup'
import { capitalizeString, parseBackendException } from '../../../utils'
import * as yup from 'yup'

interface IAdminStartSessionDrawerProps {
  source: Source
  pedestal: Pedestal
  open: boolean
  setOpen: (val: boolean) => void
  setOpenParentDrawer: (val: boolean) => void
  level?: string
  levelMove?: number
  className?: string
}

interface IStartSessionForm {
  sourceId: number
  account: any
  vehicle: any
}

export function AdminStartSessionDrawer(props: IAdminStartSessionDrawerProps) {
  const {
    open,
    setOpen,
    level,
    levelMove,
    className,
    pedestal,
    source,
    setOpenParentDrawer,
  } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAccounts] = useState<Account[]>()
  const [vehicles, setVehicles] = useState<Vehicle[]>()

  const schema = yup.object().shape({
    sourceId: yup.number().required(),
    account: yup
      .object()
      .shape({ label: yup.string(), value: yup.number().required() })
      .required(),
    vehicle: yup
      .object()
      .shape({ label: yup.string(), value: yup.number().required() })
      .required(),
  })

  const defaultValues = {
    sourceId: source.id,
  }

  const methods = useForm<IStartSessionForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    shouldUseNativeValidation: false,
    mode: 'onBlur',
  })

  const vehicleInput = methods.watch('vehicle')

  const onSubmit = methods.handleSubmit(async (data) => {
    try {
      await http.startSession(
        data.sourceId,
        //@ts-ignore
        data.account.value,
        //@ts-ignore
        data.vehicle.value,
      )
      setOpen(false)
      setOpenParentDrawer(false)
    } catch (e: any) {
      parseBackendException(e)
    }
  })

  useEffect(() => {
    if (vehicles) {
      const vehicle = vehicles.find((vehicle: Vehicle) => {
        return vehicle.id === vehicleInput.value
      })
      vehicle &&
        //@ts-ignore
        methods.setValue('account', {
          //backend should always send one account that is associated with given location
          value: vehicle.user.accounts[0].id,
          label: vehicle.user.name,
        })
    }
    return () => {}
  }, [vehicleInput])

  return (
    <CustomDrawer
      open={open}
      title={'Activate Session'}
      subtitle={'Manually activate a session for a user'}
      close={() => setOpen(false)}
      level={level}
      levelMove={levelMove}
      className={className}
      render={() => (
        <form onSubmit={onSubmit} noValidate tw='mt-8 space-y-6 flex-grow'>
          <FormProvider {...methods}>
            <div tw='flex h-full flex-col justify-between'>
              <div tw='m-6 h-full'>
                <SelectField
                  name='pedestalIdentifier'
                  label='Pedestal'
                  placeholder=''
                  options={[
                    {
                      label: pedestal.identifier.toUpperCase(),
                      value: pedestal.identifier,
                    },
                  ]}
                  defaultValue={{
                    label: pedestal.identifier.toUpperCase(),
                    value: pedestal.identifier,
                  }}
                  isDisabled
                />
                <SelectField
                  name='sourceId'
                  label='Outlet/Water'
                  options={pedestal.sources.map((source: Source) => ({
                    label: `${sourceLabels[source.type]} ${source.position}`,
                    value: source.id,
                  }))}
                  defaultValue={{
                    label: `${sourceLabels[source.type]} ${source.position}`,
                    value: source.id,
                  }}
                  isDisabled
                />
                <SimpleAutocompleteField
                  tw='mb-6'
                  name='vehicle'
                  label='Vehicle'
                  placeholder='Search...'
                  onChange={async (term: string) => {
                    const searchResult = await http.searchVehicles({
                      order: 'ASC',
                      orderField: 'registration',
                      page: 1,
                      term,
                      size: 10,
                    })
                    setVehicles(searchResult.items)
                    return searchResult.items.map<IDropdownOption>(
                      (vehicle: Vehicle) => ({
                        label: vehicle?.registration,
                        value: vehicle?.id,
                      }),
                    )
                  }}
                />
                <SimpleAutocompleteField
                  tw='mb-6'
                  name='account'
                  placeholder='Search...'
                  label='Customer'
                  onChange={async (term: string) => {
                    const searchResult = await http.searchAdminClients({
                      order: 'ASC',
                      orderField: 'name',
                      page: 1,
                      term,
                      size: 10,
                    })

                    setAccounts(searchResult.items)
                    return searchResult.items.map<IDropdownOption>(
                      (customer: Account) => ({
                        label: `${customer.owner?.name} (${capitalizeString(
                          customer.paymentType,
                        )})`,
                        value: customer.id,
                      }),
                    )
                  }}
                />
              </div>
              <div>
                <Divider type='horizontal' />
                <div tw='flex flex-row justify-end m-4'>
                  <Button text={'Activate'} onClick={onSubmit} />
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      )}
    />
  )
}
