import 'twin.macro'

import * as yup from 'yup'

import { FormProvider, useForm } from 'react-hook-form'

import { Button } from '../../../general/Button'
import { SuperadminCurrentFieldArray } from './SuperadminCurrentFieldArray'
import { CustomDrawer } from '../../../general/drawer/CustomDrawer'
import { Divider } from '../../../general/Divider'
import { InputField } from '../../../general/fields/InputField'
import { PedestalType } from '../../../../../api-types'
import { SuperadminWaterFieldArray } from './SuperadminWaterFieldArray'
import { errors } from '../../../general/utils'
import { showErrorNotification } from '../../../general/notification'
import { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

/** @jsxImportSource @emotion/react */

interface IPedestalTypeDrawer {
  open: boolean
  initialValues?: IPedestalTypeForm
  isEdit: boolean
  setOpen(val: boolean): void
  onSubmit(val: IPedestalTypeForm): void
}

export interface IPedestalTypeForm {
  name: string
  configuration: PedestalType['configuration']
}

const schema = yup.object().shape({
  name: yup.string().required(errors.required),
  configuration: yup.object().shape({
    currents: yup.array().of(yup.object().shape({})),
    waters: yup.array().of(yup.object().shape({})),
  }),
})

const validateAndParseConfiguration = (
  config: IPedestalTypeForm['configuration'],
) => {
  const { currents, waters } = config
  const errors: string[] = []
  const newCurrents = []
  const newWaters = []
  for (const current of currents) {
    const bothDefined = current.impulses && current.modbus
    const bothUndefined = !current.impulses && !current.modbus
    if (bothDefined) {
      errors.push('Cannot add both impulses and modbus')
      continue
    }

    if (current.phases && current.power && !bothDefined && !bothUndefined) {
      newCurrents.push(current)
    }
  }
  for (const water of waters) {
    if (water.impulses && water.size) {
      newWaters.push(water)
    }
  }

  return [{ currents: newCurrents, waters: newWaters }, errors]
}

export const PedestalTypeDrawer = (props: IPedestalTypeDrawer) => {
  const defaultValues = {
    name: '',
    configuration: {
      waters: [],
      currents: [],
    },
    ...(props.initialValues ?? {}),
  }
  const methods = useForm<IPedestalTypeForm>({
    defaultValues: defaultValues,
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [props.initialValues])

  const onSubmit = methods.handleSubmit(async (data) => {
    const [config, errors] = validateAndParseConfiguration(data.configuration)
    // @ts-ignore
    if (errors.length > 0) {
      // @ts-ignore
      showErrorNotification(errors[0])
      return
    }

    props.setOpen(false)
    props.onSubmit({
      ...data,
      // @ts-ignore
      configuration: config,
    })
  })

  return (
    <CustomDrawer
      open={props.open}
      title={props.isEdit ? 'Edit type' : 'New type'}
      subtitle='Pedestal types are location specific.'
      render={() => (
        <form onSubmit={onSubmit} noValidate tw='space-y-6 flex-grow'>
          <FormProvider {...methods}>
            <div tw='flex h-full flex-col justify-between flex-grow'>
              <div tw='m-6 flex flex-grow flex-col'>
                <InputField name='name' label='Name' />
                <SuperadminCurrentFieldArray tw='h-full mb-6' />
                <SuperadminWaterFieldArray tw='h-full' />
              </div>
              <div>
                <Divider type='horizontal' />
                <div tw='flex flex-row justify-end m-4'>
                  <Button
                    text={props.isEdit ? 'Edit' : 'Create'}
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      )}
      close={() => props.setOpen(false)}
    />
  )
}
