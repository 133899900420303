export const publicRoutes = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
}

export const adminRoutes = {
  PEDESTALS: '/pedestals',
  CUSTOMERS: '/customers',
  CHECK_EMAIL: '/customers/check-email',
  ADD_NEW_CUSTOMER: '/customers/new',
  VIEW_CUSTOMER: '/customers/view',
  DASHBOARD: '/dashboard',
  ALARMS: '/alarms',
  VIEW_PEDESTAL: '/pedestals/view',
  SESSION_DETAILS: '/pedestals/view/:identifier/session-details/:sessionId',
  VIEW_TRANSACTION: '/customers/transaction',
  SETTINGS: '/settings',
  SESSIONS: '/sessions',
  REPORTS: '/reports',
  REPORTS_SESSIONS: '/reports/sessions',
  REPORTS_MANUAL_SESSIONS: '/reports/manual-sessions',
  REPORTS_ALARMS: '/reports/alarms',
  REPORTS_TRANSACTIONS: '/reports/transactions',
  REPORTS_CONSUMPTION: '/reports/consumption',
  REPORTS_SOURCES: '/reports/sources',
  ADMIN_TRANSACTIONS_TABLE: '/transactions/table',
}

export const superadminRoutes = {
  // Location
  LOCATIONS: '/locations',
  EDIT_LOCATION: '/locations/edit',
  NEW_LOCATION: '/locations/new',
  // Pedestal
  PEDESTALS: '/pedestals',
  PEDESTALS_CONFIGURATION: '/pedestals/wizard/configuration',
  PEDESTALS_TESTING: '/pedestals/wizard/testing',
  PEDESTALS_INSTALLATION: '/pedestals/wizard/installation',
  PEDESTALS_CREATION: '/pedestals/wizard/creation',
  VIEW_PEDESTAL: '/pedestals/view',
  SESSION_DETAILS: '/pedestals/view/:identifier/session-details/:sessionId',
  // Report
  REPORTS: '/reports',
  // User
  USERS: '/users',
  ALARMS: '/alarms',
}
