/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import tw from 'twin.macro'

interface DatePaddingProps {
  from: Date
  to: Date
  setDates: (from: Date, to: Date) => void
}

export const DatePadding = (props: DatePaddingProps) => {
  const { from, to, setDates } = props
  const [dateRange, setDateRange] = useState<(Date | null)[]>([from, to])

  //#region Hooks
  const intervalStep = useMemo(
    () => dayjs.duration(dayjs(to).diff(from)),
    [from, to],
  )
  useEffect(() => {
    if (dateRange[0] !== from && dateRange[1] !== to) {
      setDateRange([from, to])
    }
  }, [from, to])
  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      setDates(dateRange[0], dateRange[1])
    }
  }, [dateRange])
  //#endregion

  //#region Functions
  const onDecreaseInterval = () => {
    const newFrom = dayjs(from).subtract(intervalStep).toDate()
    const newTo = dayjs(to).subtract(intervalStep).toDate()

    setDates(newFrom, newTo)
    setDateRange([newFrom, newTo])
  }
  const onIncreaseInterval = () => {
    const newFrom = dayjs(from).add(intervalStep).toDate()
    const newTo = dayjs(to).add(intervalStep).toDate()

    setDates(newFrom, newTo)
    setDateRange([newFrom, newTo])
  }
  //#endregion

  return (
    <div tw='flex flex-row flex-1 justify-end items-center'>
      <div
        tw='py-2 px-2 rounded-l-md bg-primary-100 hover:bg-primary-200 hover:cursor-pointer'
        onClick={onDecreaseInterval}
      >
        <ChevronLeftIcon width={24} />
      </div>
      <div>
        <DatePicker
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          onChange={(dates) => setDateRange(dates)}
          selectsRange
          css={[
            tw`flex h-10 font-sans font-normal text-gray-900 text-l text-center items-center border border-primary-100 bg-primary-100`,
            tw`hover:cursor-pointer hover:bg-primary-200 hover:border-primary-200 focus:outline-none focus:ring-primary-700 focus:bg-white`,
          ]}
          dateFormat={'dd/MM/yyyy'}
        />
      </div>
      <div
        tw='py-2 px-2 rounded-r-md bg-primary-100 hover:bg-primary-200 hover:cursor-pointer'
        onClick={onIncreaseInterval}
      >
        <ChevronRightIcon width={24} />
      </div>
    </div>
  )
}
