import { FormProvider, useForm } from 'react-hook-form'
import { InputField } from '../../common/general/fields/InputField'
import { Button } from '../../common/general/Button'
import { CustomDrawer } from '../../common/general/drawer/CustomDrawer'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { Charter, User } from '../../../api-types'
/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { CountrySelectField } from '../../common/general/fields/CountrySelectField'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { Txt } from '../../common/general/Txt'
import { omitBy } from 'lodash'
import { ErrorText } from '../../common/general/ErrorText'

export interface EditEmployeeForm {
  id?: number
  charterId: number
  name: string
  email?: string
  username?: string
  phoneNumber?: string
  country: string
}

const schema = yup.object().shape(
  {
    email: yup
      .string()
      .email()
      .when(['username', 'emailEmptyConfirmation'], {
        is: (username: string, emailEmptyConfirmation: boolean) =>
          !username && !emailEmptyConfirmation,
        then: yup.string().required(),
        otherwise: yup.string().optional().nullable(),
      }),
    username: yup.string().when(['email', 'emailEmptyConfirmation'], {
      is: (email: string, emailEmptyConfirmation: boolean) =>
        !email && emailEmptyConfirmation,
      then: yup.string().required(),
      otherwise: yup.string().optional().nullable(),
    }),
    emailEmptyConfirmation: yup.boolean().when(['email'], {
      is: (email: string) => {
        return !email
      },
      then: yup
        .boolean()
        .oneOf([true], 'Field must be checked or enter an email'),
      otherwise: yup
        .boolean()
        .oneOf([false], 'Field must be unchecked if email is entered'),
    }),
    phoneNumber: yup.string().optional().nullable(),
    name: yup.string().required(),
    country: yup.string().required(),
  },
  [
    ['username', 'email'],
    ['email', 'username'],
    ['email', 'emailEmptyConfirmation'],
  ],
)

interface EmployeeDrawerProps {
  open: boolean
  close: () => void
  onSubmit: (val: any) => void
  isEdit: boolean
  owner: User
  charter: Charter
  employee?: User
}

export const EmployeeDrawer = (props: EmployeeDrawerProps) => {
  const { open, onSubmit, close, employee, owner, charter, isEdit } = props

  const defaultValues: Partial<
    EditEmployeeForm & { emailEmptyConfirmation: boolean }
  > = {
    country: owner.country,
    ...(employee ?? undefined),
    charterId: charter.id,
    emailEmptyConfirmation: false,
  }

  const methods = useForm<
    EditEmployeeForm & { emailEmptyConfirmation: boolean }
  >({
    defaultValues: defaultValues,
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const {
    formState: {
      errors: { emailEmptyConfirmation: emailEmptyConfirmationError },
    },
  } = methods

  const emailEmptyConfirmation = methods.watch('emailEmptyConfirmation')

  useEffect(() => {
    methods.reset(defaultValues)
  }, [employee])

  useEffect(() => {
    methods.trigger(emailEmptyConfirmation ? 'email' : 'username')
  }, [emailEmptyConfirmation])

  const handleSubmit = methods.handleSubmit(async (data) => {
    onSubmit(omitBy(data, ['emailEmptyConfirmation']))
    !isEdit && methods.reset()
  })

  return (
    <CustomDrawer
      open={open}
      title={isEdit ? 'Edit employee info' : 'Invite a new employee'}
      subtitle={''}
      render={() => (
        <form
          onSubmit={handleSubmit}
          tw='space-y-6 flex-grow'
          style={{ padding: 24 }}
        >
          <FormProvider {...methods}>
            <div tw='flex h-full flex-col justify-between flex-grow'>
              <div tw='m-6 flex flex-grow flex-col'>
                <InputField name='name' label='Full name' type={'text'} />
                <InputField name='phoneNumber' label='Phone' type={'text'} />
                <InputField name='email' label='Email' type={'text'} />
                {!isEdit && (
                  <Txt tw='mb-6'>
                    <label>
                      <input
                        {...methods.register('emailEmptyConfirmation')}
                        type='checkbox'
                      />
                      <span tw='ml-2'>Create user without email</span>
                    </label>
                    {emailEmptyConfirmationError &&
                      emailEmptyConfirmationError.message && (
                        <ErrorText tw='mt-2'>
                          {emailEmptyConfirmationError.message}
                        </ErrorText>
                      )}
                  </Txt>
                )}
                <InputField name='username' label='Username' type={'text'} />
                <CountrySelectField name='country' label='Country' />
              </div>
              <div tw='flex flex-row items-center'>
                <InformationCircleIcon tw='mr-1 text-primary' width={24} />
                <Txt>
                  An email with password reset link will be sent to new employee
                  after creation.
                </Txt>
              </div>
              <div>
                <div tw='flex flex-row justify-end m-4'>
                  <Button text={'Close'} onClick={close} cancel tw='mr-3' />
                  <Button
                    text={isEdit ? 'Save' : 'Create'}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      )}
      close={close}
    />
  )
}
