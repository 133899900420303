import {
  LocationStatus,
  PedestalStatus,
  SessionStatus,
  SessionEventType,
  SourceLabelEnum,
  SourceTypeEnum,
  PhaseTypeEnum,
  PowerLabelEnum,
  SourceStatusEnum,
  AlarmTypeEnum,
} from '../../../api-types'

import { IDropdownOption } from '../../../types'
import { format } from 'date-fns'

interface IFormatDateOptions {
  dateFormat?: string
  uppercase?: boolean
}

export const formatDate = (date: Date, options?: IFormatDateOptions): any => {
  const { dateFormat = 'yyyy-MM-dd', uppercase = false } = options || {}
  const startDate = format(date, dateFormat)

  return uppercase ? startDate.toUpperCase() : startDate
}

export const yesNoOptions: IDropdownOption[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const errors = {
  required: 'This field is required',
  number: 'This field is number',
  email: 'This field is email',
}

export const locationStatusMeta = {
  [LocationStatus.ACTIVE]: {
    colorProps: { green: true },
    value: 'Active',
  },
  [LocationStatus.TEST]: {
    colorProps: { purple: true },
    value: 'Test',
  },
  [LocationStatus.INACTIVE]: {
    colorProps: { gray: true },
    value: 'Inactive',
  },
}

export const sessionStatusMeta = {
  [SessionStatus.PAID]: {
    colorProps: { green: true },
    value: 'Paid',
  },
  [SessionStatus.IN_USE]: {
    colorProps: { blue: true },
    value: 'In use',
  },
  [SessionStatus.UNPAID]: {
    colorProps: { red: true },
    value: 'Unpaid',
  },
}

export const sessionEventTypeMeta = {
  [SessionEventType.USER]: {
    colorProps: { white: true },
    value: 'USER',
  },
  [SessionEventType.DANGER]: {
    colorProps: { red: true },
    value: 'DANGER',
  },
  [SessionEventType.WARNING]: {
    colorProps: { yellow: true },
    value: 'WARNING',
  },
  [SessionEventType.SUCCESS]: {
    colorProps: { green: true },
    value: 'SUCCESS',
  },
}

export const sourceLabelMeta = {
  [SourceTypeEnum.SOCKET]: SourceLabelEnum.POWER,
  [SourceTypeEnum.VALVE]: SourceLabelEnum.WATER,
}

export const sourceStateMeta = {
  [PhaseTypeEnum.MONO_PHASE]: PowerLabelEnum.SINGLE,
  [PhaseTypeEnum.THREE_PHASE]: PowerLabelEnum.TRIPLE,
}

export const pedestalStatusMeta = {
  [PedestalStatus.ACTIVE]: {
    colorProps: { green: true },
    value: 'Active',
  },
  [PedestalStatus.INACTIVE]: {
    colorProps: { gray: true },
    value: 'Inactive',
  },
  [PedestalStatus.OUT_OF_SERVICE]: {
    colorProps: { red: true },
    value: 'Out of service',
  },
  [PedestalStatus.READY_TO_CONFIGURE]: {
    colorProps: { purple: true },
    value: 'Ready to configure',
  },
  [PedestalStatus.READY_TO_INSTALL]: {
    colorProps: { yellow: true },
    value: 'Ready to install',
  },
  [PedestalStatus.READY_TO_TEST]: {
    colorProps: { blue: true },
    value: 'Ready to test',
  },
}

export const sourceStatusMeta = {
  [SourceStatusEnum.AVAILABLE]: {
    colorProps: { green: true },
    value: 'Available',
  },
  [SourceStatusEnum.IN_USE]: {
    colorProps: { blue: true },
    value: 'In Use',
  },
  [SourceStatusEnum.OUT_OF_SERVICE]: {
    colorProps: { red: true },
    value: 'Out Of Service',
  },
  [SourceStatusEnum.PLUGGED_IN]: {
    colorProps: { purple: true },
    value: 'Plugged In',
  },
  [SourceStatusEnum.STATUS_UNAVAILABLE]: {
    colorProps: { red: true },
    value: 'Status unavailable',
  },
  [SourceStatusEnum.REQUEST_PENDING]: {
    colorProps: { gray: true },
    value: 'Request pending',
  },
  [SourceStatusEnum.MANUAL_MODE]: {
    colorProps: { orange: true },
    value: 'Manual mode',
  },
}

export const alarmStatusMeta = {
  [AlarmTypeEnum.FUSE]: {
    colorProps: { red: true },
    value: 'Fuse',
  },
  [AlarmTypeEnum.MODBUS]: {
    colorProps: { red: true },
    value: 'Modbus',
  },
  [AlarmTypeEnum.HEARTBEAT]: {
    colorProps: { red: true },
    value: 'Heartbeat',
  },
}
