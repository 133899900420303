/** @jsxImportSource @emotion/react */
import 'twin.macro'
import tw from 'twin.macro'
import { Txt } from '../../common/general/Txt'
import { TableCell } from '../../common/general/table/cells'
import { AdminSourceCardContainer } from './AdminSourceCardContainer'
import { SourceTypeEnum, Valve } from '../../../api-types'
import { sourceLabelMeta, sourceStatusMeta } from '../../common/general/utils'
import { roundToFloatWithExactlyNDecimals } from '../../../utils'
import { WaterIcon } from '../../common/icons/icons'

interface AdminValveCardProps {
  valve: Valve
  onClick(valve: Valve): void
}

export const AdminValveCard = (props: AdminValveCardProps) => {
  const { valve, onClick } = props
  const { type, position, connectionSize, state } = valve
  const availableMeta = sourceStatusMeta[valve.status]

  return (
    <AdminSourceCardContainer
      tw='w-[45%] min-w-[300px]'
      source={valve}
      onClick={() => onClick(valve)}
    >
      <div tw='h-2/3 flex flex-row pb-6 gap-5 px-6 pt-6'>
        <div
          css={[
            tw`h-12 w-12 flex justify-center items-center rounded-md bg-primary-500`,
          ]}
        >
          <WaterIcon />
        </div>
        <div tw='flex flex-col gap-0.5'>
          <div tw='flex flex-row gap-2'>
            <Txt tw='font-semibold text-gray-900' xl>
              {sourceLabelMeta[type as SourceTypeEnum]} {position}
            </Txt>
            <TableCell.Chip
              value={availableMeta.value}
              {...availableMeta.colorProps}
            />
          </div>
          <div tw='flex flex-row gap-1'>
            <Txt tw='text-gray-500' sm>
              {connectionSize && `${connectionSize}"`}
            </Txt>
          </div>
          <div tw='flex flex-row gap-1'>
            <Txt tw='text-gray-500' sm>
              Consumption: {state.value} {`m\u00B3`}
            </Txt>
          </div>
          <div tw='flex flex-row gap-1'>
            {valve.activeSession && (
              <Txt tw='text-gray-500' sm>
                Session Consumption:{' '}
                {roundToFloatWithExactlyNDecimals(
                  state.value - valve.activeSession.initialMeterState,
                  3,
                )}{' '}
                {`m\u00B3`}
              </Txt>
            )}
          </div>
        </div>
      </div>
      <div tw='h-1/3 bg-gray-50 px-6 py-3 rounded-b-lg'>
        <span>
          <Txt tw='text-primary-600 font-medium' sm>
            More info
          </Txt>
        </span>
      </div>
    </AdminSourceCardContainer>
  )
}
