/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useState } from 'react'
import { useGetAllPedestals, useGetAllPedestalTypes } from '../../../../queries'
import { adminRoutes } from '../../general/route-names'
import { ActionBar } from '../../general/ActionBar'
import { Breadcrumbs } from '../../general/Breadcrumbs'
import { ContentContainer } from '../../general/ContentContainer'
import { PageLoader } from '../../general/PageLoader'
import { isEmpty } from 'lodash'
import { ReportComponent } from '../report-components/ReportComponent'
import { useSearchQueryParams } from '../../general/hooks/useSearchQueryParams'
import { AlarmTypeEnum, ReportType } from '../report-types/report-enums'
import { AlarmsReportSearchParams } from '../report-types/report-search-params-types'
import { initialReportFilters } from '../report-types/report-types'

export const AlarmsReportPage = () => {
  const [searchParams] = useSearchQueryParams<AlarmsReportSearchParams>()

  const [filters, setFilters] = useState<AlarmsReportSearchParams>(
    !isEmpty(searchParams) ? searchParams : initialReportFilters,
  )

  //#region Fetching data
  const {
    data: pedestals,
    error: pedestalsError,
    isLoading: isLoadingPedestals,
  } = useGetAllPedestals()
  const {
    data: pedestalTypes,
    error: pedestalTypesError,
    isLoading: isLoadingPedestalTypes,
  } = useGetAllPedestalTypes()
  //#endregion

  if (isLoadingPedestals || isLoadingPedestalTypes) {
    return <PageLoader />
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-4'
        links={[
          { name: 'Reports', route: adminRoutes.REPORTS },
          { name: 'Alarms' },
        ]}
      />
      <ActionBar title='Alarms report' tw='mb-5' />
      <ReportComponent
        reportType={ReportType.ALARMS}
        filters={filters}
        setFilters={setFilters}
        filtersOptions={[
          {
            label: 'Alarm type',
            filterKey: 'alarmType',
            options: [
              {
                label: 'Fuse',
                value: AlarmTypeEnum.FUSE,
              },
              {
                label: 'Modbus',
                value: AlarmTypeEnum.MODBUS,
              },
              {
                label: 'Heartbeat',
                value: AlarmTypeEnum.HEARTBEAT,
              },
            ],
            linkedFilters: ['pedestalId', 'pedestalTypeId'],
          },
          {
            label: 'Pedestal',
            filterKey: 'pedestalId',
            options: pedestals
              ? pedestals.map((pedestal) => ({
                  label: pedestal.identifier,
                  value: pedestal.id,
                }))
              : [],
            disablesFilters: ['pedestalTypeId'],
          },
          {
            label: 'Pedestal type',
            filterKey: 'pedestalTypeId',
            options: pedestalTypes
              ? pedestalTypes.map((pedestalType) => ({
                  label: pedestalType.name,
                  value: pedestalType.id,
                }))
              : [],
          },
        ]}
        isError={pedestalsError || pedestalTypesError}
      />
    </ContentContainer>
  )
}
