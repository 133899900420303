import { FormProvider, useForm } from 'react-hook-form'
import { InputField } from '../../common/general/fields/InputField'
import { Button } from '../../common/general/Button'
import { CustomDrawer } from '../../common/general/drawer/CustomDrawer'
import * as yup from 'yup'
import { errors } from '../../common/general/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { SelectField } from '../../common/general/fields/SelectField'
import {
  Account,
  Vehicle,
  VehicleTypeEnum,
  vehicleTypeLabels,
} from '../../../api-types'
/** @jsxImportSource @emotion/react */
import 'twin.macro'

export interface EditVehicleForm {
  length: number
  registration: string
  type: VehicleTypeEnum
  userId: number
  vehicleId: number
}

const schema = yup.object().shape({
  userId: yup.number().optional(),
  vehicleId: yup.number().optional(),
  type: yup
    .mixed<keyof typeof VehicleTypeEnum>()
    .oneOf(Object.values(VehicleTypeEnum)),
  registration: yup.string().required(errors.required),
  length: yup
    .number()
    .nullable()
    .when('type', {
      is: VehicleTypeEnum.BOAT,
      then: yup.number().typeError(errors.number).required(errors.required),
    }),
})

interface VehicleDrawerProps {
  open: boolean
  close: () => void
  onSubmit: (val: any) => void
  isEdit: boolean
  customer: Account
  vehicle?: Vehicle
}

export const VehicleDrawer = (props: VehicleDrawerProps) => {
  const { open, onSubmit, close, vehicle, customer, isEdit } = props

  const defaultValues = {
    userId: customer.owner.id,
    vehicleId: vehicle?.id,
    ...(vehicle ?? undefined),
  }

  const methods = useForm<EditVehicleForm>({
    defaultValues: defaultValues,
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [vehicle])

  const vehicleType = methods.watch('type')

  const handleSubmit = methods.handleSubmit(async (data) => {
    onSubmit(data)
    !isEdit && methods.reset()
  })

  return (
    <CustomDrawer
      open={open}
      title={isEdit ? 'Edit vehicle info' : 'Add new vehicle'}
      subtitle={''}
      render={() => (
        <form
          onSubmit={onSubmit}
          tw='space-y-6 flex-grow'
          style={{ padding: 24 }}
        >
          <FormProvider {...methods}>
            <div tw='flex h-full flex-col justify-between flex-grow'>
              <div tw='m-6 flex flex-grow flex-col'>
                <InputField
                  name='registration'
                  label='Registration'
                  type={'text'}
                />
                <SelectField
                  name='type'
                  label='Type'
                  options={Object.keys(VehicleTypeEnum).map((key) => ({
                    label:
                      vehicleTypeLabels[key as keyof typeof VehicleTypeEnum],
                    value: key,
                  }))}
                />
                {vehicleType === VehicleTypeEnum.BOAT && (
                  <InputField name='length' label='Length' type={'number'} />
                )}
              </div>
              <div>
                <div tw='flex flex-row justify-end m-4'>
                  <Button text={'Close'} onClick={close} cancel tw='mr-3' />
                  <Button
                    text={isEdit ? 'Save' : 'Create'}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      )}
      close={close}
    />
  )
}
