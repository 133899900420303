/** @jsxImportSource @emotion/react */
import 'twin.macro'

import { ForgotPasswordForm } from './ForgotPasswordForm'
import { MarexLogo } from '../general/assets'
import { Txt } from '../general/Txt'

export const ForgotPasswordPage = () => {
  return (
    <div tw='m-auto  h-screen max-w-448 w-full p-8 flex flex-col justify-center items-center'>
      <MarexLogo tw='mb-6 w-16 h-16 ' />
      <Txt xxxl tw='mb-2 text-center'>
        Forgot password?
      </Txt>
      <ForgotPasswordForm />
    </div>
  )
}
