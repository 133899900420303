/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { IDropdownOption } from '../../../../types'
import React from 'react'
import { SelectField } from './SelectField'
import { countries } from './countries'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ICountrySelectField<T> {
  name: string
  label?: string
  className?: string
  isDisabled?: boolean
  defaultValue?: IDropdownOption
}

export const CountrySelectField = <T extends Object>(
  props: ICountrySelectField<T>,
) => {
  return (
    <SelectField
      {...props}
      options={countries.map((c) => ({ label: c.name, value: c.name }))}
      isSearchable
    />
  )
}
