/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ContentContainer } from '../../common/general/ContentContainer'
import { ActionBar } from '../../common/general/ActionBar'
import { CustomTable } from '../../common/general/table/CustomTable'
import { FilterBar } from '../../common/general/FilterBar'
import { ISearchParams } from '../../../types'
import { TableCell } from '../../common/general/table/cells'
import { useSearch } from '../../common/general/hooks/useSearch'
import { useAdminClients } from '../../../queries'
import { useHistory } from 'react-router-dom'
import { Button } from '../../common/general/Button'
import { PlusIcon } from '@heroicons/react/solid'
import { adminRoutes } from '../../common/general/route-names'
import { PageLoader } from '../../common/general/PageLoader'

export const CustomerList = () => {
  const history = useHistory()

  const initialSearchParams: Partial<ISearchParams> = {
    term: '',
    orderField: 'id',
    order: 'ASC',
  }

  const [searchParams, { setOrder, setOrderField, setPage, setTerm }] =
    useSearch(initialSearchParams)
  const { data, isLoading } = useAdminClients(searchParams)

  return (
    <ContentContainer>
      <ActionBar title='Customers' tw='mb-5'>
        <Button
          primary
          icon={PlusIcon}
          text='Add New Customer'
          onClick={() =>
            history.push({
              pathname: adminRoutes.CHECK_EMAIL,
              search: new URLSearchParams({
                charter: '0',
              }).toString(),
            })
          }
        />
        <Button
          primary
          icon={PlusIcon}
          text='Add New Charter'
          onClick={() =>
            history.push({
              pathname: adminRoutes.CHECK_EMAIL,
              search: new URLSearchParams({
                charter: '1',
              }).toString(),
            })
          }
        />
      </ActionBar>
      <FilterBar tw='mb-4'>
        <FilterBar.Select
          value={{
            order: searchParams.order,
            orderField: searchParams.orderField,
          }}
          options={[
            {
              label: 'Name (A-Z)',
              value: { order: 'ASC', orderField: 'name' },
            },
            {
              label: 'Name (Z-A)',
              value: { order: 'DESC', orderField: 'name' },
            },
            {
              label: 'Email (A-Z)',
              value: { order: 'ASC', orderField: 'email' },
            },
            {
              label: 'Email (Z-A)',
              value: { order: 'DESC', orderField: 'email' },
            },
          ]}
          setValue={(option) => {
            setOrder(option.value.order)
            setOrderField(option.value.orderField)
          }}
        />
        <FilterBar.TermSearch setValue={setTerm} placeholder='Search...' />
      </FilterBar>

      {isLoading || !data ? (
        <PageLoader />
      ) : (
        <CustomTable
          loading={isLoading}
          maxPage={data.totalPages}
          currentPage={searchParams.page}
          setPage={setPage}
          pageSize={searchParams.size}
          data={data.items}
          totalCount={data.count}
          onRowClick={({ id }) => {
            history.push(`/customers/view/${id}`)
          }}
          columns={[
            {
              Header: 'Vehicle',
              accessor: 'location',
              cell: (l) => {
                if (l.owner?.vehicles.length === 0) {
                  return <TableCell.Text value={'(No vehicles found!)'} />
                }
                return l.owner?.vehicles.map((v) => (
                  <TableCell.Text value={v.registration} />
                ))
              },
            },
            {
              Header: 'Name',
              accessor: 'id',
              cell: (l) => <TableCell.Text value={l.owner?.name} />,
            },
            {
              Header: 'Email',
              accessor: 'owner',
              cell: (l) => <TableCell.Text value={l.owner?.email} />,
            },
            {
              Header: 'Type',
              accessor: 'paymentType',
              cell: (l) => <TableCell.Text value={l.paymentType} />,
            },
          ]}
        />
      )}
    </ContentContainer>
  )
}
