import ClipLoader from 'react-spinners/ClipLoader'

type LoaderProps = {
  color?: string
}

export const Loader = (props: LoaderProps) => {
  const { color = '#ffffff' } = props
  return <ClipLoader color={color} size={14} />
}
