/** @jsxImportSource @emotion/react */
import 'twin.macro'

export const Divider = (props: {
  type?: 'horizontal' | 'vertical'
  height?: number
  width?: number
  className?: string | undefined
}) => {
  const type = props.type ?? 'horizontal'
  const styles: any = {
    padding: 0,
    margin: 0,
    backgroundColor: '#E5E7EB',
  }
  if (type === 'vertical') {
    styles.height = props.height ?? '100%'
    styles.width = props.width ?? '1px'
  } else {
    styles.width = props.width ?? '100%'
    styles.height = props.height ?? '1px'
  }

  return (
    <div className={props.className}>
      <div style={styles} />
    </div>
  )
}
