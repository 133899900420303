import 'twin.macro'

/** @jsxImportSource @emotion/react */
import * as outline from '@heroicons/react/outline'
import * as solid from '@heroicons/react/solid'

import { Link, useLocation } from 'react-router-dom'

import { Divider } from './Divider'
import Drawer from 'rc-drawer'
import { MarexTextLogo } from './assets'
import { Txt } from './Txt'
import { isMobile } from '../../../utils'
import tw from 'twin.macro'
import { useLoggedInUser } from './hooks/useUser'
import { useQueryClient } from 'react-query'
import { useState } from 'react'
import { superadminRoutes } from './route-names'
import { adminRoutes } from './route-names'
import { UserRole } from '../../../api-types'

const superAdminNavigationElements = [
  {
    url: superadminRoutes.LOCATIONS,
    icons: [outline.LocationMarkerIcon, solid.LocationMarkerIcon],
    label: 'Locations',
  },
  {
    url: superadminRoutes.ALARMS,
    icons: [outline.BellIcon, solid.BellIcon],
    label: 'Alarms',
  },
  {
    url: superadminRoutes.PEDESTALS,
    icons: [outline.ChipIcon, solid.ChipIcon],
    label: 'Pedestals',
  },
  {
    url: superadminRoutes.USERS,
    icons: [outline.UsersIcon, solid.UsersIcon],
    label: 'Users',
  },
]

const adminNavigationElements = [
  {
    url: adminRoutes.DASHBOARD,
    icons: [outline.HomeIcon, solid.HomeIcon],
    label: 'Dashboard',
  },
  {
    url: superadminRoutes.ALARMS,
    icons: [outline.BellIcon, solid.BellIcon],
    label: 'Alarms',
  },
  {
    url: adminRoutes.PEDESTALS,
    icons: [outline.ChipIcon, solid.ChipIcon],
    label: 'Pedestals',
  },
  {
    url: adminRoutes.CUSTOMERS,
    icons: [outline.UsersIcon, solid.UsersIcon],
    label: 'Customers',
  },
  {
    url: adminRoutes.SESSIONS,
    icons: [outline.ChartBarIcon, solid.ChartBarIcon],
    label: 'Sessions',
  },
  {
    url: adminRoutes.REPORTS,
    icons: [outline.DocumentReportIcon, solid.DocumentReportIcon],
    label: 'Reports',
  },
  {
    url: adminRoutes.SETTINGS,
    icons: [outline.CogIcon, solid.CogIcon],
    label: 'Settings',
  },
  {
    url: adminRoutes.ADMIN_TRANSACTIONS_TABLE,
    icons: [outline.CashIcon, solid.CashIcon],
    label: 'Transactions',
  },
]
const operatorNavigationElements = [
  {
    url: adminRoutes.DASHBOARD,
    icons: [outline.HomeIcon, solid.HomeIcon],
    label: 'Dashboard',
  },
  {
    url: superadminRoutes.ALARMS,
    icons: [outline.BellIcon, solid.BellIcon],
    label: 'Alarms',
  },
  {
    url: adminRoutes.PEDESTALS,
    icons: [outline.ChipIcon, solid.ChipIcon],
    label: 'Pedestals',
  },
]

export const SidebarContent = (props: { onItemClick?(): void }) => {
  const user = useLoggedInUser()
  const { pathname } = useLocation()
  const path = pathname.endsWith('/') ? pathname : `${pathname}/`
  const client = useQueryClient()

  let items: any[] = []
  if (user.role === UserRole.SUPERADMIN) {
    items = superAdminNavigationElements
  }
  if (user.role === UserRole.ADMIN) {
    items = adminNavigationElements
  }
  if (user.role === UserRole.MARINA_OPERATOR) {
    items = operatorNavigationElements
  }

  const selectedRoute =
    items.find((i) => new RegExp(`^${i.url}/.*`).test(path)) ?? items[0]

  return (
    <div tw='flex flex-col w-256 min-width[256px] sticky top-0 bg-white h-screen border-gray-200 border-b border-r'>
      <MarexTextLogo tw='my-6 mx-2 px-3' />
      <div tw='flex-1 px-3'>
        {items.map((i) => {
          const isSelected = i.url === selectedRoute?.url
          const Icon = i.icons[isSelected ? 1 : 0]
          return (
            <Link
              to={i.url}
              key={i.url}
              css={[
                tw`flex flex-row align-items[center] space-x-4 py-2 cursor-pointer px-2 rounded-md`,
                isSelected && tw`bg-primary-50`,
              ]}
              onClick={() => props.onItemClick && props.onItemClick()}
            >
              <Icon width={24} color={isSelected ? '#39749B' : '#9CA3AF'} />
              <span tw='flex-1'>{i.label}</span>
            </Link>
          )
        })}
      </div>

      <div tw='flex flex-col p-4 border-t'>
        <Divider type='vertical' />
        <Txt md tw='text-gray-700'>
          {user.name}
        </Txt>
        <Txt md tw='text-gray-700 capitalize'>
          {user.role.toLowerCase()}
        </Txt>
        <div>
          <Txt tw='text-gray-500 font-medium' xs>
            <span
              onClick={() => {
                window.sessionStorage.clear()
                window.localStorage.clear()
                client.invalidateQueries()
              }}
              tw='hover:bg-primary-200 cursor-pointer rounded-full p-[3px] '
            >
              Log out
            </span>
          </Txt>
        </div>
      </div>
    </div>
  )
}

export const Sidebar = () => {
  const [open, setOpen] = useState(false)
  if (!isMobile) {
    return <SidebarContent />
  }
  return (
    <>
      <div>
        <solid.MenuIcon
          onClick={() => setOpen(true)}
          color='#6B7280'
          tw='h-8 w-12 ml-4 mt-4 mb-4 cursor-pointer'
        />
      </div>

      <Drawer
        width={`256px`}
        open={open}
        maskClosable
        handler={false}
        onHandleClick={() => setOpen(false)}
        duration={(0.3).toString()}
        placement='left'
        onClose={() => setOpen(false)}
      >
        <SidebarContent onItemClick={() => setOpen(false)} />
      </Drawer>
    </>
  )
}
