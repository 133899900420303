import 'twin.macro'

import * as yup from 'yup'

import { FormProvider, useForm } from 'react-hook-form'
import React, { useCallback } from 'react'

import { Button } from '../general/Button'
import { ErrorText } from '../general/ErrorText'
/** @jsxImportSource @emotion/react */
import { LockClosedIcon } from '@heroicons/react/solid'
import { SimpleInputField } from '../general/fields/SimpleInputField'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../general/notification'
import { yupResolver } from '@hookform/resolvers/yup'
import { http } from '../../../http'
import { useHistory, useParams } from 'react-router-dom'
import { publicRoutes } from '../general/route-names'

interface IResetPasswordForm {
  password: string
  passwordConfirm: string
}

const schema = yup.object().shape({
  password: yup.string().min(10).required('password is required'),
  passwordConfirm: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
})

export const ResetPasswordForm: React.FC = () => {
  const methods = useForm<IResetPasswordForm>({
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const history = useHistory()
  const { passwordResetToken } = useParams<{ passwordResetToken: string }>()

  const onSubmit = useCallback(
    methods.handleSubmit(async (data) => {
      try {
        await http.resetPassword({
          password: data.password,
          passwordResetToken,
        })
        showSuccessNotification('Password successfully updated!')
        history.push(publicRoutes.LOGIN)
      } catch (e) {
        console.log(e)
        showErrorNotification('Password reset link has expired.')
      }
    }),
    [],
  )
  const errors = Object.values(methods.formState.errors)

  return (
    <form onSubmit={onSubmit} noValidate tw='w-full'>
      <FormProvider {...methods}>
        <div>
          <SimpleInputField
            name='password'
            placeholder='Password'
            type='password'
            tw='rounded-br-none rounded-bl-none border-b-0'
          />
          <SimpleInputField
            name='passwordConfirm'
            placeholder='Confirm password'
            type='password'
            tw='rounded-br-none rounded-bl-none border-b-0'
          />
        </div>
        <div tw='flex flex-col space-y-1 mb-5 mt-2'>
          {errors.map((e) => (
            <ErrorText key={e.message}>{e.message}</ErrorText>
          ))}
        </div>

        <Button
          text='Set password'
          type='submit'
          icon={LockClosedIcon}
          full
          primary
          onClick={() => {}}
        />
      </FormProvider>
    </form>
  )
}
