import 'twin.macro'

import { Button } from '../../general/Button'
import { Card } from '../../general/Card'
import { Chip } from '../../general/Chip'
import { ILocationForm } from '../../../superadmin/location-new/SuperadminNewLocationPage'
import { PedestalTypeDrawer } from './pedestal-type-drawer/PedestalTypeDrawer'
import { Txt } from '../../general/Txt'
import { useFormContext } from 'react-hook-form'
/** @jsxImportSource @emotion/react */
import { useState } from 'react'

export const SuperadminLocationFormPedestalTypesSection = () => {
  const [open, setOpen] = useState(false)
  const [selectedTypeIndex, setSelectedTypeIndexIndex] = useState<
    number | undefined
  >(undefined)
  const { setValue, watch } = useFormContext<ILocationForm>()
  const pedestalTypes = watch<'pedestalTypes'>('pedestalTypes')
  return (
    <Card tw='mb-6'>
      <div tw='flex flex-row'>
        <Txt xl tw='mb-1 flex-grow'>
          Pedestal types
        </Txt>
        <Button
          text='New type'
          light
          onClick={() => {
            setOpen(true)
            setSelectedTypeIndexIndex(undefined)
          }}
        />
      </div>
      <Txt sm tw='text-gray-500'>
        Add pedestal types which will be present at this location.
      </Txt>
      {pedestalTypes.length > 0 && (
        <div tw='flex flex-col space-y-3 mt-6'>
          {pedestalTypes.map((p, i) => {
            return (
              <div key={i} tw='flex flex-row justify-between items-center'>
                <div tw='flex flex-col flex-grow'>
                  <Txt md>{p.name}</Txt>
                  <Txt sm tw='text-gray-500'>
                    {p.configuration.currents.length} Power,{' '}
                    {p.configuration.waters.length} water
                  </Txt>
                </div>
                <Chip
                  value='Edit'
                  tw='cursor-pointer'
                  white
                  onClick={() => {
                    setSelectedTypeIndexIndex(i)
                    setOpen(true)
                  }}
                />
              </div>
            )
          })}
        </div>
      )}
      <PedestalTypeDrawer
        open={open}
        isEdit={selectedTypeIndex != undefined}
        initialValues={
          selectedTypeIndex !== undefined
            ? pedestalTypes[selectedTypeIndex]
            : undefined
        }
        setOpen={setOpen}
        onSubmit={(p) => {
          if (selectedTypeIndex != undefined) {
            const newTypes = [...pedestalTypes]
            newTypes[selectedTypeIndex] = p
            setValue('pedestalTypes', newTypes)
          } else {
            setValue('pedestalTypes', [...pedestalTypes, p])
          }
        }}
      />
    </Card>
  )
}
