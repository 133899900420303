/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ContentContainer } from '../../common/general/ContentContainer'
import { adminRoutes } from '../../common/general/route-names'
import { HeartbeatBadge } from '../../common/pedestal-view/HeartbeatBadge'
import { Breadcrumbs } from '../../common/general/Breadcrumbs'
import { Txt } from '../../common/general/Txt'
import { Tabs } from '../../common/general/Tabs'
import { TemplateIcon, UserGroupIcon } from '@heroicons/react/solid'
import { useHistory, useParams } from 'react-router-dom'
import { AdminPedestalStatuses } from './AdminPedestalStatuses'
import { useAdminPedestal } from '../../../queries'
import { PedestalLogs } from '../../common/pedestal-view/logs/PedestalLogs'
import { PedestalSessions } from '../../common/pedestal/sessions/PedestalSessions'
import { heartbeatService } from '../../common/general/heartbeat-service'
import { PageLoader } from '../../common/general/PageLoader'

export const AdminPedestalViewPage = () => {
  const history = useHistory()
  const { identifier } = useParams<{ identifier: string }>()
  const {
    data: pedestal,
    isLoading: isLoadingPedestal,
    isError: isErrorPedestal,
  } = useAdminPedestal(identifier)

  if (isErrorPedestal) {
    throw new Error()
  }

  if (isLoadingPedestal || !pedestal) {
    return <PageLoader />
  }

  const heartbeat = heartbeatService.getHeartbeatStable(
    pedestal.lastHartbeatReceivedOn,
    pedestal?.plcConstants?.thart ?? pedestal?.location?.plcConstants?.thart,
  )

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-4'
        links={[
          { name: 'Pedestals', route: adminRoutes.PEDESTALS },
          { name: identifier },
        ]}
      />
      <div tw='flex '>
        <div>
          <Txt xxl tw='mb-2'>
            {identifier.toUpperCase()}
          </Txt>
          <div tw='flex flex-row mb-6 items-center'>
            <HeartbeatBadge heartbeatStable={heartbeat} />
            <TemplateIcon tw='h-5 w-5 mr-2 ml-6' color='#9CA3AF' />
            <Txt tw='text-gray-500'>{pedestal.pedestalType?.name || ''}</Txt>
            {pedestal.charter.length > 0 &&
              pedestal.charter.map((c) => (
                <div
                  tw='flex flex-row items-center ml-8 hover: cursor-pointer'
                  onClick={() =>
                    history.push({
                      pathname: '/customers',
                      search: `search="${c.owner.name}"`,
                    })
                  }
                >
                  <UserGroupIcon tw='h-5 w-5 mr-2' color='#1D5883' />
                  <Txt tw='text-primary-600'>{c.owner.name}</Txt>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Tabs
        items={[
          {
            title: 'Statuses',
            content: <AdminPedestalStatuses pedestal={pedestal} />,
          },
          {
            title: 'Logs',
            content: <PedestalLogs pedestal={pedestal} />,
          },
          {
            title: 'Sessions',
            content: <PedestalSessions pedestal={pedestal} />,
          },
        ]}
      />
    </ContentContainer>
  )
}
