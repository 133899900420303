import { Txt } from './Txt'
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro'

interface IStepperProps {
  steps: string[]
  activeStep: number
  className?: string | undefined
}

export const Stepper = (props: IStepperProps) => {
  return (
    <div className={props.className} tw='flex flex-row justify-start space-x-8'>
      {props.steps.map((step, index) => {
        const isActive = index <= props.activeStep
        return (
          <div key={step} tw='flex flex-col flex-grow'>
            <div
              css={[
                tw`h-1 flex-grow bg-gray-200 mb-4`,
                isActive && tw`bg-primary-600`,
              ]}
            />
            <Txt xs tw='text-primary-600'>{`STEP ${index + 1}`}</Txt>
            <Txt md>{step}</Txt>
          </div>
        )
      })}
    </div>
  )
}
