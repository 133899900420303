import React from 'react'
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import { Txt } from '../Txt'

import 'twin.macro'
/** @jsxImportSource @emotion/react */

interface ISuccessAlertProps {
  text: string
  onClose: () => void
}

export const SuccessAlert = (props: ISuccessAlertProps) => {
  const { onClose, text } = props
  return (
    <div tw='p-4 rounded-md bg-green-50 mt-6 flex items-center'>
      <CheckCircleIcon tw='text-green-400 w-6 h-6 mr-3' />
      <Txt tw='text-green-800'>{text}</Txt>
      <XIcon
        tw='text-green-500 w-6 h-6 ml-auto cursor-pointer'
        onClick={onClose}
      />
    </div>
  )
}
