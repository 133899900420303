/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { CustomTable } from '../../common/general/table/CustomTable'
import { ISearchParams } from '../../../types'
import { TableCell } from '../../common/general/table/cells'
import { useSearch } from '../../common/general/hooks/useSearch'
import { useAdminAccountReceipts } from '../../../queries'
import { Account } from '../../../api-types'
import { useHistory } from 'react-router-dom'
import { adminRoutes } from '../../common/general/route-names'
import { PageLoader } from '../../common/general/PageLoader'
import {
  convertEURToHRK,
  roundToFloatWithExactlyNDecimals,
  standardizedFormatDate,
} from '../../../utils'

interface ICustomerTransactionListProps {
  customer: Account
}

export const CustomerTransactionList = (
  props: ICustomerTransactionListProps,
) => {
  const { customer } = props
  const history = useHistory()
  const initialSearchParams: Partial<ISearchParams> = {
    orderField: 'createdAt',
    order: 'DESC',
  }
  const [searchParams, { setPage }] = useSearch(initialSearchParams)
  const { data, isLoading } = useAdminAccountReceipts(
    customer.id || -1,
    searchParams,
  )

  if (isLoading || !data) {
    return <PageLoader />
  }

  return (
    <div tw='mt-4'>
      <CustomTable
        loading={isLoading}
        maxPage={data.totalPages}
        currentPage={searchParams.page}
        setPage={setPage}
        pageSize={searchParams.size}
        data={data.items}
        totalCount={data.count}
        onRowClick={(data) =>
          history.push(`${adminRoutes.VIEW_TRANSACTION}/${data.transactionId}`)
        }
        columns={[
          {
            Header: 'Status',
            accessor: 'status',
            cell: (l) => (
              <TableCell.Chip
                value={`${l.paidAt ? 'PAID' : 'UNPAID'}`}
                red={l.paidAt === null}
                green={l.paidAt !== null}
              />
            ),
          },
          {
            Header: 'Payment date',
            accessor: 'paidAt',
            cell: (l) => (
              <TableCell.Text
                value={l.paidAt ? standardizedFormatDate(l.paidAt) : '-'}
              />
            ),
          },
          {
            Header: 'Created date',
            accessor: 'createdAt',
            cell: (l) => (
              <TableCell.Text
                value={l.createdAt ? standardizedFormatDate(l.createdAt) : '-'}
              />
            ),
          },
          {
            Header: 'Period',
            accessor: 'periodStartDate',
            cell: (l) => (
              <TableCell.Text
                value={`${standardizedFormatDate(
                  l.periodStartDate,
                )} - ${standardizedFormatDate(l.periodEndDate)}`}
              />
            ),
          },
          {
            Header: 'Total price',
            accessor: 'items',
            cell: (l) => {
              const total = l.items
                ? roundToFloatWithExactlyNDecimals(
                    l.items.reduce(
                      (sum, item) => sum + item.consumption * item.tariff,
                      0,
                    ),
                    2,
                  )
                : 0
              return (
                <TableCell.Text
                  value={`${total} EUR (${convertEURToHRK(
                    parseFloat(total.toString()),
                  )} HRK)`}
                />
              )
            },
          },
        ]}
      />
    </div>
  )
}
