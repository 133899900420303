import cleanDeep from 'clean-deep'
import qs from 'qs'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export const useSearchQueryParams = <TParams>(): [
  TParams,
  (newParams: TParams) => void,
] => {
  const { search } = useLocation()
  const history = useHistory()

  const params = useMemo<TParams>(() => {
    const paramsElements = qs.parse(search, {
      ignoreQueryPrefix: true,
    })

    return Object.fromEntries(
      Object.keys(paramsElements).map((key) => {
        const value = paramsElements[key] as string
        if (Number(value)) {
          return [key, parseInt(value)]
        } else if (isDate(value)) {
          return [key, new Date(value)]
        } else {
          return [key, value]
        }
      }),
    )
  }, [search])

  const setParams = useCallback((newParams: TParams) => {
    const newQueryString = qs.stringify(cleanDeep(newParams) ?? {}, {
      addQueryPrefix: true,
    })

    history.replace({ search: newQueryString })
  }, [])

  return [params, setParams]
}

// * Helper functions

const isDate = (str: string): boolean => {
  const parsedDate = Date.parse(str)
  return !isNaN(parsedDate) && str.trim() !== '' && !/^\d+$/.test(str)
}

const isNumber = (str: string): boolean => {
  return parseInt(str) ? true : false
}
