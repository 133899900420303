/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { ContentContainer } from '../../common/general/ContentContainer'
import { ActionBar } from '../../common/general/ActionBar'
import { CustomTable } from '../../common/general/table/CustomTable'
import { FilterBar } from '../../common/general/FilterBar'
import { ISearchParams } from '../../../types'
import { TableCell } from '../../common/general/table/cells'
import { useHistory } from 'react-router-dom'
import {
  useSearch,
  infiniteSearchParams,
} from '../../common/general/hooks/useSearch'
import { useState } from 'react'
import { adminRoutes } from '../../common/general/route-names'
import { useAdminPedestals } from '../../../queries'
import { Button } from '../../common/general/Button'
import { ViewListIcon } from '@heroicons/react/outline'
import { heartbeatService } from '../../common/general/heartbeat-service'
import { PageLoader } from '../../common/general/PageLoader'
import { SourceStatusEnum } from '../../../api-types'

export const AdminPedestalsPage = () => {
  const history = useHistory()

  const initialSearchParams: Partial<ISearchParams> = {
    term: '',
    orderField: 'id',
    order: 'ASC',
  }
  const initialFilters = {}

  const [filters] = useState(initialFilters)
  const [expandList, setExpandList] = useState(false)
  const [searchParams, { setPage, setTerm }] = useSearch(
    expandList ? infiniteSearchParams : initialSearchParams,
  )
  const { data, isLoading } = useAdminPedestals({
    ...searchParams,
    ...filters,
  })

  const viewPedestal = (identifier: string) => {
    history.push(`${adminRoutes.VIEW_PEDESTAL}/${identifier}`)
  }

  const handleExpandList = () => {
    setPage(1)
    setExpandList(!expandList)
  }

  return (
    <ContentContainer>
      <ActionBar title='Pedestals' tw='mb-5'></ActionBar>
      <FilterBar tw='mb-4'>
        <FilterBar.TermSearch setValue={setTerm} placeholder='Search...' />
        <Button
          text={expandList ? 'Reduce list' : 'Expand list'}
          onClick={handleExpandList}
          icon={ViewListIcon}
          cancel
        />
      </FilterBar>
      {isLoading || !data ? (
        <PageLoader />
      ) : (
        <CustomTable
          loading={isLoading}
          maxPage={data.totalPages}
          currentPage={searchParams.page}
          setPage={setPage}
          pageSize={searchParams.size}
          data={data.items}
          totalCount={data.count}
          onRowClick={(l) => viewPedestal(l.identifier)}
          columns={[
            {
              Header: 'ID',
              accessor: 'identifier',
              cell: (l) => (
                <TableCell.Text value={l.identifier.toUpperCase()} />
              ),
            },
            {
              Header: 'Status',
              accessor: 'status',
              cell: (l) => {
                const heartbeat = heartbeatService.getHeartbeatStable(
                  l.lastHartbeatReceivedOn,
                  l.plcConstants?.thart ?? l.location?.plcConstants?.thart,
                )
                if (!heartbeat) {
                  return <TableCell.Chip value={'Offline'} red />
                }
                let tableChip
                if (l.socketsManualModeOn && l.valvesManualModeOn) {
                  tableChip = <TableCell.Chip value={'Manual mode'} orange />
                } else if (l.socketsManualModeOn) {
                  tableChip = (
                    <TableCell.Chip value={'Sockets manual mode'} orange />
                  )
                } else if (l.valvesManualModeOn) {
                  tableChip = (
                    <TableCell.Chip value={'Valves manual mode'} orange />
                  )
                } else {
                  l.sources?.forEach((source) => {
                    if (source.status === SourceStatusEnum.OUT_OF_SERVICE) {
                      return (tableChip = (
                        <TableCell.Chip
                          value={'Modbus or fuse down'}
                          tw='bg-[#9e80ff99]'
                        />
                      ))
                    }
                    if (source.status === SourceStatusEnum.IN_USE) {
                      return (tableChip = (
                        <TableCell.Chip value={'In use'} blue />
                      ))
                    }
                    if (source.status === SourceStatusEnum.PLUGGED_IN) {
                      return (tableChip = (
                        <TableCell.Chip value={'Plugged in'} purple />
                      ))
                    }
                  })
                }
                return tableChip ?? <TableCell.Chip value={'Available'} green />
              },
            },
            {
              Header: 'Alarms',
              accessor: 'unresolvedAlarms',
              cell: (l) => {
                const hasUnresolvedAlarms = !!l.unresolvedAlarms.length
                const numberOfAlarms = l.unresolvedAlarms.length
                return (
                  <TableCell.Chip
                    value={
                      hasUnresolvedAlarms
                        ? `Active alarms: ${numberOfAlarms}`
                        : 'No alarms'
                    }
                    red={hasUnresolvedAlarms}
                    green={!hasUnresolvedAlarms}
                  />
                )
              },
            },
            {
              Header: 'Charter',
              accessor: 'charter',
              cell: (l) =>
                l.charter.length ? (
                  l.charter.map((c) => <TableCell.Text value={c.owner.name} />)
                ) : (
                  <TableCell.Text value={'Not assigned'} />
                ),
            },
          ]}
        />
      )}
    </ContentContainer>
  )
}
