import React from 'react'
/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { Card } from '../general/Card'
import { InputField } from '../general/fields/InputField'
import { Txt } from '../general/Txt'

export const SuperadminLocationFormConstantsSection = () => {
  return (
    <Card tw='mb-6'>
      <Txt xl tw='mb-1'>
        Lighting and heating configuration
      </Txt>
      <Txt sm tw='mb-6 text-gray-500'>
        All of the following fields are required.
      </Txt>
      <div tw='flex flex-col space-y-3'>
        <InputField
          name='constants.minLightRequests'
          label='Minimum requests for turning on the lights'
        />
        <div tw='flex  flex-col md:flex-row md:space-x-6'>
          <InputField
            tw='flex-grow'
            name='constants.turnOnLightsTime'
            label='Turning on the lights time'
          />
          <InputField
            tw='flex-grow'
            name='constants.turnOffLightsTime'
            label='Turning off the lights time'
          />
        </div>
        <InputField
          name='constants.minHeatRequests'
          label='Minimum requests for turning on the heating'
        />
      </div>
    </Card>
  )
}
