/** @jsxImportSource @emotion/react */
import 'twin.macro'

import { LoginForm } from './LoginForm'
import { MarexLogo } from '../general/assets'
import { Txt } from '../general/Txt'

export const LoginPage = () => {
  return (
    <div tw='m-auto  h-screen max-w-448 w-full p-8 flex flex-col justify-center items-center'>
      <MarexLogo tw='mb-6 w-16 h-16' />
      <Txt xxxl tw='mb-2 text-center'>
        Sign in to your account
      </Txt>
      <Txt sm tw='mb-9 text-gray-600 text-center'>
        Get started here.
      </Txt>
      <LoginForm />
    </div>
  )
}
