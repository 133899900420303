import 'twin.macro'
import { useParams } from 'react-router-dom'

import { useSuperAdminPedestal } from '../../../queries'
import { SuperadminPedestalInstallation } from './SuperadminPedestalInstallation'

/** @jsxImportSource @emotion/react */

export const SuperadminPedestalInstallationPage = () => {
  const { identifier } = useParams<{ identifier: string }>()

  const { data: pedestal, isLoading: pedestalLoading } =
    useSuperAdminPedestal(identifier)

  if (!pedestal || pedestalLoading) {
    return null
  }

  return <SuperadminPedestalInstallation pedestal={pedestal} />
}
