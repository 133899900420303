/** @jsxImportSource @emotion/react */
import 'twin.macro'

import { ResetPasswordForm } from './ResetPasswordForm'
import { MarexLogo } from '../general/assets'
import { Txt } from '../general/Txt'

export const ResetPasswordPage = () => {
  return (
    <div tw='m-auto  h-screen max-w-448 w-full p-8 flex flex-col justify-center items-center'>
      <MarexLogo tw='mb-6 w-16 h-16 ' />
      <Txt xxxl tw='mb-2 text-center'>
        Reset your password
      </Txt>
      <ResetPasswordForm />
    </div>
  )
}
