import 'twin.macro'

import * as yup from 'yup'

import { FormProvider, useForm } from 'react-hook-form'
import React, { useCallback } from 'react'

import { Button } from '../general/Button'
import { ErrorText } from '../general/ErrorText'
/** @jsxImportSource @emotion/react */
import { MailIcon } from '@heroicons/react/solid'
import { SimpleInputField } from '../general/fields/SimpleInputField'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../general/notification'
import { yupResolver } from '@hookform/resolvers/yup'
import { http } from '../../../http'
import { useHistory } from 'react-router-dom'
import { publicRoutes } from '../general/route-names'

interface IForgotPasswordForm {
  email: string
}

const schema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
})

export const ForgotPasswordForm: React.FC = () => {
  const methods = useForm<IForgotPasswordForm>({
    defaultValues: {
      email: '',
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const history = useHistory()

  const onSubmit = useCallback(
    methods.handleSubmit(async (data) => {
      try {
        await http.forgotPassword(data)
        showSuccessNotification(
          'Password reset link sent. Please check your email inbox.',
        )
        history.push(publicRoutes.LOGIN)
      } catch (e) {
        console.log(e)
        showErrorNotification('Something went wrong')
      }
    }),
    [],
  )
  const errors = Object.values(methods.formState.errors)

  return (
    <form onSubmit={onSubmit} noValidate tw='w-full'>
      <FormProvider {...methods}>
        <div>
          <SimpleInputField name='email' placeholder='Email' type='email' />
        </div>
        <div tw='flex flex-col space-y-1 mb-5 mt-2'>
          {errors.map((e) => (
            <ErrorText key={e.message}>{e.message}</ErrorText>
          ))}
        </div>

        <Button
          text='Send me an email'
          type='submit'
          icon={MailIcon}
          full
          primary
          onClick={() => {}}
        />
      </FormProvider>
    </form>
  )
}
