/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useParams } from 'react-router-dom'
import { useSuperAdminPedestal } from '../../../queries'
import { SuperadminPedestalTest } from './SuperadminPedestalTest'

export const SuperadminPedestalTestPage = () => {
  const { identifier } = useParams<{ identifier: string }>()

  const { data: pedestal, isLoading: pedestalLoading } =
    useSuperAdminPedestal(identifier)

  if (!pedestal || pedestalLoading) {
    return null
  }

  return <SuperadminPedestalTest pedestal={pedestal} />
}
