import { Maybe } from '../../../../utils'
/** @jsxImportSource @emotion/react */
import React from 'react'
import { User } from '../../../../api-types'
import { useCurrentUser } from '../../../../queries'

export const Context = React.createContext<Maybe<User> | undefined>(undefined)

export const useLoggedInUser = () => {
  return React.useContext(Context)!
}

export const UserContext = (props: any) => {
  const { data, isLoading } = useCurrentUser()

  if (isLoading) {
    return null
  }

  return <Context.Provider value={data}>{props.children}</Context.Provider>
}
