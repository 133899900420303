/** @jsxImportSource @emotion/react */
import 'twin.macro'
import React, { useMemo } from 'react'
import { ContentContainer } from '../../common/general/ContentContainer'
import {
  useAdminAccountReceipt,
  useUpdateReceiptStatus,
} from '../../../queries'
import { Txt } from '../../common/general/Txt'
import { Link, useParams } from 'react-router-dom'
import { uniq } from 'lodash'
import { TableCell } from '../../common/general/table/cells'
import { ActionBar } from '../../common/general/ActionBar'
import { Button } from '../../common/general/Button'
import { CustomTable } from '../../common/general/table/CustomTable'
import { PageLoader } from '../../common/general/PageLoader'
import { CurrencyEuroIcon } from '@heroicons/react/outline'
import {
  convertEURToHRK,
  roundToFloatWithExactlyNDecimals,
  standardizedFormatDate,
} from '../../../utils'
import { adminRoutes } from '../../common/general/route-names'
import { Breadcrumbs } from '../../common/general/Breadcrumbs'
import {
  CheckCircleIcon,
  LocationMarkerIcon,
  XCircleIcon,
} from '@heroicons/react/solid'
import { CardWithHeader } from '../../common/general/CardWithHeader'
import {
  ReceiptStatus,
  SourceTypeEnum,
  VehicleTypeEnum,
  vehicleTypeLabels,
} from '../../../api-types'
import { showErrorNotification } from '../../common/general/notification'

export const CustomerTransactionDetails = () => {
  const { id } = useParams<{ id: string }>()

  const { data: receipt, isLoading } = useAdminAccountReceipt(id)
  const updateReceiptStatus = useUpdateReceiptStatus(id)

  const total = useMemo(() => {
    if (receipt && receipt.items)
      return receipt.items.reduce(
        (sum, item) => sum + item.consumption * item.tariff,
        0,
      )
  }, [receipt])

  if (isLoading || !receipt) {
    return <PageLoader />
  }

  const statusColor =
    receipt.status === ReceiptStatus.PAID ? { green: true } : { red: true }

  let vehicleRegistration: string | undefined
  let vehicleType: VehicleTypeEnum | undefined

  // if all sessions are related to the same vehicle
  if (
    uniq(receipt.items?.map((i) => i.vehicleRegistration) || []).length === 1
  ) {
    if (receipt.items) {
      vehicleRegistration = receipt?.items[0].vehicleRegistration
      vehicleType = receipt?.items[0].vehicleType as VehicleTypeEnum
    }
  }

  const changeReceiptStatus = async () => {
    try {
      await updateReceiptStatus()
    } catch (e) {
      showErrorNotification('Failed to change the receipt status.')
    }
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-2'
        links={[
          { name: 'Customers', route: adminRoutes.CUSTOMERS },
          {
            name: receipt.account.owner.name,
            route: `${adminRoutes.VIEW_CUSTOMER}/${receipt.account.id}`,
          },
          { name: 'Transaction details' },
        ]}
      />
      <ActionBar title='Transaction Details'>
        <Button
          primary
          icon={
            receipt.status === ReceiptStatus.UNPAID
              ? CheckCircleIcon
              : XCircleIcon
          }
          text={
            receipt.status === ReceiptStatus.UNPAID
              ? 'Marks As Paid'
              : 'Mark As Unpaid'
          }
          onClick={changeReceiptStatus}
        />
      </ActionBar>
      <div tw='flex flex-row mb-6 items-center'>
        <Link to={{}} tw='flex items-center'>
          <LocationMarkerIcon tw='h-5 w-5 mr-2' color='#9CA3AF' />
          <Txt tw='text-gray-500'>{receipt.account.location?.name}</Txt>
        </Link>
        <Link to={{}} tw='flex items-center'>
          <CurrencyEuroIcon tw='h-5 w-5 mr-2 ml-6' color='#9CA3AF' />
          <Txt tw='text-gray-500'>{receipt.transactionId}</Txt>
        </Link>
      </div>
      <CardWithHeader tw='mb-5'>
        <div tw='flex flex-wrap'>
          <div tw='w-full pb-8'>
            <Txt tw='text-gray-400 mb-2'>Amount</Txt>
            <Txt>
              {roundToFloatWithExactlyNDecimals(total ?? 0, 2)} EUR (
              {convertEURToHRK(total ?? 0)} HRK)
            </Txt>
          </div>
          <div tw='w-1/2 pb-8'>
            <Txt tw='text-gray-400 mb-2'>Created at</Txt>
            <Txt>{standardizedFormatDate(receipt.createdAt)}</Txt>
          </div>
          <div tw='w-1/2 pb-8'>
            <Txt tw='text-gray-400 mb-2'>Status</Txt>
            <TableCell.Chip
              value={receipt.status === ReceiptStatus.PAID ? 'Paid' : 'Unpaid'}
              {...statusColor}
            />
          </div>
          <div tw='w-1/2 pb-8'>
            <Txt tw='text-gray-400 mb-2'>Period start date</Txt>
            <Txt>{standardizedFormatDate(receipt.periodStartDate)}</Txt>
          </div>
          <div tw='w-1/2 pb-8'>
            <Txt tw='text-gray-400 mb-2'>Period end date</Txt>
            <Txt>{standardizedFormatDate(receipt.periodEndDate)}</Txt>
          </div>
          {receipt.status === ReceiptStatus.PAID && receipt.paidAt && (
            <div tw='w-1/2'>
              <Txt tw='text-gray-400 mb-2'>Payment date</Txt>
              <Txt>{standardizedFormatDate(receipt.paidAt)}</Txt>
            </div>
          )}
        </div>
      </CardWithHeader>
      <CardWithHeader title='Customer Details' tw='mb-5'>
        <div tw='flex flex-wrap'>
          <div tw='w-1/2 pb-8'>
            <Txt tw='text-gray-400 mb-2'>Full name</Txt>
            <Txt>{receipt.account?.owner.name}</Txt>
          </div>
          <div tw='w-1/2 pb-8'>
            <Txt tw='text-gray-400 mb-2'>Email address</Txt>
            <Txt>{receipt.account?.owner.email}</Txt>
          </div>
          {vehicleRegistration && vehicleType && (
            <>
              <div tw='w-1/2'>
                <Txt tw='text-gray-400 mb-2'>Vehicle type</Txt>
                <Txt>{vehicleTypeLabels[vehicleType]}</Txt>
              </div>
              <div tw='w-1/2'>
                <Txt tw='text-gray-400 mb-2'>Vehicle registration</Txt>
                <Txt>{vehicleRegistration}</Txt>
              </div>
            </>
          )}
        </div>
      </CardWithHeader>

      <CustomTable
        loading={isLoading}
        maxPage={1}
        currentPage={1}
        setPage={console.log}
        pageSize={receipt.items?.length || 0}
        data={receipt.items || []}
        totalCount={receipt.items?.length || 0}
        columns={[
          {
            Header: 'Service',
            accessor: 'service',
            cell: (l) => <TableCell.Text value={`${l.service}`} />,
          },
          {
            Header: 'Consumption',
            accessor: 'consumption',
            cell: (l) => (
              <TableCell.Text
                value={`${roundToFloatWithExactlyNDecimals(l.consumption, 3)} ${
                  l.sourceType === SourceTypeEnum.SOCKET ? 'kWh' : 'm\u00B3'
                }`}
              />
            ),
          },
          {
            Header: 'Start date',
            accessor: 'startDate',
            cell: (l) => (
              <TableCell.Text
                value={`${
                  l.startDate ? standardizedFormatDate(l.startDate) : '-'
                }`}
              />
            ),
          },
          {
            Header: 'Total',
            accessor: 'tariff',
            cell: (l) => {
              const total = roundToFloatWithExactlyNDecimals(
                l.consumption * l.tariff,
                2,
              )
              return (
                <TableCell.Text
                  value={`${total} EUR (${convertEURToHRK(
                    parseFloat(total),
                  )} HRK)`}
                />
              )
            },
          },
        ]}
      />
    </ContentContainer>
  )
}
