/** @jsxImportSource @emotion/react */
import 'twin.macro'
import React, { useState } from 'react'
import { ContentContainer } from '../../common/general/ContentContainer'
import { adminRoutes } from '../../common/general/route-names'
import { Breadcrumbs } from '../../common/general/Breadcrumbs'
import { Txt } from '../../common/general/Txt'
import { Tabs } from '../../common/general/Tabs'
import { useHistory, useParams } from 'react-router-dom'
import {
  useAdminClient,
  useDeleteCustomer,
  useUpdateCustomerPassword,
} from '../../../queries'
import { CustomerInfo } from './CustomerInfo'
import { CustomerSessionList } from './CustomerSessionList'
import { CustomerTransactionList } from './CustomerTransactionList'
import { CustomerOptionsDropdown } from './CustomerOptionsDropdown'
import { EditCustomerPasswordDrawer } from './EditCustomerPassword'
import { Button } from '../../common/general/Button'
import { CurrencyEuroIcon, PlusIcon } from '@heroicons/react/outline'
import AdminStartSessionForClientDrawer from './AdminStartSessionForClientDrawer'
import { PageLoader } from '../../common/general/PageLoader'
import { CreateTransactionDrawer } from './CreateTransactionDrawer'
import { RequestSessioStopStatusModal } from './SessionRequestStatusModal'
import { PaymentTypeEnum, SessionRequest } from '../../../api-types'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../common/general/notification'
import { http } from '../../../http'

export const CustomerView = () => {
  const { id } = useParams<{ id: string }>()
  const {
    data: customer,
    isLoading: isLoadingPedestal,
    isError: isErrorPedestal,
    refetch,
  } = useAdminClient(id)
  const history = useHistory()
  const updatePassword = useUpdateCustomerPassword()
  const deleteCustomer = useDeleteCustomer()
  const [passwordDrawerVisible, setPasswordDrawerVisible] =
    useState<boolean>(false)
  const [startSessionDrawerVisible, setStartSessionDrawerVisible] =
    useState<boolean>(false)
  const [createTransactionDrawerVisible, setCreateTransactionDrawerVisible] =
    useState<boolean>(false)
  const [sessionRequestStatusModalOpen, setSessionRequestStatusModalOpen] =
    useState<boolean>(false)
  const [SessionRequests, setSessionRequests] = useState<
    SessionRequest[] | undefined
  >(undefined)

  if (isErrorPedestal) {
    throw new Error()
  }

  if (isLoadingPedestal || !customer) {
    return <PageLoader />
  }

  return (
    <ContentContainer>
      <Breadcrumbs
        tw='mb-4'
        links={[
          { name: 'Customers', route: adminRoutes.CUSTOMERS },
          { name: customer.owner.name },
        ]}
      />
      <div tw='flex justify-between mb-2'>
        <div>
          <Txt xxl tw='mb-2'>
            {customer?.owner?.name || ''}
          </Txt>
        </div>
        <div tw='flex gap-x-2'>
          <Button
            text='Activate Session'
            icon={PlusIcon}
            onClick={() => setStartSessionDrawerVisible(true)}
          />
          {customer.paymentType !== PaymentTypeEnum.PREPAID && (
            <Button
              cancel
              icon={CurrencyEuroIcon}
              text='Create transaction'
              onClick={() => setCreateTransactionDrawerVisible(true)}
            />
          )}
          <CustomerOptionsDropdown
            onResetPassword={() => setPasswordDrawerVisible(true)}
            onDeactivateUser={async () => {
              if (
                !confirm(
                  `Are you sure you want to delete customer ${customer.owner.name} (${customer.owner.email})`,
                )
              ) {
                return
              }
              await deleteCustomer(customer.id || -1)
              history.push(adminRoutes.CUSTOMERS)
            }}
            onSendPasswordResetEmail={async () => {
              try {
                await http.resetCustomerPassword({ id: customer.owner.id })
                showSuccessNotification('Reset password email sent.')
              } catch (e) {
                showErrorNotification('Failed to send a reset password email.')
              }
            }}
          />
        </div>
      </div>
      <Tabs
        items={[
          {
            title: 'Info',
            content: <CustomerInfo refetch={refetch} customer={customer} />,
          },
          {
            title: 'Sessions',
            content: <CustomerSessionList customer={customer} />,
          },
          {
            title: 'Transactions',
            content: <CustomerTransactionList customer={customer} />,
          },
        ]}
      />
      <EditCustomerPasswordDrawer
        open={passwordDrawerVisible}
        close={() => setPasswordDrawerVisible(false)}
        onSubmit={async (data) => {
          await updatePassword({
            password: data.password,
            id: customer.owner.id || -1,
          })
          setPasswordDrawerVisible(false)
        }}
      />
      <AdminStartSessionForClientDrawer
        customer={customer}
        open={startSessionDrawerVisible}
        setOpen={setStartSessionDrawerVisible}
      />
      <CreateTransactionDrawer
        customer={customer}
        open={createTransactionDrawerVisible}
        close={() => setCreateTransactionDrawerVisible(false)}
        setSessionRequests={setSessionRequests}
        setSessionRequestStatusModalOpen={(val: boolean) =>
          setSessionRequestStatusModalOpen(val)
        }
      />
      <RequestSessioStopStatusModal
        isOpen={sessionRequestStatusModalOpen}
        onConfirm={() => {}}
        setIsOpen={(val) => {
          setSessionRequestStatusModalOpen(val)
        }}
        sessionRequests={SessionRequests}
        setSessionRequests={setSessionRequests}
      />
    </ContentContainer>
  )
}
