/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { useEffect } from 'react'
import * as yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Button } from './Button'
import { CustomDrawer } from './drawer/CustomDrawer'
import { Divider } from './Divider'
import { InputField } from './fields/InputField'
import { yupResolver } from '@hookform/resolvers/yup'
import { UserIcon } from '@heroicons/react/solid'
import { SelectField } from './fields/SelectField'
import { UserRole, userRoleLabels } from '../../../api-types'
import { useLocations } from '../../../queries'
import { infiniteSearchParams } from './hooks/useSearch'
import { Txt } from './Txt'
import { ErrorText } from './ErrorText'

interface IUserDrawer {
  open: boolean
  initialValues?: IUserForm
  isEdit: boolean
  showSuperAdminRole?: boolean
  //location is user only for superadmin, use this to disable for use in admin pages
  showWorkingLocationInput?: boolean
  setOpen(val: boolean): void
  onSubmit(val: IUserForm): void
  onDelete?(id: number): void
}

export interface IUserForm {
  id?: number
  name: string
  email: string
  username: string
  password?: string
  role?: UserRole
  workingLocation?: any
  emailEmptyConfirmation?: boolean
}

const createUserSchema = yup.object().shape(
  {
    id: yup.number().optional(),
    name: yup.string().required(),
    phoneNumber: yup.string().optional().nullable(),
    role: yup.string().nullable().optional(),
    emailEmptyConfirmation: yup.boolean().when('email', {
      is: (email: string) => !email,
      then: yup
        .boolean()
        .oneOf([true], 'Field must be checked or enter an email'),
      otherwise: yup
        .boolean()
        .oneOf([false], 'Field must be unchecked if email is entered'),
    }),
    email: yup
      .string()
      .email()
      .when('emailEmptyConfirmation', {
        is: (emailEmptyConfirmation: boolean) => !emailEmptyConfirmation,
        then: yup.string().required(),
        otherwise: yup.string().optional().nullable(),
      }),
    username: yup.string().when('emailEmptyConfirmation', {
      is: (emailEmptyConfirmation: boolean) => emailEmptyConfirmation,
      then: yup.string().required(),
      otherwise: yup.string().optional().nullable(),
    }),
    password: yup.string().when('emailEmptyConfirmation', {
      is: (emailEmptyConfirmation: boolean) => emailEmptyConfirmation,
      then: yup.string().required(),
      otherwise: yup.string().optional().nullable(),
    }),
  },
  [
    ['email', 'emailEmptyConfirmation'],
    ['emailEmptyConfirmation', 'username'],
    ['emailEmptyConfirmation', 'email'],
    ['emailEmptyConfirmation', 'password'],
  ],
)

const editUserSchema = yup.object().shape(
  {
    id: yup.number().optional(),
    name: yup.string().required(),
    phoneNumber: yup.string().optional().nullable(),
    role: yup.string().nullable().optional(),
    emailEmptyConfirmation: yup.boolean().when('email', {
      is: (email: string) => !email,
      then: yup
        .boolean()
        .oneOf([true], 'Field must be checked or enter an email'),
      otherwise: yup
        .boolean()
        .oneOf([false], 'Field must be unchecked if email is entered'),
    }),
    email: yup
      .string()
      .email()
      .when('emailEmptyConfirmation', {
        is: (emailEmptyConfirmation: boolean) => !emailEmptyConfirmation,
        then: yup.string().required(),
        otherwise: yup.string().optional().nullable(),
      }),
    username: yup.string().when('emailEmptyConfirmation', {
      is: (emailEmptyConfirmation: boolean) => emailEmptyConfirmation,
      then: yup.string().required(),
      otherwise: yup.string().optional().nullable(),
    }),
  },
  [
    ['email', 'emailEmptyConfirmation'],
    ['emailEmptyConfirmation', 'username'],
    ['emailEmptyConfirmation', 'email'],
  ],
)

export const UserDrawer = (props: IUserDrawer) => {
  const { showWorkingLocationInput = true } = props
  const defaultValues = {
    name: '',
    email: '',
    username: '',
    role: UserRole.SUPERADMIN,
    ...(props.initialValues ?? {}),
    workingLocation: props.initialValues?.workingLocation?.id,
    password: '',
  }
  const methods = useForm<IUserForm>({
    defaultValues: defaultValues,
    shouldUseNativeValidation: false,
    resolver: yupResolver(props.isEdit ? editUserSchema : createUserSchema),
    mode: 'onTouched',
  })
  const {
    formState: {
      errors: { emailEmptyConfirmation: emailEmptyConfirmationError },
    },
  } = methods

  useEffect(() => {
    methods.reset(defaultValues)
  }, [props.initialValues])

  const { data: locations } = useLocations(
    infiniteSearchParams,
    showWorkingLocationInput,
  )

  const onSubmit = methods.handleSubmit(async (data) => {
    props.setOpen(false)
    if (data.role === UserRole.SUPERADMIN) {
      delete data.workingLocation
    }
    props.onSubmit(data)
    methods.reset(defaultValues)
  })

  const deleteUser = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (
      !confirm(
        `Are you sure that you want to delete user ${props.initialValues?.name} (${props.initialValues?.email})`,
      )
    ) {
      return
    }
    if (props.initialValues?.id && props.onDelete) {
      props.setOpen(false)
      props.onDelete(props.initialValues.id)
      methods.reset(defaultValues)
    }
  }

  const role = methods.watch('role')
  const emailEmptyConfirmation = methods.watch('emailEmptyConfirmation')

  let roleOptions = [UserRole.ADMIN, UserRole.MARINA_OPERATOR]
  if (props.showSuperAdminRole) {
    roleOptions = [UserRole.SUPERADMIN, ...roleOptions]
  }

  useEffect(() => {
    methods.trigger(emailEmptyConfirmation ? 'email' : ['username', 'password'])
    if (!emailEmptyConfirmation) {
      methods.setValue('password', undefined)
    }
  }, [emailEmptyConfirmation])
  useEffect(() => {
    methods.trigger('emailEmptyConfirmation')
  }, [methods.watch('email')])

  return (
    <CustomDrawer
      open={props.open}
      title={props.isEdit ? 'Edit user' : 'New user'}
      subtitle='Users are location specific.'
      render={() => (
        <form onSubmit={onSubmit} tw='mt-8 space-y-6 flex-grow'>
          <FormProvider {...methods}>
            <div tw='flex h-full flex-col justify-between'>
              <div tw='m-6 h-full'>
                <InputField name='name' label='Name' />
                <InputField name='email' label='Email' type='email' />
                <Txt tw='mb-6'>
                  <label>
                    <input
                      {...methods.register('emailEmptyConfirmation')}
                      type='checkbox'
                    />
                    <span tw='ml-2'>Create user without email</span>
                  </label>
                  {emailEmptyConfirmationError &&
                    emailEmptyConfirmationError.message && (
                      <ErrorText tw='mt-2'>
                        {emailEmptyConfirmationError.message}
                      </ErrorText>
                    )}
                </Txt>
                <InputField
                  name='username'
                  label={`Username${
                    !emailEmptyConfirmation ? ' (optional)' : ''
                  }`}
                  type='text'
                />
                <InputField
                  name='password'
                  label='Password'
                  type='password'
                  placeholder={'*******'}
                />
                <SelectField
                  name='role'
                  label='Role'
                  options={roleOptions.map((key) => ({
                    label: userRoleLabels[key],
                    value: key,
                  }))}
                />
                {showWorkingLocationInput &&
                  locations.items &&
                  role &&
                  [UserRole.ADMIN, UserRole.MARINA_OPERATOR].includes(role) && (
                    <SelectField
                      label='Working location'
                      name='workingLocation'
                      options={locations.items.map((l) => ({
                        label: l.name,
                        value: l.id,
                      }))}
                    />
                  )}
                {props.isEdit && (
                  <div
                    tw='flex text-red-600 cursor-pointer items-center'
                    onClick={deleteUser}
                  >
                    <UserIcon tw='h-4 pr-2' />
                    <a href='#'>Delete user</a>
                  </div>
                )}
              </div>
              <div>
                <Divider type='horizontal' />
                <div tw='flex flex-row justify-end m-4'>
                  <Button
                    text={props.isEdit ? 'Edit' : 'Create'}
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
          </FormProvider>
        </form>
      )}
      close={() => {
        methods.reset()
        props.setOpen(false)
      }}
    />
  )
}
