/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { Button } from '../general/Button'
import { Card } from '../general/Card'
import { Chip } from '../general/Chip'
import { ILocationForm } from '../../superadmin/location-new/SuperadminNewLocationPage'
import { Txt } from '../general/Txt'
import { UserDrawer } from '../general/UserDrawer'
import { useFormContext } from 'react-hook-form'
import { useState } from 'react'
import { useDeleteUser } from '../../../queries'

export const SuperadminLocationFormUsersSection = () => {
  const [open, setOpen] = useState(false)
  const [selectedUserIndex, setSelectedUserIndex] = useState<
    number | undefined
  >(undefined)
  const { setValue, watch } = useFormContext<ILocationForm>()
  const users = watch<'users'>('users')
  const deleteUser = useDeleteUser()

  const onDeleteUser = async (id: number) => {
    const newUsers = users.filter((u) => u.id !== id)
    setValue('users', [...newUsers])
    await deleteUser(id)
  }

  return (
    <Card tw='mb-6'>
      <div tw='flex flex-row'>
        <Txt xl tw='mb-1 flex-grow'>
          Users
        </Txt>
        <Button
          text='New user'
          light
          onClick={() => {
            setOpen(true)
            setSelectedUserIndex(undefined)
          }}
        />
      </div>
      <Txt sm tw='text-gray-500'>
        Add users who will access the admin dashboard for this location.
      </Txt>
      {users.length > 0 && (
        <div tw='flex flex-col space-y-3 mt-6'>
          {users.map((u, i) => {
            return (
              <div key={i} tw='flex flex-row justify-between items-center'>
                <div tw='flex flex-col flex-grow'>
                  <Txt md>{u.name}</Txt>
                  <Txt sm tw='text-gray-500'>
                    {u.email}
                  </Txt>
                </div>
                <Chip
                  value='Edit'
                  tw='cursor-pointer'
                  white
                  onClick={() => {
                    setSelectedUserIndex(i)
                    setOpen(true)
                  }}
                />
              </div>
            )
          })}
        </div>
      )}
      <UserDrawer
        open={open}
        isEdit={selectedUserIndex != undefined}
        initialValues={
          selectedUserIndex !== undefined ? users[selectedUserIndex] : undefined
        }
        onDelete={onDeleteUser}
        setOpen={setOpen}
        onSubmit={(p) => {
          if (selectedUserIndex != undefined) {
            const newTypes = [...users]
            newTypes[selectedUserIndex] = p
            setValue('users', newTypes)
          } else {
            setValue('users', [...users, p])
          }
        }}
      />
    </Card>
  )
}
