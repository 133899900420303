/** @jsxImportSource @emotion/react */
import 'twin.macro'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import Select, { components } from 'react-select'
import { useController, useFormContext } from 'react-hook-form'
import { ErrorText } from '../ErrorText'
import { IDropdownOption } from '../../../../types'
import React from 'react'
import { Txt } from '../Txt'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ISelectField<T> {
  name: string
  options: IDropdownOption[]
  label?: string
  className?: string
  isDisabled?: boolean
  defaultValue?: IDropdownOption
  placeholder?: string
  isSearchable?: boolean
}

export const SelectField = <T extends Object>(props: ISelectField<T>) => {
  const {
    name,
    label,
    className,
    isDisabled = false,
    defaultValue,
    placeholder,
    isSearchable = false,
    options,
  } = props

  const { control } = useFormContext()
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const selectedOption = options.find((o) => o.value === field.value)

  return (
    <div className={className}>
      <div tw='mb-1'>
        <label tw='font-sans font-medium text-sm text-gray-700'>{label}</label>
      </div>
      <Select
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        value={selectedOption?.value}
        onChange={(option) => field.onChange(option.value)}
        placeholder={selectedOption ? '' : placeholder}
        options={options}
        formatOptionLabel={(o) => (
          <div tw='flex items-center'>
            <Txt sm tw='text-gray-700 flex-grow'>
              {o.label}
            </Txt>
            {o.value === field.value && (
              <CheckIcon tw='w-4 h-4 text-primary-600' />
            )}
          </div>
        )}
        styles={{
          placeholder: (base) => ({
            ...base,
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: 14,
            color: '#6B7280',
            position: 'absolute',
          }),
          control: (base) => ({
            ...base,
            border: error
              ? '1px #EF4444 solid !important'
              : '1.5px rgb(229, 231, 235) solid !important',
            borderRadius: '0.375rem !important',
            padding: '0.49rem 0.6rem !important',
            cursor: 'pointer',
            boxShadow: 'none',
          }),
          valueContainer: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          menuPortal: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            border: 'none',
            borderRadius: '0.375rem !important',
          }),
          menuList: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
            border: 'none',
            borderRadius: '0.375rem !important',
          }),
          menu: (base) => ({
            ...base,
            padding: 0,
            margin: '0.5rem 0rem 0rem 0rem',
            boxShadow: 'none',
            border: '1.5px rgb(229, 231, 235) solid !important',
            borderRadius: '0.375rem !important',
          }),

          option: (base) => ({
            ...base,
            backgroundColor: 'white',
            cursor: 'pointer',
            ':hover': {
              backgroundColor: '#E6F1F7',
            },
          }),
        }}
        components={{
          IndicatorSeparator: () => <div />,
          ValueContainer: ({ selectProps: { value }, children }) => {
            return (
              <div tw='flex flex-row items-center'>
                <Txt tw='absolute' sm>
                  {options.find((o) => o.value === value)?.label ??
                    defaultValue?.label}
                </Txt>
                {React.Children.map(children, (child) =>
                  // @ts-ignore
                  child && [components.SingleValue].indexOf(child.type) === -1
                    ? child
                    : null,
                )}
              </div>
            )
          },
          DropdownIndicator: () => (
            <SelectorIcon tw='h-4 w-4' color='#9CA3AF' />
          ),
        }}
      />
      <div tw='h-6'>
        {error && error.message && (
          <ErrorText tw='mt-2'>{error.message}</ErrorText>
        )}
      </div>
    </div>
  )
}
