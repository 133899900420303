/** @jsxImportSource @emotion/react */
import { forEach } from 'lodash'
import 'twin.macro'
import { SelectField } from '../../../general/non-form-fields/SelectField'

/**
 * @param label - label for the select field
 * @param filterKey - key for the filters object
 * @param options - options for the select field -> label: option name, value: option id
 * @param searchable - toggle option for enabling or disabling search in select field
 * @param linkedFilters - keys for the filters object that are linked to the select field and will be set to default when the select field is changed
 * @param disablesFilters - keys for the filters object that are linked to the select field and will be disabled when the select field is selected
 */
export interface FilterOption<TItem> {
  label: string
  filterKey: keyof TItem
  defaultValue?: string
  searchable?: boolean
  options: {
    label: string
    value: TItem[keyof TItem]
  }[]
  linkedFilters?: Array<keyof TItem>
  disablesFilters?: Array<keyof TItem>
}

interface ReportFilterHeaderProps<TItem> {
  filters: TItem
  setFilters: (filters: TItem) => void
  filtersOptions: Array<FilterOption<TItem>>
  onDownloadCsv: () => void
}

export const ReportFilterHeader = <
  TItem extends {
    [key: string]: any | undefined
  },
>(
  props: ReportFilterHeaderProps<TItem>,
) => {
  const { filters, setFilters, filtersOptions, onDownloadCsv } = props

  //#region Functions
  const onFilterChange = (
    filterOption: FilterOption<TItem>,
    newValue: TItem[keyof TItem],
  ) => {
    const newFilters: TItem = {
      ...filters,
      [filterOption.filterKey]:
        filters[filterOption.filterKey] === newValue ? undefined : newValue,
    }

    if (
      (filterOption.linkedFilters && filterOption.linkedFilters.length > 0) ||
      (filterOption.disablesFilters && filterOption.disablesFilters.length > 0)
    ) {
      forEach(
        [
          ...(filterOption.linkedFilters ?? []),
          ...(filterOption.disablesFilters ?? []),
        ],
        (linkedFilter) => {
          newFilters[linkedFilter] = undefined as TItem[keyof TItem]
        },
      )
    }

    setFilters(newFilters)
  }
  const isFilterDisabled = (filterOption: FilterOption<TItem>) => {
    return (
      filtersOptions.filter(
        (option) =>
          option.disablesFilters?.includes(filterOption.filterKey) &&
          filters[option.filterKey] !== undefined,
      ).length !== 0
    )
  }
  //#endregion

  return (
    <div tw='mx-6 flex flex-row items-center justify-between'>
      <div tw='flex flex-row items-center gap-3'>
        {filtersOptions.map(
          (filterOption, index) =>
            filterOption.options.length > 0 && (
              <SelectField
                isDisabled={isFilterDisabled(filterOption)}
                isSearchable={filterOption.searchable}
                key={index}
                label={filterOption.label}
                placeholder={filterOption.defaultValue ?? 'Select...'}
                selectedOption={
                  filters[filterOption.filterKey]
                    ? {
                        label:
                          filterOption.options.find(
                            (option) =>
                              option.value === filters[filterOption.filterKey],
                          )?.label ?? '',
                        value: filters[filterOption.filterKey],
                      }
                    : undefined
                }
                onChange={(newOption) =>
                  onFilterChange(filterOption, newOption.value)
                }
                options={filterOption.options.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
              />
            ),
        )}
      </div>
      <button
        tw='bg-primary-500 px-4 py-2 rounded-md text-white font-medium font-sans text-l'
        onClick={onDownloadCsv}
      >
        Export to CSV
      </button>
    </div>
  )
}
